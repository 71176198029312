import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { AsyncSubject, Observable, take } from 'rxjs';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GatekeeperService {
  ModernizedManageGroups: AsyncSubject<boolean>;
  EnablePW1FocusPanel: AsyncSubject<boolean>;
  constructor(private http: HttpClient, private location: Location) { 
    this.ModernizedManageGroups = new AsyncSubject<boolean>();
    this.EnablePW1FocusPanel = new AsyncSubject<boolean>();
    this.fetchModernizedManageGroupToggle();
    this.fetchEnablePW1FocusPanel();
  }

  fetchEnablePW1FocusPanel(){
    this.CallGateKeeper("EnablePW1FocusPanel", this.EnablePW1FocusPanel);
  }

  fetchModernizedManageGroupToggle(){
    this.CallGateKeeper("ModernizedManageGroups", this.ModernizedManageGroups);
  }

  public getModernizedManageGroupsToggle(): Observable<boolean>{
    return this.ModernizedManageGroups.asObservable();
  }

  public getEnablePW1FocusPanel(): Observable<boolean>{
    return this.EnablePW1FocusPanel.asObservable();
  }

  private normalizeUrl(url: string): string {
    return url.startsWith('http') ? url : this.location.prepareExternalUrl(url);
  }
  
  private CallGateKeeper(toggleName: string, AsyncSubject:AsyncSubject<boolean>)
  {
    const endpoint = this.normalizeUrl(environment.GATEKEEPER_PW2_API).replace("{{toggleid}}", toggleName);
    this.http.get(endpoint, {withCredentials: true}).pipe(take(1)).subscribe((data: boolean) => {
      AsyncSubject.next(data);
      AsyncSubject.complete();
    }, () => {
      AsyncSubject.next(false);
      AsyncSubject.complete();
    });
  }
}
