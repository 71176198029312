<div #content="pwContent" pwContent class="modernized-overview-wrapper">
  <div class="cardsRow">
    <div class="cardContainer">
      <asset-card
        [chartIdForCurrent]="'overviewCurrentAssetsChartId'"
        [chartIdForTarget]="'overviewTargetAssetsChartId'"
        class="main-page-card"
      ></asset-card>
    </div>
    <div class="cardContainer">
      <stock-card [chartId]="'overviewStockDonutChartId'" class="main-page-card"></stock-card>
    </div>
  </div>
  <div class="cardsRow c11n-space-stack-3x">
    <div class="cardContainer">
      <bond-card [chartId]="'overviewBondDonutChartId'" class="main-page-card"></bond-card>
    </div>
    <div class="cardContainer">
      <cost-card
        [industryChartId]="'overviewCostModernizedIndustryChartId'"
        [vanguardChartId]="'overviewCostModernizedVanguardChartId'"
        [yourCostsChartId]="'overviewCostModernizedYourCostsChartId'"
        class="main-page-card"
      ></cost-card>
    </div>
  </div>
  <PW1-investment-costs-disclaimer-accordion></PW1-investment-costs-disclaimer-accordion>
  <PW1-disclaimer
    contentTag="PWCosts_Disclaimer_Body"
    headerTag="PWCosts_Disclaimer_Header"
    linkTag="PWCosts_Disclaimer_Link_Vanguard"
  ></PW1-disclaimer>
</div>
