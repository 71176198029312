import { Component, OnInit } from '@angular/core';
import { PortfolioDataService } from '../../services/portfolio-data-service/portfolio-data.service';
import { LegendLine } from '../../models/chart-models/legend-line';
import { ActivatedRoute } from '@angular/router';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';

@Component({
  selector: 'PW1-costs-risk',
  templateUrl: './costs-risk.component.html',
  styleUrls: ['./costs-risk.component.scss'],
})
export class CostsRiskComponent implements OnInit {
  chartData: any;
  modernizedChartData: any;
  legendArray: Array<LegendLine>;
  hasLoadedChartData = false;

  constructor(
    private portfolioDataService: PortfolioDataService,
    private route: ActivatedRoute,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {}

  ngOnInit() {
    this.setUpChart();
    this.route.data.subscribe((data) => {
      this.adobeAnalyticsService.trackRoutingData(data);
    });
  }

  setUpChart() {
    this.portfolioDataService.getInvestmentCostsPayload().subscribe((data) => {
      this.modernizedChartData = data.modernizedInvestmentCostsChart;

      this.hasLoadedChartData = true;
    });
  }
}
