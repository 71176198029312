/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { ChartData } from '../../models/chart-models/chart-data';
import { AllChartsResponseData } from '../../models/chart-data-response-models/all-charts-response-data';
import { ChartColors } from '../../models/chart-models/chart-colors';
import { LegendLine } from '../../models/chart-models/legend-line';
import { PortfolioDataService } from '../portfolio-data-service/portfolio-data.service';
import { TargetAssetResponse } from '../../models/chart-data-response-models/target-asset-response';
import { ManagerRiskResponse } from '../../models/chart-data-response-models/managerRiskResponse';
import { Response } from '../../models/chart-data-response-models/response';
import { StockBondDomesticInternationalData } from '../../models/chart-data-response-models/stock-bond-domestic-international-data';
import { BondTaxData } from '../../models/chart-data-response-models/bond-tax-data';
import { ModernizedChartColors } from '../../models/chart-models/modernized-chart-colors';

@Injectable({
  providedIn: 'root',
})
export class ChartDataService {
  assetAllocationChartData: ChartData;
  assetAllocationChartDataPayload = new BehaviorSubject(new ChartData());

  stockAnalysisChartData: ChartData;
  stockAnalysisResponseData: Response<StockBondDomesticInternationalData>;
  stockAnalysisDataPayload = new BehaviorSubject(new ChartData());

  managerRiskChartData: ChartData;
  managerRiskResponseData: Response<ManagerRiskResponse>;
  managerRiskChartDataPayload = new BehaviorSubject(new ChartData());

  bondAnalysisChartData: ChartData;
  bondAnalysisResponseData: Response<StockBondDomesticInternationalData>;
  bondAnalysisDataPayload = new BehaviorSubject(new ChartData());

  targetAssetAllocationChartData: ChartData;
  targetAssetAllocationChartDataPayload = new BehaviorSubject(new ChartData());

  taxabilityChartData: ChartData;
  taxabilityResponseData: Response<BondTaxData>;
  taxabilityChartDataPayload = new BehaviorSubject(new ChartData());

  assetAllocationResponseData: Response<TargetAssetResponse>;
  assetAllocationResponseDataPayload = new BehaviorSubject(new Response());

  targetAllocationData: TargetAssetResponse;
  targetAllocationDataPayload = new BehaviorSubject(new TargetAssetResponse());

  actualAssetAllocationValueList = new Array<number>();
  targetAssetAllocationValueList = new Array<number>();
  managerRiskValueList = new Array<number>();

  private allocationDifferenceMap: Map<any, any>;
  allocationDifferenceMapPayload = new BehaviorSubject(new Map());

  allocationDataHasLoaded = false;
  targetDataHasLoaded = false;

  isLoading = new BehaviorSubject(true);

  chartColorMap = new Map();

  constructor(private portfolioDataService: PortfolioDataService) {
    this.chartColorMap = ChartColors.getColorMap();

    this.loadAssetAllocationChartDataService();
    this.loadTargetAssetAllocationChartDataService();
    this.loadStockAnalysisChartDataService();
    this.loadBondAnalysisChartDataService();
    this.loadTaxabilityChartDataService();
    this.loadManagerRiskChartDataService();
  }

  // --------------------------------------------------- //
  // ----------- OBSERVABLE RETURN FUNCTIONS ----------- //

  public getIsLoading(): Observable<boolean> {
    return this.isLoading.asObservable();
  }

  public getAssetAllocationChartPayload(): Observable<ChartData> {
    return this.assetAllocationChartDataPayload.asObservable();
  }

  public getStockAnalysisChartPayload(): Observable<ChartData> {
    return this.stockAnalysisDataPayload.asObservable();
  }

  public getBondAnalysisChartPayload(): Observable<ChartData> {
    return this.bondAnalysisDataPayload.asObservable();
  }

  public getTargetAssetAllocationPayload(): Observable<ChartData> {
    return this.targetAssetAllocationChartDataPayload.asObservable();
  }

  public getAllocationDifferenceMapPayload(): Observable<Map<any, any>> {
    return this.allocationDifferenceMapPayload.asObservable();
  }

  public getTaxabilityChartPayload(): Observable<ChartData> {
    return this.taxabilityChartDataPayload.asObservable();
  }

  public getStockPercentage(): number {
    return this.assetAllocationChartData.percentageDetails[0];
  }

  public getBondPercentage(): number {
    return this.assetAllocationChartData.percentageDetails[1];
  }

  public hasStocks(): boolean {
    return this.stockAnalysisResponseData.applicableBalance > 0;
  }

  public hasBonds(): boolean {
    return this.bondAnalysisResponseData.applicableBalance > 0;
  }

  // --------------------------------------------------- //
  // --------- END OBSERVABLE RETURN FUNCTIONS --------- //

  // ----------------------------------------------- //
  // ----------- CHART PAYLOAD FUNCTIONS ----------- //

  loadStockAnalysisChartDataService() {
    this.portfolioDataService.getChartDataPayload().subscribe((response: AllChartsResponseData) => {
      if (response.stockDomesticInternational) {
        this.convertStockAnalysisResponseToChartData(response.stockDomesticInternational);
      }
    });
  }

  loadBondAnalysisChartDataService() {
    this.portfolioDataService.getChartDataPayload().subscribe((response: AllChartsResponseData) => {
      if (response.bondDomesticInternational) {
        this.convertBondAnalysisResponseToChartData(response.bondDomesticInternational);
      }
    });
  }

  loadTargetAssetAllocationChartDataService() {
    this.portfolioDataService.getChartDataPayload().subscribe((response: AllChartsResponseData) => {
      if (response.assetTypeTarget) {
        this.targetAllocationDataPayload.next(response.assetTypeTarget);
        this.convertTargetAssetAllocationResponseToChartData(response.assetTypeTarget);
        this.targetDataHasLoaded = true;
        this.calculateLegendTargetvsDifference();
      }
    });
  }

  loadAssetAllocationChartDataService() {
    this.portfolioDataService.getChartDataPayload().subscribe((response: AllChartsResponseData) => {
      if (response.assetTypes) {
        this.assetAllocationResponseDataPayload.next(response.assetTypes);
        this.convertAssetAllocationResponseToChartData(response.assetTypes);
        this.allocationDataHasLoaded = true;
        this.calculateLegendTargetvsDifference();
      }
    });
  }

  loadTaxabilityChartDataService() {
    this.portfolioDataService.getChartDataPayload().subscribe((response: AllChartsResponseData) => {
      if (response.bondTax) {
        this.convertTaxabilityResponseToChartData(response.bondTax);
      }
    });
  }

  loadManagerRiskChartDataService() {
    this.portfolioDataService.getChartDataPayload().subscribe((response: AllChartsResponseData) => {
      if (response.managerRisk) {
        this.convertManagerRiskResponseToChartData(response.managerRisk);
      }
    });
  }

  // --------- END CHART PAYLOAD FUNCTIONS --------- //
  // ----------------------------------------------- //

  // ------------------------------------------------- //
  // --------- RESPONSE CONVERSION FUNCTIONS --------- //

  convertAssetAllocationResponseToChartData(response: Response<TargetAssetResponse>) {
    this.assetAllocationResponseData = response;
    this.assetAllocationChartData = TargetAssetResponse.buildChartDataList(response.chart);

    this.actualAssetAllocationValueList = this.assetAllocationChartData.percentageDetails;

    this.assetAllocationChartDataPayload.next(this.assetAllocationChartData);
  }

  convertManagerRiskResponseToChartData(response: Response<ManagerRiskResponse>) {
    this.managerRiskResponseData = response;
    this.managerRiskChartData = ManagerRiskResponse.buildChartDataList(response.chart);

    this.managerRiskValueList = this.managerRiskChartData.percentageDetails;

    this.managerRiskChartDataPayload.next(this.managerRiskChartData);
  }

  convertBondAnalysisResponseToChartData(response: Response<StockBondDomesticInternationalData>) {
    this.bondAnalysisResponseData = response;
    this.bondAnalysisChartData = StockBondDomesticInternationalData.buildChartDataList(
      response.chart,
    );

    this.bondAnalysisDataPayload.next(this.bondAnalysisChartData);
  }

  convertStockAnalysisResponseToChartData(response: Response<StockBondDomesticInternationalData>) {
    this.stockAnalysisResponseData = response;
    this.stockAnalysisChartData = StockBondDomesticInternationalData.buildChartDataList(
      response.chart,
    );

    this.stockAnalysisDataPayload.next(this.stockAnalysisChartData);
  }

  convertTargetAssetAllocationResponseToChartData(response: TargetAssetResponse) {
    this.targetAllocationData = response;
    this.targetAssetAllocationChartData = TargetAssetResponse.buildChartDataList(response);
    this.targetAssetAllocationValueList = this.targetAssetAllocationChartData.percentageDetails;

    this.targetAssetAllocationChartDataPayload.next(this.targetAssetAllocationChartData);
  }

  convertTaxabilityResponseToChartData(response: Response<BondTaxData>) {
    this.taxabilityResponseData = response;

    var chartData = BondTaxData.buildChartDataList(response.chart);

    if (this.taxabilityChartData) {
      this.taxabilityChartData.labelDetails = chartData.labelDetails;
      this.taxabilityChartData.percentageDetails = chartData.percentageDetails;
    }

    this.taxabilityChartDataPayload.next(chartData);
  }

  calculateLegendTargetvsDifference() {
    if (this.allocationDataHasLoaded && this.targetDataHasLoaded) {
      try {
        this.allocationDifferenceMap = new Map<any, any>();
        this.allocationDifferenceMap.set(
          'stocks',
          this.actualAssetAllocationValueList[0] - this.targetAssetAllocationValueList[0],
        );
        this.allocationDifferenceMap.set(
          'bonds',
          this.actualAssetAllocationValueList[1] - this.targetAssetAllocationValueList[1],
        );
        this.allocationDifferenceMap.set(
          'shortTermReserves',
          this.actualAssetAllocationValueList[2] - this.targetAssetAllocationValueList[2],
        );
        this.allocationDifferenceMap.set(
          'other',
          this.actualAssetAllocationValueList[3] - this.targetAssetAllocationValueList[3],
        );

        this.allocationDifferenceMapPayload.next(this.allocationDifferenceMap);
      } catch (error) {
        //Add Error Handling
        console.log(error);
      }
    }
  }

  // ------- END RESPONSE CONVERSION FUNCTIONS ------- //
  // ------------------------------------------------- //

  // --------------------------------------------- //
  // --------- LEGEND CREATION FUNCTIONS --------- //

  public getAssetAllocationCardLegend(): Array<LegendLine> {
    this.chartColorMap = ChartColors.getColorMap();
    var legendArray = new Array<LegendLine>();

    // base legend items\
    var stocks = new LegendLine();
    stocks.circleColor = this.chartColorMap.get('stocks');
    stocks.legendLineName = 'Stocks';
    if (
      this.assetAllocationResponseData.chart.stocks === 0 &&
      this.stockAnalysisResponseData.applicableBalance > 0
    ) {
      stocks.percentageAllocationOffset = '<1';
    } else {
      stocks.percentageAllocationOffset = this.assetAllocationResponseData.chart.stocks;
    }

    var bonds = new LegendLine();
    bonds.circleColor = this.chartColorMap.get('bonds');
    bonds.legendLineName = 'Bonds';
    if (
      this.assetAllocationResponseData.chart.bonds === 0 &&
      this.bondAnalysisResponseData.applicableBalance > 0
    ) {
      bonds.percentageAllocationOffset = '<1';
    } else {
      bonds.percentageAllocationOffset = this.assetAllocationResponseData.chart.bonds;
    }

    var shortTermReserves = new LegendLine();
    shortTermReserves.circleColor = this.chartColorMap.get('shortTermReserves');
    shortTermReserves.legendLineName = 'Short term reserves';
    shortTermReserves.percentageAllocationOffset =
      this.assetAllocationResponseData.chart.shortTermReserves;

    var other = new LegendLine();
    other.circleColor = this.chartColorMap.get('other');
    other.legendLineName = 'Other';
    other.percentageAllocationOffset = this.assetAllocationResponseData.chart.others;

    legendArray.push(stocks);
    legendArray.push(bonds);
    legendArray.push(shortTermReserves);
    legendArray.push(other);

    return legendArray;
  }

  public getStockAnalysisCardLegend(): Array<LegendLine> {
    this.chartColorMap = ChartColors.getColorMap();
    var legendArray = new Array<LegendLine>();

    // base legend items
    var domestic = new LegendLine();
    domestic.circleColor = this.chartColorMap.get('domesticSA');
    domestic.legendLineName = 'Domestic';
    domestic.percentageAllocationOffset = this.stockAnalysisResponseData.chart.domestic;

    var international = new LegendLine();
    international.circleColor = this.chartColorMap.get('internationalSA');
    international.legendLineName = 'International';
    international.percentageAllocationOffset = this.stockAnalysisResponseData.chart.international;

    var other = new LegendLine();
    other.circleColor = this.chartColorMap.get('otherSA');
    other.legendLineName = 'Other';
    other.percentageAllocationOffset = this.stockAnalysisResponseData.chart.uncategorized;

    legendArray.push(domestic);
    legendArray.push(international);
    legendArray.push(other);

    return legendArray;
  }

  public getBondAnalysisCardLegend(): Array<LegendLine> {
    this.chartColorMap = ChartColors.getColorMap();
    var legendArray = new Array<LegendLine>();

    // base legend items
    var domestic = new LegendLine();
    domestic.circleColor = this.chartColorMap.get('domesticBA');
    domestic.legendLineName = 'Domestic';
    domestic.percentageAllocationOffset = this.bondAnalysisResponseData.chart.domestic;

    var international = new LegendLine();
    international.circleColor = this.chartColorMap.get('internationalBA');
    international.legendLineName = 'International';
    international.percentageAllocationOffset = this.bondAnalysisResponseData.chart.international;

    var other = new LegendLine();
    other.circleColor = this.chartColorMap.get('otherBA');
    other.legendLineName = 'Other';
    other.percentageAllocationOffset = this.bondAnalysisResponseData.chart.uncategorized;

    legendArray.push(domestic);
    legendArray.push(international);
    legendArray.push(other);

    return legendArray;
  }

  public getTargetLegend(): Array<LegendLine> {
    this.chartColorMap = ChartColors.getColorMap();
    var legendArray = new Array<LegendLine>();

    // base legend items
    if (this.targetAllocationData.stocks) {
      var stocks = new LegendLine();
      stocks.circleColor = this.chartColorMap.get('stocks');
      stocks.legendLineName = 'Stocks';
      stocks.percentageAllocationOffset = this.targetAllocationData.stocks;
      legendArray.push(stocks);
    }

    if (this.targetAllocationData.bonds) {
      var bonds = new LegendLine();
      bonds.circleColor = this.chartColorMap.get('bonds');
      bonds.legendLineName = 'Bonds';
      bonds.percentageAllocationOffset = this.targetAllocationData.bonds;
      legendArray.push(bonds);
    }

    if (this.targetAllocationData.shortTermReserves) {
      var shortTermReserves = new LegendLine();
      shortTermReserves.circleColor = this.chartColorMap.get('shortTermReserves');
      shortTermReserves.legendLineName = 'Short term reserves';
      shortTermReserves.percentageAllocationOffset = this.targetAllocationData.shortTermReserves;
      legendArray.push(shortTermReserves);
    }

    if (this.targetAllocationData.others) {
      var other = new LegendLine();
      other.circleColor = this.chartColorMap.get('other');
      other.legendLineName = 'Other';
      other.percentageAllocationOffset = this.targetAllocationData.others;
      legendArray.push(other);
    }

    return legendArray;
  }

  public getTaxLegend(): Array<LegendLine> {
    this.chartColorMap = ChartColors.getColorMap();
    var legendArray = new Array<LegendLine>();

    // base legend items
    var taxable = new LegendLine();
    taxable.circleColor = this.chartColorMap.get('stocks');
    taxable.legendLineName = 'Taxable';
    taxable.percentageAllocationOffset = this.taxabilityResponseData.chart.taxable;
    legendArray.push(taxable);

    var taxExempt = new LegendLine();
    taxExempt.circleColor = this.chartColorMap.get('bonds');
    taxExempt.legendLineName = 'Tax Exempt';
    taxExempt.percentageAllocationOffset = this.taxabilityResponseData.chart.taxExempt;
    legendArray.push(taxExempt);

    return legendArray;
  }

  public getAssetAllocationCardLegendModern(): Array<LegendLine> {
    this.chartColorMap = ModernizedChartColors.getColorMap();
    var legendArray = new Array<LegendLine>();

    // base legend items\
    var stocks = new LegendLine();
    stocks.circleColor = this.chartColorMap.get('stocks');
    stocks.legendLineName = 'Stocks';
    stocks.percentageAllocationOffset = this.assetAllocationResponseData.chart.stocks;

    var bonds = new LegendLine();
    bonds.circleColor = this.chartColorMap.get('bonds');
    bonds.legendLineName = 'Bonds';
    bonds.percentageAllocationOffset = this.assetAllocationResponseData.chart.bonds;

    var shortTermReserves = new LegendLine();
    shortTermReserves.circleColor = this.chartColorMap.get('shortTermReserves');
    shortTermReserves.legendLineName = 'Short term reserves';
    shortTermReserves.percentageAllocationOffset =
      this.assetAllocationResponseData.chart.shortTermReserves;

    var other = new LegendLine();
    other.circleColor = this.chartColorMap.get('other');
    other.legendLineName = 'Other';
    other.percentageAllocationOffset = this.assetAllocationResponseData.chart.others;

    legendArray.push(stocks);
    legendArray.push(bonds);
    legendArray.push(shortTermReserves);
    legendArray.push(other);

    return legendArray;
  }

  public getStockAnalysisCardLegendModern(): Array<LegendLine> {
    this.chartColorMap = ModernizedChartColors.getColorMap();
    var legendArray = new Array<LegendLine>();

    // base legend items
    var domestic = new LegendLine();
    domestic.circleColor = this.chartColorMap.get('domesticSA');
    domestic.legendLineName = 'Domestic';
    domestic.percentageAllocationOffset = this.stockAnalysisResponseData.chart.domestic;

    var international = new LegendLine();
    international.circleColor = this.chartColorMap.get('internationalSA');
    international.legendLineName = 'International';
    international.percentageAllocationOffset = this.stockAnalysisResponseData.chart.international;

    var other = new LegendLine();
    other.circleColor = this.chartColorMap.get('otherSA');
    other.legendLineName = 'Other';
    other.percentageAllocationOffset = this.stockAnalysisResponseData.chart.uncategorized;

    legendArray.push(domestic);
    legendArray.push(international);
    legendArray.push(other);

    return legendArray;
  }

  public getBondAnalysisCardLegendModern(): Array<LegendLine> {
    this.chartColorMap = ModernizedChartColors.getColorMap();
    var legendArray = new Array<LegendLine>();

    // base legend items
    var domestic = new LegendLine();
    domestic.circleColor = this.chartColorMap.get('domesticBA');
    domestic.legendLineName = 'Domestic';
    domestic.percentageAllocationOffset = this.bondAnalysisResponseData.chart.domestic;

    var international = new LegendLine();
    international.circleColor = this.chartColorMap.get('internationalBA');
    international.legendLineName = 'International';
    international.percentageAllocationOffset = this.bondAnalysisResponseData.chart.international;

    var other = new LegendLine();
    other.circleColor = this.chartColorMap.get('otherBA');
    other.legendLineName = 'Other';
    other.percentageAllocationOffset = this.bondAnalysisResponseData.chart.uncategorized;

    legendArray.push(domestic);
    legendArray.push(international);
    legendArray.push(other);

    return legendArray;
  }

  public getTargetLegendModern(): Array<LegendLine> {
    this.chartColorMap = ModernizedChartColors.getColorMap();
    var legendArray = new Array<LegendLine>();

    // base legend items
    if (this.targetAllocationData.stocks) {
      var stocks = new LegendLine();
      stocks.circleColor = this.chartColorMap.get('stocks');
      stocks.legendLineName = 'Stocks';
      stocks.percentageAllocationOffset = this.targetAllocationData.stocks;
      legendArray.push(stocks);
    }

    if (this.targetAllocationData.bonds) {
      var bonds = new LegendLine();
      bonds.circleColor = this.chartColorMap.get('bonds');
      bonds.legendLineName = 'Bonds';
      bonds.percentageAllocationOffset = this.targetAllocationData.bonds;
      legendArray.push(bonds);
    }

    if (this.targetAllocationData.shortTermReserves) {
      var shortTermReserves = new LegendLine();
      shortTermReserves.circleColor = this.chartColorMap.get('shortTermReserves');
      shortTermReserves.legendLineName = 'Short term reserves';
      shortTermReserves.percentageAllocationOffset = this.targetAllocationData.shortTermReserves;
      legendArray.push(shortTermReserves);
    }

    if (this.targetAllocationData.others) {
      var other = new LegendLine();
      other.circleColor = this.chartColorMap.get('other');
      other.legendLineName = 'Other';
      other.percentageAllocationOffset = this.targetAllocationData.others;
      legendArray.push(other);
    }

    return legendArray;
  }

  public getTaxLegendModern(): Array<LegendLine> {
    this.chartColorMap = ModernizedChartColors.getColorMap();
    var legendArray = new Array<LegendLine>();

    // base legend items
    var taxable = new LegendLine();
    taxable.circleColor = this.chartColorMap.get('stocks');
    taxable.legendLineName = 'Taxable';
    taxable.percentageAllocationOffset = this.taxabilityResponseData.chart.taxable;
    legendArray.push(taxable);

    var taxExempt = new LegendLine();
    taxExempt.circleColor = this.chartColorMap.get('bonds');
    taxExempt.legendLineName = 'Tax Exempt';
    taxExempt.percentageAllocationOffset = this.taxabilityResponseData.chart.taxExempt;
    legendArray.push(taxExempt);

    return legendArray;
  }

  // ------- END LEGEND CREATION FUNCTIONS ------- //
  // --------------------------------------------- //
}
