import { Component, Input } from '@angular/core';
import { HoldingsTableData } from '../../models/holdings-table';
import { Router } from '@angular/router';
import { DrawerTrackerService } from '@vanguard/pfx-components';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';

@Component({
  selector: 'PW1-holdings-table',
  templateUrl: './holdings-table.component.html',
  styleUrls: ['./holdings-table.component.scss'],
})
export class HoldingsTableComponent {
  @Input() tableData: HoldingsTableData;
  constructor(
    public router: Router,
    private drawerTrackerService: DrawerTrackerService,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {}

  navigateToFAQPage() {
    this.drawerTrackerService.openDrawer(1);
    this.adobeAnalyticsService.clickedOnFaq();
  }
}
