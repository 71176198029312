import { Component, OnInit, Input } from '@angular/core';
import { MARKET_CAP_CONSTANTS } from '../../../../../models/chart.constants';
import { HoldingsViewHelperService } from '../../../../../services/holdings-view-helper/holdings-view-helper.service';

@Component({
  selector: 'PW1-hypothetical-market-cap-table',
  templateUrl: './hypothetical-market-cap-table.component.html',
  styleUrls: ['./hypothetical-market-cap-table.component.scss'],
})
export class HypotheticalMarketCapTableComponent implements OnInit {
  @Input() chartData;
  @Input() hypotheticalData;

  tableData = [];
  tableDataHypothetical = [];
  holdingsTablesData: any[];

  holdingsTotal: number = 0;
  holdingsTotalHypothetical: number = 0;

  loaded = false;
  loadedHypothetical = false;
  viewByHoldings = false;

  portfolioLargeCap = MARKET_CAP_CONSTANTS.ZERO;
  portfolioMidCap = MARKET_CAP_CONSTANTS.ZERO;
  portfolioSmallCap = MARKET_CAP_CONSTANTS.ZERO;
  portfolioTotal: number;
  portfolioUncategorized: number;
  marketLargeCap = MARKET_CAP_CONSTANTS.ZERO;
  marketMidCap = MARKET_CAP_CONSTANTS.ZERO;
  marketSmallCap = MARKET_CAP_CONSTANTS.ZERO;
  marketTotal: number;
  marketUncategorized: number;
  private lgDifference: any;
  private mdDifference: any;
  private smDifference: any;
  private uncategorizedDifference: any;

  portfolioLargeCapHypothetical = MARKET_CAP_CONSTANTS.ZERO;
  portfolioMidCapHypothetical = MARKET_CAP_CONSTANTS.ZERO;
  portfolioSmallCapHypothetical = MARKET_CAP_CONSTANTS.ZERO;
  portfolioTotalHypothetical: number;
  portfolioUncategorizedHypothetical: number;
  marketLargeCapHypothetical = MARKET_CAP_CONSTANTS.ZERO;
  marketMidCapHypothetical = MARKET_CAP_CONSTANTS.ZERO;
  marketSmallCapHypothetical = MARKET_CAP_CONSTANTS.ZERO;
  marketTotalHypothetical: number;
  marketUncategorizedHypothetical: number;
  private lgDifferenceHypothetical: any;
  private mdDifferenceHypothetical: any;
  private smDifferenceHypothetical: any;
  private uncategorizedDifferenceHypothetical: any;

  constructor(private holdingsViewHelper: HoldingsViewHelperService) {}

  ngOnInit() {
    if (this.chartData && this.hypotheticalData) {
      this.setTableData();
      this.setTableDataHypothetical();
      this.setBothHoldingsTables();
    }
  }

  setPortfolioData() {
    let usStockMarketCap = this.chartData.stockMarketCap.usStockMarketCap;
    this.marketLargeCap = usStockMarketCap ? usStockMarketCap.large : MARKET_CAP_CONSTANTS.ZERO;
    this.marketMidCap = usStockMarketCap ? usStockMarketCap.medium : MARKET_CAP_CONSTANTS.ZERO;
    this.marketSmallCap = usStockMarketCap ? usStockMarketCap.small : MARKET_CAP_CONSTANTS.ZERO;
    this.marketUncategorized = usStockMarketCap
      ? usStockMarketCap.uncategorized
      : MARKET_CAP_CONSTANTS.ZERO;
    this.marketTotal =
      this.marketLargeCap + this.marketMidCap + this.marketSmallCap + this.marketUncategorized;
  }
  setPortfolioDataHypothetical() {
    let usStockMarketCap = this.hypotheticalData.stockMarketCap.usStockMarketCap;
    this.marketLargeCapHypothetical = usStockMarketCap
      ? usStockMarketCap.large
      : MARKET_CAP_CONSTANTS.ZERO;
    this.marketMidCapHypothetical = usStockMarketCap
      ? usStockMarketCap.medium
      : MARKET_CAP_CONSTANTS.ZERO;
    this.marketSmallCapHypothetical = usStockMarketCap
      ? usStockMarketCap.small
      : MARKET_CAP_CONSTANTS.ZERO;
    this.marketUncategorizedHypothetical = usStockMarketCap
      ? usStockMarketCap.uncategorized
      : MARKET_CAP_CONSTANTS.ZERO;
    this.marketTotalHypothetical =
      this.marketLargeCapHypothetical +
      this.marketMidCapHypothetical +
      this.marketSmallCapHypothetical +
      this.marketUncategorizedHypothetical;
  }

  setTableData() {
    this.portfolioLargeCap = this.chartData.stockMarketCap.chart.large;
    this.portfolioMidCap = this.chartData.stockMarketCap.chart.medium;
    this.portfolioSmallCap = this.chartData.stockMarketCap.chart.small;
    this.portfolioUncategorized = this.chartData.stockMarketCap.chart.uncategorized;
    this.portfolioTotal =
      this.portfolioLargeCap +
      this.portfolioMidCap +
      this.portfolioSmallCap +
      this.portfolioUncategorized;

    this.lgDifference = this.chartData.stockMarketCap.difference.large;
    this.mdDifference = this.chartData.stockMarketCap.difference.medium;
    this.smDifference = this.chartData.stockMarketCap.difference.small;
    this.uncategorizedDifference = this.chartData.stockMarketCap.difference.uncategorized;

    this.setPortfolioData();
    this.tableData = [
      {
        size: 'Large cap',
        yourStock: this.portfolioLargeCap + '%',
        market: this.marketLargeCap + '%',
        difference: this.lgDifference + '%',
      },
      {
        size: 'Mid cap',
        yourStock: this.portfolioMidCap + '%',
        market: this.marketMidCap + '%',
        difference: this.mdDifference + '%',
      },
      {
        size: 'Small cap',
        yourStock: this.portfolioSmallCap + '%',
        market: this.marketSmallCap + '%',
        difference: this.smDifference + '%',
      },
    ];

    if (this.portfolioUncategorized > 0) {
      this.tableData.push({
        size: 'Uncategorized',
        yourStock: this.portfolioUncategorized + '%',
        market: this.marketUncategorized + '%',
        difference: this.uncategorizedDifference + '%',
      });
    }

    this.tableData.push({
      size: 'Total',
      yourStock: this.portfolioTotal.toFixed(0) + '%',
      market: this.marketTotal.toFixed(0) + '%',
    });

    this.loaded = true;
  }

  setTableDataHypothetical() {
    this.portfolioLargeCapHypothetical = this.hypotheticalData.stockMarketCap.chart.large;
    this.portfolioMidCapHypothetical = this.hypotheticalData.stockMarketCap.chart.medium;
    this.portfolioSmallCapHypothetical = this.hypotheticalData.stockMarketCap.chart.small;
    this.portfolioUncategorizedHypothetical =
      this.hypotheticalData.stockMarketCap.chart.uncategorized;
    this.portfolioTotalHypothetical =
      this.portfolioLargeCapHypothetical +
      this.portfolioMidCapHypothetical +
      this.portfolioSmallCapHypothetical +
      this.portfolioUncategorizedHypothetical;

    this.lgDifferenceHypothetical = this.hypotheticalData.stockMarketCap.difference.large;
    this.mdDifferenceHypothetical = this.hypotheticalData.stockMarketCap.difference.medium;
    this.smDifferenceHypothetical = this.hypotheticalData.stockMarketCap.difference.small;
    this.uncategorizedDifferenceHypothetical =
      this.hypotheticalData.stockMarketCap.difference.uncategorized;

    this.setPortfolioDataHypothetical();
    this.tableDataHypothetical = [
      {
        size: 'Large cap',
        yourStock: this.portfolioLargeCapHypothetical + '%',
        market: this.marketLargeCapHypothetical + '%',
        difference: this.lgDifferenceHypothetical + '%',
      },
      {
        size: 'Mid cap',
        yourStock: this.portfolioMidCapHypothetical + '%',
        market: this.marketMidCapHypothetical + '%',
        difference: this.mdDifferenceHypothetical + '%',
      },
      {
        size: 'Small cap',
        yourStock: this.portfolioSmallCapHypothetical + '%',
        market: this.marketSmallCapHypothetical + '%',
        difference: this.smDifferenceHypothetical + '%',
      },
    ];

    if (this.portfolioUncategorizedHypothetical > 0) {
      this.tableDataHypothetical.push({
        size: 'Uncategorized',
        yourStock: this.portfolioUncategorizedHypothetical + '%',
        market: this.marketUncategorizedHypothetical + '%',
        difference: this.uncategorizedDifferenceHypothetical + '%',
      });
    }

    this.tableDataHypothetical.push({
      size: 'Total',
      yourStock: this.portfolioTotalHypothetical.toFixed(0) + '%',
      market: this.marketTotalHypothetical.toFixed(0) + '%',
    });

    this.loadedHypothetical = true;
  }

  generateTableDataByHoldings() {
    const categorizationObjects: any[] = [
      { title: 'Current large cap stocks & stock funds', dataPoint: 'large' },
      { title: 'Current medium cap stocks & stock funds', dataPoint: 'medium' },
      { title: 'Current small cap stocks & stock funds', dataPoint: 'small' },
      { title: 'Current uncategorized cap stocks & stock funds', dataPoint: 'uncategorized' },
    ];
    const categorizationObjectsForDifference = {
      large: this.marketLargeCap,
      medium: this.marketMidCap,
      small: this.marketSmallCap,
      uncategorized: 0,
    };

    const holdingsArray: any[] =
      this.holdingsViewHelper.getGeneratedDataWithDifferenceForTesterTool(
        categorizationObjects,
        this.chartData,
        'stockMarketCap',
        categorizationObjectsForDifference,
      );
    this.holdingsTotal = this.holdingsViewHelper.calculateTotal(holdingsArray);
    this.loaded = true;
    return holdingsArray;
  }

  generateTableDataByHoldingsHypothetical() {
    const categorizationObjects: any[] = [
      { title: 'Hypothetical large cap stocks & stock funds', dataPoint: 'large' },
      { title: 'Hypothetical medium cap stocks & stock funds', dataPoint: 'medium' },
      { title: 'Hypothetical small cap stocks & stock funds', dataPoint: 'small' },
      { title: 'Hypothetical uncategorized cap stocks & stock funds', dataPoint: 'uncategorized' },
    ];
    const categorizationObjectsForDifference = {
      large: this.marketLargeCapHypothetical,
      medium: this.marketMidCapHypothetical,
      small: this.marketSmallCapHypothetical,
      uncategorized: 0,
    };

    const holdingsArray: any[] =
      this.holdingsViewHelper.getGeneratedDataWithDifferenceForTesterTool(
        categorizationObjects,
        this.hypotheticalData,
        'stockMarketCap',
        categorizationObjectsForDifference,
      );
    this.holdingsTotalHypothetical = this.holdingsViewHelper.calculateTotal(holdingsArray);
    this.loadedHypothetical = true;
    return holdingsArray;
  }

  changeTableView(val: boolean) {
    this.viewByHoldings = val;
  }

  setBothHoldingsTables() {
    const tableDataByHoldingsActual = this.generateTableDataByHoldings();
    const tableDataByHoldingsHypothetical = this.generateTableDataByHoldingsHypothetical();

    const unfiltered = [
      {
        actual: this.getTable(tableDataByHoldingsActual, 'stockMarketCap', 'large'),
        hypothetical: this.getTable(tableDataByHoldingsHypothetical, 'stockMarketCap', 'large'),
      },
      {
        actual: this.getTable(tableDataByHoldingsActual, 'stockMarketCap', 'medium'),
        hypothetical: this.getTable(tableDataByHoldingsHypothetical, 'stockMarketCap', 'medium'),
      },
      {
        actual: this.getTable(tableDataByHoldingsActual, 'stockMarketCap', 'small'),
        hypothetical: this.getTable(tableDataByHoldingsHypothetical, 'stockMarketCap', 'small'),
      },
      {
        actual: this.getTable(tableDataByHoldingsActual, 'stockMarketCap', 'uncategorized'),
        hypothetical: this.getTable(
          tableDataByHoldingsHypothetical,
          'stockMarketCap',
          'uncategorized',
        ),
      },
    ];

    this.holdingsTablesData = unfiltered.filter((category) => {
      return category.actual.accounts.length > 0 || category.hypothetical.accounts.length > 0;
    });
  }

  getTable(dataSet, dataNode, categorization) {
    const filteredTableData = dataSet.filter((tableData) => {
      return (
        tableData && tableData.categorization === categorization && tableData.dataNode === dataNode
      );
    });

    return filteredTableData[0];
  }
}
