import { Component, OnInit, Input } from '@angular/core';
import { INDEX_CONSTANTS } from '../../../models/chart.constants';
import {
  CategorizationObject,
  HoldingsTableCategorization,
  HoldingsTableData,
} from '../../../models/holdings-table';
import { HoldingsTableBuilderService } from '../../../services/holdings-table-builder/holdings-table-builder.service';
import { Router } from '@angular/router';
import { DrawerTrackerService } from '@vanguard/pfx-components';
import { DRAWER_CONSTANTS } from 'src/app/models/drawer.constants';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';

@Component({
  selector: 'industry-sectors-table',
  templateUrl: './industry-sectors-table.component.html',
  styleUrls: ['./industry-sectors-table.component.scss'],
})
export class IndustrySectorsTableComponent implements OnInit {
  @Input() chartData;
  loaded = false;
  viewByHoldings = false;

  tableData = [];
  tableDataByHoldings = [];

  holdingsTableDataModernized: HoldingsTableData;

  holdingsTotal: number = 0;
  yourStock = [];
  stockUS = [];
  chartDifference = [];

  constructor(
    private holdingsTableBuilderService: HoldingsTableBuilderService,
    private router: Router,
    private drawerTrackerService: DrawerTrackerService,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {}

  ngOnInit() {
    if (this.chartData) {
      this.setTableData();
      this.getHoldingsDataModernized();
    }
  }

  setTableData() {
    this.yourStock = Object.entries(this.chartData.stockIndustrySector.chart).sort((a, b) =>
      a[0].localeCompare(b[0]),
    );
    this.stockUS = Object.entries(this.chartData.stockIndustrySector.usStockMarketChart).sort(
      (a, b) => a[0].localeCompare(b[0]),
    );
    this.chartDifference = Object.entries(this.chartData.stockIndustrySector.chartDifference).sort(
      (a, b) => a[0].localeCompare(b[0]),
    );

    this.tableData = this.generateTableData();
    this.loaded = true;
  }

  getHoldingsDataModernized() {
    this.holdingsTableDataModernized = this.generateTableDataByHoldingsModernized();
  }

  generateTableData() {
    let processedTableData = [];

    this.yourStock.forEach((yourStockEntry, index) => {
      let sector = this.splitAndSpace(yourStockEntry[INDEX_CONSTANTS.ZERO]);
      let stockUsEntry = this.stockUS[index];
      let chartDifferenceEntry = this.chartDifference[index];

      if (sector != 'Uncategorized') {
        processedTableData.push({
          sector: sector,
          yourStock: yourStockEntry[INDEX_CONSTANTS.ONE] + '%',
          stockUS: stockUsEntry[INDEX_CONSTANTS.ONE] + '%',
          chartDifference: chartDifferenceEntry[INDEX_CONSTANTS.ONE],
        });
      }
    });

    if (this.chartData.stockIndustrySector.chart.uncategorized > 0) {
      processedTableData.push({
        sector: 'Uncategorized',
        yourStock: this.chartData.stockIndustrySector.chart.uncategorized + '%',
        stockUS: this.chartData.stockIndustrySector.usStockMarketChart.uncategorized + '%',
        chartDifference: this.chartData.stockIndustrySector.chartDifference.uncategorized,
      });
    }

    if (this.yourStock.length > 0 && this.stockUS.length > 0) {
      processedTableData.push({
        sector: 'Total',
        yourStock: this.getTotalPercentage(this.chartData.stockIndustrySector.chart),
        stockUS: this.getTotalPercentage(this.chartData.stockIndustrySector.usStockMarketChart),
      });
    }

    return processedTableData;
  }

  generateTableDataByHoldingsModernized(): HoldingsTableData {
    const applicableBalance: number = this.chartData.stockIndustrySector.applicableBalance;
    const categorizationObjects: CategorizationObject[] = [];
    Object.keys(this.chartData.stockIndustrySector.chart).forEach((key) => {
      categorizationObjects.push({ title: this.splitAndSpace(key), categorization: key });
    });
    const holdingsDataByCategorizations: HoldingsTableCategorization[] =
      this.holdingsTableBuilderService.getHoldingsByCategorizations(
        this.chartData.stockIndustrySector,
        categorizationObjects,
      );

    return {
      categorizations: holdingsDataByCategorizations,
      holdingsTotal: applicableBalance, // TODO: IS THIS VALUE THE CORRECT ONE?
    };
  }

  getTotalPercentage(chartObj) {
    let total: any = Object.values(chartObj)
      .filter((val: any) => !isNaN(val))
      .reduce((acc: number, value: number) => acc + value, 0);
    return total.toFixed(0) + '%';
  }

  changeTableView(val: boolean) {
    this.viewByHoldings = val;
    if (val) {
      this.adobeAnalyticsService.clickedOnHoldingsDetails();
    }
  }

  splitAndSpace(chartEntry: string): string {
    let spacedString = chartEntry.replace(/([a-zA-Z])(?=[A-Z])/g, '$1 ');
    return (
      spacedString.substring(INDEX_CONSTANTS.ZERO, INDEX_CONSTANTS.ONE).toUpperCase() +
      spacedString.slice(INDEX_CONSTANTS.ONE).toLowerCase()
    );
  }

  navigateToLearnMorePage() {
    this.adobeAnalyticsService.clickedOnLearnMore('/learn-more/sector-allocation');
    this.router.navigate(['/learn-more/sector-allocation']);
  }
  openIndustrySectorsDrawer() {
    this.drawerTrackerService.setSelectSection(DRAWER_CONSTANTS.INDUSTRY_SECTORS);
  }
}
