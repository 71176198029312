export class LegendLine {
  legendLineName: string;
  percentageAllocationOffset: number | string;
  circleColor: string;

  constructor();
  constructor(
    legendLineName: string,
    percentageAllocationOffset: number | string,
    circleColor: string,
  );
  constructor(
    legendLineName?: string,
    percentageAllocationOffset?: number | string,
    circleColor?: string,
  ) {
    this.legendLineName = legendLineName ?? '';
    this.percentageAllocationOffset = percentageAllocationOffset ?? '';
    this.circleColor = circleColor ?? '';
  }
}
