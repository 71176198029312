export class targetAllocation {
  stocks: number;
  bonds: number;
  shortTermReserves: number;
  others: number;
}

export enum targetAssetMixRoutes {
  selectionTool = '/target-mix-custom-view',
  questionnaire = '/target-mix-questionnaire',
  learnAbout = '/target-mix-about',
  suggestedMix = '/suggested-target-asset-mix',
}

export enum CustomTargetAssetMixTool {
  manualTool = 'manually',
  sliderTool = 'using slider',
}
