export class ModernizedStackedBarChartData {
  chartId: string;
  chartLabel: string;
  chartSlices: ModernizedStackedBarChartSlice[];
  usesMasks: boolean;
  chartBarHeight?: number;
}

export class ModernizedStackedBarChartSlice {
  name: string;
  label: string;
  value: number;
  color: string;
  nonZeroValue?: boolean;
}

export class ModernizedAssetMixColors {
  static getColorMap(): Map<any, any> {
    let chartColorMap = new Map();
    chartColorMap.set('stocks', '#1ca29c');
    chartColorMap.set('bonds', '#bf8200');
    chartColorMap.set('shortTermReserves', '#3f4444');
    chartColorMap.set('others', '#717777');

    return chartColorMap;
  }
}

export class ModernizedStockColors {
  static getColorMap(): Map<any, any> {
    let chartColorMap = new Map();
    chartColorMap.set('domestic', '#1ca29c');
    chartColorMap.set('international', '#037872');
    chartColorMap.set('uncategorized', '#c0dddc');

    return chartColorMap;
  }
}

export class ModernizedBondColors {
  static getColorMap(): Map<any, any> {
    let chartColorMap = new Map();
    chartColorMap.set('domestic', '#bf8200');
    chartColorMap.set('international', '#ffad00');
    chartColorMap.set('uncategorized', '#ffd889');

    return chartColorMap;
  }
}
