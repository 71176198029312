import { environment } from 'src/environments/environment';
import * as domains from '../resources/domains.json';

export class DomainResolver {
  static get environment(): string {
    return environment.ANGULAR_ENV || 'development';
  }

  static getDomain(domain: string): string {
    const domainObject = domains[domain];
    const environment = this.environment;

    if (domainObject && Object.prototype.hasOwnProperty.call(domainObject, environment)) {
      return domainObject[environment];
    } else {
      return '';
    }
  }

  /**
   * Static function that can be used to resolve domains for any string
   * @param originalString with the [[key]] for domain resolution
   */
  static resolve(originalString: string): string {
    const domainPattern = /\[\[(.*)]]/;
    const domainFound = domainPattern.exec(originalString);

    if (!(domainFound && domainFound.length)) {
      return originalString;
    }

    const stringToReplace = domainFound[0];
    const domainToGet = domainFound[1];
    return originalString.replace(stringToReplace, DomainResolver.getDomain(domainToGet));
  }
}
