import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import * as d3 from 'd3';
import { BAR_CHART_VIEW_CONSTANTS } from '../../models/chart.constants';

@Component({
  selector: 'bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements AfterViewInit, OnDestroy {
  @Input() chartData;
  @Input() industryChartId;
  @Input() vanguardChartId;
  @Input() yourCostsChartId;
  height: number = 50;
  width: number = 617;
  @Input() isFullWidth: boolean;
  xFunction;
  industryHashedChartId: string;
  vanguardHashedChartId: string;
  yourCostsHashedChartId: string;

  hasLoadedBarChart: boolean = false;

  ngAfterViewInit() {
    if (this.chartData && !this.hasLoadedBarChart) {
      this.createBarChart();
      this.hasLoadedBarChart = true;
    }
  }

  // eslint-disable-next-line max-statements
  createBarChart() {
    this.setupXFunction();

    this.industryHashedChartId = '#' + this.industryChartId;
    d3.select(this.industryHashedChartId).html('');

    const industrySvg = d3.select(this.industryHashedChartId).append('svg');

    industrySvg
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr('viewBox', '0 0 ' + this.width + ' ' + this.height)
      .style('overflow', 'visible');

    this.vanguardHashedChartId = '#' + this.vanguardChartId;
    d3.select(this.vanguardHashedChartId).html('');

    const vanguardSvg = d3.select(this.vanguardHashedChartId).append('svg');

    vanguardSvg
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr('viewBox', '0 0 ' + this.width + ' ' + this.height)
      .style('overflow', 'visible');

    this.yourCostsHashedChartId = '#' + this.yourCostsChartId;
    d3.select(this.yourCostsHashedChartId).html('');

    const yourCostsSvg = d3.select(this.yourCostsHashedChartId).append('svg');

    yourCostsSvg
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr('viewBox', '0 0 ' + this.width + ' ' + this.height)
      .style('overflow', 'visible');

    this.addIndustryBar(industrySvg);
    this.addVanguardBar(vanguardSvg);
    this.addCostsBar(yourCostsSvg);
  }

  setupXFunction() {
    this.xFunction = d3
      .scaleLinear()
      // domain sets the min and max values of the ticks on the x axis, max returns the largest in the dataset
      .domain([BAR_CHART_VIEW_CONSTANTS.ZERO, this.getMaxDomain()]) //hard coding for now
      // range sets the pixel width for the x axis
      .range([BAR_CHART_VIEW_CONSTANTS.ZERO, this.width]);
  }

  addIndustryBar(svg) {
    const g = svg
      .append('g')
      .attr('class', 'barGroup')
      .attr('stroke', 'white')
      .attr('stroke-width', '3px');

    const industryDataPoint = this.chartData[0];
    const industryBar = industryDataPoint.bars[0];

    g.append('rect')
      .attr('id', 'industryBar')
      .attr('fill', '#507092')
      .attr('width', this.xFunction(industryBar.percent))
      .attr('y', 0)
      .attr('height', this.height)
      .append('title')
      .text(industryDataPoint.label)
      .transition()
      .duration(BAR_CHART_VIEW_CONSTANTS.DURATION)
      .attr('width', this.xFunction(industryBar.percent))
      .delay(function (i: any) {
        return i * BAR_CHART_VIEW_CONSTANTS.HUNDRED;
      });
  }

  addVanguardBar(svg) {
    const g = svg
      .append('g')
      .attr('class', 'barGroup')
      .attr('stroke', 'white')
      .attr('stroke-width', '3px');

    const vgDataPoint = this.chartData[1];
    const vgBar = vgDataPoint.bars[0];

    g.append('rect')
      .attr('id', 'vanguardBar')
      .attr('fill', '#507092')
      .attr('width', this.xFunction(vgBar.percent))
      .attr('y', 0)
      .attr('height', this.height)
      .append('title')
      .text(vgDataPoint.label)
      .transition()
      .duration(BAR_CHART_VIEW_CONSTANTS.DURATION)
      .attr('width', this.xFunction(vgBar.percent))
      .delay(function (i: any) {
        return i * BAR_CHART_VIEW_CONSTANTS.HUNDRED;
      });
  }

  addCostsBar(svg) {
    const g = svg
      .append('g')
      .attr('class', 'barGroup')
      .attr('stroke', 'white')
      .attr('stroke-width', '3px');

    const costsDataPoint = this.chartData[2];
    const costsBar = costsDataPoint.bars[0];

    g.append('rect')
      .attr('id', 'costsBar')
      .attr('fill', '#507092')
      .attr('width', this.xFunction(costsBar.percent))
      .attr('y', 0)
      .attr('height', this.height)
      .append('title')
      .text(costsDataPoint.label)
      .transition()
      .duration(BAR_CHART_VIEW_CONSTANTS.DURATION)
      .attr('width', this.xFunction(costsBar.percent))
      .delay(function (i: any) {
        return i * BAR_CHART_VIEW_CONSTANTS.HUNDRED;
      });
  }

  getMaxDomain(): number {
    let arr = [
      this.chartData[0].bars[0].percent,
      this.chartData[1].bars[0].percent,
      this.chartData[2].bars[0].percent,
    ];
    return arr.sort((a, b) => a - b)[arr.length - 1];
  }

  ngOnDestroy() {
    d3.select(this.industryHashedChartId).remove();
    d3.select(this.vanguardHashedChartId).remove();
    d3.select(this.yourCostsHashedChartId).remove();
  }
}
