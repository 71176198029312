<div #content="pwContent" pwContent>
  <c11n-banner
    [headingText]="content[headerTag]"
    [iconName]="'announcement'"
    [size]="'medium'"
    [variant]="'basic'"
    [hasCloseControl]="hasCloseControl"
  >
    <span
      class="c11n-text-md--crop"
      [innerHtml]="
        content[contentTag] | aemlink : GetLinkText(content[linkTag]) : GetLinkUrl(content[linkTag])
      "
    ></span>
  </c11n-banner>
</div>
