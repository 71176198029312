import { Pipe, PipeTransform } from '@angular/core';
import { DomainResolver } from 'src/app/utility-classes/domain-resolver';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'domainHelper',
})
export class DomainHelperPipe implements PipeTransform {
  transform(value: string): string {
    if (value !== null && value !== undefined) {
      return DomainResolver.getDomain(environment.UserLocation) + value;
    } else {
      return '';
    }
  }
}
