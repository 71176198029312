import { ChartData } from '../chart-models/chart-data';

export class TargetAssetResponse {
  stocks: number;
  bonds: number;
  shortTermReserves: number;
  others: number;

  public static buildChartDataList(data: TargetAssetResponse): ChartData {
    const fraction_digits = 2;
    var retData = new ChartData();

    var labelList = new Array<string>();
    labelList.push('Stocks', 'Bonds', 'Short term reserves', 'Other');

    var chartValueList = new Array<number>();
    chartValueList.push(data.stocks).toFixed(fraction_digits);
    chartValueList.push(data.bonds).toFixed(fraction_digits);
    chartValueList.push(data.shortTermReserves).toFixed(fraction_digits);
    chartValueList.push(data.others).toFixed(fraction_digits);

    retData.labelDetails = labelList;
    retData.percentageDetails = chartValueList;

    return retData;
  }
}
