import { Component, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'target-mix-success-banner',
  templateUrl: './target-mix-success-banner.component.html',
  styleUrls: ['./target-mix-success-banner.component.scss'],
})
export class TargetMixSuccessBannerComponent {
  @Output() activate: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router) {}

  closeBanner() {
    this.activate.emit(false);
  }

  navigateToPage() {
    this.router.navigate(['/target-mix-about']);
  }
}
