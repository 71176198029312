import { Component, OnInit } from '@angular/core';
import { ChartDataService } from '../../services/chart-data-service/chart-data.service';
import { ChartData } from '../../models/chart-models/chart-data';
import { LegendLine } from '../../models/chart-models/legend-line';
import { StockBondDomesticInternationalData } from '../../models/chart-data-response-models/stock-bond-domestic-international-data';
import { Response } from '../../models/chart-data-response-models/response';
import { Observable } from 'rxjs';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';

@Component({
  selector: 'PW1-stock-analysis',
  templateUrl: './stock-analysis.component.html',
  styleUrls: ['./stock-analysis.component.scss'],
})
export class StockAnalysisComponent implements OnInit {
  chartData: ChartData;
  stockChartId = 'stockChartId';
  assetResponseData: Response<StockBondDomesticInternationalData>;
  hasLoadedChartData = false;

  legendArray: Array<LegendLine>;

  modernizedEnabled: Observable<boolean>;

  constructor(
    private chartService: ChartDataService,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {}

  ngOnInit() {
    const ZERO_INDEX = 0;
    this.chartService.getStockAnalysisChartPayload().subscribe((data) => {
      if (data.labelDetails.length !== ZERO_INDEX) {
        this.chartData = data;
        this.legendArray = this.chartService.getStockAnalysisCardLegend();
        this.hasLoadedChartData = true;
      }
    });
  }

  trackDomesticVsInternationalLink() {
    this.adobeAnalyticsService.clickedOnDomesticVsInternational('Stock analysis');
  }
}
