import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { targetAllocation } from '../../models/chart-models/target-allocation';
import { PortfolioDataService } from '../../services/portfolio-data-service/portfolio-data.service';

@Component({
  selector: 'target-asset-allocation-slider-input',
  templateUrl: './target-asset-allocation-slider-input.component.html',
  styleUrls: ['./target-asset-allocation-slider-input.component.scss'],
})
export class TargetAssetAllocationSliderInputComponent implements OnInit {
  stocks: HTMLInputElement;
  bonds: HTMLInputElement;
  slider: HTMLInputElement;

  stockSVG;
  bondSVG;
  @Output() values = new EventEmitter<any>();
  @Input() targetAssetMixCurrent: targetAllocation;

  constructor(private portfolioDataService: PortfolioDataService) {}

  ngOnInit(): void {
    this.stocks = document.getElementById('stock-input') as HTMLInputElement;
    this.bonds = document.getElementById('bond-input') as HTMLInputElement;
    this.slider = document.getElementById('myRange') as HTMLInputElement;

    this.bondSVG = document.getElementById('bond-svg-slider');
    this.stockSVG = document.getElementById('stock-svg-slider');

    this.stocks.value = this.targetAssetMixCurrent.stocks.toString();
    this.bonds.value = this.targetAssetMixCurrent.bonds.toString();
    this.slider.value = this.targetAssetMixCurrent.stocks.toString();

    this.slider.style.background =
      'linear-gradient(to right, #1ca29c ' +
      this.stocks.value +
      '%, #bf8200 ' +
      this.stocks.value +
      '%)';

    this.stockSVG.style.width = this.targetAssetMixCurrent.stocks.toString() + '%';
    this.bondSVG.style.width = this.targetAssetMixCurrent.bonds.toString() + '%';
    this.portfolioDataService.setNewTargetMix(
      this.targetAssetMixCurrent.stocks,
      this.targetAssetMixCurrent.bonds,
      0);
    this.values.emit(true);
  }
  more() {
    this.bonds.value = (parseInt(this.bonds.value) - 5).toString();
    this.stocks.value = (parseInt(this.stocks.value) + 5).toString();
    this.clamp();
    this.setSVGs(this.stocks.value, this.bonds.value);
    this.setSlider(this.stocks.value);
  }
  less() {
    this.bonds.value = (parseInt(this.bonds.value) + 5).toString();
    this.stocks.value = (parseInt(this.stocks.value) - 5).toString();
    this.clamp();
    this.setSVGs(this.stocks.value, this.bonds.value);
    this.setSlider(this.stocks.value);
  }
  changeValueStocks() {
    this.clamp();
    const stocksVal = parseFloat(this.stocks.value);
    if (Number.isInteger(stocksVal)) {
      this.stocks.value = parseInt(this.stocks.value).toString();
    } else {
      this.stocks.value = Math.round(stocksVal).toString();
    }
    this.bonds.value = (100 - parseInt(this.stocks.value)).toString();
    this.setSVGs(this.stocks.value, this.bonds.value);
    this.setSlider(this.stocks.value);
  }
  changeValueBonds() {
    this.clamp();
    const bondsVal = parseFloat(this.bonds.value);
    if (Number.isInteger(bondsVal)) {
      this.bonds.value = parseInt(this.bonds.value).toString();
    } else {
      this.bonds.value = Math.round(bondsVal).toString();
    }
    this.stocks.value = (100 - parseInt(this.bonds.value)).toString();
    this.setSVGs(this.stocks.value, this.bonds.value);
    this.setSlider(this.stocks.value);
  }
  // eslint-disable-next-line complexity
  clamp() {
    if (parseInt(this.stocks.value) > 100) this.stocks.value = '100';
    if (parseInt(this.bonds.value) > 100) this.bonds.value = '100';

    if (parseInt(this.stocks.value) < 0 || this.stocks.value == '') this.stocks.value = '0';
    if (parseInt(this.bonds.value) < 0 || this.bonds.value == '') this.bonds.value = '0';

    this.setSVGs(this.stocks.value, this.bonds.value);
    this.setSlider(this.stocks.value);
  }
  setSVGs(stocksVal, bondsVal) {
    this.stockSVG.style.width = stocksVal + '%';
    this.bondSVG.style.width = bondsVal + '%';
    const stocksNew: number = parseInt(stocksVal) ? parseInt(stocksVal) : 0;
    const bondsNew: number = parseInt(bondsVal) ? parseInt(bondsVal) : 0;
    this.portfolioDataService.setNewTargetMix(stocksNew, bondsNew, 0);
    this.getValues();
  }
  setSlider(stocksVal) {
    this.slider.value = stocksVal;
    this.slider.style.background =
      'linear-gradient(to right, #1ca29c ' + stocksVal + '%, #bf8200 ' + stocksVal + '%)';
  }
  onChangeSlider() {
    this.stocks.value = this.slider.value;
    this.changeValueStocks();
  }
  getValues() {
    this.values.emit(true);
  }
}
