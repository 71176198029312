<div #content="pwContent" pwContent class="tableComponentWrapper">
  <PW1-alert-notifications [pageName]="'/bond-analysis/nine-box'"></PW1-alert-notifications>
  <div class="tableHeaderWrapper">
    <div class="tableTitle">
      <span [innerHTML]="content['PWBond_DetailsCard_Bond9BoxStyle']"></span>
      <button class="icon icon-question-mark-black" (click)="openNineBoxDrawer()" aria-label="Help Button"></button>
    </div>
  </div>

  <table>
    <tr class="headers">
      <th rowspan="5" class="title-y">
        <h3>
          <span [innerHTML]="content['PWBond_DetailsCard_Bond9BoxStyle_CreditQuality']"></span>
        </h3>
      </th>
      <th colspan="4" class="title-x">
        <h3>
          <span
            [innerHTML]="content['PWBond_DetailsCard_Bond9BoxStyle_InterestRateSensitivity']"
          ></span>
        </h3>
      </th>
    </tr>
    <tr>
      <th></th>
      <th scope="col" *ngFor="let content of xHeaders" class="headerX">{{ content }}</th>
    </tr>
    <tr *ngFor="let data of dataArray; let i = index">
      <th scope="row" class="headerY">{{ yHeaders[i] }}</th>
      <td *ngFor="let value of data; let x = index" class="content"> {{ dataArray[x][i] }}</td>
    </tr>
  </table>
</div>
