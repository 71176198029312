import { Component, Input } from '@angular/core';

@Component({
  selector: 'PW1-investment-costs-disclaimer-accordion',
  templateUrl: './investment-costs-disclaimer-accordion.component.html',
  styleUrls: ['./investment-costs-disclaimer-accordion.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'investment-costs-disclaimer-accordion c11n-space-stack-3x',
  },
})
export class InvestmentCostsDisclaimerAccordionComponent {
  @Input() printView: boolean = false;

  GetLinkUrl(content: string) {
    if (content) {
      return content.split(' : ')[1];
    }
    return null;
  }

  GetLinkText(content: string) {
    if (content) {
      return content.split(' : ')[0];
    }
    return null;
  }
}
