import { Component, OnInit, Input } from '@angular/core';
import { HoldingsViewHelperService } from '../../../../../services/holdings-view-helper/holdings-view-helper.service';

@Component({
  selector: 'PW1-hypothetical-investment-style-table',
  templateUrl: './hypothetical-investment-style-table.component.html',
  styleUrls: ['./hypothetical-investment-style-table.component.scss'],
})
export class HypotheticalInvestmentStyleTableComponent implements OnInit {
  @Input() chartData;
  @Input() hypotheticalData;

  tableData = [];
  tableDataHypothetical = [];
  holdingsTablesData: any[];

  loaded = false;
  loadedHypothetical = false;
  viewByHoldings = false;
  holdingsTotal: number = 0;
  holdingsTotalHypothetical: number = 0;

  valueYourFundsAndStocks: number;
  blendYourFundsAndStocks: number;
  growthYourFundsAndStocks: number;
  uncategorized: number;
  total: number = 0;

  valueYourFundsAndStocksHypothetical: number;
  blendYourFundsAndStocksHypothetical: number;
  growthYourFundsAndStocksHypothetical: number;
  uncategorizedHypothetical: number;
  totalHypothetical: number = 0;

  constructor(private holdingsViewHelper: HoldingsViewHelperService) {}

  ngOnInit() {
    if (this.chartData && this.hypotheticalData) {
      this.setTableData();
      this.setTableDataHypothetical();
      this.setBothHoldingsTables();
    }
  }

  setTableData() {
    this.valueYourFundsAndStocks = this.chartData.stockInvestmentStyle.chart.value;
    this.blendYourFundsAndStocks = this.chartData.stockInvestmentStyle.chart.blend;
    this.growthYourFundsAndStocks = this.chartData.stockInvestmentStyle.chart.growth;
    this.uncategorized = this.chartData.stockInvestmentStyle.chart.uncategorized;
    this.total =
      this.valueYourFundsAndStocks +
      this.blendYourFundsAndStocks +
      this.growthYourFundsAndStocks +
      this.uncategorized;

    this.tableData = [
      { size: 'Value', yours: this.valueYourFundsAndStocks + '%' },
      { size: 'Blend', yours: this.blendYourFundsAndStocks + '%' },
      { size: 'Growth', yours: this.growthYourFundsAndStocks + '%' },
    ];

    if (this.uncategorized > 0) {
      this.tableData.push({ size: 'Uncategorized', yours: this.uncategorized + '%' });
    }

    this.tableData.push({ size: 'Total', yours: this.total.toFixed(0) + '%' });

    this.loaded = true;
  }

  setTableDataHypothetical() {
    this.valueYourFundsAndStocksHypothetical =
      this.hypotheticalData.stockInvestmentStyle.chart.value;
    this.blendYourFundsAndStocksHypothetical =
      this.hypotheticalData.stockInvestmentStyle.chart.blend;
    this.growthYourFundsAndStocksHypothetical =
      this.hypotheticalData.stockInvestmentStyle.chart.growth;
    this.uncategorizedHypothetical = this.hypotheticalData.stockInvestmentStyle.chart.uncategorized;
    this.totalHypothetical =
      this.valueYourFundsAndStocksHypothetical +
      this.blendYourFundsAndStocksHypothetical +
      this.growthYourFundsAndStocksHypothetical +
      this.uncategorizedHypothetical;

    this.tableDataHypothetical = [
      { size: 'Value', yours: this.valueYourFundsAndStocksHypothetical + '%' },
      { size: 'Blend', yours: this.blendYourFundsAndStocksHypothetical + '%' },
      { size: 'Growth', yours: this.growthYourFundsAndStocksHypothetical + '%' },
    ];

    if (this.uncategorizedHypothetical > 0) {
      this.tableDataHypothetical.push({
        size: 'Uncategorized',
        yours: this.uncategorizedHypothetical + '%',
      });
    }

    this.tableDataHypothetical.push({
      size: 'Total',
      yours: this.totalHypothetical.toFixed(0) + '%',
    });

    this.loadedHypothetical = true;
  }

  generateTableDataByHoldings() {
    const categorizationObjects = [
      { dataPoint: 'blend', title: 'Current blend stocks & stock funds' },
      { dataPoint: 'growth', title: 'Current growth stocks & stock funds' },
      { dataPoint: 'value', title: 'Current value stocks & stock funds' },
      { dataPoint: 'uncategorized', title: 'Current uncategorized stocks & stock funds' },
    ];

    const holdingsArray: any[] =
      this.holdingsViewHelper.getGeneratedDataWithoutDifferenceForTesterTool(
        categorizationObjects,
        this.chartData,
        'stockInvestmentStyle',
      );
    this.holdingsTotal = this.holdingsViewHelper.calculateTotal(holdingsArray);
    return holdingsArray;
  }

  generateTableDataByHoldingsHypothetical() {
    const categorizationObjects = [
      { dataPoint: 'blend', title: 'Hypothetical blend stocks & stock funds' },
      { dataPoint: 'growth', title: 'Hypothetical growth stocks & stock funds' },
      { dataPoint: 'value', title: 'Hypothetical value stocks & stock funds' },
      { dataPoint: 'uncategorized', title: 'Hypothetical uncategorized stocks & stock funds' },
    ];

    const holdingsArray: any[] =
      this.holdingsViewHelper.getGeneratedDataWithoutDifferenceForTesterTool(
        categorizationObjects,
        this.hypotheticalData,
        'stockInvestmentStyle',
      );
    this.holdingsTotalHypothetical = this.holdingsViewHelper.calculateTotal(holdingsArray);
    return holdingsArray;
  }

  changeTableView(val: boolean) {
    this.viewByHoldings = val;
  }

  setBothHoldingsTables() {
    const tableDataByHoldingsActual = this.generateTableDataByHoldings();
    const tableDataByHoldingsHypothetical = this.generateTableDataByHoldingsHypothetical();

    const unfiltered = [
      {
        actual: this.getTable(tableDataByHoldingsActual, 'stockInvestmentStyle', 'blend'),
        hypothetical: this.getTable(
          tableDataByHoldingsHypothetical,
          'stockInvestmentStyle',
          'blend',
        ),
      },
      {
        actual: this.getTable(tableDataByHoldingsActual, 'stockInvestmentStyle', 'growth'),
        hypothetical: this.getTable(
          tableDataByHoldingsHypothetical,
          'stockInvestmentStyle',
          'growth',
        ),
      },
      {
        actual: this.getTable(tableDataByHoldingsActual, 'stockInvestmentStyle', 'value'),
        hypothetical: this.getTable(
          tableDataByHoldingsHypothetical,
          'stockInvestmentStyle',
          'value',
        ),
      },
      {
        actual: this.getTable(tableDataByHoldingsActual, 'stockInvestmentStyle', 'uncategorized'),
        hypothetical: this.getTable(
          tableDataByHoldingsHypothetical,
          'stockInvestmentStyle',
          'uncategorized',
        ),
      },
    ];

    this.holdingsTablesData = unfiltered.filter((category) => {
      return category.actual.accounts.length > 0 || category.hypothetical.accounts.length > 0;
    });
  }

  getTable(dataSet, dataNode, categorization) {
    const filteredTableData = dataSet.filter((tableData) => {
      return (
        tableData && tableData.categorization === categorization && tableData.dataNode === dataNode
      );
    });

    return filteredTableData[0];
  }
}
