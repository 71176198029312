import { Holding } from './holding';
import { PortfolioAlert } from './PortfolioAlert';

export class Response<T> {
  holdings: Array<Holding<T>>;
  chart: T;
  applicableBalance: number;
  alertList: Array<PortfolioAlert>;
  excludedOutsideInvestments?: boolean;
}
