import { ChartData } from '../chart-models/chart-data';
import { INDEX_CONSTANTS } from '../chart.constants';

export class BondTaxData {
  taxable: number;
  taxExempt: number;

  // removing this logic from the data service to the specific classes - strategy type pattern
  public static buildChartDataList(data: BondTaxData): ChartData {
    var chartData = new ChartData();

    var labelList = new Array<string>();
    labelList.push('Taxable', 'Tax Exempt');

    var chartValueList = new Array<number>();
    chartValueList.push(data.taxable).toFixed(INDEX_CONSTANTS.TWO);
    chartValueList.push(data.taxExempt).toFixed(INDEX_CONSTANTS.TWO);

    return chartData;
  }
}
