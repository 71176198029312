import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'PW1-table-view-controls',
  templateUrl: './table-view-controls.component.html',
  styleUrls: ['./table-view-controls.component.scss'],
})
export class TableViewControlsComponent implements OnInit {
  @Output() tableViewChanged = new EventEmitter<boolean>();
  form: FormGroup;

  ngOnInit(): void {
    this.form = new FormGroup({
      viewByHoldings: new FormControl('false'),
    });
    this.tableViewChanged.emit(false);
  }
  radioChange(value) {
    if (value.inputValue === 'true') {
      this.tableViewChanged.emit(true);
    } else {
      this.tableViewChanged.emit(false);
    }
  }
}
