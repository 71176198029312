<section #content="pwContent" pwContent id="stock-analysis-content" tabindex="-1">
  <mat-card class="cardBody">
    <mat-card-actions>
      <button class="navigateLink" aria-label="Back to overview" (click)="goBackToOverview()">
        <i class="icon icon-left-arrow-blue"></i>
        <span
          class="center-vertically"
          [innerHTML]="content['TesterTool_HypotheticalStockAnalysis_BackToOverview']"
        ></span>
      </button>
    </mat-card-actions>
    <div class="titleWrapper">
      <h2 class="pageTitle" [innerHTML]="content['TesterTool_HypotheticalStockAnalysis']"></h2>
      <button
        class="icon icon-question-mark-black "
        (click)="selectSection('Stock analysis')"
      ></button>
    </div>
    <mat-card-content class="mainCardContent">
      <account-group-assets></account-group-assets>
      <div *ngIf="hasLoadedChartData && hasLoadedHypotheticalChartData" class="chartWrapper">
        <div class="cardChart current">
          <PW1-donut-chart-component
            [id]="'testerToolStockPageActualId'"
            [dataSet]="chartData"
            [height]="188"
            [width]="188"
          ></PW1-donut-chart-component>
          <PW1-chart-legend [legendArray]="legendArrayModern"> </PW1-chart-legend>
        </div>
        <div class="cardChart hypothetical">
          <PW1-donut-chart-component
            [id]="'testerToolStockPageHypotheticalId'"
            [dataSet]="hypotheticalChartData"
            [height]="188"
            [width]="188"
          ></PW1-donut-chart-component>
          <PW1-chart-legend [legendArray]="hypotheticalLegendArrayModern"> </PW1-chart-legend>
        </div>
      </div>
      <PW1-hypothetical-stock-analysis-tab-table></PW1-hypothetical-stock-analysis-tab-table>
    </mat-card-content>
  </mat-card>
</section>
