<div #content="pwContent" pwContent>
    <div class="tableHeaderWrapper">
        <div class="spacer"></div>
        <PW1-table-view-controls (tableViewChanged)="changeTableView($event)"></PW1-table-view-controls>
    </div>
    <ng-container *ngIf="loaded && loadedHypothetical && !viewByHoldings">
        <div class="side-by-side-tables">
            <div class="table-wrapper">
                <h3>Current market capitalization</h3>
                <table role="presentation" class="table holdingsTable">
                    <tr>
                        <th [innerHTML]="content['TesterTool_HypotheticalStockAnalysis_MarketCap_Table_Size']"></th>
                        <th [innerHTML]="content['TesterTool_HypotheticalStockAnalysis_MarketCap_Table_USStockPortfolio']"></th>
                        <th [innerHTML]="content['TesterTool_HypotheticalStockAnalysis_MarketCap_Table_USStockMarket']"></th>
                        <th [innerHTML]="content['TesterTool_HypotheticalStockAnalysis_MarketCap_Table_Difference']"></th>
                    </tr>
                    <tr *ngFor="let tableRow of tableData" [ngClass]="{'totalRow': tableRow.size === 'Total'}">
                        <td>{{tableRow.size}}</td>
                        <td>{{tableRow.yourStock }}</td>
                        <td>{{tableRow.market }}</td>
                        <td>{{tableRow.difference }}</td>
                    </tr>
                </table>
            </div>
            <div class="table-wrapper">
                <h3>Hypothetical market capitalization</h3>
                <table role="presentation" class="table holdingsTable hypothetical">
                    <tr>
                        <th [innerHTML]="content['TesterTool_HypotheticalStockAnalysis_MarketCap_Table_Size']"></th>
                        <th [innerHTML]="content['TesterTool_HypotheticalStockAnalysis_MarketCap_Table_USStockPortfolio']"></th>
                        <th [innerHTML]="content['TesterTool_HypotheticalStockAnalysis_MarketCap_Table_USStockMarket']"></th>
                        <th [innerHTML]="content['TesterTool_HypotheticalStockAnalysis_MarketCap_Table_Difference']"></th>
                    </tr>
                    <tr *ngFor="let tableRow of tableDataHypothetical" [ngClass]="{'totalRow': tableRow.size === 'Total'}">
                        <td>{{tableRow.size}}</td>
                        <td>{{tableRow.yourStock }}</td>
                        <td>{{tableRow.market }}</td>
                        <td>{{tableRow.difference }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="loaded && loadedHypothetical && viewByHoldings">
        <ng-container *ngFor="let tableData of holdingsTablesData; let last = last">
            <div class="side-by-side-tables-categories" [ngClass]="{ last: last }"  *ngIf="tableData.actual.accounts.length > 0 || tableData.hypothetical.accounts.length > 0">
                <div class="actual-side">
                    <h3>{{tableData.actual.header}}</h3>
                    <table role="presentation" class="table holdingsTable" *ngIf="tableData.actual.accounts.length > 0">
                        <ng-container [ngTemplateOutlet]="holdingsTableHeaders"></ng-container>
                        <tr *ngFor="let acct of tableData.actual.accounts" [ngClass]="{'totalRow': acct.name === 'Subtotal', 'accountName': !acct.value && !acct.percentage}">
                            <td>{{acct.name}}</td>
                            <td *ngIf="acct.value">{{acct.value | currency}}</td>
                            <td *ngIf="acct.value">{{acct.percentage | percentFormatted:null: acct.value}}</td>
                            <td *ngIf="acct.value">{{acct.difference | percentFormatted}}</td>
                        </tr>
                    </table>
                    <div *ngIf="!tableData.actual.accounts.length > 0">
                        <p><em>No current holdings.</em><p>
                    </div>
                </div>
                <div class="hypothetical-side">
                    <h3>{{tableData.hypothetical.header}}</h3>
                    <table role="presentation" class="table holdingsTable hypothetical" *ngIf="tableData.hypothetical.accounts.length > 0">
                        <ng-container [ngTemplateOutlet]="holdingsTableHeaders"></ng-container>
                        <tr *ngFor="let acct of tableData.hypothetical.accounts" [ngClass]="{'totalRow': acct.name === 'Subtotal', 'accountName': !acct.value && !acct.percentage}">
                            <td>{{acct.name}}</td>
                            <td *ngIf="acct.value">{{acct.value | currency}}</td>
                            <td *ngIf="acct.value">{{acct.percentage | percentFormatted: null: acct.value}}</td>
                            <td *ngIf="acct.value">{{acct.difference | percentFormatted}}</td>
                        </tr>
                    </table>
                    <div *ngIf="!tableData.hypothetical.accounts.length > 0">
                        <p><em>No current holdings.</em><p>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="side-by-side-tables-totals">
            <div class="actual-side">
                <table role="presentation" class="table holdingsTable">
                    <tr class="totalRow">
                        <td [innerHTML]="content['TesterTool_HypotheticalStockAnalysis_MarketCap_Table_Total']"></td>
                        <td>{{chartData.stockMarketCap.applicableBalance | currency}}</td>
                        <td>{{this.holdingsTotal + '%'}}</td>
                        <td></td>
                    </tr>
                </table>
            </div>
            <div class="hypothetical-side">
                <table role="presentation" class="table holdingsTable hypothetical">
                    <tr class="totalRow">
                        <td [innerHTML]="content['TesterTool_HypotheticalStockAnalysis_MarketCap_Table_Total']"></td>
                        <td>{{hypotheticalData.stockMarketCap.applicableBalance | currency}}</td>
                        <td>{{this.holdingsTotalHypothetical + '%'}}</td>
                        <td></td>
                    </tr>
                </table>
            </div>
        </div>
    </ng-container>
    <ng-template #holdingsTableHeaders>
        <tr>
            <th [innerHTML]="content['TesterTool_HypotheticalStockAnalysis_MarketCap_Table_Name']"></th>
            <th [innerHTML]="content['TesterTool_HypotheticalStockAnalysis_MarketCap_Table_Value']"></th>
            <th [innerHTML]="content['TesterTool_HypotheticalStockAnalysis_MarketCap_Table_PercentPortfolio']"></th>
            <th [innerHTML]="content['TesterTool_HypotheticalStockAnalysis_MarketCap_Table_Difference']"></th>
        </tr>
    </ng-template>
</div>
