<div class="main-wrapper" #content="pwContent" pwContent>
  <div class="svg-wrapper">
    <svg id="stock-svg-slider" class="stocks-svg" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <rect x="0" y="0" width="100%" height="100%" style="fill:#1ca29c"/>
      <rect x="0" y="0" width="100%" height="100%" fill="url(#stripey-lines)" />
      <pattern id="stripey-lines" width="10" height="10" patternUnits="userSpaceOnUse" patternTransform="rotate(45 0 0)"><line x1="0" y1="0" x2="0" y2="10" style="stroke:white" stroke-width="3px"></line></pattern><mask id="mask-for-patterned-bars"><rect x="0" y="0" width="100%" height="100%" fill="url(#stripey-lines)"></rect></mask>
    </svg>
    <svg id="bond-svg-slider" class="stocks-svg" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <rect x="0" y="0" width="100%" height="100%" style="fill:#bf8200"/>
      <rect x="0" y="0" width="100%" height="100%" fill="url(#stripey-lines)" />
      <pattern id="stripey-lines" width="10" height="10" patternUnits="userSpaceOnUse" patternTransform="rotate(45 0 0)"><line x1="0" y1="0" x2="0" y2="10" style="stroke:white" stroke-width="3px"></line></pattern><mask id="mask-for-patterned-bars"><rect x="0" y="0" width="100%" height="100%" fill="url(#stripey-lines)"></rect></mask>
    </svg>
</div>
  <div class="input-wrapper">
  <div class="asset-input">
    <div class="label">
      <svg>
      <circle cx="8" cy="8" r="5" fill="#1ca29c"/>
    </svg>
      {{content["PWSliderTool_Stocks"]}}</div>
    <c11n-input
      [size]="'medium'"
    >
      <input c11nInput
             class="number-input"
             type="number"
             id="stock-input"
             min="0"
             max="100"
             name="stock-input"
             (blur)="changeValueStocks()"
             [placeholder]=50
             aria-describedby="'Stock input field'"
      >
    </c11n-input>
    <button type="button" class="c11n-button c11n-button--small c11n-button--secondary"
    (click)="more()">
      <span class="c11n-button__box" [innerHTML]="content['PWSliderTool_Stocks_MoreRisk']">
      </span>
    </button>
    <div class="sub-risk">{{content["PWSliderTool_Stocks_MoreStocks"]}}</div>
  </div>
  <div class="slider-tool">
    <input class="slider" type="range" min="0" max="100" value="50" step="1" class="slider" id="myRange" (input)="onChangeSlider()">
  </div>
  <div class="asset-input">
    <div class="label"> <svg>
      <circle cx="8" cy="8" r="5" fill="#bf8200" />
    </svg>{{content["PWSliderTool_Bonds"]}}</div>
    <c11n-input
      [size]="'medium'"
    >
      <input c11nInput
             class="number-input"
             type="number"
             id="bond-input"
             min="0"
             max="100"
             name="stock-input"
             (blur)="changeValueBonds()"
             [placeholder]=50
             aria-describedby="'Stock input field'"
      >
    </c11n-input>
    <button type="button" class="c11n-button c11n-button--small c11n-button--secondary"
    (click)="less()">
      <span class="c11n-button__box" [innerHTML]="content['PWSliderTool_Bonds_LessRisk']">
      </span>
    </button>    
    <div class="sub-risk">{{content["PWSliderTool_Bonds_MoreBonds"]}}</div>
  </div>
</div>