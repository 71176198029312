import { Component, Input } from '@angular/core';
import { LegendLine } from '../../models/chart-models/legend-line';

@Component({
  selector: 'PW1-chart-legend',
  templateUrl: './chart-legend.component.html',
  styleUrls: ['./chart-legend.component.scss'],
})
export class ChartLegendComponent {
  @Input()
  legendArray: Array<LegendLine>;
  @Input()
  legendHeader: string;
}
