import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DrawerTrackerService } from '@vanguard/pfx-components';
import { DRAWER_CONSTANTS } from 'src/app/models/drawer.constants';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';
import {
  CategorizationObject,
  HoldingsTableCategorization,
  HoldingsTableData,
} from '../../../models/holdings-table';
import { HoldingsTableBuilderService } from '../../../services/holdings-table-builder/holdings-table-builder.service';

@Component({
  selector: 'investment-style-table',
  templateUrl: './investment-style-table.component.html',
  styleUrls: ['./investment-style-table.component.scss'],
})
export class InvestmentStyleTableComponent implements OnInit {
  @Input() chartData;

  tableData = [];

  holdingsTableDataModernized: HoldingsTableData;

  loaded = false;
  viewByHoldings = false;
  holdingsTotal: number = 0;

  valueYourFundsAndStocks: number;
  blendYourFundsAndStocks: number;
  growthYourFundsAndStocks: number;
  uncategorized: number;
  total: number = 0;

  constructor(
    private holdingsTableBuilderService: HoldingsTableBuilderService,
    private router: Router,
    private drawerTrackerService: DrawerTrackerService,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {}

  ngOnInit() {
    if (this.chartData) {
      this.setTableData();
      this.getHoldingsDataModernized();
    }
  }

  setTableData() {
    this.valueYourFundsAndStocks = this.chartData.stockInvestmentStyle.chart.value;
    this.blendYourFundsAndStocks = this.chartData.stockInvestmentStyle.chart.blend;
    this.growthYourFundsAndStocks = this.chartData.stockInvestmentStyle.chart.growth;
    this.uncategorized = this.chartData.stockInvestmentStyle.chart.uncategorized;
    this.total =
      this.valueYourFundsAndStocks +
      this.blendYourFundsAndStocks +
      this.growthYourFundsAndStocks +
      this.uncategorized;

    this.tableData = [
      { size: 'Blend', yours: this.blendYourFundsAndStocks + '%' },
      { size: 'Growth', yours: this.growthYourFundsAndStocks + '%' },
      { size: 'Value', yours: this.valueYourFundsAndStocks + '%' },
    ];

    if (this.uncategorized > 0) {
      this.tableData.push({ size: 'Uncategorized', yours: this.uncategorized + '%' });
    }

    this.tableData.push({ size: 'Total', yours: this.total.toFixed(0) + '%' });

    this.loaded = true;
  }

  getHoldingsDataModernized() {
    this.holdingsTableDataModernized = this.generateTableDataByHoldingsModernized();
  }

  generateTableDataByHoldingsModernized(): HoldingsTableData {
    const applicableBalance: number = this.chartData.stockInvestmentStyle.applicableBalance;
    const categorizationObjects: CategorizationObject[] = [
      { title: 'Blend stocks & stock funds', categorization: 'blend' },
      { title: 'Growth stocks & stock funds', categorization: 'growth' },
      { title: 'Value stocks & stock funds', categorization: 'value' },
      { title: 'Uncategorized stocks & stock funds', categorization: 'uncategorized' },
    ];
    const holdingsDataByCategorizations: HoldingsTableCategorization[] =
      this.holdingsTableBuilderService.getHoldingsByCategorizations(
        this.chartData.stockInvestmentStyle,
        categorizationObjects,

      );

    return {
      categorizations: holdingsDataByCategorizations,
      holdingsTotal: applicableBalance, // TODO: IS THIS VALUE THE CORRECT ONE?
    };
  }

  changeTableView(val: boolean) {
    this.viewByHoldings = val;
    if (val) {
      this.adobeAnalyticsService.clickedOnHoldingsDetails();
    }
  }

  navigateToLearnMorePage() {
    this.adobeAnalyticsService.clickedOnLearnMore('/learn-more/style-exposure');
    this.router.navigate(['/learn-more/style-exposure']);
  }
  openInvestmentStyleDrawer() {
    this.drawerTrackerService.setSelectSection(DRAWER_CONSTANTS.INVESTEMENT_STYLE);
  }
}
