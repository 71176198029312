import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggerCode } from '../../../models/logger';
import { Observable } from 'rxjs';
import {
  AccountInfo,
} from '../../models/chart-data-response-models/account-group-details';
import { LoggerService } from '../../services/logger/logger.service';
import { PortfolioCardsService } from '../../services/portfolio-cards/portfolio-cards.service';
import { PortfolioDataService } from '../../services/portfolio-data-service/portfolio-data.service';
import {
  CustomTargetAssetMixTool,
  targetAllocation,
} from '../../models/chart-models/target-allocation';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';
import { TargetAssetRequest, TargetAssetType } from 'src/app/models/target-asset-request';

@Component({
  selector: 'PW1-target-allocation-custom-view',
  templateUrl: './target-allocation-custom-view.component.html',
  styleUrls: ['./target-allocation-custom-view.component.scss'],
})
export class TargetAllocationCustomViewComponent implements OnInit {
  otherTool: string;
  selectedAccountGroup: AccountInfo;
  mix;
  viewId: string;
  errorState: boolean;

  bestYear: string;
  worstYear: string;
  worstYearAverage: string;
  bestYearAverage: string;
  averageReturn: string;
  fromYear: string;
  toYear: string;
  yearsWithLoss: string;
  totalYears: string;
  percentYearsOfLoss: string;
  hasLoadedTargetMixRiskReturnData: boolean = false;
  targetAssetMix: TargetAssetRequest;
  saveTargetMixError: boolean = false;
  viewName: string;
  isNewMix: boolean;
  TARGET_ASSET_MIX_STATUS = {
    NEW: 'A',
    UPDATE: 'U',
  };
  chartDataPayload$: Observable<any>;
  targetAssetMixCurrent: targetAllocation = {
    stocks: 50,
    bonds: 50,
    shortTermReserves: 0,
    others: 0,
  };
  chartData;

  // eslint-disable-next-line max-params
  constructor(
    private router: Router,
    private loggerService: LoggerService,
    private adobeAnalyticsService: AdobeAnalyticsService,
    private portfolioDataService: PortfolioDataService,
    private cardsService: PortfolioCardsService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.adobeAnalyticsService.trackRoutingData(data);
    });

    this.otherTool = 'using slider';
    this.mix = this.portfolioDataService.getNewTargetMix();
    this.chartDataPayload$ = this.portfolioDataService.getChartDataPayload();
    this.chartDataPayload$.subscribe((data) => {
      this.chartData = data;
      if (data.assetTypeTarget.shortTermReserves === 0) {
        this.otherTool = 'manually';
        if (data.assetTypeTarget.stocks || data.assetTypeTarget.bonds) {
          this.targetAssetMixCurrent.stocks = data.assetTypeTarget.stocks;
          this.targetAssetMixCurrent.bonds = data.assetTypeTarget.bonds;
        }
      } else {
        this.otherTool = 'using slider';
      }
    });

    this.portfolioDataService.getSelectedAccountGroupPayload().subscribe((accGrp) => {
      this.selectedAccountGroup = accGrp;
      this.setViewIdAndViewName(accGrp);
      this.setPostObjectPorperties();
    })
    this.getTargetMixRiskReturn();
    this.updateTargetAssetMix();
  }

  setPostObjectPorperties() {
    if (this.viewId) {
      this.chartDataPayload$.subscribe((data) => {
        if (
          Object.values(data.assetTypeTarget).findIndex((targetValue) => targetValue != 0) == -1
        ) {
          this.isNewMix = true;
        } else {
          this.isNewMix = false;
        }
      });
    } else {
      this.isNewMix = true;
    }
  }

  goBackToPortfolioWatch() {
    this.adobeAnalyticsService.clickedBackToOverview();
    this.router.navigate(['']);
  }

  changeTool() {
    if (this.otherTool === 'using slider') {
      this.otherTool = 'manually';
      this.adobeAnalyticsService.clickedOnCustomToolSelector(CustomTargetAssetMixTool.sliderTool);
    } else {
      this.otherTool = 'using slider';
      this.adobeAnalyticsService.clickedOnCustomToolSelector(CustomTargetAssetMixTool.manualTool);
    }
    this.saveTargetMixError = false;
    this.getTargetMixRiskReturn();
    this.updateTargetAssetMix();
  }

  setViewIdAndViewName(accountGroup: AccountInfo) {
      this.viewId = accountGroup ? accountGroup.ViewID : "";
      this.viewName = accountGroup?.GroupName;
  }

  updateTargetAssetMix() {
    this.targetAssetMix = new TargetAssetRequest(this.viewId ?? "");
    if (this.isNewMix){
      this.targetAssetMix = new TargetAssetRequest(this.viewId ?? "", this.viewName);
    }
    this.targetAssetMix.addAssetAllocation(TargetAssetType.Stocks,  this.mix[0]);  
    this.targetAssetMix.addAssetAllocation(TargetAssetType.Bonds, this.mix[1]);
    this.targetAssetMix.addAssetAllocation(TargetAssetType.ShortTermReserves, this.mix[2]);
  }

  getTargetMixRiskReturn() {
    this.mix = this.portfolioDataService.getNewTargetMix();
    let total = this.mix.reduce((prev, curr) => (Number(prev) || 0) + (Number(curr) || 0));

    if (total === 100) {
      this.portfolioDataService.loadTargetMixRiskReturnPayload(
        this.mix[0],
        this.mix[1],
        this.mix[2] ? this.mix[2] : '0',
        this.mix[3] ? this.mix[3] : '0',
      );

      this.portfolioDataService.getTargetMixRiskReturnPayload().subscribe((data: any) => {
        if (data) {
          this.hasLoadedTargetMixRiskReturnData = true;
          this.bestYear = data.riskAnalysisType1.bestYear;
          this.worstYear = data.riskAnalysisType1.worstYear;
          this.worstYearAverage = data.riskAnalysisType1.worstYearAverage;
          this.bestYearAverage = data.riskAnalysisType1.bestYearAverage;
          this.averageReturn = data.riskAnalysisType1.averageReturn;
          this.fromYear = data.riskAnalysisType1.fromYear;
          this.toYear = data.riskAnalysisType1.toYear;
          this.yearsWithLoss = data.riskAnalysisType1.yearsWithLoss;
          this.totalYears = data.riskAnalysisType1.totalYears;
          this.percentYearsOfLoss = data.riskAnalysisType1.percentYearsOfLoss;
        } else {
          this.hasLoadedTargetMixRiskReturnData = false;
        }
      });
    }
  }

  getErrorState($event: any) {
    this.errorState = $event;
  }

  saveTargetAllocation(event) {
    event.currentTarget.disabled = true;
    this.adobeAnalyticsService.clickedOnSaveNewTargetMix();
    this.loggerService.info(
      {
        message: 'Saving Target Mix from Custom Tool',
      },
      LoggerCode.SAVING_TARGET_MIX_FROM_CUSTOM_TOOL,
    );

    this.portfolioDataService.setTargetAllocation(this.targetAssetMix, this.isNewMix).subscribe(
      () => {
        this.portfolioDataService.setTargetMixSuccess(true);
        this.saveTargetMixError = false;
        if (!this.viewId) {
          this.portfolioDataService.setSelectedAccountGroupDetails(this.selectedAccountGroup);
          this.portfolioDataService.loadChartDataPayload();
        } else {
          this.chartData.assetTypeTarget = {
            stocks: this.targetAssetMix.getAssetAllocation(TargetAssetType.Stocks).percentageAssetAllocation,
            bonds: this.targetAssetMix.getAssetAllocation(TargetAssetType.Bonds).percentageAssetAllocation,
            shortTermReserves: this.targetAssetMix.getAssetAllocation(TargetAssetType.ShortTermReserves).percentageAssetAllocation,
          };
          this.chartData.riskReturnAnalysisResponse.targetAllocationRiskAnalysis = {
            averageReturn: this.averageReturn,
            bestYear: this.bestYear,
            bestYearAverage: this.bestYearAverage,
            fromYear: this.fromYear,
            percentYearsOfLoss: this.percentYearsOfLoss,
            toYear: this.toYear,
            totalYears: this.totalYears,
            worstYear: this.worstYear,
            worstYearAverage: this.worstYearAverage,
            yearsWithLoss: this.yearsWithLoss,
          };
          this.portfolioDataService.updateChartData(this.chartData);
        }
        this.cardsService.navigateToPage('/asset-mix');
        this.loggerService.info(
          {
            message: 'Saved new Target Mix',
          },
          LoggerCode.TARGET_MIX_SAVED,
        );
      },
      (error) => {
        this.loggerService.info(
          {
            message: error.message,
            status: error.status,
          },
          LoggerCode.TARGET_MIX_NOT_SAVED,
        );
        this.saveTargetMixError = true;
      },
    );
  }

  blur() {
    (document.activeElement as HTMLElement).blur();
  }
}
