import { Component, OnInit, Input } from '@angular/core';
import { INDEX_CONSTANTS } from '../../../../../models/chart.constants';
import { HoldingsViewHelperService } from '../../../../../services/holdings-view-helper/holdings-view-helper.service';
import * as _ from 'lodash';

@Component({
  selector: 'PW1-hypothetical-industry-sectors-table',
  templateUrl: './hypothetical-industry-sectors-table.component.html',
  styleUrls: ['./hypothetical-industry-sectors-table.component.scss'],
})
export class HypotheticalIndustrySectorsTableComponent implements OnInit {
  @Input() chartData;
  @Input() hypotheticalData;

  loaded = false;
  loadedHypothetical = false;
  viewByHoldings = false;

  tableData = [];
  tableDataHypothetical = [];
  holdingsTablesData: any[];

  holdingsTotal: number = 0;
  yourStock = [];
  stockUS = [];
  chartDifference = [];

  holdingsTotalHypothetical: number = 0;
  yourStockHypothetical = [];
  stockUSHypothetical = [];
  chartDifferenceHypothetical = [];

  categorizationKeys: string[];

  constructor(private holdingsViewHelper: HoldingsViewHelperService) {}

  ngOnInit() {
    if (this.chartData && this.hypotheticalData) {
      this.setTableData();
      this.setTableDataHypothetical();
      this.categorizationKeys = this.getAllCategorizationKeys();
      this.setBothHoldingsTables();
    }
  }

  setTableData() {
    this.yourStock = Object.entries(this.chartData.stockIndustrySector.chart).sort((a, b) =>
      a[0].localeCompare(b[0]),
    );
    this.stockUS = Object.entries(this.chartData.stockIndustrySector.usStockMarketChart).sort(
      (a, b) => a[0].localeCompare(b[0]),
    );
    this.chartDifference = Object.entries(this.chartData.stockIndustrySector.chartDifference).sort(
      (a, b) => a[0].localeCompare(b[0]),
    );

    this.tableData = this.generateTableData();
    this.loaded = true;
  }

  setTableDataHypothetical() {
    this.yourStockHypothetical = Object.entries(
      this.hypotheticalData.stockIndustrySector.chart,
    ).sort((a, b) => a[0].localeCompare(b[0]));
    this.stockUSHypothetical = Object.entries(
      this.hypotheticalData.stockIndustrySector.usStockMarketChart,
    ).sort((a, b) => a[0].localeCompare(b[0]));
    this.chartDifferenceHypothetical = Object.entries(
      this.hypotheticalData.stockIndustrySector.chartDifference,
    ).sort((a, b) => a[0].localeCompare(b[0]));

    this.tableDataHypothetical = this.generateTableDataHypothetical();
    this.loadedHypothetical = true;
  }

  generateTableData() {
    let processedTableData = [];

    this.yourStock.forEach((yourStockEntry, index) => {
      let sector = this.splitAndSpace(yourStockEntry[INDEX_CONSTANTS.ZERO]);
      let stockUsEntry = this.stockUS[index];
      let chartDifferenceEntry = this.chartDifference[index];

      if (sector != 'Uncategorized') {
        processedTableData.push({
          sector: sector,
          yourStock: yourStockEntry[INDEX_CONSTANTS.ONE] + '%',
          stockUS: stockUsEntry[INDEX_CONSTANTS.ONE] + '%',
          chartDifference: chartDifferenceEntry[INDEX_CONSTANTS.ONE],
        });
      }
    });

    if (this.chartData.stockIndustrySector.chart.uncategorized > 0) {
      processedTableData.push({
        sector: 'Uncategorized',
        yourStock: this.chartData.stockIndustrySector.chart.uncategorized + '%',
        stockUS: this.chartData.stockIndustrySector.usStockMarketChart.uncategorized + '%',
        chartDifference: this.chartData.stockIndustrySector.chartDifference.uncategorized,
      });
    }

    if (this.yourStock.length > 0 && this.stockUS.length > 0) {
      processedTableData.push({
        sector: 'Total',
        yourStock: this.getTotalPercentage(this.chartData.stockIndustrySector.chart),
        stockUS: this.getTotalPercentage(this.chartData.stockIndustrySector.usStockMarketChart),
      });
    }

    return processedTableData;
  }

  generateTableDataHypothetical() {
    let processedTableData = [];

    this.yourStockHypothetical.forEach((yourStockEntry, index) => {
      let sector = this.splitAndSpace(yourStockEntry[INDEX_CONSTANTS.ZERO]);
      let stockUsEntry = this.stockUSHypothetical[index];
      let chartDifferenceEntry = this.chartDifferenceHypothetical[index];

      if (sector != 'Uncategorized') {
        processedTableData.push({
          sector: sector,
          yourStock: yourStockEntry[INDEX_CONSTANTS.ONE] + '%',
          stockUS: stockUsEntry[INDEX_CONSTANTS.ONE] + '%',
          chartDifference: chartDifferenceEntry[INDEX_CONSTANTS.ONE],
        });
      }
    });

    if (this.hypotheticalData.stockIndustrySector.chart.uncategorized > 0) {
      processedTableData.push({
        sector: 'Uncategorized',
        yourStock: this.hypotheticalData.stockIndustrySector.chart.uncategorized + '%',
        stockUS: this.hypotheticalData.stockIndustrySector.usStockMarketChart.uncategorized + '%',
        chartDifference: this.hypotheticalData.stockIndustrySector.chartDifference.uncategorized,
      });
    }

    if (this.yourStockHypothetical.length > 0 && this.stockUSHypothetical.length > 0) {
      processedTableData.push({
        sector: 'Total',
        yourStock: this.getTotalPercentage(this.hypotheticalData.stockIndustrySector.chart),
        stockUS: this.getTotalPercentage(
          this.hypotheticalData.stockIndustrySector.usStockMarketChart,
        ),
      });
    }

    return processedTableData;
  }

  generateTableDataByHoldings() {
    const categorizationObjects: any[] = [];
    this.categorizationKeys.forEach((key) => {
      categorizationObjects.push({ title: this.formatTitle(key, 'Current'), dataPoint: key });
    });
    const categorizationObjectsForDifference =
      this.chartData.stockIndustrySector.usStockMarketChart;
    const holdingsArray: any = this.holdingsViewHelper.getGeneratedDataWithDifferenceForTesterTool(
      categorizationObjects,
      this.chartData,
      'stockIndustrySector',
      categorizationObjectsForDifference,
    );
    this.holdingsTotal = this.holdingsViewHelper.calculateTotal(holdingsArray);
    return holdingsArray;
  }

  generateTableDataByHoldingsHypothetical() {
    const categorizationObjects: any[] = [];
    this.categorizationKeys.forEach((key) => {
      categorizationObjects.push({ title: this.formatTitle(key, 'Hypothetical'), dataPoint: key });
    });
    const categorizationObjectsForDifference =
      this.hypotheticalData.stockIndustrySector.usStockMarketChart;
    const holdingsArray: any = this.holdingsViewHelper.getGeneratedDataWithDifferenceForTesterTool(
      categorizationObjects,
      this.hypotheticalData,
      'stockIndustrySector',
      categorizationObjectsForDifference,
    );
    this.holdingsTotalHypothetical = this.holdingsViewHelper.calculateTotal(holdingsArray);
    return holdingsArray;
  }

  getTotalPercentage(chartObj) {
    let total: any = Object.values(chartObj)
      .filter((val: any) => !isNaN(val))
      .reduce((acc: number, value: number) => acc + value, 0);
    return total.toFixed(0) + '%';
  }

  changeTableView(val: boolean) {
    this.viewByHoldings = val;
  }

  getAllCategorizationKeys() {
    const keysActual = Object.keys(this.chartData.stockIndustrySector.chart);
    const keysHypothetical = Object.keys(this.hypotheticalData.stockIndustrySector.chart);
    const mergedKeys = _.union(keysActual, keysHypothetical);
    const uniqueKeys = _.uniq(mergedKeys);
    return uniqueKeys.sort(function (a, b) {
      return a === b ? 0 : a < b ? -1 : 1;
    });
  }

  setBothHoldingsTables() {
    const tableDataByHoldingsActual = this.generateTableDataByHoldings();
    const tableDataByHoldingsHypothetical = this.generateTableDataByHoldingsHypothetical();
    const unfiltered = [];

    this.categorizationKeys.forEach((key) => {
      unfiltered.push({
        actual: this.getTable(tableDataByHoldingsActual, 'stockIndustrySector', key),
        hypothetical: this.getTable(tableDataByHoldingsHypothetical, 'stockIndustrySector', key),
      });
    });

    this.holdingsTablesData = unfiltered.filter((category) => {
      return category.actual.accounts.length > 0 || category.hypothetical.accounts.length > 0;
    });
  }

  getTable(dataSet, dataNode, categorization) {
    const filteredTableData = dataSet.filter((tableData) => {
      return (
        tableData && tableData.categorization === categorization && tableData.dataNode === dataNode
      );
    });

    return filteredTableData[0];
  }

  splitAndSpace(chartEntry: string): string {
    let spacedString = chartEntry.replace(/([a-zA-Z])(?=[A-Z])/g, '$1 ');
    return (
      spacedString.substring(INDEX_CONSTANTS.ZERO, INDEX_CONSTANTS.ONE).toUpperCase() +
      spacedString.slice(INDEX_CONSTANTS.ONE).toLowerCase()
    );
  }

  splitAndSpaceTitleCase(chartEntry: string): string {
    let spacedString = chartEntry.replace(/([a-zA-Z])(?=[A-Z])/g, '$1 ');
    return (
      spacedString.substring(INDEX_CONSTANTS.ZERO, INDEX_CONSTANTS.ONE).toLowerCase() +
      spacedString.slice(INDEX_CONSTANTS.ONE).toLowerCase()
    );
  }

  formatTitle(chartEntry: string, prefix: string) {
    return prefix + ' ' + this.splitAndSpaceTitleCase(chartEntry);
  }
}
