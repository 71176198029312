import { targetAllocation } from '../chart-models/target-allocation';

export class AccountGroupsDetails {
  groupName: string;
  viewId: string;
  includeVistaAccounts: boolean;
  positions?: string[];
  targetAllocation?: targetAllocation;
}
export class AccountInfo {
  GroupName: string;
  ViewID: string;
  IsGroup: boolean;

  constructor(groupName: string, viewID: string, isGroup: boolean) {
    this.GroupName = groupName;
    this.ViewID = viewID;
    this.IsGroup = isGroup;
  }
}
