import { Component, OnInit } from '@angular/core';
import { ChartDataService } from '../../services/chart-data-service/chart-data.service';
import { LegendLine } from '../../models/chart-models/legend-line';
import { ChartData } from '../../models/chart-models/chart-data';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';

@Component({
  selector: 'PW1-bond-analysis',
  templateUrl: './bond-analysis.component.html',
  styleUrls: ['./bond-analysis.component.scss'],
})
export class BondAnalysisComponent implements OnInit {
  chartData: ChartData;
  legendArray: Array<LegendLine>;
  hasLoadedChartData = false;
  bondChartId = 'bondChartId';

  constructor(
    private chartService: ChartDataService,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {}

  ngOnInit() {
    const ZERO_INDEX = 0;
    this.chartService.getBondAnalysisChartPayload().subscribe((data) => {
      if (data.percentageDetails.length !== ZERO_INDEX) {
        this.chartData = data;
        this.legendArray = this.chartService.getBondAnalysisCardLegend();
        this.hasLoadedChartData = true;
      }
    });
  }

  trackDomesticVsInternationalLink() {
    this.adobeAnalyticsService.clickedOnDomesticVsInternational('Bond analysis');
  }
}
