import { Component, OnInit } from '@angular/core';
import { PortfolioDataService } from '../../services/portfolio-data-service/portfolio-data.service';
import { AllChartsResponseData } from '../../models/chart-data-response-models/all-charts-response-data';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DrawerTrackerService } from '@vanguard/pfx-components';

@Component({
  selector: 'rounding-disclaimer',
  templateUrl: './rounding-disclaimer.component.html',
  styleUrls: ['./rounding-disclaimer.component.scss'],
})
export class RoundingDisclaimerComponent implements OnInit {
  shouldBeDisplayed: boolean;
  inlineHelpDrawerEnabled: Observable<boolean>;

  constructor(
    private portfolioDataService: PortfolioDataService,
    private router: Router,
    private drawerTrackerService: DrawerTrackerService,
  ) {}

  ngOnInit() {
    this.portfolioDataService
      .getChartDataPayload()
      .pipe(take(1))
      .subscribe((response: AllChartsResponseData) => {
        this.shouldBeDisplayed = this.shouldBeDisplayedOnPage(response);

        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
          this.shouldBeDisplayed = this.shouldBeDisplayedOnPage(response);
        });
      });
  }

  areStocksRoundedToZero(response: AllChartsResponseData): boolean {
    return (
      response.assetTypes.chart.stocks === 0 &&
      response.stockDomesticInternational.applicableBalance > 0
    );
  }

  areBondsRoundedToZero(response: AllChartsResponseData): boolean {
    return (
      response.assetTypes.chart.bonds === 0 &&
      response.bondDomesticInternational.applicableBalance > 0
    );
  }

  // eslint-disable-next-line complexity
  shouldBeDisplayedOnPage(response: AllChartsResponseData): boolean {
    const isIssueForStocks = this.areStocksRoundedToZero(response);
    const isIssueForBonds = this.areBondsRoundedToZero(response);
    const isEligibleToBeShownAtAll = isIssueForStocks || isIssueForBonds;
    const currentUrl = this.router.url;

    // /asset-allocation
    return (
      (currentUrl === '/' && isEligibleToBeShownAtAll) ||
      (currentUrl.startsWith('/asset-mix') && isEligibleToBeShownAtAll) ||
      (currentUrl.startsWith('/stock-analysis') && isIssueForStocks) ||
      (currentUrl.startsWith('/bond-analysis') && isIssueForBonds)
    );
  }

  navigateToFAQPage() {
    // to open inline drawer, pass argument 0 for guide section OR 1 for FAQ section. Default is 0.
    this.drawerTrackerService.openDrawer(1);
  }
}
