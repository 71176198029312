import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'aemlink',
})
export class AEMLinkPipe implements PipeTransform {
  constructor(private dom: DomSanitizer) {}

  transform(content: String, replaceText: string, url: string): string {
    return this.dom.sanitize(
      SecurityContext.HTML, // TODO: What should this be ?
      content?.replace(
        replaceText,
        `<a href="${url}" class="c11n-link c11n-link--secondary"><span class="c11n-link__content">${replaceText}</span></a>`,
      ),
    );
  }
}
