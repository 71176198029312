import { Component, OnInit } from '@angular/core';
import { ChartData } from '../../../models/chart-models/chart-data';
import { LegendLine } from '../../../models/chart-models/legend-line';
import { RiskAnalysisType } from '../../../models/chart-data-response-models/risk-analysis-type';
import { PortfolioCardsService } from '../../../services/portfolio-cards/portfolio-cards.service';
import { ChartDataService } from '../../../services/chart-data-service/chart-data.service';
import { PortfolioDataService } from '../../../services/portfolio-data-service/portfolio-data.service';
import { ChartColors } from '../../../models/chart-models/chart-colors';
import { HypotheticalChartDataService } from '../../../services/hypothetical-chart-data-service/hypothetical-chart-data.service';
import { Observable } from 'rxjs';
import { DrawerTrackerService } from '@vanguard/pfx-components';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';
import { ActivatedRoute } from '@angular/router';

const ZERO_INDEX = 0;

@Component({
  selector: 'hypothetical-asset-allocation',
  templateUrl: './hypothetical-asset-allocation.component.html',
  styleUrls: ['./hypothetical-asset-allocation.component.scss'],
})
export class HypotheticalAssetAllocationComponent implements OnInit {
  chartData;
  hypotheticalChartData;

  assetChartId = 'assetChartId';
  hypotheticalAssetChartId = 'hypotheticalAssetChartId';

  // charts
  currentAssetAllocationChartData: ChartData;
  hypotheticalAssetAllocationChartData: ChartData;

  hasLoadedCurrentAssetChartData = false;
  hasLoadedHypotheticalAssetChartData = false;
  hasLoadedHistoricRiskReturnData = false;
  hasLoadedHistoricRiskReturnDataHypothetical = false;

  legendHeader = 'Difference in target vs Actual';
  currentLegendArray: Array<LegendLine>;
  hypotheticalLegendArray: Array<LegendLine>;
  chartColorMap = new Map();

  // Current Allocation Historic Risk/Return response
  currentAllocationHistoricRiskReturn: RiskAnalysisType;
  currentAllocationHistoricRiskReturnHypothetical: RiskAnalysisType;

  assetAllocationTableData: any[];
  hypotheticalAllocationTableData: any[];

  modernizedEnabled: Observable<boolean>;

  constructor(
    private cardsService: PortfolioCardsService,
    private chartService: ChartDataService,
    private hypotheticalChartDataService: HypotheticalChartDataService,
    private portfolioDataService: PortfolioDataService,
    private drawerTrackerService: DrawerTrackerService,
    private adobeAnalyticsService: AdobeAnalyticsService,
    private route: ActivatedRoute,
  ) {
    this.chartColorMap = ChartColors.getColorMap();
  }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.adobeAnalyticsService.trackRoutingData(data);
    });
    this.setupCharts();
    this.setAllocationTableData();
  }

  setupCharts() {
    this.setupHoldingsData();
    this.setupHypotheticalHoldingData();
    this.setupCurrentAssetAllocationChart();
    this.setupHypotheticalAssetAllocationChart();
    this.setupHistoricRiskReturn();
  }

  setupHoldingsData() {
    this.portfolioDataService.getChartDataPayload().subscribe((data) => {
      if (data.assetTypes.chart) {
        this.chartData = data;
      }
    });
  }

  setupHypotheticalHoldingData() {
    this.portfolioDataService.getHypotheticalDataPayload().subscribe((data) => {
      if (data.assetTypes.chart) {
        this.hypotheticalChartData = data;
      }
    });
  }

  setupCurrentAssetAllocationChart() {
    this.chartService.getAssetAllocationChartPayload().subscribe((data) => {
      if (data.percentageDetails.length !== ZERO_INDEX) {
        this.currentAssetAllocationChartData = data;
        this.hasLoadedCurrentAssetChartData = true;
        this.currentLegendArray = this.chartService.getAssetAllocationCardLegend();
      }
    });
  }

  setupHypotheticalAssetAllocationChart() {
    this.hypotheticalChartDataService.getAssetAllocationChartPayload().subscribe((data) => {
      if (data.percentageDetails.length !== ZERO_INDEX) {
        this.hypotheticalAssetAllocationChartData = data;
        this.hasLoadedHypotheticalAssetChartData = true;
        this.hypotheticalLegendArray =
          this.hypotheticalChartDataService.getAssetAllocationCardLegend();
      }
    });
  }

  setAllocationTableData() {
    let actualStocksValue =
      this.chartData.assetTypes.chart.stocks === 0 &&
      this.chartData.stockDomesticInternational.applicableBalance
        ? '<1%'
        : this.formatNumber(this.chartData.assetTypes.chart.stocks);
    let actualBondsValue =
      this.chartData.assetTypes.chart.bonds === 0 &&
      this.chartData.bondDomesticInternational.applicableBalance
        ? '<1%'
        : this.formatNumber(this.chartData.assetTypes.chart.bonds);
    let hypotheticalStocksValue =
      this.hypotheticalChartData.assetTypes.chart.stocks === 0 &&
      this.hypotheticalChartData.stockDomesticInternational.applicableBalance
        ? '<1%'
        : this.formatNumber(this.hypotheticalChartData.assetTypes.chart.stocks);
    let hypotheticalBondsValue =
      this.hypotheticalChartData.assetTypes.chart.bonds === 0 &&
      this.hypotheticalChartData.bondDomesticInternational.applicableBalance
        ? '<1%'
        : this.formatNumber(this.hypotheticalChartData.assetTypes.chart.bonds);

    this.assetAllocationTableData = [
      {
        category: 'Reserves',
        current: this.formatNumber(this.chartData.assetTypes.chart.shortTermReserves),
        targetDifference: this.formatNumber(
          this.chartData.assetTypeDifference.shortTermReserves,
          true,
        ),
      },
      {
        category: 'Stocks',
        current: actualStocksValue,
        targetDifference: this.formatNumber(this.chartData.assetTypeDifference.stocks, true),
      },
      {
        category: 'Bonds',
        current: actualBondsValue,
        targetDifference: this.formatNumber(this.chartData.assetTypeDifference.bonds, true),
      },
      {
        category: 'Others',
        current: this.formatNumber(this.chartData.assetTypes.chart.others),
        targetDifference: this.formatNumber(this.chartData.assetTypeDifference.others, true),
      },
    ];
    this.hypotheticalAllocationTableData = [
      {
        category: 'Reserves',
        current: this.formatNumber(this.hypotheticalChartData.assetTypes.chart.shortTermReserves),
        targetDifference: this.formatNumber(
          this.hypotheticalChartData.assetTypeDifference.shortTermReserves,
          true,
        ),
      },
      {
        category: 'Stocks',
        current: hypotheticalStocksValue,
        targetDifference: this.formatNumber(
          this.hypotheticalChartData.assetTypeDifference.stocks,
          true,
        ),
      },
      {
        category: 'Bonds',
        current: hypotheticalBondsValue,
        targetDifference: this.formatNumber(
          this.hypotheticalChartData.assetTypeDifference.bonds,
          true,
        ),
      },
      {
        category: 'Others',
        current: this.formatNumber(this.hypotheticalChartData.assetTypes.chart.others),
        targetDifference: this.formatNumber(
          this.hypotheticalChartData.assetTypeDifference.others,
          true,
        ),
      },
    ];
  }
  setupHistoricRiskReturn() {
    this.portfolioDataService.getChartDataPayload().subscribe((data) => {
      if (data.riskReturnAnalysisResponse) {
        this.currentAllocationHistoricRiskReturn =
          data.riskReturnAnalysisResponse.currentAllocationRiskAnalysis;
        this.hasLoadedHistoricRiskReturnData = true;
      }
    });

    this.portfolioDataService.getHypotheticalDataPayload().subscribe((data) => {
      if (data.riskReturnAnalysisResponse) {
        this.currentAllocationHistoricRiskReturnHypothetical =
          data.riskReturnAnalysisResponse.currentAllocationRiskAnalysis;
        this.hasLoadedHistoricRiskReturnDataHypothetical = true;
      }
    });
  }

  goBackToOverview() {
    this.adobeAnalyticsService.clickedBackToOverview(true);
    this.cardsService.navigateToPage('hypothetical-overview');
  }

  formatNumber(i: number, difference?: boolean): string {
    let s: string = '';

    if (i > 0 && difference) {
      s += '+';
    }
    return s + i.toFixed(0) + '%';
  }

  selectSection(sectionLabel: string) {
    this.drawerTrackerService.setSelectSection(sectionLabel);
  }
}
