import { Injectable } from '@angular/core';
import { Response } from '../../models/chart-data-response-models/response';
import {
  CategorizationObject,
  HoldingsTableAccountWithHoldings,
  HoldingsTableCategorization,
  HoldingsTableHolding,
} from '../../models/holdings-table';
import { Holding } from '../../models/chart-data-response-models/holding';

@Injectable({
  providedIn: 'root',
})
export class HoldingsTableBuilderService {
  getArrayOfAccountNamesForCategorization(arrayOfHoldings: HoldingsTableHolding[]): string[] {
    return arrayOfHoldings
      .map((item) => item.accountName)
      .filter((value, index, self) => self.indexOf(value) === index);
  }

  getHoldingsFromSpecificAccountNew(arrayOfHoldings: HoldingsTableHolding[], accountName: string) {
    return arrayOfHoldings.filter((holding) => {
      return holding.accountName === accountName;
    });
  }

  getArrayOfHoldingsForCategorizationNew(
    filteredChartData: Response<any>,
    categorization: string,
  ): HoldingsTableHolding[] {
    return filteredChartData.holdings
      .filter((holding) => {
        return (
          holding.categorization[categorization] && holding.categorization[categorization] !== 0
        );
      })
      .map((holding) => {
        return {
          accountName: holding.accountName,
          ticker: holding.ticker,
          name: holding.fundName,
          value: this.getHoldingValue(holding, categorization),
          percentage: this.getHoldingPercentage(holding, categorization)
        };
      });
  }

  getHoldingInformationWithoutDifferenceByPercentageNew(
    holding: Holding<any>,
    categorization: string,
  ): HoldingsTableHolding {
    const regionValue = this.getHoldingValue(holding, categorization);
    const regionPercentage = this.getHoldingPercentage(holding, categorization);
    return {
      accountName: holding.accountName,
      name: holding.fundName,
      value: regionValue,
      percentage: regionPercentage,
      ticker: holding.ticker,
    };
  }

  getHoldingsByCategorizations(
    filteredChartData,
    categorizationObjects: CategorizationObject[],
  ): HoldingsTableCategorization[] {
    let categorizationsArray: HoldingsTableCategorization[] = [];

    categorizationObjects.forEach((catObject) => {
      const holdingsForCategorization: HoldingsTableHolding[] =
        this.getArrayOfHoldingsForCategorizationNew(
          filteredChartData,
          catObject.categorization,
        );
      let accountsWithSpecifiedCategorization: string[] =
        this.getArrayOfAccountNamesForCategorization(holdingsForCategorization);

      let accountObjects: HoldingsTableAccountWithHoldings[] = [];

      accountsWithSpecifiedCategorization.forEach((accountName) => {
        let holdingsArrayForAccount: HoldingsTableHolding[] =
          this.getHoldingsFromSpecificAccountNew(holdingsForCategorization, accountName);
        let acctSubtotal = 0;
        let acctPercentage = 0;

        holdingsArrayForAccount.forEach((holding: HoldingsTableHolding) => {
          acctSubtotal += holding.value;
          acctPercentage += holding.percentage;
        });

        accountObjects.push({
          accountName: accountName,
          subtotal: acctSubtotal,
          percentageOfPortfolio: acctPercentage,
          holdings: holdingsArrayForAccount,
        });
      });

      let holdingsTableCategorizationObject: HoldingsTableCategorization = {
        accounts: accountObjects,
        header: catObject.title,
        categorization: catObject.categorization,
      };

      categorizationsArray.push(holdingsTableCategorizationObject);
    });

    return categorizationsArray;
  }

  getHoldingValue(holding: Holding<any>, categorization: string) {
    return holding.subtotals.find(x => x.name.toLowerCase() == categorization.toLowerCase()).portfolioValue;
  }

  getHoldingPercentage(holding: Holding<any>, categorization: string){
    return holding.subtotals.find(x => x.name.toLowerCase() == categorization.toLowerCase()).portfolioPercentage;
  }
}
