import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import parseAEMContent from '@vanguard/aem-headless-utils';
import { LoggerCode } from '../../../models/logger';
import { LoggerService } from '../logger/logger.service';
import { environment } from 'src/environments/environment';
import localParsedData from '../../../assets/content.json'

@Injectable({
  providedIn: 'root',
})
export class ContentAsAService {
  contentPayload = new BehaviorSubject<any>('');

  constructor(private http: HttpClient, private loggerService: LoggerService) {
    this.loadContentPayload();
  }

  getContentPayload(): Observable<any> {
    return this.contentPayload.asObservable();
  }

  loadContentPayload() {
    this.getContentFromAEM().subscribe(
      (response: any) => {         
        const parsedData = parseAEMContent(response);
        this.contentPayload.next(parsedData.Data.Pw1Chunk.content);
      },
      (error) => {
        this.loggerService.error(
          {
            message: error.message,
            status: error.status,
          },
          LoggerCode.AEM_CONTENT_NOT_LOADED,
        );
        this.contentPayload.next(localParsedData.content.Data.Pw1Chunk.content);
      },
    );
  }

  private getContentFromAEM() {
    const url = environment.AEMContentUrl;
    return this.http.get(url);
  }
}
