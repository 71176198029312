/* eslint-disable */
import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import * as d3 from 'd3';
import { ChartData } from '../../models/chart-models/chart-data';
import { ModernizedChartColors } from '../../models/chart-models/modernized-chart-colors';
import { DONUT_CHART_CONSTANTS } from '../../models/chart.constants';
import { ChartDataService } from '../../services/chart-data-service/chart-data.service';
import { HypotheticalChartDataService } from '../../services/hypothetical-chart-data-service/hypothetical-chart-data.service';

@Component({
  selector: 'PW1-donut-chart-component',
  templateUrl: './donut-chart-component.component.html',
  styleUrls: ['./donut-chart-component.component.scss'],
})
export class DonutChartComponentComponent implements OnChanges, AfterViewInit {
  @Input() height: number;
  @Input() width: number;
  @Input() dataSet: ChartData;
  @Input() colorScales: Array<string>;
  @Input() id: string;
  @Input() includeZero: boolean = false;

  colorMap = ModernizedChartColors.getColorMap();

  color = d3
    .scaleOrdinal()
    .domain(['Stocks', 'Bonds', 'Short term reserves', 'Other', 'Alternatives'])
    .range([
      this.colorMap.get('stocks'),
      this.colorMap.get('bonds'),
      this.colorMap.get('shortTermReserves'),
      this.colorMap.get('other'),
      this.colorMap.get('alternatives'),
    ]);

  stockColor = d3
    .scaleOrdinal()
    .domain(['Domestic', 'International', 'Other'])
    .range([
      this.colorMap.get('domesticSA'),
      this.colorMap.get('internationalSA'),
      this.colorMap.get('otherSA'),
    ]);

  bondColor = d3
    .scaleOrdinal()
    .domain(['Domestic', 'International', 'Other'])
    .range([
      this.colorMap.get('domesticBA'),
      this.colorMap.get('internationalBA'),
      this.colorMap.get('otherBA'),
    ]);

  constructor(
    private chartDataService: ChartDataService,
    private hypotheticalChartDataService: HypotheticalChartDataService,
  ) {}

  ngOnChanges() {
    if (this.dataSet) {
      this.renderDonutChart();
    }
  }

  ngAfterViewInit(): void {
    if (this.dataSet) {
      this.renderDonutChart();
    }
  }

  renderDonutChart() {
    var data = [] as any;
    for (var i = DONUT_CHART_CONSTANTS.ZERO; i < this.dataSet.percentageDetails.length; i++) {
      if (this.includeZero || this.dataSet.percentageDetails[i] !== DONUT_CHART_CONSTANTS.ZERO) {
        data.push({
          name: this.dataSet.labelDetails[i],
          percent: this.dataSet.percentageDetails[i],
        });
      }
    }

    var radius = Math.min(this.width, this.height) / DONUT_CHART_CONSTANTS.TWO,
      outerRadius = radius,
      innerRadius = radius - 20,
      hoverStateTopValue = '30%',
      hoverStateLeftValue = '30%';

    var arc = d3.arc().outerRadius(outerRadius).innerRadius(innerRadius);

    var pie = d3
      .pie()
      .sort(null)
      .startAngle(DONUT_CHART_CONSTANTS.ZERO)
      .padAngle(0.02)
      .value(function (d: any) {
        return d.percent;
      });

    d3.select('#' + this.id).html('');

    const svg = this.addSvg();

    const g = svg
      .selectAll('.arc')
      .data(pie(data))
      .enter()
      .append('g')
      .on('mouseover', handleMouseOver)
      .on('mousemove', handleMouseMove)
      .on('mouseout', handleMouseOut);

    g.append('path')
      .style('fill', this.assignColor)
      .transition()
      .delay(function (d: any, i: any) {
        return i * DONUT_CHART_CONSTANTS.EIGHT_HUNDRED;
      })
      .duration(DONUT_CHART_CONSTANTS.EIGHT_HUNDRED)
      .attrTween('d', function (d: any) {
        const i = d3.interpolate(d.startAngle + DONUT_CHART_CONSTANTS.INTERPOLATE, d.endAngle);
        return function (t: any) {
          d.endAngle = i(t);
          return arc(d);
        };
      });

    this.addChartText(svg);

    // create a tooltip
    var tooltip = d3
      .select('#' + this.id)
      .append('div')
      .style('background', 'white')
      .style('box-shadow', '0 1px 5px rgb(0, 0, 0, 0.4')
      .style('color', '#333')
      .style('display', 'none')
      .style('font-size', '12px')
      .style('border-radius', '5px')
      .style('margin-top', '10px')
      .style('position', 'absolute')
      .style('text-align', 'center')
      .style('pointer-events', 'none')
      .style('z-index', '10');

    function handleMouseOver(d: any, i: any, group) {
      d3.select(group[i]).style('opacity', DONUT_CHART_CONSTANTS.OPACITY);
      let tooltipLength;
      if (d.data.name.length <= DONUT_CHART_CONSTANTS.SIX) {
        tooltipLength = d.data.name.length + DONUT_CHART_CONSTANTS.HUNDRED_TWENTY;
      } else if (
        d.data.name.length > DONUT_CHART_CONSTANTS.SIX &&
        d.data.name.length < DONUT_CHART_CONSTANTS.THIRTEEN
      ) {
        tooltipLength = d.data.name.length + DONUT_CHART_CONSTANTS.HUNDRED_TWENTY;
      } else if (
        d.data.name.length >= DONUT_CHART_CONSTANTS.THIRTEEN &&
        d.data.name.length < DONUT_CHART_CONSTANTS.FIFTEEN
      ) {
        tooltipLength = d.data.name.length + DONUT_CHART_CONSTANTS.HUNDRED_FIFTY;
      } else {
        tooltipLength = d.data.name.length + DONUT_CHART_CONSTANTS.HUNDRED_SIXTY_FIVE;
      }
      tooltip
        .style('display', 'inline')
        .html('<h4>' + d.data.name + ': ' + d.data.percent + '%' + '</4>')
        .style('width', tooltipLength + 'px')
        .style('padding', '14px')
        .style('line-height', '0')
        .style('margin-block-start', '0')
        .style('margin-block-end', '0')
        .style('margin-inline-start', '0px')
        .style('margin-inline-end', '0px')
        .style('font-weight', 'normal')
        .style('top', hoverStateTopValue)
        .style('left', hoverStateLeftValue)
        .style('font-family', 'MarkPro, Arial, sans-serif');
    }

    function handleMouseOut(d: any, i: any, group) {
      d3.select(group[i]).style('opacity', DONUT_CHART_CONSTANTS.ONE);
      tooltip.style('display', 'none');
    }

    function handleMouseMove(d: any, i: any, group) {
      var el = d3.select(group[i]);
      var x = Number.parseInt(el.attr('x'));
      var y = Number.parseInt(el.attr('y'));
      tooltip
        .style('left', x + DONUT_CHART_CONSTANTS.TWENTY_FIVE + 'px')
        .style('top', y - DONUT_CHART_CONSTANTS.FIFTEEN + 'px');
    }
  }

  assignColor = (d: any) => {
    if (this.id.toLowerCase().indexOf('stock') !== -1) {
      return this.stockColor(d.data.name);
    } else if (this.id.toLowerCase().indexOf('bond') !== -1) {
      return this.bondColor(d.data.name);
    } else {
      return this.color(d.data.name);
    }
  };

  removeChart() {
    let svg = d3.select('#' + this.id);
    svg.selectAll('g').remove();
    svg.remove();
  }
  t;

  ngOnDestroy(): void {
    this.removeChart();
  }

  addSvg() {
    return d3
      .select('#' + this.id)
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .append('g')
      .attr(
        'transform',
        'translate(' +
          this.width / DONUT_CHART_CONSTANTS.TWO +
          ',' +
          this.height / DONUT_CHART_CONSTANTS.TWO +
          ')',
      )
      .attr('style', 'background-color:white');
  }

  addChartText(svg) {
    if (
      this.id.toLowerCase().indexOf('stock') !== -1 &&
      this.id.toLowerCase().indexOf('hypothetical') === -1
    ) {
      const stockPercentage = this.chartDataService.getStockPercentage();
      this.addPercentageOnChart(svg, stockPercentage, 'stocks');
    }

    if (
      this.id.toLowerCase().indexOf('stock') !== -1 &&
      this.id.toLowerCase().indexOf('hypothetical') !== -1
    ) {
      const stockPercentage = this.hypotheticalChartDataService.getStockPercentage();
      this.addPercentageOnChart(svg, stockPercentage, 'stocks');
    }

    if (
      this.id.toLowerCase().indexOf('bond') !== -1 &&
      this.id.toLowerCase().indexOf('hypothetical') === -1
    ) {
      const bondPercentage = this.chartDataService.getBondPercentage();
      this.addPercentageOnChart(svg, bondPercentage, 'bonds');
    }

    if (
      this.id.toLowerCase().indexOf('bond') !== -1 &&
      this.id.toLowerCase().indexOf('hypothetical') !== -1
    ) {
      const bondPercentage = this.hypotheticalChartDataService.getBondPercentage();
      this.addPercentageOnChart(svg, bondPercentage, 'bonds');
    }
  }

  addPercentageOnChart(svg, percentage: number, name: string) {
    let percentageText: string = percentage.toString();
    if (percentage === 0) {
      percentageText = '<1';
    }

    const text = svg
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('font-size', '20px')
      .attr('font-weight', 'bold')
      .attr('font-family', 'MarkPro, Arial, sans-serif')
      .attr('color', '#1A1A1A')
      .text(percentageText + '%');

    svg
      .append('text')
      .attr('y', 20)
      .attr('font-family', 'MarkPro, Arial, sans-serif')
      .attr('text-anchor', 'middle')
      .attr('font-weight', 'normal')
      .attr('font-size', '15px')
      .attr('color', '#1A1A1A')
      .text(name);
  }
}
