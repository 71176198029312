import { Injectable } from '@angular/core';
import { Log, LoggerLevel, LoggerCode } from '../../../models/logger';
import { environment } from 'src/environments/environment';
import { HttpXsrfTokenExtractor } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor(private xsrfTokenExtractor: HttpXsrfTokenExtractor) {}
  xsrfToken: string;

  private sendLog(level: LoggerLevel, logProperties: Record<string, any>, code: LoggerCode): void {
    logProperties.app = 'Portfolio-watch';
    const stringifiedLogProperties: string = JSON.stringify(logProperties);
    const payload = new Log(level, stringifiedLogProperties, code);
    const loggerURL = environment.LOGGER_URL;

    this.setTokenHeader();
    if (!environment.development) {
      fetch(loggerURL, {
        method: 'POST',
        headers: {
          'X-XSRF-TOKEN': this.xsrfToken,
          'Content-Type': 'application/json',
          Accept: '*/*',
        },
        credentials: 'include',
        body: JSON.stringify(payload),
      }).catch(() => undefined);
    }
  }

  setTokenHeader() {
    this.xsrfToken = this.xsrfTokenExtractor.getToken()
      ? this.xsrfTokenExtractor.getToken()
      : 'X-XSRF-TOKEN-DUMMY';
  }

  info(message: Record<string, any>, code: LoggerCode): void {
    this.sendLog(LoggerLevel.INFO, message, code);
  }

  warn(message: Record<string, any>, code: LoggerCode): void {
    this.sendLog(LoggerLevel.WARN, message, code);
  }

  error(message: Record<string, any>, code: LoggerCode): void {
    this.sendLog(LoggerLevel.ERROR, message, code);
  }

  alert(message: Record<string, any>, code: LoggerCode): void {
    this.sendLog(LoggerLevel.ALERT, message, code);
  }

  logChartDataError(error): void {
    switch (error.message) {
      case 'PW2-999': {
        this.error(
          {
            message: 'Chart data loaded with no positive balances',
            status: 200,
          },
          LoggerCode.NO_POSITIVE_BALANCES,
        );
        break;
      }
      default: {
        this.error(
          {
            message: error.message,
            status: error.status,
          },
          LoggerCode.CHART_DATA_NOT_LOADED,
        );
      }
    }
  }
}
