import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { skipWhile, take, takeUntil } from 'rxjs/operators';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';
import { PortfolioDataService } from '../../services/portfolio-data-service/portfolio-data.service';
import { ResizeObserverService } from '@vg-constellation/angular-13/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'PW1-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
})
export class MainPageComponent implements OnInit, AfterViewInit, OnDestroy {
  showTargetModal: boolean = false;
  private readonly _destroy: Subject<void> = new Subject<void>();
  rootContainerElement: HTMLElement;

  constructor(
    private route: ActivatedRoute,
    private portfolioDataService: PortfolioDataService,
    private adobeAnalyticsService: AdobeAnalyticsService,
    private ros: ResizeObserverService
  ) {}

  ngOnInit() {
    this.route.data.pipe(take(1))
    .pipe(takeUntil(this._destroy))
    .subscribe((data) => {
      this.adobeAnalyticsService.trackRoutingData(data);
    });

    this.route.queryParams
      .pipe(skipWhile((params) => !Object.keys(params).includes('showTargetModal')))
      .subscribe((params) => {
        if (params.showTargetModal) {
          this.showTargetModal = params.showTargetModal;
          this.portfolioDataService.setShowTargetModal(true);
        }
      });
  }

  ngAfterViewInit() {
    this.rootContainerElement = document.querySelector('#pw-main');
    const containerSizeChange$ = this.ros.observe(this.rootContainerElement);

    containerSizeChange$
    .pipe(takeUntil(this._destroy))
    .subscribe((coords) => {
      const overviewCardsRows = document.querySelectorAll('.cardsRow');
      const containerWidth = coords.width;
      if(containerWidth > 992) {
        overviewCardsRows.forEach((cardsRow: any) => {
          cardsRow.style.flexDirection = 'row';
        });
      } else {
        overviewCardsRows.forEach((cardsRow: any) => {
          cardsRow.style.flexDirection = 'column';
        });
      }
    })
  }

  ngOnDestroy() {
    this.ros.unobserve(this.rootContainerElement);
    this._destroy.complete();
  }
}
