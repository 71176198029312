import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { AsyncSubject } from 'rxjs';
import { WindowRef } from '@vanguard/secure-site-components-lib';
import { PortfolioDataService } from '../portfolio-data-service/portfolio-data.service';
import { environment } from '../../../environments/environment';
import { AdobeTracking } from '../../models/adobe-tracking';
import { Data } from '@angular/router';
import {
  CustomTargetAssetMixTool,
  targetAssetMixRoutes,
} from '../../models/chart-models/target-allocation';

@Injectable()
export class AdobeAnalyticsService {
  $window: any;
  currentEnvironment: string;
  isClientTrackable: AsyncSubject<boolean>;

  private adobeWindowRef: Window & { dataLayer: Array<Object> };

  constructor(windowRef: WindowRef, private portfolioDataService: PortfolioDataService) {
    this.setEnvironment();
    this.adobeWindowRef = !windowRef.nativeWindow.dataLayer
      ? Object.assign(windowRef.nativeWindow, { dataLayer: [] })
      : windowRef.nativeWindow;
    this.$window = windowRef.nativeWindow;
    this.isClientTrackable = new AsyncSubject<boolean>();
    this.getClientAge();
  }

  setEnvironment() {
    switch (environment.ANGULAR_ENV) {
      case 'non-production':
        this.currentEnvironment = 'test';
        break;
      case 'production':
        this.currentEnvironment = 'production';
        break;
      default:
        this.currentEnvironment = 'local';
        break;
    }
  }

  getClientAge() {
    this.portfolioDataService
      .getChartDataPayload()
      .pipe(take(1))
      .subscribe((data: any) => {
        let isUserConfirmedOlderThan14 = false;

        if (data && data.dateofBirth) {
          isUserConfirmedOlderThan14 = this.calculateAge(data.dateofBirth) >= 14;
        }

        this.isClientTrackable.next(isUserConfirmedOlderThan14);
        this.isClientTrackable.complete();
      });
  }

  trackRoutingData(data) {
    const pageConfig: AdobeTracking = this.getPageInfo(data);

    this.$window.adobeLaunch.initializePage(pageConfig, this.currentEnvironment);
  }

  isInitalizePageOnDataLayer(): boolean {
    return (
      this.$window.dataLayer &&
      this.$window.dataLayer.filter((ent) => ent.event === 'pageLoaded').length > 0
    );
  }

  getCurrentUrl(): string {
    return this.$window.location.pathname.replace('/pw1-portfolio-analysis', '');
  }

  CreateCTATrigger(ctaLocation: string, ctaName: string, ctaType: string) {
    if (this.isClientTrackable) {
      this.adobeWindowRef.dataLayer.push({
        event: 'callToAction',
        callToAction: {
          location: ctaLocation,
          ctaName: ctaName,
          ctaType: ctaType
        },
        fireTrackLink: {
          fireTrackLink: 'true',
        },
      });
    }
  }

  ClickedonSetTargetAssetMixLink(){
    if (this.isClientTrackable){
      this.adobeWindowRef.dataLayer.push({
        event: "subPageViewed",//e166
        subPage: {
            "subPageID": "Set Target Asset Mix", //v105
            "spClicked": "true"  
        }
    });
    }
  }

  clickedOnTab(urlName) {
    if (this.isClientTrackable) {
      this.adobeWindowRef.dataLayer.push({
        event: 'subPageViewed',
        subPage: {
          subPageID: urlName,
          spClicked: true,
        },
      });
    }
  }

  clickedOnShowAlerts() {
    if (this.isClientTrackable) {
      this.adobeWindowRef.dataLayer.push({
        event: 'subPageViewed',
        subPage: {
          subPageID: 'showAlerts',
          spClicked: true,
        },
      });
    }
  }

  clickedOnAlert(url: string) {
    if (this.isClientTrackable) {
      this.adobeWindowRef.dataLayer.push({
        event: 'callToAction',
        callToAction: {
          location: this.getCurrentUrl(),
          ctaName: 'Navigating to Alert link: ' + url,
          ctaType: 'Button',
        },
        fireTrackLink: {
          fireTrackLink: 'true',
        },
      });
    }
  }

  clickedBackToOverview(isHypothetical?: boolean) {
    if (this.isClientTrackable) {
      this.adobeWindowRef.dataLayer.push({
        event: 'callToAction',
        callToAction: {
          location: this.getCurrentUrl(),
          ctaName: isHypothetical
            ? 'Navigating back to Hypothetical Overview'
            : 'Navigating back to Overview',
          ctaType: 'Text Link',
        },
        fireTrackLink: {
          fireTrackLink: 'true',
        },
      });
    }
  }

  clickedOnSeeDetails(location: string) {
    if (this.isClientTrackable) {
      this.adobeWindowRef.dataLayer.push({
        event: 'callToAction',
        callToAction: {
          location: this.getCurrentUrl(),
          ctaName: 'Navigating to page with See Details button: ' + location,
          ctaType: 'Button',
        },
        fireTrackLink: {
          fireTrackLink: 'true',
        },
      });
    }
  }

  clickedOnFaq() {
    if (this.isClientTrackable) {
      this.adobeWindowRef.dataLayer.push({
        event: 'callToAction',
        callToAction: {
          location: this.getCurrentUrl(),
          ctaName: 'Navigating to FAQ',
          ctaType: 'Text Link',
        },
        fireTrackLink: {
          fireTrackLink: 'true',
        },
      });
    }
  }

  clickedOnLearnMore(learnMorePageUrl) {
    if (this.isClientTrackable) {
      this.adobeWindowRef.dataLayer.push({
        event: 'callToAction',
        callToAction: {
          location: this.getCurrentUrl(),
          ctaName: 'Navigating to Learn More: ' + learnMorePageUrl,
          ctaType: 'Text Link',
        },
        fireTrackLink: {
          fireTrackLink: 'true',
        },
      });
    }
  }

  changedAccountGroup(accountGroupName) {
    if (this.isClientTrackable) {
      this.adobeWindowRef.dataLayer.push({
        event: 'callToAction',
        callToAction: {
          location: this.getCurrentUrl(),
          ctaName: 'Changing account group: ' + accountGroupName,
          ctaType: 'Dropdown',
        },
        fireTrackLink: {
          fireTrackLink: 'true',
        },
      });
    }
  }

  addingNewAccountGroup() {
    if (this.isClientTrackable) {
      this.adobeWindowRef.dataLayer.push({
        event: 'callToAction',
        callToAction: {
          location: this.getCurrentUrl(),
          ctaName: 'Adding new account group',
          ctaType: 'Dropdown',
        },
        fireTrackLink: {
          fireTrackLink: 'true',
        },
      });
    }
  }

  modifyingAccountGroup() {
    if (this.isClientTrackable) {
      this.adobeWindowRef.dataLayer.push({
        event: 'callToAction',
        callToAction: {
          location: this.getCurrentUrl(),
          ctaName: 'Modifying account group',
          ctaType: 'Dropdown',
        },
        fireTrackLink: {
          fireTrackLink: 'true',
        },
      });
    }
  }

  clickedOnPrint() {
    if (this.isClientTrackable) {
      this.adobeWindowRef.dataLayer.push({
        event: 'callToAction',
        callToAction: {
          location: this.getCurrentUrl(),
          ctaName: 'Print portfolio watch report',
          ctaType: 'Text Link',
        },
        fireTrackLink: {
          fireTrackLink: 'true',
        },
      });
    }
  }

  clickedOnTesterTool() {
    if (this.isClientTrackable) {
      this.adobeWindowRef.dataLayer.push({
        event: 'callToAction',
        callToAction: {
          location: this.getCurrentUrl(),
          ctaName: 'Use tester tool',
          ctaType: 'Text Link',
        },
        fireTrackLink: {
          fireTrackLink: 'true',
        },
      });
    }
  }

  clickedOnEditScenario() {
    if (this.isClientTrackable) {
      this.adobeWindowRef.dataLayer.push({
        event: 'callToAction',
        callToAction: {
          location: this.getCurrentUrl(),
          ctaName: 'Edit scenario',
          ctaType: 'Button',
        },
        fireTrackLink: {
          fireTrackLink: 'true',
        },
      });
    }
  }

  clickedOnCloseScenario() {
    if (this.isClientTrackable) {
      this.adobeWindowRef.dataLayer.push({
        event: 'callToAction',
        callToAction: {
          location: this.getCurrentUrl(),
          ctaName: 'Close scenario',
          ctaType: 'Button',
        },
        fireTrackLink: {
          fireTrackLink: 'true',
        },
      });
    }
  }

  clickedOnHoldingsDetails() {
    if (this.isClientTrackable) {
      this.adobeWindowRef.dataLayer.push({
        event: 'callToAction',
        callToAction: {
          location: this.getCurrentUrl(),
          ctaName: 'Holdings Details',
          ctaType: 'Button',
        },
        fireTrackLink: {
          fireTrackLink: 'true',
        },
      });
    }
  }

  clickedOnExportData(fileName) {
    if (this.isClientTrackable) {
      this.adobeWindowRef.dataLayer.push({
        event: 'contentDownloaded',
        linkInfo: {
          fileLocation: this.getCurrentUrl(),
          fileName: 'csv:' + fileName,
        },
      });
    }
  }

  clickedOnDomesticVsInternational(location) {
    if (this.isClientTrackable) {
      this.adobeWindowRef.dataLayer.push({
        event: 'callToAction',
        callToAction: {
          location: this.getCurrentUrl(),
          ctaName: 'Navigating to Domestic Vs. International link: ' + location,
          ctaType: 'Text Link',
        },
        fireTrackLink: {
          fireTrackLink: 'true',
        },
      });
    }
  }

  clickedOnRetakeQuestionnaire() {
    if (this.isClientTrackable) {
      this.adobeWindowRef.dataLayer.push({
        event: 'callToAction',
        callToAction: {
          location: this.getCurrentUrl(),
          ctaName: 'Retaking questionnaire',
          ctaType: 'Button',
        },
        fireTrackLink: {
          fireTrackLink: 'true',
        },
      });
    }
  }

  clickedOnSaveTargetFromSuggested() {
    if (this.isClientTrackable) {
      this.adobeWindowRef.dataLayer.push({
        event: 'callToAction',
        callToAction: {
          location: this.getCurrentUrl(),
          ctaName: 'Saving suggested target mix',
          ctaType: 'Button',
        },
        fireTrackLink: {
          fireTrackLink: 'true',
        },
      });
    }
  }

  clickedOnChooseTargetMix() {
    if (this.isClientTrackable) {
      this.adobeWindowRef.dataLayer.push({
        event: 'callToAction',
        callToAction: {
          location: this.getCurrentUrl(),
          ctaName: 'Switching to manual tool from suggested mix',
          ctaType: 'Button',
        },
        fireTrackLink: {
          fireTrackLink: 'true',
        },
      });
    }
  }

  clickedOnTargetMixAboutPageLink() {
    if (this.isClientTrackable) {
      this.adobeWindowRef.dataLayer.push({
        event: 'callToAction',
        callToAction: {
          location: this.getCurrentUrl(),
          ctaName: 'Navigating to target mix about page',
          ctaType: 'Text Link',
        },
        fireTrackLink: {
          fireTrackLink: 'true',
        },
      });
    }
  }

  clickedOnTargetMixModalContinueButton(selectedRoute) {
    const ctaName =
      selectedRoute === targetAssetMixRoutes.selectionTool
        ? 'Choose an asset mix and Continue'
        : 'Let us suggest an asset mix and Continue';
    if (this.isClientTrackable) {
      this.adobeWindowRef.dataLayer.push({
        event: 'callToAction',
        callToAction: {
          location: this.getCurrentUrl(),
          ctaName: ctaName,
          ctaType: 'Button',
        },
        fireTrackLink: {
          fireTrackLink: 'true',
        },
      });
    }
  }

  clickedOnCustomToolSelector(selectedTargetTool) {
    const targetTool =
      selectedTargetTool === CustomTargetAssetMixTool.sliderTool
        ? 'Set target asset mix using slider tool'
        : 'Set target asset mix using manual tool';
    if (this.isClientTrackable) {
      this.adobeWindowRef.dataLayer.push({
        event: 'callToAction',
        callToAction: {
          location: this.getCurrentUrl(),
          ctaName: targetTool,
          ctaType: 'Text Link',
        },
        fireTrackLink: {
          fireTrackLink: 'true',
        },
      });
    }
  }

  clickedOnSaveNewTargetMix() {
    if (this.isClientTrackable) {
      this.adobeWindowRef.dataLayer.push({
        event: 'callToAction',
        callToAction: {
          location: this.getCurrentUrl(),
          ctaName: 'Save new target mix',
          ctaType: 'Button',
        },
        fireTrackLink: {
          fireTrackLink: 'true',
        },
      });
    }
  }

  getPageInfo(data: Data): AdobeTracking {
    return {
      pageId: `portfolio-analysis.angular:${data.siteSection1}:${data.siteSection2}`,
      pageTitle: document.title,
      pageName: `us:en:retail:web:portfolio-analysis.angular:${data.siteSection1}:${data.siteSection2}`,
      siteSection1: data.siteSection1,
      siteSection2: data.siteSection2,
      publicVsSecure: 'secure',
      siteIdentifier: 'retail',
      platform: 'web',
      is404: false,
      DLVersion: '1.0.0',
    };
  }

  private calculateAge(dateOfBirth: string): number {
    const millisecondsPerYear = 1000 * 60 * 60 * 24 * 365;
    const dob = new Date(dateOfBirth);
    const now = Date.now();
    const years = (now - dob.valueOf()) / millisecondsPerYear;
    return years < 0 ? 0 : Math.floor(years);
  }
}
