import { Component, Input } from '@angular/core';

@Component({
  selector: 'PW1-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss'],
})
export class DisclaimerComponent {
  @Input() contentTag: string;
  @Input() headerTag: string;
  @Input() linkTag: string;
  @Input() hasCloseControl = false;

  GetLinkUrl(content: string) {
    if (content) {
      return content.split(' : ')[1];
    }
    return null;
  }

  GetLinkText(content: string) {
    if (content) {
      return content.split(' : ')[0];
    }
    return null;
  }
}
