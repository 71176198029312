import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { PortfolioDataService } from '../../../services/portfolio-data-service/portfolio-data.service';

@Injectable({
  providedIn: 'root',
})
export class TesterToolGuard implements CanActivate {
  constructor(private router: Router, private portfolioDataService: PortfolioDataService) {}

  canActivate(): boolean {
    if (!this.portfolioDataService.testerToolActive) {
      this.portfolioDataService.setShowPWHeader(true);
      this.router.navigate(['']);
      return false;
    }
    return true;
  }
}
