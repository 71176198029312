<ss-lib-page-layout #content="pwContent" pwContent *ngIf="displayGreetingHeader | async">
  <div
    inner
    [ngClass]="{
      'secure-site__nav-container--custom-fp':
        checkFocusPanelOptions()
    }"
  >
    <button
      *ngIf="checkFocusPanelOptions()"
      type="button"
      (click)="openFocusPanel.emit()"
      class="fp-cta c11n-button"
      aria-label="Open Focus Panel"
    >
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" rx="16" fill="#040505"></rect>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16 22V10H10V22H16ZM16 9H22C22.5523 9 23 9.44772 23 10V22C23 22.5523 22.5523 23 22 23H16H10C9.44772 23 9 22.5523 9 22V10C9 9.44772 9.44772 9 10 9H16Z"
          fill="white"
        ></path>
      </svg>
    </button>
    <ss-lib-secondary-nav
      [activeTabItem]="'portfolio_watch'"
      [displayDownloadCenterLink]="false"
    ></ss-lib-secondary-nav>
  </div>
</ss-lib-page-layout>
