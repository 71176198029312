import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';

@Component({
  selector: 'want-help-banner',
  templateUrl: './want-help-banner.component.html',
  styleUrls: ['./want-help-banner.component.scss'],
})
export class WantHelpBannerComponent {
  constructor(private router: Router, private adobeAnalyticsService: AdobeAnalyticsService) {}
  navigateToAbout() {
    this.adobeAnalyticsService.clickedOnTargetMixAboutPageLink();
    this.router.navigate(['/target-mix-about']);
  }
}
