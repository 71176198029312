import { Injectable } from '@angular/core';
import { PortfolioAlert } from '../../models/chart-data-response-models/PortfolioAlert';
import { AllChartsResponseData } from '../../models/chart-data-response-models/all-charts-response-data';
import { LoggerCode } from '../../../models/logger';
import { LoggerService } from '../logger/logger.service';
import { PortfolioDataService } from '../portfolio-data-service/portfolio-data.service';

@Injectable({
  providedIn: 'root',
})
export class AlertsHelperService {
  getAlertsList(chartResponseData: AllChartsResponseData): Array<PortfolioAlert> {
    let alertList: PortfolioAlert[] = [];

    //alerts for asset-allocation
    alertList = alertList.concat(
      this.getCompletedAlertsForData(chartResponseData.assetTypes.alertList, '/asset-mix', 'asset'),
    );

    //alerts for /stock-analysis/xxx
    alertList = alertList.concat(
      this.getCompletedAlertsForData(
        chartResponseData.stockMarketCap.alertList,
        '/stock-analysis/market-capitalization',
        'stock',
      ),
    );
    alertList = alertList.concat(
      this.getCompletedAlertsForData(
        chartResponseData.stockInvestmentStyle.alertList,
        '/stock-analysis/investment-style',
        'stock',
      ),
    );
    alertList = alertList.concat(
      this.getCompletedAlertsForData(
        chartResponseData.stockIndustrySector.alertList,
        '/stock-analysis/industry-sectors',
        'stock',
      ),
    );
    alertList = alertList.concat(
      this.getCompletedAlertsForData(
        chartResponseData.stockInternationalRegions.alertList,
        '/stock-analysis/international-regions',
        'stock',
      ),
    );
    alertList = alertList.concat(
      this.getCompletedAlertsForData(
        chartResponseData.stockDomesticInternational.alertList,
        '/stock-analysis/international-regions',
        'stock',
      ),
    );

    //alerts for /bond-analysis/xxx
    alertList = alertList.concat(
      this.getCompletedAlertsForData(
        chartResponseData.bondCreditQuality.alertList,
        '/bond-analysis/credit-quality',
        'bond',
      ),
    );
    alertList = alertList.concat(
      this.getCompletedAlertsForData(
        chartResponseData.bondDomesticInternational.alertList,
        '/bond-analysis/credit-quality',
        'bond',
      ),
    );
    alertList = alertList.concat(
      this.getCompletedAlertsForData(
        chartResponseData.bondMaturity.alertList,
        '/bond-analysis/interest-rate-sensitivity',
        'bond',
      ),
    );
    alertList = alertList.concat(
      this.getCompletedAlertsForData(
        chartResponseData.bondTax.alertList,
        '/bond-analysis/nine-box',
        'bond',
      ),
    );

    //alerts for /costs
    alertList = alertList.concat(
      this.getCompletedAlertsForData(
        chartResponseData.investmentCost.alertList,
        '/costs/mutual-fund-costs',
        'tax',
      ),
    );

    return alertList;
  }

  getCompletedAlertsForData(
    incompleteAlerts: Array<PortfolioAlert>,
    alertUrl: string,
    alertType: string,
  ): PortfolioAlert[] {
    let arrayOfCompletedAlerts: PortfolioAlert[] = [];

    if (incompleteAlerts) {
      incompleteAlerts.forEach((alert: PortfolioAlert) => {
        let headerString;
        if (alert) {
          const alertStatus = alert.status;
          if (alertStatus) {
            if (alertStatus.endsWith('Alert')) {
              headerString = 'Alert';
            } else {
              headerString = 'Information';
            }
          }
        }
        if (headerString) {
          arrayOfCompletedAlerts.push(
            Object.assign(alert, { url: alertUrl, type: alertType, header: headerString }),
          );
        }
      });
    }

    return arrayOfCompletedAlerts;
  }

  // eslint-disable-next-line
  removeAlert(
    alert: PortfolioAlert,
    chartData: AllChartsResponseData,
    logger: LoggerService,
    chartDataService: PortfolioDataService,
  ) {
    switch (alert.url) {
      case '/asset-mix':
        this.removeAlertBasedOnAlertUrl(alert, 'assetTypes', chartData, logger, chartDataService);
        break;
      case '/stock-analysis/market-capitalization':
        this.removeAlertBasedOnAlertUrl(
          alert,
          'stockMarketCap',
          chartData,
          logger,
          chartDataService,
        );
        break;
      case '/stock-analysis/investment-style':
        this.removeAlertBasedOnAlertUrl(
          alert,
          'stockInvestmentStyle',
          chartData,
          logger,
          chartDataService,
        );
        break;
      case '/stock-analysis/industry-sectors':
        this.removeAlertBasedOnAlertUrl(
          alert,
          'stockIndustrySector',
          chartData,
          logger,
          chartDataService,
        );
        break;
      case '/stock-analysis/international-regions':
        this.removeAlertBasedOnAlertUrl(
          alert,
          'stockInternationalRegions',
          chartData,
          logger,
          chartDataService,
        );
        this.removeAlertBasedOnAlertUrl(
          alert,
          'stockDomesticInternational',
          chartData,
          logger,
          chartDataService,
        );
        break;
      case '/bond-analysis/credit-quality':
        this.removeAlertBasedOnAlertUrl(
          alert,
          'bondCreditQuality',
          chartData,
          logger,
          chartDataService,
        );
        this.removeAlertBasedOnAlertUrl(
          alert,
          'bondDomesticInternational',
          chartData,
          logger,
          chartDataService,
        );
        break;
      case '/bond-analysis/interest-rate-sensitivity':
        this.removeAlertBasedOnAlertUrl(alert, 'bondMaturity', chartData, logger, chartDataService);
        break;
      case '/bond-analysis/nine-box':
        this.removeAlertBasedOnAlertUrl(alert, 'bondTax', chartData, logger, chartDataService);
        break;
      case '/costs/mutual-fund-costs':
        this.removeAlertBasedOnAlertUrl(
          alert,
          'investmentCost',
          chartData,
          logger,
          chartDataService,
        );
        break;
      default:
        break;
    }
  }

  // eslint-disable-next-line max-params
  removeAlertBasedOnAlertUrl(
    alert: PortfolioAlert,
    dataPoint: string,
    chartData: AllChartsResponseData,
    logger: LoggerService,
    chartDataService: PortfolioDataService,
  ) {
    const alertIndex = this.findIndexOfAlert(alert, dataPoint, chartData);
    this.removeAlertFromChartData(
      alert,
      dataPoint,
      alertIndex,
      logger,
      chartData,
      chartDataService,
    );
  }

  findIndexOfAlert(alert: PortfolioAlert, dataPoint, chartData: AllChartsResponseData) {
    return chartData[dataPoint].alertList.findIndex((a) => a.longMessage === alert.longMessage);
  }

  // eslint-disable-next-line max-params
  removeAlertFromChartData(
    alert: PortfolioAlert,
    dataPoint: string,
    alertIndex: number,
    logger: LoggerService,
    chartData: AllChartsResponseData,
    chartDataService: PortfolioDataService,
  ) {
    if (alertIndex !== -1) {
      logger.info(
        {
          message: 'User removed alert',
          alertId: alert.longMessage,
          alertCategory: alert.type,
          alertDataPoint: dataPoint,
          alertUrl: alert.url,
        },
        LoggerCode.ALERT_CLOSED,
      );

      chartData[dataPoint].alertList.splice(alertIndex, 1);
      chartDataService.updateChartData(chartData);
    }
  }
}
