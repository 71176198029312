import { Component, OnInit, Input } from '@angular/core';
import { INDEX_CONSTANTS } from '../../../models/chart.constants';
import {
  CategorizationObject,
  HoldingsTableCategorization,
  HoldingsTableData,
} from '../../../models/holdings-table';
import { HoldingsTableBuilderService } from '../../../services/holdings-table-builder/holdings-table-builder.service';
import { DrawerTrackerService } from '@vanguard/pfx-components';
import { DRAWER_CONSTANTS } from 'src/app/models/drawer.constants';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';

@Component({
  selector: 'PW1-effective-maturity-table',
  templateUrl: './effective-maturity-table.component.html',
  styleUrls: ['./effective-maturity-table.component.scss'],
})
export class EffectiveMaturityTableComponent implements OnInit {
  @Input() chartData;

  total: number = 0;
  taxableTotal;
  municipalTotal;

  isUncategorizedEnabled = false;

  tableData = [];
  tableDataByHoldings: HoldingsTableData;
  totalData = [];
  holdingsTotal: number = 0;

  loaded = false;
  viewByHoldings = false;
  private totalHigh = INDEX_CONSTANTS.ZERO;
  private totalMedium = INDEX_CONSTANTS.ZERO;
  private totalLow = INDEX_CONSTANTS.ZERO;

  private taxableHigh = INDEX_CONSTANTS.ZERO;
  private taxableMedium = INDEX_CONSTANTS.ZERO;
  private taxableLow = INDEX_CONSTANTS.ZERO;

  private municipalHigh = INDEX_CONSTANTS.ZERO;
  private municipalMedium = INDEX_CONSTANTS.ZERO;
  private municipalLow = INDEX_CONSTANTS.ZERO;

  private taxableUncategorized = INDEX_CONSTANTS.ZERO;
  private municipalUncategorized = INDEX_CONSTANTS.ZERO;
  private totalUncategorized = INDEX_CONSTANTS.ZERO;

  constructor(
    private holdingsTableBuilderService: HoldingsTableBuilderService,
    private drawerTrackerService: DrawerTrackerService,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {}

  ngOnInit() {
    if (this.chartData) {
      this.setChartData();
      this.setTableData();
      this.setTableDataByHoldings();
    }
  }

  setChartData() {
    this.taxableHigh = this.chartData.bondMaturity.chart.taxableHigh;
    this.municipalHigh = this.chartData.bondMaturity.chart.municipalHigh;
    this.totalHigh = this.taxableHigh + this.municipalHigh;

    this.taxableMedium = this.chartData.bondMaturity.chart.taxableMedium;
    this.municipalMedium = this.chartData.bondMaturity.chart.municipalMedium;
    this.totalMedium = this.taxableMedium + this.municipalMedium;

    this.municipalLow = this.chartData.bondMaturity.chart.municipalLow;
    this.taxableLow = this.chartData.bondMaturity.chart.taxableLow;
    this.totalLow = this.taxableLow + this.municipalLow;

    this.taxableUncategorized = this.chartData.bondMaturity.chart.taxableUncategorized;
    this.municipalUncategorized = this.chartData.bondMaturity.chart.municipalUncategorized;
    this.totalUncategorized = this.taxableUncategorized + this.municipalUncategorized;
    this.setUncategorizedData();
  }

  setUncategorizedData() {
    this.taxableTotal =
      this.taxableHigh + this.taxableMedium + this.taxableLow + this.taxableUncategorized;
    this.municipalTotal =
      this.municipalHigh + this.municipalMedium + this.municipalLow + this.municipalUncategorized;
    this.total = this.totalHigh + this.totalMedium + this.totalLow + this.totalUncategorized;
    this.isUncategorizedEnabled = this.taxableUncategorized > 0 || this.municipalUncategorized > 0;
  }

  setTableData() {
    this.tableData = [
      {
        quality: 'High',
        yourTaxableBonds: this.taxableHigh,
        yourMunicipal: this.municipalHigh,
        yourTotalBonds: this.totalHigh,
      },
      {
        quality: 'Medium',
        yourTaxableBonds: this.taxableMedium,
        yourMunicipal: this.municipalMedium,
        yourTotalBonds: this.totalMedium,
      },
      {
        quality: 'Low',
        yourTaxableBonds: this.taxableLow,
        yourMunicipal: this.municipalLow,
        yourTotalBonds: this.totalLow,
      },
    ];

    if (this.isUncategorizedEnabled) {
      this.tableData.push({
        quality: 'Uncategorized',
        yourTaxableBonds: this.taxableUncategorized,
        yourMunicipal: this.municipalUncategorized,
        yourTotalBonds: this.totalUncategorized,
      });
    }

    this.totalData = [
      {
        quality: 'Total',
        yourTaxableBonds: this.taxableTotal,
        yourMunicipal: this.municipalTotal,
        yourTotalBonds: this.total.toFixed(0),
      },
    ];

    this.loaded = true;
  }

  setTableDataByHoldings() {
    this.tableDataByHoldings = this.generateTableDataByHoldings();
    this.loaded = true;
  }

  generateTableDataByHoldings(): HoldingsTableData {
    let applicableBalance = this.chartData.bondMaturity.applicableBalance;

    const categorizationObjects: CategorizationObject[] = [
      { title: 'High sensitivity bond funds (Taxable)', categorization: 'taxableHigh' },
      { title: 'High sensitivity bond funds (Municipal)', categorization: 'municipalHigh' },
      { title: 'Medium sensitivity bond funds (Taxable)', categorization: 'taxableMedium' },
      { title: 'Medium sensitivity bond funds (Municipal)', categorization: 'municipalMedium' },
      { title: 'Low sensitivity bond funds (Taxable)', categorization: 'taxableLow' },
      { title: 'Low sensitivity bond funds (Municipal)', categorization: 'municipalLow' },
      {
        title: 'Uncategorized sensitivity bond funds (Taxable)',
        categorization: 'taxableUncategorized',
      },
      {
        title: 'Uncategorized sensitivity bond funds (Municipal)',
        categorization: 'municipalUncategorized',
      },
    ];
    const holdingsDataByCategorizations: HoldingsTableCategorization[] =
      this.holdingsTableBuilderService.getHoldingsByCategorizations(
        this.chartData.bondMaturity,
        categorizationObjects,

      );

    return {
      categorizations: holdingsDataByCategorizations,
      holdingsTotal: applicableBalance,
    };
  }

  changeTableView(val: boolean) {
    this.viewByHoldings = val;
    if (val) {
      this.adobeAnalyticsService.clickedOnHoldingsDetails();
    }
  }

  openInterestRateDrawer() {
    this.drawerTrackerService.setSelectSection(DRAWER_CONSTANTS.INTEREST_RATE_SENSITIVITY);
  }
}
