import { Component, OnInit, Input } from '@angular/core';
import { INDEX_CONSTANTS } from '../../../models/chart.constants';
import {
  CategorizationObject,
  HoldingsTableCategorization,
  HoldingsTableData,
} from '../../../models/holdings-table';
import { HoldingsTableBuilderService } from '../../../services/holdings-table-builder/holdings-table-builder.service';
import { Router } from '@angular/router';
import { DrawerTrackerService } from '@vanguard/pfx-components';
import { DRAWER_CONSTANTS } from 'src/app/models/drawer.constants';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';

@Component({
  selector: 'international-regions-table',
  templateUrl: './international-regions-table.component.html',
  styleUrls: ['./international-regions-table.component.scss'],
})
export class InternationalRegionsTableComponent implements OnInit {
  @Input() chartData;
  loaded = false;
  viewByHoldings = false;

  tableData = [];
  tableDataByHoldings = [];

  holdingsTableDataModernized: HoldingsTableData;

  chart = [];
  stockOutsideUS = [];
  difference = [];

  internationalDisplayedColumns: string[] = ['market', 'yourStock', 'stockOutsideUS', 'difference'];

  developedMarkets = ['Europe', 'Pacific', 'North America', 'Middle East'];
  emergingMarkets = ['Emerging Markets'];
  uncategorizedHoldings = ['Other', 'Uncategorized'];
  holdingsTotal: number = 0;

  developedMarketsData = [];
  emergingMarketsData = [];
  uncategorizedHoldingsData = [];
  totalData = [];

  constructor(
    private holdingsTableBuilderService: HoldingsTableBuilderService,
    private router: Router,
    private drawerTrackerService: DrawerTrackerService,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {}

  ngOnInit() {
    if (this.chartData) {
      this.setTableData();
      this.getHoldingsDataModernized();
    }
  }

  setTableData() {
    this.chart = Object.entries(this.chartData.stockInternationalRegions.chart);
    this.stockOutsideUS = Object.entries(this.chartData.stockInternationalRegions.stockOutsideUS);
    this.difference = Object.entries(this.chartData.stockInternationalRegions.difference);

    this.tableData = this.generateTableData();
    this.loaded = true;
  }

  isEitherValueNonZero(chartEntryItem, entriesForSecondColumn) {
    const propertyName: string = chartEntryItem[INDEX_CONSTANTS.ZERO];
    const entryItemForSecondColumn = entriesForSecondColumn.find(
      (element) => element[0] === propertyName,
    );

    return (
      chartEntryItem[INDEX_CONSTANTS.ONE] > 0 || entryItemForSecondColumn[INDEX_CONSTANTS.ONE] > 0
    );
  }

  generateTableData() {
    let processedTableData = [];

    let chartEntry = this.chart;
    let stockOutsideUSEntry = this.stockOutsideUS;
    let differenceEntry = this.difference;

    chartEntry.forEach((chartEntryItem, index) => {
      const marketName = this.splitAndSpace(chartEntryItem[INDEX_CONSTANTS.ZERO]);

      if (this.developedMarkets.includes(marketName)) {
        this.developedMarketsData.push({
          market: marketName,
          yourStock: chartEntryItem[INDEX_CONSTANTS.ONE] + ' %',
          stockOutsideUS: stockOutsideUSEntry[index][INDEX_CONSTANTS.ONE] + ' %',
          difference: differenceEntry[index][INDEX_CONSTANTS.ONE] + ' %',
        });
      } else if (this.emergingMarkets.includes(marketName)) {
        this.emergingMarketsData.push({
          market: marketName.charAt(0).toUpperCase() + marketName.slice(1).toLowerCase(),
          yourStock: chartEntryItem[INDEX_CONSTANTS.ONE] + ' %',
          stockOutsideUS: stockOutsideUSEntry[index][INDEX_CONSTANTS.ONE] + ' %',
          difference: differenceEntry[index][INDEX_CONSTANTS.ONE] + ' %',
        });
      } else if (this.uncategorizedHoldings.includes(marketName)) {
        if (this.isEitherValueNonZero(chartEntryItem, this.stockOutsideUS)) {
          this.uncategorizedHoldingsData.push({
            market: marketName,
            yourStock: chartEntryItem[INDEX_CONSTANTS.ONE] + ' %',
            stockOutsideUS: stockOutsideUSEntry[index][INDEX_CONSTANTS.ONE] + ' %',
            difference: differenceEntry[index][INDEX_CONSTANTS.ONE] + ' %',
          });
        }
      }
    });

    if (this.chart.length > 0 && this.stockOutsideUS.length > 0) {
      this.totalData = [
        {
          market: 'Total',
          yourStock: this.getTotalPercentage(this.chartData.stockInternationalRegions.chart),
          stockOutsideUS: this.getTotalPercentage(
            this.chartData.stockInternationalRegions.stockOutsideUS,
          ),
          difference: '',
        },
      ];
    }

    processedTableData.push({
      market: 'Developed markets',
      yourStock: '',
      stockOutsideUS: '',
      difference: '',
    });
    processedTableData = processedTableData.concat(this.developedMarketsData);
    processedTableData.push({
      market: 'Emerging markets',
      yourStock: '',
      stockOutsideUS: '',
      difference: '',
    });
    processedTableData = processedTableData.concat(this.emergingMarketsData);

    if (this.uncategorizedHoldingsData.length > 0) {
      processedTableData.push({
        market: 'Uncategorized holdings',
        yourStock: '',
        stockOutsideUS: '',
        difference: '',
      });
      processedTableData = processedTableData.concat(this.uncategorizedHoldingsData);
    }

    return processedTableData;
  }

  getHoldingsDataModernized() {
    this.holdingsTableDataModernized = this.generateTableDataByHoldingsModernized();
  }

  generateTableDataByHoldingsModernized(): HoldingsTableData {
    const applicableBalance: number = this.chartData.stockInternationalRegions.applicableBalance;
    const categorizationObjects: CategorizationObject[] = [
      { categorization: 'europe', title: 'Stocks & stock funds in developed European countries' },
      { categorization: 'pacific', title: 'Stocks & stock funds in developed Pacific countries' },
      { categorization: 'northAmerica', title: 'Stocks & stock funds in Canada' },
      { categorization: 'middleEast', title: 'Stocks & stock funds in Middle Eastern countries' },
      {
        categorization: 'emergingMarkets',
        title: 'Stocks & stock funds in emerging market countries',
      },
      { categorization: 'other', title: 'Stocks & stock funds in other regions' },
      { categorization: 'uncategorized', title: 'Uncategorized stocks & stock funds' },
    ];
    const holdingsDataByCategorizations: HoldingsTableCategorization[] =
      this.holdingsTableBuilderService.getHoldingsByCategorizations(
        this.chartData.stockInternationalRegions,
        categorizationObjects,

      );

    return {
      categorizations: holdingsDataByCategorizations,
      holdingsTotal: applicableBalance,
    };
  }

  splitAndSpace(chartEntry: string): string {
    let spacedString = chartEntry.replace(/([a-zA-Z])(?=[A-Z])/g, '$1 ');
    return (
      spacedString.substring(INDEX_CONSTANTS.ZERO, INDEX_CONSTANTS.ONE).toUpperCase() +
      spacedString.slice(INDEX_CONSTANTS.ONE)
    );
  }

  getTotalPercentage(chartObj) {
    let total: any = Object.values(chartObj)
      .filter((val: any) => !isNaN(val))
      .reduce((acc: number, value: number) => acc + value, 0);
    return total.toFixed(0) + '%';
  }

  changeTableView(val: boolean) {
    this.viewByHoldings = val;
    if (val) {
      this.adobeAnalyticsService.clickedOnHoldingsDetails();
    }
  }

  navigateToLearnMorePage() {
    this.adobeAnalyticsService.clickedOnLearnMore('/learn-more/international-regions');
    this.router.navigate(['/learn-more/international-regions']);
  }
  openInternationalRegionsDrawer() {
    this.drawerTrackerService.setSelectSection(DRAWER_CONSTANTS.INTERNATIONAL_REGIONS);
  }
}
