import { TargetAssetAllocation } from "./chart-data-response-models/target-asset-allocation";

export class TargetAssetRequest{
    clientViewId: string;
    viewName?: string;
    targetAssetAllocations: TargetAssetAllocation[];

    constructor(clientViewId: string, viewName:string = undefined){
        this.viewName = viewName;
        this.clientViewId = clientViewId;
        this.targetAssetAllocations = [];
    }

    addAssetAllocation(assetType: TargetAssetType, percentageAssetAllocation: number){
        this.targetAssetAllocations.push({assetType: assetType, percentageAssetAllocation: percentageAssetAllocation});
    }

    getAssetAllocation(assetType: TargetAssetType): TargetAssetAllocation{
        return this.targetAssetAllocations.find(x => x.assetType === assetType);
    }
}

export enum TargetAssetType{
    Stocks = "STOCKS",
    Bonds = "BONDS",
    ShortTermReserves = "SHORT_TERM_RESERVES",
}