<section #content="pwContent" pwContent id="costs-risk-content" tabindex="-1">
  <div>
    <div *ngIf="hasLoadedChartData" class="mainCardContent">
      <div class="modernizedContainer c11n-space-stack-3x">
        <div class="modernized-bar-chart-wrapper">
          <div class="bar-chart">
            <bar-chart
              [chartData]="modernizedChartData"
              [industryChartId]="'costPageModernizedIndustryChartId'"
              [vanguardChartId]="'costPageModernizedVanguardChartId'"
              [yourCostsChartId]="'costPageModernizedYourCostsChartId'"
              [isFullWidth]="true"
            ></bar-chart>
            <div class="investment-costs-risk-disclaimer">
              <!-- TODO : update the AEM content key once the AEM changes are available in PROD -->
              <p>*As of December 2022</p>
            </div>
          </div>
        </div>
      </div>
      <PW1-investment-costs-disclaimer-accordion></PW1-investment-costs-disclaimer-accordion>
      <cost-risk-tab-table></cost-risk-tab-table>
      <PW1-disclaimer
        contentTag="PWCosts_Disclaimer_Body"
        headerTag="PWCosts_Disclaimer_Header"
        linkTag="PWCosts_Disclaimer_Link_Vanguard"
      ></PW1-disclaimer>
    </div>
  </div>
</section>
