import { ChartData } from '../chart-models/chart-data';
export class StockBondDomesticInternationalData {
  domestic: number;
  international: number;
  uncategorized: number;

  public static buildChartDataList(data: StockBondDomesticInternationalData): ChartData {
    const fraction_digits = 2;
    var retData = new ChartData();
    var labelList = new Array<string>();
    labelList.push('Domestic', 'International', 'Other');

    var chartValueList = new Array<number>();
    chartValueList.push(data.domestic).toFixed(fraction_digits);
    chartValueList.push(data.international).toFixed(fraction_digits);
    chartValueList.push(data.uncategorized).toFixed(fraction_digits);

    retData.labelDetails = labelList;
    retData.percentageDetails = chartValueList;

    return retData;
  }
}
