/* eslint-disable */
import { Component, Input, OnInit } from '@angular/core';
import { DrawerTrackerService } from '@vanguard/pfx-components';
import { DRAWER_CONSTANTS } from 'src/app/models/drawer.constants';

@Component({
  selector: 'PW1-hypothetical-nine-box-component',
  templateUrl: './hypothetical-nine-box.component.html',
  styleUrls: ['./hypothetical-nine-box.component.scss'],
})
export class HypotheticalNineBoxComponent implements OnInit {
  @Input() chartData;
  @Input() hypotheticalChartData;

  xHeaders = ['Low', 'Medium', 'High'];
  yHeaders = ['High', 'Medium', 'Low'];
  dataArray: string[][];
  hypotheticalArray: string[][];

  constructor(private drawerTrackerService: DrawerTrackerService) {}

  ngOnInit() {
    if (this.chartData) {
      this.setTableData();
    }
    if (this.hypotheticalChartData) {
      this.setHypotheticalTableData();
    }
  }

  setTableData() {
    this.dataArray = [
      [
        this.chartData.bondNineBox.maturityLowQualityHigh + '%',
        this.chartData.bondNineBox.maturityLowQualityMedium + '%',
        this.chartData.bondNineBox.maturityLowQualityLow + '%',
      ],
      [
        this.chartData.bondNineBox.maturityMediumQualityHigh + '%',
        this.chartData.bondNineBox.maturityMediumQualityMedium + '%',
        this.chartData.bondNineBox.maturityMediumQualityLow + '%',
      ],
      [
        this.chartData.bondNineBox.maturityHighQualityHigh + '%',
        this.chartData.bondNineBox.maturityHighQualityMedium + '%',
        this.chartData.bondNineBox.maturityHighQualityLow + '%',
      ],
    ];
  }
  setHypotheticalTableData() {
    this.hypotheticalArray = [
      [
        this.hypotheticalChartData.bondNineBox.maturityLowQualityHigh + '%',
        this.hypotheticalChartData.bondNineBox.maturityLowQualityMedium + '%',
        this.hypotheticalChartData.bondNineBox.maturityLowQualityLow + '%',
      ],
      [
        this.hypotheticalChartData.bondNineBox.maturityMediumQualityHigh + '%',
        this.hypotheticalChartData.bondNineBox.maturityMediumQualityMedium + '%',
        this.hypotheticalChartData.bondNineBox.maturityMediumQualityLow + '%',
      ],
      [
        this.hypotheticalChartData.bondNineBox.maturityHighQualityHigh + '%',
        this.hypotheticalChartData.bondNineBox.maturityHighQualityMedium + '%',
        this.hypotheticalChartData.bondNineBox.maturityHighQualityLow + '%',
      ],
    ];
  }

  openNineBoxDrawer() {
    this.drawerTrackerService.setSelectSection(DRAWER_CONSTANTS.BOND_NINE_BOX);
  }
}
