/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AllChartsResponseData } from 'src/app/models/chart-data-response-models/all-charts-response-data';
import { PortfolioDataService } from 'src/app/services/portfolio-data-service/portfolio-data.service';

@Component({
  selector: 'print-view',
  templateUrl: './print-view.component.html',
  styleUrls: ['./print-view.component.scss'],
})
export class PrintViewComponent implements OnInit {
  hasLoadedTables: boolean;
  hasLoadedCharts: boolean;
  chartData: AllChartsResponseData;
  costBarChartData;
  currentDate: Date;
  accountGroup: string;

  constructor(private portfolioDataService: PortfolioDataService) {}

  ngOnInit() {
    this.currentDate = new Date();
    this.portfolioDataService.getSelectedAccountGroupPayload().subscribe((data) => {
      this.accountGroup = data.GroupName;
    });

    this.portfolioDataService.getChartDataPayload().subscribe((data) => {
      if (data) {
        this.chartData = data;
        // TODO: use these in the template to verify what can be printed
        this.hasLoadedCharts = true;
        this.hasLoadedTables = true;
      }
    });

    this.portfolioDataService.getInvestmentCostsPayload().subscribe((data) => {
      this.costBarChartData = data.modernizedInvestmentCostsChart;
    });
  }
}
