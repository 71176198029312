import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { IEnvironment } from 'src/app/models/interfaces/IEnvironment';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomUserModernizationService {
  private readonly COOKIE_NAME: string = 'vgTargetPop';
  private readonly MODERNIZED_COOKIE_VALUE: string = '|7|';
  env: IEnvironment = environment;
  private _isModernizedUser: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private cookieService: CookieService) {
    this.initializeCustomUserModernization();
  }

  initializeCustomUserModernization() {
    if (
      this.cookieService.check(this.COOKIE_NAME) &&
      this.env.isExternal.toLowerCase() === 'true'
    ) {
      const isModernizedUserValue = this.cookieService
        .get(this.COOKIE_NAME)
        .includes(this.MODERNIZED_COOKIE_VALUE);
      this._isModernizedUser.next(isModernizedUserValue);
    } else if (this.env.isExternal.toLowerCase() === 'true') {
      this._isModernizedUser.next(false);
    } else {
      this._isModernizedUser.next(true);
    }
  }

  get isModernizedUser(): Observable<boolean> {
    return this._isModernizedUser.asObservable();
  }
}
