/* eslint-disable */
import * as d3 from 'd3';

export const BAR_CHART_DATA_WIDTHS: any = [
  { label: 'Vanguard', percent: 0.0018, color: '#661605' },
  { label: 'Industry', percent: 0.0102, color: '#0A388C' },
  { label: 'Savings', percent: 0.0084, color: '#21DC0E' },
];

export const BAR_CHART_MARGIN: any = { top: 20, right: 20, bottom: 30, left: 40 },
  width = 400 - BAR_CHART_MARGIN.left - BAR_CHART_MARGIN.right,
  height = 180 - BAR_CHART_MARGIN.top - BAR_CHART_MARGIN.bottom,
  padding = 0.4;

export const BAR_CHART_PERCENT_FORMAT = '.1%';
export const BAR_CHART_VIEW_CONSTANTS = {
  ZERO: 0,
  ONE: 1,
  SIX: 6,
  FIFTEEN: 15,
  TWENTY: 20,
  TWENTY_FIVE: 25,
  THIRTY: 30,
  FORTY: 40,
  HUNDRED: 100,
  HUNDRED_EIGHTY: 180,
  FOUR_HUNDRED: 400,
  FOUR_HUNDRED_FIFTY: 450,
  DURATION: 1000,
  OPACITY: 0.4,
  PADDING: 0.4,
  VANGUARD_PERC: 0.0018,
  INDUSTRY_PERC: 0.01,
  SAVINGS_PERC: 0.0084,
};

export const NINE_BOX_VIEW_CONSTANTS = {
  ZERO: 0,
  THIRTY_FIVE: 35,
  FIFTY: 50,
  SEVENTY: 70,
  EIGHTY_FIVE: 85,
  HUNDRED: 100,
  HUNDRED_FIVE: 105,
  HUNDRED_TWENTY_FIVE: 125,
  HUNDRED_FORTY: 140,
  HUNDRED_FIFTY_FIVE: 155,
  HUNDRED_SIXTY: 160,
  HUNDRED_SEVENTY: 170,
  HUNDRED_SEVENTY_FIVE: 175,
  TWO_HUNDRED: 200,
  TWO_HUNDRED_TWENTY: 220,
  TWO_HUNDRED_THIRTY_FIVE: 235,
  TWO_HUNDRED_FIFTY: 250,
  TWO_HUNDRED_FIFTY_FIVE: 255,
  TWO_HUNDRED_EIGHTY: 280,
  THREE_HUNDRED_TEN: 310,
};

export const DONUT_CHART_CONSTANTS = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  FOUR: 4,
  SIX: 6,
  EIGHT: 8,
  NINE: 9,
  THIRTEEN: 13,
  TWENTY_FIVE: 25,
  FIFTEEN: 15,
  SIXTY_FIVE: 65,
  SEVENTY: 70,
  HUNDRED: 100,
  HUNDRED_TEN: 110,
  HUNDRED_TWENTY: 120,
  HUNDRED_THIRTY: 130,
  HUNDRED_FIFTY: 150,
  HUNDRED_SIXTY_FIVE: 165,
  HUNDRED_EIGHTY: 180,
  TWO_HUNDRED_FIFTY: 250,
  FIVE_HUNDRED: 500,
  EIGHT_HUNDRED: 800,
  INTERPOLATE: 0.1,
  OPACITY: 0.4,
};

export const PRINTVIEW_CONSTANTS = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  HUNDRED: 100,
  INITIAL_PERCENTAGE: 0.0,
};

export const TARGET_ALLOCATION_CONSTANTS = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  TWENTY: 20,
  THIRTY: 30,
  FORTY: 40,
  FIFTY: 50,
  SIXTY: 60,
  SEVENTY: 70,
  EIGHTY: 80,
  HUNDRED: 100,
};

export const INDEX_CONSTANTS = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
};

export const MARKET_CAP_CONSTANTS = {
  ZERO: 0,
  EIGHT: 8,
  TWENTY: 20,
  SEVENTY_TWO: 72,
};
