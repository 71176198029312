import { Component, OnInit } from '@angular/core';
import { LegendLine } from '../../../models/chart-models/legend-line';
import { PortfolioCardsService } from '../../../services/portfolio-cards/portfolio-cards.service';
import { PortfolioDataService } from '../../../services/portfolio-data-service/portfolio-data.service';
import { HypotheticalChartDataService } from '../../../services/hypothetical-chart-data-service/hypothetical-chart-data.service';
import { Observable } from 'rxjs';
import { DrawerTrackerService } from '@vanguard/pfx-components';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';

@Component({
  selector: 'hypothetical-costs-risk',
  templateUrl: './hypothetical-costs-risk.component.html',
  styleUrls: ['./hypothetical-costs-risk.component.scss'],
})
export class HypotheticalCostsRiskComponent implements OnInit {
  chartData: any;
  chartDataModern: any;
  hypotheticalChartData: any;
  hypotheticalChartDataModern: any;
  legendArray: Array<LegendLine>;
  hypotheticalLegendArray: Array<LegendLine>;
  hasLoadedChartData = false;
  hasLoadedHypotheticalChartData = false;

  modernizedEnabled: Observable<boolean>;

  constructor(
    private cardsService: PortfolioCardsService,
    private portfolioDataService: PortfolioDataService,
    private hypotheticalChartDataService: HypotheticalChartDataService,
    private drawerTrackerService: DrawerTrackerService,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {}

  ngOnInit() {
    this.setUpChart();
    this.setUpHypotheticalChart();
  }

  setUpChart() {
    this.portfolioDataService.getInvestmentCostsPayload().subscribe((data) => {
      this.chartDataModern = data.modernizedInvestmentCostsChart;
      this.hasLoadedChartData = true;
    });
  }

  setUpHypotheticalChart() {
    this.hypotheticalChartDataService.getInvestmentCostsPayload().subscribe((data) => {
      this.hypotheticalChartDataModern = data.modernizedInvestmentCostsChart;
      this.hasLoadedHypotheticalChartData = true;
    });
  }

  goBackToOverview() {
    this.adobeAnalyticsService.clickedBackToOverview(true);
    this.cardsService.navigateToPage('hypothetical-overview');
  }

  selectSection(sectionLabel: string) {
    this.drawerTrackerService.setSelectSection(sectionLabel);
  }
}
