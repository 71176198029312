import { Component, OnInit, Input } from '@angular/core';
import { HoldingsViewHelperService } from '../../../../../services/holdings-view-helper/holdings-view-helper.service';
import { INDEX_CONSTANTS } from '../../../../../models/chart.constants';

@Component({
  selector: 'PW1-hypothetical-international-regions-table',
  templateUrl: './hypothetical-international-regions-table.component.html',
  styleUrls: ['./hypothetical-international-regions-table.component.scss'],
})
export class HypotheticalInternationalRegionsTableComponent implements OnInit {
  @Input() chartData;
  @Input() hypotheticalData;

  loaded = false;
  loadedHypothetical = false;
  viewByHoldings = false;

  tableData = [];
  tableDataHypothetical = [];
  holdingsTablesData: any[];

  chart = [];
  stockOutsideUS = [];
  difference = [];

  chartHypothetical = [];
  stockOutsideUSHypothetical = [];
  differenceHypothetical = [];

  developedMarkets = ['Europe', 'Pacific', 'North America', 'Middle East'];
  emergingMarkets = ['Emerging Markets'];
  uncategorizedHoldings = ['Other', 'Uncategorized'];
  holdingsTotal: number = 0;
  holdingsTotalHypothetical: number = 0;

  developedMarketsData = [];
  emergingMarketsData = [];
  uncategorizedHoldingsData = [];
  totalData = [];

  developedMarketsDataHypothetical = [];
  emergingMarketsDataHypothetical = [];
  uncategorizedHoldingsDataHypothetical = [];
  totalDataHypothetical = [];

  constructor(private holdingsViewHelper: HoldingsViewHelperService) {}

  ngOnInit() {
    if (this.chartData && this.hypotheticalData) {
      this.setTableData();
      this.setTableDataHypothetical();
      this.setBothHoldingsTables();
    }
  }

  setTableData() {
    this.chart = Object.entries(this.chartData.stockInternationalRegions.chart);
    this.stockOutsideUS = Object.entries(this.chartData.stockInternationalRegions.stockOutsideUS);
    this.difference = Object.entries(this.chartData.stockInternationalRegions.difference);

    this.tableData = this.generateTableData();

    this.loaded = true;
  }

  setTableDataHypothetical() {
    this.chartHypothetical = Object.entries(this.hypotheticalData.stockInternationalRegions.chart);
    this.stockOutsideUSHypothetical = Object.entries(
      this.hypotheticalData.stockInternationalRegions.stockOutsideUS,
    );
    this.differenceHypothetical = Object.entries(
      this.hypotheticalData.stockInternationalRegions.difference,
    );

    this.tableDataHypothetical = this.generateTableDataHypothetical();

    this.loadedHypothetical = true;
  }

  isEitherValueNonZero(chartEntryItem, entriesForSecondColumn) {
    const propertyName: string = chartEntryItem[INDEX_CONSTANTS.ZERO];
    const entryItemForSecondColumn = entriesForSecondColumn.find(
      (element) => element[0] === propertyName,
    );

    return (
      chartEntryItem[INDEX_CONSTANTS.ONE] > 0 || entryItemForSecondColumn[INDEX_CONSTANTS.ONE] > 0
    );
  }

  generateTableData() {
    let processedTableData = [];

    let chartEntry = this.chart;
    let stockOutsideUSEntry = this.stockOutsideUS;
    let differenceEntry = this.difference;

    chartEntry.forEach((chartEntryItem, index) => {
      const marketName = this.splitAndSpace(chartEntryItem[INDEX_CONSTANTS.ZERO]);

      if (this.developedMarkets.indexOf(marketName) != -1) {
        this.developedMarketsData.push({
          market: marketName,
          yourStock: chartEntryItem[INDEX_CONSTANTS.ONE] + ' %',
          stockOutsideUS: stockOutsideUSEntry[index][INDEX_CONSTANTS.ONE] + ' %',
          difference: differenceEntry[index][INDEX_CONSTANTS.ONE] + ' %',
        });
      } else if (this.emergingMarkets.indexOf(marketName) != -1) {
        this.emergingMarketsData.push({
          market: marketName.charAt(0).toUpperCase() + marketName.slice(1).toLowerCase(),
          yourStock: chartEntryItem[INDEX_CONSTANTS.ONE] + ' %',
          stockOutsideUS: stockOutsideUSEntry[index][INDEX_CONSTANTS.ONE] + ' %',
          difference: differenceEntry[index][INDEX_CONSTANTS.ONE] + ' %',
        });
      } else if (this.uncategorizedHoldings.indexOf(marketName) != -1) {
        if (this.isEitherValueNonZero(chartEntryItem, this.stockOutsideUS)) {
          this.uncategorizedHoldingsData.push({
            market: marketName,
            yourStock: chartEntryItem[INDEX_CONSTANTS.ONE] + ' %',
            stockOutsideUS: stockOutsideUSEntry[index][INDEX_CONSTANTS.ONE] + ' %',
            difference: differenceEntry[index][INDEX_CONSTANTS.ONE] + ' %',
          });
        }
      }
    });

    if (this.chart.length > 0 && this.stockOutsideUS.length > 0) {
      this.totalData = [
        {
          market: 'Total',
          yourStock: this.getTotalPercentage(this.chartData.stockInternationalRegions.chart),
          stockOutsideUS: this.getTotalPercentage(
            this.chartData.stockInternationalRegions.stockOutsideUS,
          ),
          difference: '',
        },
      ];
    }

    processedTableData.push({
      market: 'Developed markets',
      yourStock: '',
      stockOutsideUS: '',
      difference: '',
    });
    processedTableData = processedTableData.concat(this.developedMarketsData);
    processedTableData.push({
      market: 'Emerging markets',
      yourStock: '',
      stockOutsideUS: '',
      difference: '',
    });
    processedTableData = processedTableData.concat(this.emergingMarketsData);

    if (this.uncategorizedHoldingsData.length > 0) {
      processedTableData.push({
        market: 'Uncategorized holdings',
        yourStock: '',
        stockOutsideUS: '',
        difference: '',
      });
      processedTableData = processedTableData.concat(this.uncategorizedHoldingsData);
    }

    return processedTableData;
  }

  generateTableDataHypothetical() {
    let processedTableData = [];

    let chartEntry = this.chartHypothetical;
    let stockOutsideUSEntry = this.stockOutsideUSHypothetical;
    let differenceEntry = this.differenceHypothetical;

    chartEntry.forEach((chartEntryItem, index) => {
      const marketName = this.splitAndSpace(chartEntryItem[INDEX_CONSTANTS.ZERO]);

      if (this.developedMarkets.indexOf(marketName) != -1) {
        this.developedMarketsDataHypothetical.push({
          market: marketName,
          yourStock: chartEntryItem[INDEX_CONSTANTS.ONE] + ' %',
          stockOutsideUS: stockOutsideUSEntry[index][INDEX_CONSTANTS.ONE] + ' %',
          difference: differenceEntry[index][INDEX_CONSTANTS.ONE] + ' %',
        });
      } else if (this.emergingMarkets.indexOf(marketName) != -1) {
        this.emergingMarketsDataHypothetical.push({
          market: marketName.charAt(0).toUpperCase() + marketName.slice(1).toLowerCase(),
          yourStock: chartEntryItem[INDEX_CONSTANTS.ONE] + ' %',
          stockOutsideUS: stockOutsideUSEntry[index][INDEX_CONSTANTS.ONE] + ' %',
          difference: differenceEntry[index][INDEX_CONSTANTS.ONE] + ' %',
        });
      } else if (this.uncategorizedHoldings.indexOf(marketName) != -1) {
        if (this.isEitherValueNonZero(chartEntryItem, this.stockOutsideUSHypothetical)) {
          this.uncategorizedHoldingsDataHypothetical.push({
            market: marketName,
            yourStock: chartEntryItem[INDEX_CONSTANTS.ONE] + ' %',
            stockOutsideUS: stockOutsideUSEntry[index][INDEX_CONSTANTS.ONE] + ' %',
            difference: differenceEntry[index][INDEX_CONSTANTS.ONE] + ' %',
          });
        }
      }
    });

    if (this.chartHypothetical.length > 0 && this.stockOutsideUSHypothetical.length > 0) {
      this.totalDataHypothetical = [
        {
          market: 'Total',
          yourStock: this.getTotalPercentage(this.hypotheticalData.stockInternationalRegions.chart),
          stockOutsideUS: this.getTotalPercentage(
            this.hypotheticalData.stockInternationalRegions.stockOutsideUS,
          ),
          difference: '',
        },
      ];
    }

    processedTableData.push({
      market: 'Developed markets',
      yourStock: '',
      stockOutsideUS: '',
      difference: '',
    });
    processedTableData = processedTableData.concat(this.developedMarketsDataHypothetical);
    processedTableData.push({
      market: 'Emerging markets',
      yourStock: '',
      stockOutsideUS: '',
      difference: '',
    });
    processedTableData = processedTableData.concat(this.emergingMarketsDataHypothetical);

    if (this.uncategorizedHoldingsDataHypothetical.length > 0) {
      processedTableData.push({
        market: 'Uncategorized holdings',
        yourStock: '',
        stockOutsideUS: '',
        difference: '',
      });
      processedTableData = processedTableData.concat(this.uncategorizedHoldingsDataHypothetical);
    }

    return processedTableData;
  }

  generateTableDataByHoldings() {
    const categorizationObjects = [
      {
        dataPoint: 'europe',
        title: 'Current stocks & stock funds in developed European countries',
      },
      {
        dataPoint: 'pacific',
        title: 'Current stocks & stock funds in developed Pacific countries',
      },
      { dataPoint: 'northAmerica', title: 'Current stocks & stock funds in Canada' },
      {
        dataPoint: 'middleEast',
        title: 'Current stocks & stock funds in Middle Eastern countries',
      },
      {
        dataPoint: 'emergingMarkets',
        title: 'Current stocks & stock funds in emerging market countries',
      },
      { dataPoint: 'other', title: 'Current stocks & stock funds in other regions' },
      { dataPoint: 'uncategorized', title: 'Current uncategorized stocks & stock funds' },
    ];

    const holdingsArray: any[] =
      this.holdingsViewHelper.getGeneratedDataWithoutDifferenceForTesterTool(
        categorizationObjects,
        this.chartData,
        'stockInternationalRegions',
      );
    this.holdingsTotal = this.holdingsViewHelper.calculateTotal(holdingsArray);
    return holdingsArray;
  }

  generateTableDataByHoldingsHypothetical() {
    const categorizationObjects = [
      {
        dataPoint: 'europe',
        title: 'Hypothetical stocks & stock funds in developed European countries',
      },
      {
        dataPoint: 'pacific',
        title: 'Hypothetical stocks & stock funds in developed Pacific countries',
      },
      { dataPoint: 'northAmerica', title: 'Hypothetical stocks & stock funds in Canada' },
      {
        dataPoint: 'middleEast',
        title: 'Hypothetical stocks & stock funds in Middle Eastern countries',
      },
      {
        dataPoint: 'emergingMarkets',
        title: 'Hypothetical stocks & stock funds in emerging market countries',
      },
      { dataPoint: 'other', title: 'Hypothetical stocks & stock funds in other regions' },
      { dataPoint: 'uncategorized', title: 'Hypothetical uncategorized stocks & stock funds' },
    ];

    const holdingsArray: any[] =
      this.holdingsViewHelper.getGeneratedDataWithoutDifferenceForTesterTool(
        categorizationObjects,
        this.hypotheticalData,
        'stockInternationalRegions',
      );
    this.holdingsTotalHypothetical = this.holdingsViewHelper.calculateTotal(holdingsArray);
    return holdingsArray;
  }

  splitAndSpace(chartEntry: string): string {
    let spacedString = chartEntry.replace(/([a-zA-Z])(?=[A-Z])/g, '$1 ');
    return (
      spacedString.substring(INDEX_CONSTANTS.ZERO, INDEX_CONSTANTS.ONE).toUpperCase() +
      spacedString.slice(INDEX_CONSTANTS.ONE)
    );
  }

  getTotalPercentage(chartObj) {
    let total: any = Object.values(chartObj)
      .filter((val: any) => !isNaN(val))
      .reduce((acc: number, value: number) => acc + value, 0);
    return total.toFixed(0) + '%';
  }

  changeTableView(val: boolean) {
    this.viewByHoldings = val;
  }

  setBothHoldingsTables() {
    const tableDataByHoldingsActual = this.generateTableDataByHoldings();
    const tableDataByHoldingsHypothetical = this.generateTableDataByHoldingsHypothetical();

    const unfiltered = [];
    const keys = [
      'europe',
      'pacific',
      'northAmerica',
      'middleEast',
      'emergingMarkets',
      'other',
      'uncategorized',
    ];
    keys.forEach((key) => {
      unfiltered.push({
        actual: this.getTable(tableDataByHoldingsActual, 'stockInternationalRegions', key),
        hypothetical: this.getTable(
          tableDataByHoldingsHypothetical,
          'stockInternationalRegions',
          key,
        ),
      });
    });

    this.holdingsTablesData = unfiltered.filter((category) => {
      return category.actual.accounts.length > 0 || category.hypothetical.accounts.length > 0;
    });
  }

  getTable(dataSet, dataNode, categorization) {
    const filteredTableData = dataSet.filter((tableData) => {
      return (
        tableData && tableData.categorization === categorization && tableData.dataNode === dataNode
      );
    });

    return filteredTableData[0];
  }
}
