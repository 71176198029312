import { Directive, OnInit } from '@angular/core';
import { ContentAsAService } from '../../services/content-as-a-service/content-as-a-service.service';

@Directive({
  selector: '[pwContent]',
  exportAs: 'pwContent',
})
export class ContentDirective implements OnInit {
  constructor(private contentAsAService: ContentAsAService) {}

  ngOnInit(): void {
    const _this = this;
    this.contentAsAService.getContentPayload().subscribe((data) => {
      if (data) {
        Object.assign(_this, data);
      }
    });
  }
}
