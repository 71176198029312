<div class="wantHelpContainer" #content="pwContent" pwContent>
  <div class="wrapper">
    <div class="wantHelpTitleAndDescription">
      <h2 class="wantHelpTitle">{{content['PWQuestionnaire_WantHelp']}}</h2>
      <span class="wantHelpDescription">{{content['PWQuestionnaire_WantHelp_Description']}}</span>
      <a c11n-link
         [href]="'https://investor.vanguard.com/advice/compare-financial-advice'"
         [target]="true"
         [variant]="'primary-independent'"
         [fontWeight]="'bold'">{{content['PWTargetMixAbout_LearnMore']}}
      </a>
    </div>
  <img class="desktopIllustration" src="assets/images/want-help.png" alt="">
  </div>
</div>
