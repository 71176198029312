import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentFormatted',
})
export class PercentFormattingPipe implements PipeTransform {
  transform(value: number, precision?: number, portfolioValue?: number): string | null {
    if (value !== null && value !== undefined) {
      if (value > 0 && value < 0.01 || value === 0 && portfolioValue > 0) {
        return '<0.01%';
      } else {
        return precision !== null && precision !== undefined
          ? this.roundNumber(value) + '%'
          : this.roundNumber(value) + '%';
      }
    } else {
      return null;
    }
  }

  roundNumber(num) {
    return Number(+(Math.round(Number(Number(num) + 'e+2')) + 'e-2')).toFixed(2);
  }
}
