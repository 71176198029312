import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DrawerTrackerService } from '@vanguard/pfx-components';
import { DRAWER_CONSTANTS } from 'src/app/models/drawer.constants';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';
import { TaxEfficiencyData } from '../../../models/chart-data-response-models/tax-efficiency-data';

@Component({
  selector: 'tax-efficiency-table',
  templateUrl: './tax-efficiency-table.component.html',
  styleUrls: ['./tax-efficiency-table.component.scss'],
})
export class TaxEfficiencyTableComponent implements OnInit {
  @Input() chartData;
  taxEffData: TaxEfficiencyData;
  taxEffTable: any;
  holdingsTotal: number = 0;
  loaded: boolean = false;
  excludedOutsideInvestments: boolean;
  csvTableData: any = [];

  constructor(
    private router: Router,
    private drawerTrackerService: DrawerTrackerService,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {}

  ngOnInit() {
    if (this.chartData) {
      this.taxEffData = this.chartData.taxEfficiency.chart;
      this.excludedOutsideInvestments = this.chartData.taxEfficiency.excludedOutsideInvestments;
      this.setTotal();
      this.setTableData();
      this.setCsvTableData(this.taxEffData);
    }
  }

  setTotal() {
    let holdings: string[] = Object.keys(this.taxEffData);
    holdings.forEach(
      (holdingsItem: string) => (this.holdingsTotal += this.taxEffData[holdingsItem]),
    );
  }

  setTableData() {
    let taxEffData: TaxEfficiencyData = this.taxEffData;
    this.taxEffTable = this.getTableData(taxEffData);
    this.loaded = true;
  }

  getTableData(taxEffData: TaxEfficiencyData) {
    return [
      {
        holdingsCategory: 'Other tax-advantaged accounts',
        holdings: [
          { assets: 'Tax-advantaged accounts', assetsPercentage: taxEffData.taxAdvantageAssets },
        ],
      },
      {
        holdingsCategory: 'Other tax-efficient assets',
        holdings: [
          {
            assets: 'Index and tax-managed stock and balanced funds',
            assetsPercentage: taxEffData.indexedAndTaxManagedStock,
          },
          {
            assets: 'Municipal bonds, bond funds, and money market funds',
            assetsPercentage: taxEffData.taxFreeBondsAndBondFunds,
          },
          { assets: 'Individual stocks', assetsPercentage: taxEffData.individualStocks },
        ],
      },
      {
        holdingsCategory: 'Possible tax reduction opportunities',
        holdings: [
          {
            assets: 'Other stock and balanced funds',
            assetsPercentage: taxEffData.otherStockAndBalancedFunds,
          },
          {
            assets: 'Taxable bonds and bond funds',
            assetsPercentage: taxEffData.taxableBondsAndBondFunds,
          },
          {
            assets: 'Taxable money market funds and other short-term investments',
            assetsPercentage: taxEffData.taxableMoneyMarketAndCash,
          },
          {
            assets: 'Other investments',
            assetsPercentage: taxEffData.otherUncategorizedInvestments,
          },
          { assets: 'Total', assetsPercentage: this.holdingsTotal },
        ],
      },
    ];
  }

  navigateToLearnMorePage() {
    this.adobeAnalyticsService.clickedOnLearnMore('/learn-more/tax-efficiency');
    this.router.navigate(['/learn-more/tax-efficiency']);
  }

  setCsvTableData(taxEffData) {
    let taxData = [
      {
        assets: 'Tax-advantaged accounts',
        column2: taxEffData.taxAdvantageAssets.toFixed(2) + '%',
        column3: '',
        column4: '',
      },
      { assets: 'Other tax-efficient assets', column2: '', column3: '', column4: '' },
      {
        assets: 'Index and tax-managed stock and balanced funds',
        column2: taxEffData.indexedAndTaxManagedStock.toFixed(2) + '%',
        column3: '',
        column4: '',
      },
      {
        assets: 'Municipal bonds, bond funds, and money market funds',
        column2: taxEffData.taxFreeBondsAndBondFunds.toFixed(2) + '%',
        column3: '',
        column4: '',
      },
      {
        assets: 'Individual stocks',
        column2: taxEffData.individualStocks.toFixed(2) + '%',
        column3: '',
        column4: '',
      },
      { assets: 'Possible tax reduction opportunities', column2: '', column3: '', column4: '' },
      {
        assets: 'Other stock and balanced funds',
        column2: taxEffData.otherStockAndBalancedFunds.toFixed(2) + '%',
        column3: '',
        column4: '',
      },
      {
        assets: 'Taxable bonds and bond funds',
        column2: taxEffData.taxableBondsAndBondFunds.toFixed(2) + '%',
        column3: '',
        column4: '',
      },
      {
        assets: 'Taxable money market funds and other short-term investments',
        column2: taxEffData.taxableMoneyMarketAndCash.toFixed(2) + '%',
        column3: '',
        column4: '',
      },
      {
        assets: 'Other investments',
        column2: taxEffData.otherUncategorizedInvestments.toFixed(2) + '%',
        column3: '',
        column4: '',
      },
      { assets: 'Total', column2: this.getCsvTotal(taxEffData), column3: '', column4: '' },
    ];
    this.csvTableData = taxData;
  }

  getCsvTotal(taxEffData) {
    let total =
      taxEffData.taxAdvantageAssets +
      taxEffData.indexedAndTaxManagedStock +
      taxEffData.taxFreeBondsAndBondFunds +
      taxEffData.individualStocks +
      taxEffData.otherStockAndBalancedFunds +
      taxEffData.taxableBondsAndBondFunds +
      taxEffData.taxableMoneyMarketAndCash +
      taxEffData.otherUncategorizedInvestments;
    return total.toFixed(1) + '%';
  }
  openTaxEfficiencyDrawer() {
    this.drawerTrackerService.setSelectSection(DRAWER_CONSTANTS.TAX_EFFICIENCY);
  }
}
