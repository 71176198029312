import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PortfolioDataService } from '../../services/portfolio-data-service/portfolio-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';

@Component({
  selector: 'cost-risk-tab-table',
  templateUrl: './cost-risk-tab-table.component.html',
  styleUrls: ['./cost-risk-tab-table.component.scss'],
})
export class CostRiskTabTableComponent implements OnInit {
  @Output() emitSelectedTab = new EventEmitter<string>();

  navigation;
  chartData;
  selectedTabIndex;
  urlList: string[] = ['mutual-fund-costs', 'tax-efficiency'];

  constructor(
    private portfolioDataService: PortfolioDataService,
    private router: Router,
    private route: ActivatedRoute,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {
    this.navigation = this.router.getCurrentNavigation();
  }

  ngOnInit() {
    this.portfolioDataService.getChartDataPayload().subscribe((data) => {
      if (data) {
        this.chartData = data;
      }
    });
    this.selectedTabIndex = this.route.snapshot.data['tabId'];
  }

  updateUrl(tab) {
    const url: string = '/costs/' + this.urlList[tab.index];
    this.router.navigateByUrl(url);
    this.adobeAnalyticsService.clickedOnTab(url);
    this.route.data.subscribe((data) => {
      this.adobeAnalyticsService.trackRoutingData(data);
    });
  }
}
