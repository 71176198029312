  <div #content="pwContent" pwContent *ngFor="let e of numberOfAnswers; index as i" id="questionnaire-page-{{i}}" [attr.name]="'questionnaire-page'" class="questionnaire-page">
    <div class="header">
      <div class="container">
        <div class="back-icon-display">
          <c11n-icon [name]="'link-left'" [size]="'small'" [altText]="'Back to Portfolio Watch'"></c11n-icon>
        </div>
          <a c11n-link tabindex="0"
             class="back"
             (click)="navigateBackToPortfolioWatch()"
             (keyup.enter)="navigateBackToPortfolioWatch()"
             [variant]="'secondary-independent'"
             [fontWeight]="'bold'">{{content['PWQuestionnaire_BackToPortfolioWatch']}}
          </a>
        <div class="progress-bar">
          <div id="current-progress-{{i + 1}}" class="current-progress"></div>
        </div>
        <div class="progress-label">{{content['PWQuestionnaire_Question'] + (i + 1) + content['PWQuestionnaire_Of11']}}</div>
        <div class="question-content">{{content['PWQuestionnaire_Question_' + (i + 1)]}}</div>
      </div>
    </div>
    <div class="container">
      <div *ngIf="currentPage === 6" class="graph">
        <img src="assets/images/graph-questionnaire.png" alt="The graph shows both the greatest one-year loss and gain of value on three different hypothetical investments of $10,000.">
      </div>
      <div *ngIf="content['PWQuestionnaire_Question_' + (i + 1) + '_Extra']" class="extra">
        <div class="extra-content" [innerText]="content['PWQuestionnaire_Question_' + (i + 1) + '_Extra']"></div>
      </div>
      <div class="banner" *ngIf="error">
        <div class="c11n-banner c11n-banner--small c11n-banner--error">
          <div class="c11n-banner__leading-icon">
            <svg class="c11n-error-fill-medium"><use xlink:href="#error-fill-medium"></use></svg>
          </div>
          <div class="c11n-banner__main">
            <div class="c11n-banner__content-header" [innerHTML]="content['PWQuestionnaire_Error']"></div>
          </div>
        </div>
      </div>
      <PW1-questionnaire-page [numAnswers]="numberOfAnswers[i]" [questionNumber]="i + 1" (next)="next()" (previous)="previous()" (submit)="submit()"></PW1-questionnaire-page>
    </div>
   </div>

