import { Component, OnInit, Input } from '@angular/core';
import { DrawerTrackerService } from '@vanguard/pfx-components';
import { DRAWER_CONSTANTS } from 'src/app/models/drawer.constants';

@Component({
  selector: 'PW1-hypothetical-tax-efficiency-table',
  templateUrl: './hypothetical-tax-efficiency-table.component.html',
  styleUrls: ['./hypothetical-tax-efficiency-table.component.scss'],
})
export class HypotheticalTaxEfficiencyTableComponent implements OnInit {
  @Input() chartData;
  @Input() hypotheticalData;

  loaded = false;
  loadedHypothetical = false;

  taxDisplayedColumns: string[];
  taxSource: any;
  taxEffData: any;

  taxSourceHypothetical: any;
  taxEffDataHypothetical: any;

  constructor(private drawerTrackerService: DrawerTrackerService) {}

  ngOnInit() {
    if (this.chartData) {
      this.taxEffData = this.chartData.taxEfficiency.chart;
      this.taxSource = this.setTableData(this.taxEffData);
      this.loaded = true;
    }
    if (this.hypotheticalData) {
      this.taxEffDataHypothetical = this.hypotheticalData.taxEfficiency.chart;
      this.taxSourceHypothetical = this.setTableData(this.taxEffDataHypothetical);
      this.loadedHypothetical = true;
    }

    this.taxDisplayedColumns = ['assets', 'column2', 'column3', 'column4'];
  }

  setTableData(taxEffData) {
    let taxData = [
      {
        assets: 'Tax-advantaged accounts',
        column2: taxEffData.taxAdvantageAssets + '%',
        column3: '',
        column4: '',
      },
      { assets: 'Other tax efficient assets', column2: '', column3: '', column4: '' },
      {
        assets: 'Index and tax managed stock and balanced funds',
        column2: taxEffData.indexedAndTaxManagedStock + '%',
        column3: '',
        column4: '',
      },
      {
        assets: 'Municipal bonds, bond funds, and money market funds',
        column2: taxEffData.taxFreeBondsAndBondFunds + '%',
        column3: '',
        column4: '',
      },
      {
        assets: 'Individual stocks',
        column2: taxEffData.individualStocks + '%',
        column3: '',
        column4: '',
      },
      { assets: 'Possible tax reduction opportunities', column2: '', column3: '', column4: '' },
      {
        assets: 'Other stock and balanced funds',
        column2: taxEffData.otherStockAndBalancedFunds + '%',
        column3: '',
        column4: '',
      },
      {
        assets: 'Taxable bonds and bond funds',
        column2: taxEffData.taxableBondsAndBondFunds + '%',
        column3: '',
        column4: '',
      },
      {
        assets: 'Taxable money market funds and other short-term investments',
        column2: taxEffData.taxableMoneyMarketAndCash + '%',
        column3: '',
        column4: '',
      },
      {
        assets: 'Other investments',
        column2: taxEffData.otherUncategorizedInvestments + '%',
        column3: '',
        column4: '',
      },
      { assets: 'Total', column2: this.getTotal(taxEffData), column3: '', column4: '' },
    ];
    return taxData;
  }

  getTotal(taxEffData) {
    let total =
      taxEffData.taxAdvantageAssets +
      taxEffData.indexedAndTaxManagedStock +
      taxEffData.taxFreeBondsAndBondFunds +
      taxEffData.individualStocks +
      taxEffData.otherStockAndBalancedFunds +
      taxEffData.taxableBondsAndBondFunds +
      taxEffData.taxableMoneyMarketAndCash +
      taxEffData.otherUncategorizedInvestments;
    return total.toFixed(1) + '%';
  }

  openTaxEfficiencyDrawer() {
    this.drawerTrackerService.setSelectSection(DRAWER_CONSTANTS.TAX_EFFICIENCY);
  }
}
