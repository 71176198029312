import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';

@Component({
  selector: 'PW1-target-mix-about',
  templateUrl: './target-mix-about.component.html',
  styleUrls: ['./target-mix-about.component.scss'],
})
export class TargetMixAboutComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {
    this.route.data.subscribe((data) => {
      this.adobeAnalyticsService.trackRoutingData(data);
    });
  }

  navigateBackToPortfolioWatch() {
    this.adobeAnalyticsService.clickedBackToOverview();
    this.router.navigate(['/']);
  }
}
