export enum INLINE_DRAWER_ARTICLES_KEYS {
  ASSET_MIX = 'Asset mix',
  BOND_9_BOX = 'Bond 9-box',
  BOND_ANALYSIS = 'Bond analysis',
  CREDIT_QUALITY = 'Credit quality',
  DOMESTICS_VS_INTERNATIONAL_BOND_HOLDINGS_DETAILS = 'Domestic vs. international bond holdings details',
  DOMESTIC_VS_INTERNATIONAL_BONDS = 'Domestic vs. international bonds',
  DOMESTIC_VS_INTERNATIONAL_STOCK_HOLDINGS_DETAILS = 'Domestic vs. international stock holdings details',
  DOMESTIC_VS_INTERNATIONAL_STOCKS = 'Domestic vs. international stocks',
  INDUSTRY_SECTORS = 'Industry sectors',
  INTEREST_RATE_SENSISITIVITY = 'Interest rate sensitivity',
  INTERNATIONAL_REGIONS = 'International regions',
  INVESTMENT_COSTS = 'Investment costs',
  INVESTMENT_STYLE = 'Investment style',
  MARKET_CAPITALIZATION = 'Market capitalization',
  MUTUAL_FUND_COSTS = 'Mutual fund costs',
  OTHER_INVESTMENTS = 'Other investments',
  RISK_RETURN_MIX_ANALYSIS = 'Risk-return mix analysis',
  STOCK_ANALYSIS = 'Stock analysis',
  TAX_EFFICIENCY = 'Tax efficiency',
}
