import { APP_ID, Component, Inject, Input, OnInit } from '@angular/core';
import { PortfolioCardsService } from '../../services/portfolio-cards/portfolio-cards.service';
import { Observable, of } from 'rxjs';
import { PortfolioDataService } from '../../services/portfolio-data-service/portfolio-data.service';
import { DrawerTrackerService } from '@vanguard/pfx-components';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';

@Component({
  selector: 'asset-card',
  templateUrl: './asset-card.component.html',
  styleUrls: ['./asset-card.component.scss'],
})
export class AssetCardComponent implements OnInit {
  @Input() chartIdForCurrent;
  @Input() chartIdForTarget;
  @Input() isHypotheticalData: boolean;
  isMFE: boolean = false;
  isChartDataFailed: Observable<boolean> = of(false);

  constructor(
    private cardsService: PortfolioCardsService,
    @Inject(APP_ID) private appId: string,
    private portfolioDataService: PortfolioDataService,
    private drawerTrackerService: DrawerTrackerService,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {
    // if(this.appId !== 'portfolio-analysis') { PW1-Migration clean up along with other MFE code.
    if (false) {
      this.chartIdForCurrent = 'overviewCurrentAssetsChartId';
      this.chartIdForTarget = 'overviewTargetAssetsChartId';
      this.isHypotheticalData = false;
      this.isMFE = true;
    }
  }

  ngOnInit() {
    this.isChartDataFailed = this.portfolioDataService.getFailure();
  }

  goToAssetAllocationPage() {
    if (this.isMFE) {
      // This means the component is loaded as a MFE in some other app.
      // redirect to portfolio watch
      // uncomment the window location replace function one the environment files are updated
    } else if (this.isHypotheticalData) {
      this.adobeAnalyticsService.clickedOnSeeDetails('Hypothetical asset mix');
      this.cardsService.navigateToPage('hypothetical-asset-mix');
    } else {
      this.adobeAnalyticsService.clickedOnSeeDetails('Asset mix');
      this.cardsService.navigateToPage('asset-mix');
    }
  }

  selectSection(sectionLabel: string) {
    this.drawerTrackerService.setSelectSection(sectionLabel);
  }
}
