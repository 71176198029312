<div #content="pwContent" pwContent *ngIf='chartData' class="tab-table modernizedTabs">
    <mat-tab-group animationDuration="500ms" [disableRipple]="true" [(selectedIndex)]="selectedTabIndex"  (selectedTabChange)="updateUrl($event)">
        <mat-tab [label]="content['PWCosts_DetailsCard_MutualFundCosts_MutualFundCosts']" role="link">
            <mutual-fund-table [chartData]='chartData'></mutual-fund-table>
        </mat-tab>
        <mat-tab [label]="content['PWCosts_DetailsCard_TaxEfficiency_TaxEfficiency']" role="link">
            <tax-efficiency-table [chartData]='chartData'></tax-efficiency-table>
        </mat-tab>
    </mat-tab-group>
</div>
