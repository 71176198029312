<div *ngIf="shouldBeDisplayed" class="roundingDisclaimerWrapper c11n-banner c11n-banner--generic-plus-icon c11n-banner--medium">
    <div class="c11n-banner__leading-icon">
        <svg class="c11n-link-left-small c11n-icon--normal"><use xlink:href="#announcement-small"></use></svg>
    </div>
    <div class="c11n-banner__main" #content="pwContent" pwContent>
        <div class="c11n-banner__body c11n-text-medium" >
            <div class="c11n-text-md--crop">
                {{content['PWOverview_RoundingDisclaimer1']}}
                <a c11n-link tabindex="0"
                   (click)="navigateToFAQPage()"
                   [variant]="'primary-reinforced'"
                   class="cursor-pointer"
                   [fontWeight]="'normal'"
                   (keyup.enter)="navigateToFAQPage()">{{content['PWAssetMix_BondMessage_LinkText']}}
                </a>
                {{content['PWOverview_RoundingDisclaimer2']}}
            </div>
        </div>
    </div>
</div>
