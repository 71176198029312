<div class="header c11n-text-4xl-headline c11n-color-ink-100" #content="pwContent" pwContent>
  <div class="container">
    <div class="row">
      <div class="col p-0">
        <div class="back-icon-display">
          <c11n-icon [name]="'link-left'" [size]="'small'" [altText]="'Back to Portfolio Watch'"></c11n-icon>
          <a c11n-link tabindex="0" class="backToPWLink" (click)="goBackToPortfolioWatch()"
            (keyup.enter)="goBackToPortfolioWatch()" [variant]="'secondary-independent'"
            [fontWeight]="'bold'">{{content['PWQuestionnaire_BackToPortfolioWatch']}}
          </a>
        </div>
        <div class="header-content-wrapper">
          <span class="header-content">{{content['PWQuestionnaire_SetACustomMix']}}</span>
          <span class="header-content">{{selectedAccountGroup.GroupName}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container">

  <div class="middle-content">
    <div class="choose-target">
      <div class="choose-target-header">
        <p class="headline">{{content['PWCustomMix_SetATarget']}}</p>
        <a c11n-link [variant]="'primary-reinforced'" (click)="changeTool()" class="change-tool"
          tabindex="0">{{content['PWCustomMix_Set'] + otherTool}}</a>
      </div>
      <set-target-mix-manually (values)="getTargetMixRiskReturn();updateTargetAssetMix();"
        (errorStateChanged)="getErrorState($event)" *ngIf="otherTool==='using slider'"></set-target-mix-manually>
      <target-asset-allocation-slider-input [targetAssetMixCurrent]="targetAssetMixCurrent"
        (values)="getTargetMixRiskReturn();updateTargetAssetMix();" *ngIf="otherTool==='manually'">
      </target-asset-allocation-slider-input>
      <c11n-banner *ngIf="saveTargetMixError" [size]="'medium'" [hasCloseControl]="false" [hasDetails]="false"
        [variant]="'error'" [headingText]="content['PWCustomMix_SaveTargetMixError']"></c11n-banner>
      <button type="button" class="c11n-button c11n-button--medium c11n-button--primary" [disabled]="errorState"
        (click)="saveTargetAllocation($event)">
        <span class="c11n-button__box" [innerHTML]="content['PWCustomMix_SaveAllocation']">
        </span>
      </button>
    </div>
    <div class="risk-return-graph">
      <div class="target-asset-mix-analysis">
        <table class="suggested-risk-return-analysis" style="width:100%;" *ngIf="hasLoadedTargetMixRiskReturnData">
          <thead class="table-header">
            <tr>
              <th colspan="3">
                <h2 [innerHTML]="content['TargetAssetResult_RiskReturnAnalysisTable_Header']"></h2>
              </th>
            </tr>
          </thead>
          <tbody class="table-body">
            <tr>
              <td class="left-align">
                <p [innerHTML]="content['TargetAssetResult_RiskReturnAnalysisTable_AverageReturn']"></p>
              </td>
              <td class="right-align">
                <p>{{this.averageReturn}}%</p>
              </td>
            </tr>
            <tr>
              <td class="left-align">
                <p>{{content['PWAsset_DetailsCard_Accordion_TargetBestYear'] + ' (' + this.bestYear + ')'}}</p>
              </td>
              <td class="right-align">
                <p>{{this.bestYearAverage}}%</p>
              </td>
            </tr>
            <tr>
              <td class="left-align">
                <p>{{content['PWAsset_DetailsCard_Accordion_TargetWorstYear'] + ' (' + this.worstYear + ')'}}</p>
              </td>
              <td class="right-align">
                <p>{{this.worstYearAverage}}%</p>
              </td>
            </tr>
          </tbody>
          <tfoot class="table-footer">
            <tr>
              <td colspan="3" class="table-footer left-align">
                <p>{{content['TargetAssetResult_RiskReturnAnalysisTable_Disclaimer_1']}}
                  {{content['TargetAssetResult_RiskReturnAnalysisTable_Disclaimer_2']}}{{this.fromYear}}-{{this.toYear}}.
                  {{content['TargetAssetResult_RiskReturnAnalysisTable_Disclaimer_3']}}{{this.yearsWithLoss}} of
                  {{this.totalYears}} ({{this.percentYearsOfLoss}}%)
                </p>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</div>
<want-help-banner></want-help-banner>
