import { Component, Input, OnInit } from '@angular/core';
import { PortfolioCardsService } from '../../services/portfolio-cards/portfolio-cards.service';
import { LegendLine } from '../../models/chart-models/legend-line';
import { ChartData } from '../../models/chart-models/chart-data';
import { ChartDataService } from '../../services/chart-data-service/chart-data.service';
import { INDEX_CONSTANTS } from '../../models/chart.constants';
import { PortfolioDataService } from '../../services/portfolio-data-service/portfolio-data.service';
import { Observable } from 'rxjs';
import { HypotheticalChartDataService } from '../../services/hypothetical-chart-data-service/hypothetical-chart-data.service';
import { DrawerTrackerService } from '@vanguard/pfx-components';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';

@Component({
  selector: 'bond-card',
  templateUrl: './bond-card.component.html',
  styleUrls: ['./bond-card.component.scss'],
})
export class BondCardComponent implements OnInit {
  @Input() chartId;
  @Input() isHypotheticalData: boolean;
  hasLoadedChartData = false;

  bondAnalysisChartData = new ChartData();

  legendHeader: string;
  legendArrayModern: Array<LegendLine>;

  chartColorMap = new Map();

  hasBonds: boolean;
  contentNumber = 'PWOverview_BondAnalysis_Error_Portfolio';

  inlineHelpDrawerEnabled: Observable<boolean>;

  constructor(
    private cardsService: PortfolioCardsService,
    private chartService: ChartDataService,
    private hypotheticalChartDataService: HypotheticalChartDataService,
    private portfolioDataService: PortfolioDataService,
    private drawerTrackerService: DrawerTrackerService,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {}

  ngOnInit() {
    let dataObservable: Observable<any>;
    let dataLegend: Array<LegendLine>;

    if (this.isHypotheticalData) {
      dataObservable = this.hypotheticalChartDataService.getBondAnalysisChartPayload();
      dataLegend = this.hypotheticalChartDataService.getBondAnalysisCardLegendModern();
    } else {
      dataObservable = this.chartService.getBondAnalysisChartPayload();
      dataLegend = this.chartService.getBondAnalysisCardLegendModern();
    }

    this.legendHeader = "BONDS (domestic vs. int'l)";
    dataObservable.subscribe((data) => {
      if (data.percentageDetails.length !== INDEX_CONSTANTS.ZERO) {
        this.bondAnalysisChartData = data;
        this.hasLoadedChartData = true;
        this.legendArrayModern = dataLegend;
        this.hasBonds = this.chartService.hasBonds();
        this.contentNumber =
          this.portfolioDataService.accountGroup == 'All Accounts'
            ? 'PWOverview_BondAnalysis_Error_Portfolio'
            : 'PWOverview_BondAnalysis_Error_Group';
      }
    });
  }

  goToBondAnalysisPage() {
    if (this.isHypotheticalData) {
      this.adobeAnalyticsService.clickedOnSeeDetails('Hypothetical bond analysis');
      this.cardsService.navigateToPage('hypothetical-bond-analysis');
    } else {
      this.adobeAnalyticsService.clickedOnSeeDetails('Bond analysis');
      this.cardsService.navigateToPage('bond-analysis');
    }
  }

  selectSection(sectionLabel: string) {
    this.drawerTrackerService.setSelectSection(sectionLabel);
  }
}
