import { Component, OnInit, Input } from '@angular/core';
import { HoldingsViewHelperService } from '../../../../../services/holdings-view-helper/holdings-view-helper.service';

@Component({
  selector: 'PW1-hypothetical-mutual-fund-table',
  templateUrl: './hypothetical-mutual-fund-table.component.html',
  styleUrls: ['./hypothetical-mutual-fund-table.component.scss'],
})
export class HypotheticalMutualFundTableComponent implements OnInit {
  @Input() chartData;
  @Input() hypotheticalData;

  tableData = [];
  tableDataHypothetical = [];
  loaded = false;
  loadedHypothetical = false;

  constructor(private holdingsViewHelper: HoldingsViewHelperService) {}

  ngOnInit() {
    if (this.chartData && this.hypotheticalData) {
      this.setTableData(this.chartData);
      this.setTableDataHypothetical(this.hypotheticalData);
    }
  }

  setTableData(chart: any) {
    this.tableData = this.generateTableData(chart);
    this.loaded = true;
  }

  setTableDataHypothetical(chart: any) {
    this.tableDataHypothetical = this.generateTableData(chart);
    this.loadedHypothetical = true;
  }

  generateTableData(chart: any) {
    const holdings = this.holdingsViewHelper.getArrayOfHoldingsForCategorization(
      chart,
      'investmentCost',
      'expenseRatio',
    );
    const accounts = this.holdingsViewHelper.getArrayOfAccountsForCategorization(holdings);
    let arrayOfAccounts = [];

    accounts.forEach((accountName) => {
      arrayOfAccounts.push(this.holdingsViewHelper.getAccountNameObject(accountName));

      let holdingsArrayForAccount = this.holdingsViewHelper.getHoldingsFromSpecificAccount(
        holdings,
        accountName,
      );

      holdingsArrayForAccount.forEach((holding) => {
        arrayOfAccounts.push({
          name: holding.fundName,
          value: holding.fundBalance,
          expenseRatio: holding.categorization.expenseRatio,
        });
      });
    });

    return arrayOfAccounts;
  }
}
