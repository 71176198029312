import { INLINE_DRAWER_ARTICLES_KEYS } from '../models/drawer-articles-keys';
import { DrawerArticlesMapper } from '@vanguard/pfx-components/lib/components/drawer/drawer-data.interface';

export const DrawerPageSortMapper: DrawerArticlesMapper = {
  commonArticles: [],
  pageSpecificArticles: [
    {
      path: '/',
      articles: [
        INLINE_DRAWER_ARTICLES_KEYS.ASSET_MIX,
        INLINE_DRAWER_ARTICLES_KEYS.STOCK_ANALYSIS,
        INLINE_DRAWER_ARTICLES_KEYS.BOND_ANALYSIS,
        INLINE_DRAWER_ARTICLES_KEYS.INVESTMENT_COSTS,
      ],
    },
    {
      path: '/asset-mix',
      articles: [INLINE_DRAWER_ARTICLES_KEYS.ASSET_MIX,
                 INLINE_DRAWER_ARTICLES_KEYS.RISK_RETURN_MIX_ANALYSIS,],
    },
    {
      path: '/stock-analysis/market-capitalization',
      articles: [
        INLINE_DRAWER_ARTICLES_KEYS.DOMESTIC_VS_INTERNATIONAL_STOCKS,
        INLINE_DRAWER_ARTICLES_KEYS.MARKET_CAPITALIZATION,
      ],
    },
    {
      path: '/stock-analysis/investment-style',
      articles: [
        INLINE_DRAWER_ARTICLES_KEYS.DOMESTIC_VS_INTERNATIONAL_STOCKS,
        INLINE_DRAWER_ARTICLES_KEYS.INVESTMENT_STYLE,
      ],
    },
    {
      path: '/stock-analysis/industry-sectors',
      articles: [
        INLINE_DRAWER_ARTICLES_KEYS.DOMESTIC_VS_INTERNATIONAL_STOCKS,
        INLINE_DRAWER_ARTICLES_KEYS.INDUSTRY_SECTORS,
      ],
    },
    {
      path: '/stock-analysis/international-regions',
      articles: [
        INLINE_DRAWER_ARTICLES_KEYS.DOMESTIC_VS_INTERNATIONAL_STOCKS,
        INLINE_DRAWER_ARTICLES_KEYS.INTERNATIONAL_REGIONS,
      ],
    },
    {
      path: '/stock-analysis/domestic-vs-international',
      articles: [INLINE_DRAWER_ARTICLES_KEYS.DOMESTIC_VS_INTERNATIONAL_STOCK_HOLDINGS_DETAILS],
    },
    {
      path: '/bond-analysis/credit-quality',
      articles: [
        INLINE_DRAWER_ARTICLES_KEYS.DOMESTIC_VS_INTERNATIONAL_BONDS,
        INLINE_DRAWER_ARTICLES_KEYS.CREDIT_QUALITY,
      ],
    },
    {
      path: '/bond-analysis/interest-rate-sensitivity',
      articles: [
        INLINE_DRAWER_ARTICLES_KEYS.DOMESTIC_VS_INTERNATIONAL_BONDS,
        INLINE_DRAWER_ARTICLES_KEYS.INTEREST_RATE_SENSISITIVITY,
      ],
    },
    {
      path: '/bond-analysis/nine-box',
      articles: [
        INLINE_DRAWER_ARTICLES_KEYS.DOMESTIC_VS_INTERNATIONAL_BONDS,
        INLINE_DRAWER_ARTICLES_KEYS.BOND_9_BOX,
      ],
    },
    {
      path: '/bond-analysis/domestic-vs-international',
      articles: [INLINE_DRAWER_ARTICLES_KEYS.DOMESTICS_VS_INTERNATIONAL_BOND_HOLDINGS_DETAILS],
    },
    {
      path: '/costs/mutual-fund-costs',
      articles: [
        INLINE_DRAWER_ARTICLES_KEYS.INVESTMENT_COSTS,
        INLINE_DRAWER_ARTICLES_KEYS.MUTUAL_FUND_COSTS,
      ],
    },
    {
      path: '/costs/tax-efficiency',
      articles: [
        INLINE_DRAWER_ARTICLES_KEYS.INVESTMENT_COSTS,
        INLINE_DRAWER_ARTICLES_KEYS.TAX_EFFICIENCY,
      ],
    },
  ],
};
