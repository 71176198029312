export class ChartColors {
  static getColorMap(): Map<any, any> {
    let chartColorMap = new Map();
    chartColorMap.set('stocks', '#1ca29c');
    chartColorMap.set('bonds', '#bf8200');
    chartColorMap.set('shortTermReserves', '#545454');
    chartColorMap.set('domesticSA', '#1ca29c');
    chartColorMap.set('internationalSA', '#037872');
    chartColorMap.set('otherSA', '#c0dddc');
    chartColorMap.set('domesticBA', '#bf8200');
    chartColorMap.set('internationalBA', '#ffad00');
    chartColorMap.set('otherBA', '#ffd889');
    chartColorMap.set('other', '#717777');
    chartColorMap.set('alternatives', '#940000');
    chartColorMap.set('onTarget', '#999999');

    return chartColorMap;
  }
}
