<div #content="pwContent" pwContent>
  <div class="header">
    <div class="container">
      <button class="back" aria-label='Back to Portfolio Watch' (click)="navigateBackToPortfolioWatch()">
        <i class="icon icon-left-arrow-blue-modernized"></i><span [innerHTML]="content['PWQuestionnaire_BackToPortfolioWatch']"></span>
      </button>
      <div class="page-title">
        {{content['PWTargetMixAbout_Title']}}
      </div>
    </div>
  </div>
  <div class="page-content">
    <div class="container">
      <mat-tab-group class="tab-content-wrapper" animationDuration="0ms">
        <mat-tab label="Assumptions" role="link">
          <div class="tab-content">
            <div class="main-content" [innerHTML]="content['PWTargetMixAbout_Assumptions_Content']"></div>
          </div>
        </mat-tab>
        <mat-tab label="Limitations" role="link">
          <div class="tab-content">
            <div class="main-content" [innerHTML]="content['PWTargetMixAbout_Limitations_Content']"></div>
          </div>
        </mat-tab>
        <mat-tab label="Considerations" role="link">
          <div class="tab-content">
            <div class="main-content" [innerHTML]="content['PWTargetMixAbout_Considerations_Content']"></div>
          </div>
        </mat-tab>
        <mat-tab label="Help" role="link">
          <div class="tab-content">
            <div class="main-content" [innerHTML]="content['PWTargetMixAbout_Help_Content']"></div>
          </div>
        </mat-tab>
      </mat-tab-group>
      <div class="side-content">
        <advice-banner></advice-banner>
      </div>
    </div>
  </div>
</div>
