<div class="container">
    <div class="wantHelpContainer" #content="pwContent" pwContent>
        <div class="wantHelpTitleAndDescription">
            <h2 class="wantHelpTitle">{{content['PWQuestionnaire_WantHelp']}}</h2>
            <a c11n-link tabindex="0"
               (click)="navigateToAbout()"
               (keyup.enter)="navigateToAbout()"
               [variant]="'primary-independent'"
               class="cursor-pointer"
               [fontWeight]="'bold'">{{content['PWQuestionnaire_LearnMore']}}
            </a>
            <a c11n-link tabindex="0"
               [href]="'https://investor.vanguard.com/advice/compare-financial-advice'"
               [variant]="'primary-independent'"
               [fontWeight]="'bold'">{{content['PWQuestionnaire_SeeOurAdviceOfferings']}}
            </a>
        </div>
    </div>
</div>
