<div class="c11n-text-md-bold c11n-space-stack-2_5x">Expense ratios</div>

<div class="chartLineWrapper" [ngClass]="{ fullWidth: isFullWidth }">
  <span *ngIf="chartData[2].label" class="chartLabel">{{ chartData[2].label }}</span>
  <div class="chartContainer" id="{{ yourCostsChartId }}"></div>
</div>
<div class="chartLineWrapper" [ngClass]="{ fullWidth: isFullWidth }">
  <span *ngIf="chartData[1].label" class="chartLabel">{{ chartData[1].label }}</span>
  <div class="chartContainer" id="{{ vanguardChartId }}"></div>
</div>
<div class="chartLineWrapper" [ngClass]="{ fullWidth: isFullWidth }">
  <span *ngIf="chartData[0].label" class="chartLabel">{{ chartData[0].label }}</span>
  <div class="chartContainer" id="{{ industryChartId }}"></div>
</div>
