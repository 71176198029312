import { Component, OnInit } from '@angular/core';
import { PortfolioDataService } from '../../services/portfolio-data-service/portfolio-data.service';
import { AlertsHelperService } from '../../services/alerts-helper/alerts-helper.service';
import { LoggerService } from '../../services/logger/logger.service';
import { Router } from '@angular/router';
import { AllChartsResponseData } from '../../models/chart-data-response-models/all-charts-response-data';
import { PortfolioAlert } from '../../models/chart-data-response-models/PortfolioAlert';
import { LoggerCode } from '../../../models/logger';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';

@Component({
  selector: 'PW1-alert-container',
  templateUrl: './alert-container.component.html',
  styleUrls: ['./alert-container.component.scss'],
})
export class AlertContainerComponent implements OnInit {
  // eslint-disable-next-line max-params
  constructor(
    private portfolioDataService: PortfolioDataService,
    private alertsService: AlertsHelperService,
    private loggerService: LoggerService,
    private router: Router,
    private logger: LoggerService,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {}

  allChartResponseData: AllChartsResponseData;
  alertList: Array<PortfolioAlert> = new Array<PortfolioAlert>();
  openCard = false;
  isOpening: boolean;
  excludeOutsideHoldings: boolean;
  customizedAccountsView: boolean;
  accountGroup = 'All Accounts';

  ngOnInit() {
    this.portfolioDataService.getChartDataPayload().subscribe((data) => {
      this.allChartResponseData = data;
      this.alertList = this.alertsService.getAlertsList(this.allChartResponseData);
      this.excludeOutsideHoldings = data.excludeOutsideHoldings === true;
      this.customizedAccountsView = data.customizedAccountsView === true;
    });

    this.portfolioDataService.getSelectedAccountGroupPayload().subscribe((data) => {
      this.accountGroup = data.GroupName;
    });
  }

  animateCardPrint() {
    this.loggerService.info(
      {
        message: 'User clicked print button',
      },
      LoggerCode.PRINT_BUTTON_CLICKED,
    );
    this.isOpening = true;
    window.print();
  }

  viewAlerts() {
    if (this.alertList.length > 0) {
      this.openCard = !this.openCard;
      this.adobeAnalyticsService.clickedOnShowAlerts();
    } else {
      return;
    }
  }

  navigateToAlertPage(url) {
    this.adobeAnalyticsService.clickedOnAlert(url);
    this.router.navigate([url]);
  }

  removeAlert(alert: PortfolioAlert) {
    this.alertsService.removeAlert(
      alert,
      this.allChartResponseData,
      this.logger,
      this.portfolioDataService,
    );
  }
}
