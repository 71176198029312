import { Component, OnInit } from '@angular/core';
import { PortfolioDataService } from '../../../services/portfolio-data-service/portfolio-data.service';

@Component({
  selector: 'account-group-assets',
  templateUrl: './account-group-assets.component.html',
  styleUrls: ['./account-group-assets.component.scss'],
})
export class AccountGroupAssetsComponent implements OnInit {
  accountGroupBalance: number;
  hypotheticalBalance: number;
  accountGroup: string;

  // eslint-disable-next-line max-params
  constructor(private portfolioDataService: PortfolioDataService) {}

  ngOnInit() {
    this.setupAssets();
  }

  setupAssets() {
    this.portfolioDataService.getChartDataPayload().subscribe((data) => {
      if (data.assetTypes) {
        this.accountGroupBalance = data.assetTypes.applicableBalance;
      }
    });

    this.portfolioDataService.getHypotheticalDataPayload().subscribe((data) => {
      if (data.assetTypes) {
        this.hypotheticalBalance = data.assetTypes.applicableBalance;
      }
    });
  }
}
