<div #content="pwContent" pwContent>
  <div class="tableHeaderWrapper">
    <div class="tableTitle">
      <span [innerHTML]="content['TesterTool_HypotheticalCostsCard_TabTable_TaxEfficiency']"></span>
      <button class="icon icon-question-mark-black" (click)="openTaxEfficiencyDrawer()" aria-label="Help Button"></button>
    </div>
  </div>
  <ng-container *ngIf="loaded && loadedHypothetical">
    <div class="side-by-side-tables">
      <div class="table-wrapper">
        <table role="presentation" class="table holdingsTable">
          <tr>
            <th
              [innerHTML]="
                content['TesterTool_HypotheticalCosts_TaxEfficiency_Table_PercentagePortfolio']
              "
            ></th>
          </tr>
          <tr
            *ngFor="let tableRow of taxSource"
            [ngClass]="{ totalRow: tableRow.size === 'Total', rowHeader: tableRow.column2 === '' }"
          >
            <td>{{ tableRow.assets }}</td>
            <td>{{ tableRow.column2 }}</td>
          </tr>
        </table>
      </div>
      <div class="table-wrapper">
        <table role="presentation" class="table holdingsTable hypothetical">
          <tr>
            <th
              [innerHTML]="
                content['TesterTool_HypotheticalCosts_TaxEfficiency_Table_HypoPercentagePortfolio']
              "
            ></th>
          </tr>
          <tr
            *ngFor="let tableRow of taxSourceHypothetical"
            [ngClass]="{ totalRow: tableRow.size === 'Total', rowHeader: tableRow.column2 === '' }"
          >
            <td>{{ tableRow.assets }}</td>
            <td>{{ tableRow.column2 }}</td>
          </tr>
        </table>
      </div>
    </div>
  </ng-container>
</div>
