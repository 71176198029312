import { Component, Input, OnInit } from '@angular/core';
import { PortfolioCardsService } from '../../services/portfolio-cards/portfolio-cards.service';
import { LegendLine } from '../../models/chart-models/legend-line';
import { PortfolioDataService } from '../../services/portfolio-data-service/portfolio-data.service';
import { HypotheticalChartDataService } from '../../services/hypothetical-chart-data-service/hypothetical-chart-data.service';
import { Observable } from 'rxjs';
import { DrawerTrackerService } from '@vanguard/pfx-components';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';
@Component({
  selector: 'cost-card',
  templateUrl: './cost-card.component.html',
  styleUrls: ['./cost-card.component.scss'],
})
export class CostCardComponent implements OnInit {
  @Input() industryChartId;
  @Input() vanguardChartId;
  @Input() yourCostsChartId;
  @Input() isHypotheticalData: boolean;
  modernizedChartData: any;
  legendArray: Array<LegendLine>;
  hasLoadedChartData = false;
  inlineHelpDrawerEnabled: Observable<boolean>;

  constructor(
    private cardsService: PortfolioCardsService,
    private hypotheticalChartDataService: HypotheticalChartDataService,
    private portfolioDataService: PortfolioDataService,
    private drawerTrackerService: DrawerTrackerService,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {}

  ngOnInit() {
    this.setUpChart();
  }

  setUpChart() {
    let dataObservable: Observable<any>;

    if (this.isHypotheticalData) {
      dataObservable = this.hypotheticalChartDataService.getInvestmentCostsPayload();
    } else {
      dataObservable = this.portfolioDataService.getInvestmentCostsPayload();
    }

    dataObservable.subscribe((data) => {
      this.modernizedChartData = data.modernizedInvestmentCostsChart;
      this.hasLoadedChartData = true;
    });
  }

  goToInvestmentCostsPage() {
    if (this.isHypotheticalData) {
      this.adobeAnalyticsService.clickedOnSeeDetails('Hypothetical costs');
      this.cardsService.navigateToPage('hypothetical-costs');
    } else {
      this.adobeAnalyticsService.clickedOnSeeDetails('Costs');
      this.cardsService.navigateToPage('costs');
    }
  }

  selectSection(sectionLabel: string) {
    this.drawerTrackerService.setSelectSection(sectionLabel);
  }
}
