import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ModernizedAssetMixColors } from '../../models/chart-models/modernized-stacked-bar-chart';
import { PortfolioDataService } from '../../services/portfolio-data-service/portfolio-data.service';
import { AllChartsResponseData } from '../../models/chart-data-response-models/all-charts-response-data';
import { DOCUMENT } from '@angular/common';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'set-target-mix-manually',
  templateUrl: './set-target-mix-manually.component.html',
  styleUrls: ['./set-target-mix-manually.component.scss'],
})
export class SetTargetMixManuallyComponent implements OnInit {
  targetAssetMixChartData;
  errorState: Observable<boolean> = of(false);
  assetFormArray: FormArray = new FormArray([]);
  @Output() values = new EventEmitter<any>();
  @Output() errorStateChanged = new EventEmitter<any>();
  assetMixControlsMap: Map<string, string> = new Map([
    ['stock-input-manual', 'stockControl'],
    ['bond-input-manual', 'bondControl'],
    ['short-term-reserves-input-manual', 'shortTermReserveControl']
  ]);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private portfolioDataService: PortfolioDataService,
  ) {}

  ngOnInit() {
    let chartDataObservable: Observable<any>;
    chartDataObservable = this.portfolioDataService.getChartDataPayload();

    // eslint-disable-next-line complexity
    chartDataObservable.subscribe((response: AllChartsResponseData) => {
      if (
        !response.assetTypeTarget ||
        (response.assetTypeTarget.stocks === 0 &&
          response.assetTypeTarget.bonds === 0 &&
          response.assetTypeTarget.shortTermReserves === 0)
      ) {
        this.targetAssetMixChartData = {
          targetAssetTypes: [
            {
              name: 'stocks',
              label: 'Stocks',
              value: 50,
              color: ModernizedAssetMixColors.getColorMap().get('stocks'),
            },
            {
              name: 'bonds',
              label: 'Bonds',
              value: 50,
              color: ModernizedAssetMixColors.getColorMap().get('bonds'),
            },
            {
              name: 'shortTermReserves',
              label: 'Short term reserves',
              value: 0,
              color: ModernizedAssetMixColors.getColorMap().get('shortTermReserves'),
            }
          ],
        };
      } else {
        this.targetAssetMixChartData = {
          targetAssetTypes: [
            {
              name: 'stocks',
              label: 'Stocks',
              value: response.assetTypeTarget.stocks,
              color: ModernizedAssetMixColors.getColorMap().get('stocks'),
            },
            {
              name: 'bonds',
              label: 'Bonds',
              value: response.assetTypeTarget.bonds,
              color: ModernizedAssetMixColors.getColorMap().get('bonds'),
            },
            {
              name: 'shortTermReserves',
              label: 'Short term reserves',
              value: response.assetTypeTarget.shortTermReserves,
              color: ModernizedAssetMixColors.getColorMap().get('shortTermReserves'),
            }
          ],
        };
      }
    });

    this.assetFormArray.push(
      new FormGroup({
        stockControl: new FormControl(
          this.targetAssetMixChartData.targetAssetTypes[0].value,
          Validators.compose([Validators.required, Validators.min(0), Validators.max(100)]),
        ),
        bondControl: new FormControl(
          this.targetAssetMixChartData.targetAssetTypes[1].value,
          Validators.compose([Validators.required, Validators.min(0), Validators.max(100)]),
        ),
        shortTermReserveControl: new FormControl(
          this.targetAssetMixChartData.targetAssetTypes[2].value,
          Validators.compose([Validators.required, Validators.min(0), Validators.max(100)]),
        )
      }),
    );

    this.setInitialBarValues();
  }

  validateNumber($event) {
    this.roundAssetMixFormControls($event);
    let total =
      this.assetFormArray.value[0].stockControl +
      this.assetFormArray.value[0].bondControl +
      this.assetFormArray.value[0].shortTermReserveControl;
    if (total > 100 || total != 100) {
      this.errorState = of(true);
      this.errorStateChanged.emit(true);
    } else {
      this.errorState = of(false);
      this.errorStateChanged.emit(false);
      this.document
        .getElementById('stock-svg-bar-manual')
        .setAttribute(
          'style',
          'width: ' + this.assetFormArray.value[0].stockControl.toString() + '% !important',
        );
      this.document
        .getElementById('bond-svg-bar-manual')
        .setAttribute(
          'style',
          'width: ' + this.assetFormArray.value[0].bondControl.toString() + '% !important',
        );
      this.document
        .getElementById('short-term-reserves-svg-bar-manual')
        .setAttribute(
          'style',
          'width: ' +
            this.assetFormArray.value[0].shortTermReserveControl.toString() +
            '% !important',
        );
      this.portfolioDataService.setNewTargetMix(
        this.assetFormArray.value[0].stockControl,
        this.assetFormArray.value[0].bondControl,
        this.assetFormArray.value[0].shortTermReserveControl
      );
      this.getValues();
    }
  }

  roundAssetMixFormControls($event) {
    if ($event) {
      // set input element value to positive values
      $event.target.value = Math.abs($event.target.value);
      // set input element value to rounded value
      $event.target.value = Math.round($event.target.value);

      let selectedFormControlName = this.assetMixControlsMap.get($event.target.name);
      let selectedFormControlValue = parseInt($event.target.value);
      this.assetFormArray.controls[0].patchValue({
        [selectedFormControlName]: selectedFormControlValue,
      });
    }
  }

  setInitialBarValues() {
    this.document
      .getElementById('stock-svg-bar-manual')
      .setAttribute(
        'style',
        'width: ' + this.targetAssetMixChartData.targetAssetTypes[0].value.toString() + '%',
      );
    this.document
      .getElementById('bond-svg-bar-manual')
      .setAttribute(
        'style',
        'width: ' + this.targetAssetMixChartData.targetAssetTypes[1].value.toString() + '%',
      );
    this.document
      .getElementById('short-term-reserves-svg-bar-manual')
      .setAttribute(
        'style',
        'width: ' + this.targetAssetMixChartData.targetAssetTypes[2].value.toString() + '%',
      );
    this.portfolioDataService.setNewTargetMix(
      this.targetAssetMixChartData.targetAssetTypes[0].value,
      this.targetAssetMixChartData.targetAssetTypes[1].value,
      this.targetAssetMixChartData.targetAssetTypes[2].value
    );
    this.getValues();
  }

  getValues() {
    this.values.emit(true);
  }
}
