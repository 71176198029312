import { Component, OnInit } from '@angular/core';
import { PortfolioDataService } from '../../../services/portfolio-data-service/portfolio-data.service';
import { HypotheticalChartDataService } from '../../../services/hypothetical-chart-data-service/hypothetical-chart-data.service';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';

@Component({
  selector: 'PW1-hypothetical-overview',
  templateUrl: './hypothetical-overview.component.html',
  styleUrls: ['./hypothetical-overview.component.scss'],
})
export class HypotheticalOverviewComponent implements OnInit {
  modernizedEnabled: Observable<boolean>;

  constructor(
    private portfolioDataService: PortfolioDataService,
    private hypotheticalChartDataService: HypotheticalChartDataService,
    private route: ActivatedRoute,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {
    this.portfolioDataService.setShowPWHeader(false);
  }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.adobeAnalyticsService.trackRoutingData(data);
    });
    this.hypotheticalChartDataService.loadAllHypotheticalData();
  }
}
