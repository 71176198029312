import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpXsrfTokenExtractor } from '@angular/common/http';
import { TargetAssetResponse } from '../../models/chart-data-response-models/target-asset-response';
import { SetTargetResponse } from '../../models/chart-data-response-models/set-target';
import { AssetTypeHistoricResponse } from '../../models/chart-data-response-models/asset-type-historic-response';
import { AllChartsResponseData } from '../../models/chart-data-response-models/all-charts-response-data';
import { AssetTypeCustomHistoricResponse } from '../../models/chart-data-response-models/asset-type-custom-historic-response';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { LoggerService } from '../logger/logger.service';
import { LoggerCode } from '../../../models/logger';
import {
  AccountGroupsDetails,
  AccountInfo,
} from '../../models/chart-data-response-models/account-group-details';
import { AlertsHelperService } from '../alerts-helper/alerts-helper.service';
import { ErrorData } from 'src/app/models/chart-data-response-models/error-data';
import { Holding } from 'src/app/models/chart-data-response-models/holding';
import { TargetAssetRequest } from 'src/app/models/target-asset-request';

@Injectable({
  providedIn: 'root',
})
export class PortfolioDataService {
  private chartDataUrl: string;
  private hypotheticalDataUrl: string;
  private securityDataUrl: string;
  private assetTypeCustomHistoricUrl: string;
  private saveTargetMixUrl: string;
  private targetMixRiskReturnUrl: string;
  private updateTargetMixUrl: string;

  showSetTargetMixModal: boolean = false;
  newMix: Array<number> = [50, 50, 0, 0];
  isLoading = new BehaviorSubject<boolean>(true);
  isFailure = new BehaviorSubject<boolean>(false);
  showPWHeader = new BehaviorSubject<boolean>(true);
  testerToolActive: boolean = false;
  assetTypePayload = new BehaviorSubject('');
  assetTypeHistoricPayload = new BehaviorSubject('');
  targetMixRiskReturnPayload = new BehaviorSubject('');
  targetAssetPayload = new BehaviorSubject('');
  domesticVsInternationalStocksPayload = new BehaviorSubject('');
  domesticVsInternationalBondsPayload = new BehaviorSubject('');
  investmentCostsPayload = new BehaviorSubject('');
  chartDataPayload = new BehaviorSubject('');
  hypotheticalDataPayload = new BehaviorSubject('');
  accountGroupsDetailsPayload = new BehaviorSubject([]);
  accountGroup: string = 'All Accounts';
  clientDataPayload = new BehaviorSubject('');
  _accountInfoList : AccountInfo[] = []
  selectedAccountInfo = new BehaviorSubject<AccountInfo>(null);
  isEcsEnabledForPW2: boolean = false;
  targetMixSuccess = new BehaviorSubject<boolean>(false);
  viewId: string = '';
  viewIdPayload = new BehaviorSubject('');
  viewNamePayload = new BehaviorSubject('');
  resetTesterTool = new BehaviorSubject(false);

  constructor(
    private httpClient: HttpClient,
    private token: HttpXsrfTokenExtractor,
    private alertsService: AlertsHelperService,
    private loggerService: LoggerService,
  ) {
    this.isEcsEnabledForPW2 = true;
    this.chartDataUrl = environment.chartDataUrlEcs;
    this.hypotheticalDataUrl = environment.hypotheticalDataUrlEcs;
    this.securityDataUrl = environment.securityDataUrlEcs;

    this.assetTypeCustomHistoricUrl = environment.assetTypeCustomHistoricUrl;
    this.saveTargetMixUrl = environment.saveTargetMixUrl;
    this.updateTargetMixUrl = environment.updateTargetMixUrl;
    this.targetMixRiskReturnUrl = environment.targetMixRiskReturnUrl;
    this.initialCalls();
  }

  xsrfToken = this.token.getToken() ? this.token.getToken() : 'X-XSRF-TOKEN-DUMMY';
  tokenHeader = new HttpHeaders({
    'X-XSRF-TOKEN': this.xsrfToken,
  });

  initialCalls() {
    this.loadChartDataPayload();
    this.loadAccountGroupsDetailsList();
    this.loadViewName();
    this.loadViewId();
    this.loadInvestmentCostPayload();
  }

  /////////////////////////////// Asset Type Historic risk/return section///////////////////////////////
  private getAssetTypeHistoricData() {
    return this.httpClient.get<AssetTypeHistoricResponse>(String(this.targetMixRiskReturnUrl), {
      withCredentials: true,
      responseType: 'text' as 'json',
    });
  }

  loadAssetTypeHistoricPayload() {
    this.getAssetTypeHistoricData().subscribe((response: any) => {
      if (response) {
        this.assetTypeHistoricPayload.next(response);
      }
    });
  }

  getAssetTypeHistoricPayload(): Observable<any> {
    return this.assetTypeHistoricPayload.asObservable();
  }

  /////////////////////////////// Target mix risk/return section///////////////////////////////
  private getTargetMixRiskReturnData(stocks, bonds, shortTermReserves = '0', others = '0') {
    return this.httpClient.get<any>(
      String(
        this.targetMixRiskReturnUrl +
          '?stocks=' +
          stocks +
          '&bonds=' +
          bonds +
          '&shortTermReserves=' +
          shortTermReserves +
          '&others=' +
          others,
      ),
      { withCredentials: true },
    );
  }

  loadTargetMixRiskReturnPayload(stocks, bonds, shortTermReserves?, others?) {
    this.getTargetMixRiskReturnData(stocks, bonds, shortTermReserves, others).subscribe(
      (response: any) => {
        if (response) {
          this.targetMixRiskReturnPayload.next(response);
        }
      },
    );
  }

  getTargetMixRiskReturnPayload(): Observable<any> {
    return this.targetMixRiskReturnPayload.asObservable();
  }

  /////////////////////////////// Custom Historic Risk/Return Post //////////////////////////
  calculatehistoricRiskReturn(params: any): Observable<AssetTypeCustomHistoricResponse> {
    return this.httpClient.post<AssetTypeCustomHistoricResponse>(
      this.assetTypeCustomHistoricUrl + params,
      null,
      {
        withCredentials: true,
        headers: this.tokenHeader,
      },
    );
  }

  /////////////////////////////// Set Target section///////////////////////////////
  setTargetAllocation(data: TargetAssetRequest, isNewMix: boolean): Observable<SetTargetResponse> {
    if (isNewMix) {
      return this.saveTargetAllocation(data);
    } else {
      return this.updateTargetAllocation(data);
    }
  }
  
  saveTargetAllocation(data: TargetAssetRequest): Observable<SetTargetResponse> {
    return this.httpClient.post<SetTargetResponse>(this.saveTargetMixUrl, data, {
      withCredentials: true,
      headers: this.tokenHeader,
    });
  }

  updateTargetAllocation(data: TargetAssetRequest): Observable<SetTargetResponse> {
    return this.httpClient.put<SetTargetResponse>(this.updateTargetMixUrl, data, {
      withCredentials: true,
      headers: this.tokenHeader,
    });
  }

  getInvestmentCostsPayload(): Observable<any> {
    return this.investmentCostsPayload.asObservable();
  }

  loadInvestmentCostPayload() {
    this.getChartDataPayload().subscribe((response: any) => {
      if (response) {
        this.updateInvestmentChartData(response);
        this.investmentCostsPayload.next(response.investmentCost);
      }
    });
  }

  updateInvestmentChartData(response) {
    let chartData: any = response.investmentCost;
    this.updateAveragesInChart(chartData);
  }

  updateAveragesInChart(chartData: any) {
    const industryAverage: number = chartData?.industryAverage;
    const vanguardAverage: number = chartData?.vanguardAverage;
    const yourAverageCost: number = chartData?.chart?.expenseRatio;

    chartData.modernizedInvestmentCostsChart = [
      {
        label: 'Industry average* (' + industryAverage + '%)',
        bars: [{ percent: industryAverage, color: '#4094E6' }],
      },
      {
        label: 'Vanguard average* (' + vanguardAverage + '%)',
        bars: [{ percent: vanguardAverage, color: '#D1405F' }],
      },
      {
        label: 'Your expense ratio (' + yourAverageCost + '%)',
        bars: [{ percent: yourAverageCost, color: '#54BAB5' }],
      },
    ];
  }

  /////////////////////////////// Chart Data section///////////////////////////////

  private getChartData(): Observable<AllChartsResponseData> {
    let serviceUrl = this.ReplaceQueryStrings(this.chartDataUrl);

    return this.httpClient.get<AllChartsResponseData>(serviceUrl, { withCredentials: true }).pipe(
      map((res: AllChartsResponseData) => {
        let xs1Error: ErrorData = res?.error?.find((value) => value.code == 'PW2-999');
        if (xs1Error) {
          throw new Error(xs1Error.code);
        } else {
          this._accountInfoList = this.MapAccountInfo(res.accountGroupsDetails);
          this.initializeAccountGroupSelection(res);
          return res;
        }
      }),
      catchError(this.handleError),
    );
  }

  initializeAccountGroupSelection(chartDataResponse: AllChartsResponseData) : void {
    if(!this.selectedAccountInfo.value) {
      let defaultGroupName : string = 'All Accounts';
      let defaultGroupViewId : string = chartDataResponse.accountGroupsDetails.find((agDetails) => agDetails.groupName === defaultGroupName).viewId || '';
      let defaultAccountGroupInfo : AccountInfo = new AccountInfo(defaultGroupName, defaultGroupViewId, true);
      this.setSelectedAccountGroupDetails(defaultAccountGroupInfo);
    } else if(this.selectedAccountInfo.value.ViewID === '') {
      let selectedAccountGroupName: string = this.selectedAccountInfo.value.GroupName;
      let updatedViewId: string = chartDataResponse.accountGroupsDetails.find((agDetails) => agDetails.groupName === selectedAccountGroupName).viewId || '';
      let updatedAccountGroupInfo: AccountInfo = new AccountInfo(selectedAccountGroupName, updatedViewId, true);
      this.setSelectedAccountGroupDetails(updatedAccountGroupInfo);
    }
  }

  private ReplaceQueryStrings(serviceUrl: string) {
    if (!this.selectedAccountInfo.value) {
      serviceUrl = serviceUrl.replace('ACCOUNTGROUP', 'All Accounts');
      serviceUrl = serviceUrl.replace('VIEWID', '');
      serviceUrl = encodeURI(serviceUrl);
    } else {
      serviceUrl = serviceUrl.replace('ACCOUNTGROUP', this.selectedAccountInfo.value.GroupName);
      serviceUrl = serviceUrl.replace('VIEWID', this.selectedAccountInfo.value.ViewID);
      serviceUrl = encodeURI(serviceUrl);
    }

    return serviceUrl;
  }

  updateChartData(chartData) {
    this.chartDataPayload.next(chartData);
  }

  loadChartDataPayload() {
    let alertsList;
    this.isLoading.next(true);
    this.isFailure.next(false);
    const startTime = new Date().getTime();
    this.getChartData().subscribe(
      (response: any) => {
        if (response) {
          this.updateChartDataWithRoundedValues(response);
          alertsList = this.alertsService.getAlertsList(response);
          response = this.handleFundNameError(response);
          this.chartDataPayload.next(response);
        }
      },
      (error) => {
        this.isLoading.next(false);
        this.isFailure.next(true);

        this.loggerService.logChartDataError(error);
      },
      () => {
        this.isLoading.next(false);
        this.isFailure.next(false);

        this.logAlerts(alertsList);
        const endTime = new Date().getTime();
        const totalTime = endTime - startTime;
        this.loggerService.info(
          {
            message:
              'Time taken to get total response time and display chart Data: ' +
              totalTime +
              ' milliseconds.',
          },
          LoggerCode.CHART_DATA_RESPONSE_TIME,
        );
      },
    );
  }

  /////////////////////////////// Hypothetical Data section///////////////////////////////
  logAlerts(alertsList: any[]) {
    alertsList.forEach((alert) => {
      this.loggerService.info(
        {
          message: 'Alert displayed for user',
          alertId: alert.longMessage,
          alertCategory: alert.type,
          alertUrl: alert.url,
        },
        LoggerCode.ALERT_DISPLAYED,
      );
    });
  }

  setSelectedAccountGroupDetails(accountGroup: AccountInfo) {
    accountGroup.ViewID ??= '';
    this.selectedAccountInfo.next(accountGroup);
  }

  private getHypotheticalData(objectToPostToEndpoint) {
    let serviceUrl = this.ReplaceQueryStrings(this.hypotheticalDataUrl);
    return this.httpClient
      .post<AllChartsResponseData>(serviceUrl, objectToPostToEndpoint, {
        withCredentials: true,
        headers: this.tokenHeader,
      })
      .pipe(catchError(this.handleError));
  }

  updateHypotheticalData(hypotheticalData) {
    this.hypotheticalDataPayload.next(hypotheticalData);
  }

  async loadHypotheticalDataPayload(objectToPostToEndpoint) {
    return await this.getHypotheticalData(objectToPostToEndpoint)
      .toPromise()
      .then((response: any) => {
        if (response) {
          this.loggerService.info(
            {
              message: 'Hypothetical data loaded',
            },
            LoggerCode.HYPOTHETICAL_DATA_LOADED,
          );

          this.updateChartDataWithRoundedValues(response);

          this.updateHypotheticalData(response);

          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        this.loggerService.info(
          {
            message: error.message,
            status: error.status,
          },
          LoggerCode.HYPOTHETICAL_DATA_NOT_LOADED,
        );
        return false;
      });
  }

  getSecurityDataUrl() {
    return this.securityDataUrl;
  }

  getHypotheticalDataPayload(): Observable<any> {
    return this.hypotheticalDataPayload.asObservable();
  }

  getLoading(): Observable<boolean> {
    return this.isLoading.asObservable();
  }

  getFailure(): Observable<boolean> {
    return this.isFailure.asObservable();
  }

  setShowPWHeader(value: boolean) {
    return this.showPWHeader.next(value);
  }

  getShowPWHeader(): Observable<boolean> {
    return this.showPWHeader.asObservable();
  }

  setTargetMixSuccess(value: boolean) {
    return this.targetMixSuccess.next(value);
  }

  getTargetMixSuccess(): Observable<boolean> {
    return this.targetMixSuccess.asObservable();
  }

  setTesterToolResetValue(value: boolean) {
    this.resetTesterTool.next(value);
  }

  getTesterToolResetValue(): Observable<boolean> {
    return this.resetTesterTool.asObservable();
  }

  setTesterToolActive(value: boolean) {
    this.testerToolActive = value;
  }

  updateChartDataWithRoundedValues(response) {
    response.assetTypes.chart = this.returnRoundedNumbers(response.assetTypes.chart);
    response.stockDomesticInternational.chart = this.returnRoundedNumbers(
      response.stockDomesticInternational.chart,
    );
    response.bondDomesticInternational.chart = this.returnRoundedNumbers(
      response.bondDomesticInternational.chart,
    );
  }

  returnRoundedNumbers(chartObj) {
    let entries: [string, number][] = Object.entries(chartObj);
    let entriesToTestVariance: [string, number][] = Object.entries(Object.assign({}, chartObj));
    const newObject = {};

    //check what variance would be if all values are normally rounded
    let tempSum = 0;
    entriesToTestVariance.forEach((copiedEntry) => {
      tempSum += Math.round(JSON.parse(JSON.stringify(copiedEntry[1])));
    });

    // if data is all zeroes, don't round, just return object as is
    if (tempSum === 0) {
      return chartObj;
    }

    // variance will be the number of values that need to manipulated to add up to 100
    // making collections of values that will round up or round down
    // manipulating the "round up" ones when the normal sum would be > 100
    // manipulating the "round down" ones when the normal sum would be < 100
    // no manipulation needed when normal sum is 100
    const variance = tempSum - 100;
    const entriesThatRoundUp: [string, number][] = entries
      .filter((entry) => Number(entry[1]) % 1 >= 0.5 && Number(entry[1]) > 1)
      .sort(function (a: any, b: any) {
        return b[1] - a[1];
      })
      .sort(function (a: any, b: any) {
        return (b[1] % 1) - (a[1] % 1);
      });
    const entriesThatRoundDown: [string, number][] = entries
      .filter((entry) => Number(entry[1]) % 1 > 0 && Number(entry[1]) % 1 < 0.5)
      .sort(function (a: any, b: any) {
        return (b[1] % 1) - (a[1] % 1);
      })
      .sort(function (a: any, b: any) {
        return b[1] - a[1];
      });

    if (variance > 0) {
      for (let i = 0; i < variance; i += 1) {
        entriesThatRoundUp[i][1] = Number(entriesThatRoundUp[i][1]) - 1;
      }
    } else if (variance < 0) {
      const spotsToRound = Math.abs(variance);
      for (let i = 0; i < spotsToRound; i += 1) {
        entriesThatRoundDown[i][1] = Number(entriesThatRoundDown[i][1]) + 1;
      }
    }

    // adding the manipulated entry values back into a fresh object
    entries.forEach((entry: [string, number]) => {
      entry[1] = Math.round(entry[1]);
      newObject[entry[0]] = entry[1];
    });

    return newObject;
  }

  getChartDataPayload(): Observable<any> {
    return this.chartDataPayload.asObservable();
  }

  /////////////////////////////// Account Groupings section///////////////////////////////

  getAccountGroupsDetailsListPayload(): Observable<AccountGroupsDetails[]> {
    return this.accountGroupsDetailsPayload.asObservable();
  }

  getSelectedAccountGroupPayload(): Observable<AccountInfo> {
    return this.selectedAccountInfo.asObservable();
  }

  get accountInfoList() {
    return this._accountInfoList;
  }

  getViewIdPayload(): Observable<string> {
    return this.viewIdPayload.asObservable();
  }

  loadAccountGroupsDetailsList() {
    this.getChartDataPayload().subscribe((response: AllChartsResponseData) => {
      if (response.accountGroupsDetails) {
        this.accountGroupsDetailsPayload.next(response.accountGroupsDetails);
      }
    });
  }

  private getSecureSiteData(query) {
    const body = { query, operationName: null, variables: {} };
    return this.httpClient
      .post(environment['GraphQLUrl'], body, {
        withCredentials: true,
        headers: this.tokenHeader,
      })
      .pipe(catchError(this.handleError));
  }

  async loadSecureSitePayload(): Promise<any> {
    const query: string =
      '{client { preferredName, lastLoginTimestamp, lastLoginTimestampWithTimeZone }, accountsInfo { totals { totalBalance, totalBalanceAsOfDate}}, unreadSecureMessageCount}';
    return await this.getSecureSiteData(query)
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(() => {
        return false;
      });
  }

  //order of [stocks, bonds, short term reserves, other]
  setNewTargetMix(stocks: number, bonds: number, str: number) {
    this.newMix = [stocks, bonds, str];
  }

  getNewTargetMix(): Array<number> {
    return this.newMix;
  }

  setShowTargetModal(showTargetModalFlag: boolean) {
    this.showSetTargetMixModal = showTargetModalFlag;
  }

  getShowTargetModal(): boolean {
    return this.showSetTargetMixModal;
  }

  loadViewName() {
    combineLatest([
      this.getSelectedAccountGroupPayload(),
      this.getAccountGroupsDetailsListPayload(),
    ])
      .pipe(
        map(([accountGroup, accountGroupDetailsList]) => ({
          accountGroup: accountGroup,
          accountGroupDetailsList: accountGroupDetailsList,
        })),
      )
      .subscribe((data) => {
        if (data.accountGroupDetailsList.length) {
          const selectedAccountGroupDetails = data.accountGroupDetailsList.find(
            (accountGroupDetails: AccountGroupsDetails) =>
              accountGroupDetails.groupName === data.accountGroup.GroupName,
          );
          this.viewNamePayload.next(selectedAccountGroupDetails?.groupName);
        }
      });
  }

  getCurrentViewName(): Observable<string> {
    return this.viewNamePayload.asObservable();
  }

  loadViewId() {
    combineLatest([
      this.getSelectedAccountGroupPayload(),
      this.getAccountGroupsDetailsListPayload(),
    ])
      .pipe(
        map(([accountGroup, accountGroupDetailsList]) => ({
          accountGroup: accountGroup,
          accountGroupDetailsList: accountGroupDetailsList,
        })),
      )
      .subscribe((data) => {
        if (data.accountGroupDetailsList.length) {
          const selectedAccountGroupDetails = data.accountGroupDetailsList.find(
            (accountGroupDetails: AccountGroupsDetails) =>
              accountGroupDetails.groupName === data.accountGroup.GroupName,
          );
          const viewId = selectedAccountGroupDetails?.viewId
            ? selectedAccountGroupDetails.viewId
            : '0';
          this.viewIdPayload.next(viewId);
        }
      });
  }

  MapAccountInfo(accountGroupsDetails: AccountGroupsDetails[]): AccountInfo[] {
    let accInfoList : AccountInfo[] = accountGroupsDetails.map((accountGroupsDetail) => {
      return new AccountInfo(accountGroupsDetail.groupName, accountGroupsDetail.viewId, true);
    });
    return accInfoList;
  }

  getCurrentSelectedValue() {
    return this.selectedAccountInfo?.value?.GroupName ?? 'All Accounts';
  }
  /////////////////////////////// Error handling///////////////////////////////
  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      error.clientSide = true;
    } else {
      error.clientSide = false;
    }
    console.log(error);
    return throwError(error);
  }

  private handleFundNameError(response: AllChartsResponseData): AllChartsResponseData {
    let dataNode;
    for (dataNode in response) {
      if (response[dataNode]?.holdings) {
        let totalHoldingsArray = response[dataNode].holdings;
        let correctedTotalHoldingsArray: Array<Holding<TargetAssetResponse>>;
        correctedTotalHoldingsArray = totalHoldingsArray.map((holding) => {
          if (holding.fundName === null) {
            holding.fundName = '';
            this.loggerService.error(
              {
                message: 'Null fund name error reported',
                ticker: holding.ticker ?? 'ticker not available',
              },
              LoggerCode.FUND_NAME_NULL_ERROR,
            );
            return holding;
          } else {
            return holding;
          }
        });

        response[dataNode].holdings = correctedTotalHoldingsArray;
      }
    }

    return response;
  }
}
