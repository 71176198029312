<div #content="pwContent" pwContent>
  <ng-container *ngIf="!printView; else printDisclaimer">
    <c11n-accordion
      [labelText]="content['PWCosts_AccordionDisclaimer_Header']"
      iconPosition="leading"
      accordionId="investment-costs-accordion"
      labelWeight="bold"
      size="large"
      [isExpanded]="false"
      [headingLevel]="2"
    >
      <div accordion-body>
        <div class="c11n-accordion-story-content">
          <div
            class="c11n-text-md--crop c11n-link--reinforced"
            [innerHTML]="
              content['PWCosts_AccordionDisclaimer_Body']
                | aemlink
                  : GetLinkText(content['PWCosts_Disclaimer_Link_Vanguard'])
                  : GetLinkUrl(content['PWCosts_Disclaimer_Link_Vanguard'])
            "
          ></div>
        </div>
      </div>
    </c11n-accordion>
  </ng-container>
  <ng-template #printDisclaimer>
    <div class="print-disclaimer__header">
      <h4
        class="c11n-text-md-bold c11n-space-stack-2x c11n-space-stack--force"
        [innerHTML]="content['PWCosts_AccordionDisclaimer_Header']"
      ></h4>
    </div>
    <div class="print-disclaimer__body">
      <div
        class="c11n-text-md--crop c11n-space-stack-2x c11n-space-stack--force"
        [innerHTML]="content['PWCosts_AccordionDisclaimer_Body']"
      ></div>
    </div>
  </ng-template>
</div>
