<section #content="pwContent" pwContent id="asset-analysis-content" tabindex="-1">
  <mat-card class="cardBody bottom-padding">
    <mat-card-actions>
      <button class="navigateLink" aria-label="Back to overview" (click)="goBackToOverview()">
        <i class="icon icon-left-arrow-blue"></i>
        <span
          class="center-vertically"
          [innerHTML]="content['TesterTool_HypotheticalAssetPage_BackToOverview']"
        ></span>
      </button>
    </mat-card-actions>
    <div class="titleWrapper">
      <h2 class="pageTitle" [innerHTML]="content['TesterTool_HypotheticalAssetPage_Title']"></h2>
      <button
        class="icon icon-question-mark-black "
        (click)="selectSection('Asset mix')"
      ></button>
    </div>
    <account-group-assets></account-group-assets>
    <mat-card-content class="mainCardContent">
      <div class="side-by-side-container">
        <div class="wrapper asset-allocation">
          <asset-mix-dashboard
            [isFullWidth]="false"
            [isHypotheticalData]="false"
            [actualAssetAllocationChartId]="'modernizedTesterToolAssetPageActualAssetAllocationId'"
            [targetAssetAllocationChartId]="'modernizedTesterToolAssetPageTargetAssetAllocationId'"
          >
          </asset-mix-dashboard>
        </div>
        <div class="wrapper hypothetical-allocation">
          <asset-mix-dashboard
            [isFullWidth]="false"
            [isHypotheticalData]="true"
            [actualAssetAllocationChartId]="
              'modernizedTesterToolHypotheticalAssetPageActualAssetAllocationId'
            "
            [targetAssetAllocationChartId]="
              'modernizedTesterToolHypotheticalAssetPageTargetAssetAllocationId'
            "
          >
          </asset-mix-dashboard>
        </div>
      </div>
      <div class="side-by-side-container">
        <hypothetical-asset-allocation-holdings
          [chartData]="chartData"
          [hypotheticalData]="hypotheticalChartData"
        ></hypothetical-asset-allocation-holdings>
      </div>
      <div class="side-by-side-container">
        <div class="wrapper asset-allocation">
          <h3
            class="header"
            [innerHTML]="content['TesterTool_HypotheticalAssetPage_CurrentRiskReturn']"
          ></h3>
          <div class="riskAnalysisWrapper" *ngIf="hasLoadedHistoricRiskReturnData">
            <div class="currentColumn">
              <table role="presentation" class="table">
                <tr class="header">
                  <th
                    [innerHTML]="
                      content['TesterTool_HypotheticalAssetPage_CurrentHistoricalRiskReturn'] +
                      ' (' +
                      currentAllocationHistoricRiskReturn.fromYear +
                      '-' +
                      currentAllocationHistoricRiskReturn.toYear +
                      ')'
                    "
                  ></th>
                  <th></th>
                </tr>
                <tr class="table-row">
                  <td>{{ content['TesterTool_HypotheticalAssetPage_CurrentAverageReturn'] }}</td>
                  <td
                    class="table-data"
                    [innerHTML]="currentAllocationHistoricRiskReturn.averageReturn + '%'"
                  ></td>
                </tr>
                <tr class="table-row">
                  <td>{{ content['TesterTool_HypotheticalAssetPage_CurrentBestYear'] }}</td>
                  <td
                    class="table-data"
                    [innerHTML]="
                      currentAllocationHistoricRiskReturn.bestYearAverage +
                      '%' +
                      ' (' +
                      currentAllocationHistoricRiskReturn.bestYear +
                      ')'
                    "
                  ></td>
                </tr>
                <tr class="table-row">
                  <td>{{ content['TesterTool_HypotheticalAssetPage_CurrentWorstYear'] }}</td>
                  <td
                    class="table-data"
                    [innerHTML]="
                      currentAllocationHistoricRiskReturn.worstYearAverage +
                      '%' +
                      ' (' +
                      currentAllocationHistoricRiskReturn.worstYear +
                      ')'
                    "
                  ></td>
                </tr>
                <tr class="table-row">
                  <td>{{ content['TesterTool_HypotheticalAssetPage_CurrentYearsWithALoss'] }}</td>
                  <td
                    class="table-data"
                    [innerHTML]="
                      currentAllocationHistoricRiskReturn.yearsWithLoss +
                      ' of ' +
                      currentAllocationHistoricRiskReturn.totalYears +
                      ' (' +
                      currentAllocationHistoricRiskReturn.percentYearsOfLoss +
                      '%' +
                      ')'
                    "
                  ></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="wrapper hypothetical-allocation">
          <h3
            class="header"
            [innerHTML]="content['TesterTool_HypotheticalAssetPage_HypotheticalRiskReturn']"
          ></h3>
          <div class="riskAnalysisWrapper" *ngIf="hasLoadedHistoricRiskReturnDataHypothetical">
            <div class="currentColumn">
              <table role="presentation" class="table hypothetical">
                <tr class="header">
                  <th
                    [innerHTML]="
                      content['TesterTool_HypotheticalAssetPage_HypotheticalHistoricalRiskReturn'] +
                      ' (' +
                      currentAllocationHistoricRiskReturnHypothetical.fromYear +
                      '-' +
                      currentAllocationHistoricRiskReturnHypothetical.toYear +
                      ')'
                    "
                  ></th>
                  <th></th>
                </tr>
                <tr class="table-row">
                  <td>
                    {{ content['TesterTool_HypotheticalAssetPage_HypotheticalAverageReturn'] }}
                  </td>
                  <td
                    class="table-data"
                    [innerHTML]="
                      currentAllocationHistoricRiskReturnHypothetical.averageReturn + '%'
                    "
                  ></td>
                </tr>
                <tr class="table-row">
                  <td>{{ content['TesterTool_HypotheticalAssetPage_HypotheticalBestYear'] }}</td>
                  <td
                    class="table-data"
                    [innerHTML]="
                      currentAllocationHistoricRiskReturnHypothetical.bestYearAverage +
                      '%' +
                      ' (' +
                      currentAllocationHistoricRiskReturnHypothetical.bestYear +
                      ')'
                    "
                  ></td>
                </tr>
                <tr class="table-row">
                  <td>{{ content['TesterTool_HypotheticalAssetPage_HypotheticalWorstYear'] }}</td>
                  <td
                    class="table-data"
                    [innerHTML]="
                      currentAllocationHistoricRiskReturnHypothetical.worstYearAverage +
                      '%' +
                      ' (' +
                      currentAllocationHistoricRiskReturnHypothetical.worstYear +
                      ')'
                    "
                  ></td>
                </tr>
                <tr class="table-row">
                  <td>
                    {{ content['TesterTool_HypotheticalAssetPage_HypotheticalYearsWithALoss'] }}
                  </td>
                  <td
                    class="table-data"
                    [innerHTML]="
                      currentAllocationHistoricRiskReturnHypothetical.yearsWithLoss +
                      ' of ' +
                      currentAllocationHistoricRiskReturnHypothetical.totalYears +
                      ' (' +
                      currentAllocationHistoricRiskReturnHypothetical.percentYearsOfLoss +
                      '%' +
                      ')'
                    "
                  ></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</section>
