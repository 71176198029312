<div class="assetValueWrapper" #content="pwContent" pwContent>
    <div class="assetValue">
        <div class='accountGroupAssets' [innerHTML]="content['AccountGroupAssets_CurrentAssets']"></div>
        <span class='balance'>{{accountGroupBalance | currency}}</span>
    </div>
    <div class="assetValue">
        <div class='hypotheticalAssets' [innerHTML]="content['AccountGroupAssets_HypotheticalAssets']"></div>
        <span class='balance'>{{hypotheticalBalance | currency}}</span>
    </div>
</div>
