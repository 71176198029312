import { Component, Input, OnInit } from '@angular/core';
import { PortfolioCardsService } from '../../services/portfolio-cards/portfolio-cards.service';
import { LegendLine } from '../../models/chart-models/legend-line';
import { ChartData } from '../../models/chart-models/chart-data';
import { ChartDataService } from '../../services/chart-data-service/chart-data.service';
import { PortfolioDataService } from '../../services/portfolio-data-service/portfolio-data.service';
import { Observable } from 'rxjs';
import { HypotheticalChartDataService } from '../../services/hypothetical-chart-data-service/hypothetical-chart-data.service';
import { DrawerTrackerService } from '@vanguard/pfx-components';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';

@Component({
  selector: 'stock-card',
  templateUrl: './stock-card.component.html',
  styleUrls: ['./stock-card.component.scss'],
})
export class StockCardComponent implements OnInit {
  @Input() chartId;
  @Input() isHypotheticalData: boolean;
  hasLoadedChartData = false;

  stockAnalysisChartData = new ChartData();

  legendHeader: string;
  legendArrayModern: Array<LegendLine>;

  chartColorMap = new Map();

  hasStocks: boolean;
  contentNumber = 'PWOverview_StockAnalysis_Error_Portfolio';

  dataReadyForChart: boolean = false;

  constructor(
    private cardsService: PortfolioCardsService,
    private chartService: ChartDataService,
    private hypotheticalChartDataService: HypotheticalChartDataService,
    private portfolioDataService: PortfolioDataService,
    private drawerTrackerService: DrawerTrackerService,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {}

  ngOnInit() {
    let dataObservable: Observable<any>;
    let dataLegend: Array<LegendLine>;

    if (this.isHypotheticalData) {
      dataObservable = this.hypotheticalChartDataService.getStockAnalysisChartPayload();
      dataLegend = this.hypotheticalChartDataService.getStockAnalysisCardLegendModern();
    } else {
      dataObservable = this.chartService.getStockAnalysisChartPayload();
      dataLegend = this.chartService.getStockAnalysisCardLegendModern();
    }

    const ZERO_INDEX = 0;
    this.legendHeader = "STOCKS (Domestic vs. Int'l)";
    dataObservable.subscribe((data) => {
      if (data.percentageDetails.length !== ZERO_INDEX) {
        this.stockAnalysisChartData = data;
        this.hasLoadedChartData = true;
        this.legendArrayModern = dataLegend;
        this.hasStocks = this.chartService.hasStocks();
        this.contentNumber =
          this.portfolioDataService.accountGroup == 'All Accounts'
            ? 'PWOverview_StockAnalysis_Error_Portfolio'
            : 'PWOverview_StockAnalysis_Error_Group';
        this.dataReadyForChart = true;
      }
    });
  }

  goToStockAnalysisPage() {
    if (this.isHypotheticalData) {
      this.adobeAnalyticsService.clickedOnSeeDetails('Hypothetical stock analysis');
      this.cardsService.navigateToPage('hypothetical-stock-analysis');
    } else {
      this.adobeAnalyticsService.clickedOnSeeDetails('Stock analysis');
      this.cardsService.navigateToPage('stock-analysis');
    }
  }

  selectSection(sectionLabel: string) {
    this.drawerTrackerService.setSelectSection(sectionLabel);
  }
}
