<div class="c11n-banner c11n-banner--large c11n-banner--error c11n-banner--collapsible">
  <div class="c11n-banner__leading-icon">
    <svg class="c11n-error-fill-medium">
      <use xlink:href="#error-fill-medium"></use>
    </svg>

  </div>
  <div class="c11n-banner__main" #content="pwContent" pwContent>
    <div class="c11n-banner__content c11n-banner__content--expanded">
      <div class="c11n-banner__content-header" [innerHTML]="content['PWError_TryAgain'] | safeHtml"></div>
    </div>
    <div class="c11n-banner__body c11n-text-medium">
      <p [innerHTML]="content['PWError_LoadingErrorTryAgain'] | safeHtml"></p>
      <a [href]='"/zaa-balances/"' [innerHTML]="content['PWError_BalancesHoldings'] | safeHtml"></a>
    </div>
  </div>
</div>
