export class HoldingsTableData {
  categorizations: HoldingsTableCategorization[];
  holdingsTotal?: number;
}
export class HoldingsTableCategorization {
  categorization: string;
  header: string;
  accounts: HoldingsTableAccountWithHoldings[];
}
export class HoldingsTableAccountWithHoldings {
  accountName: string;
  holdings: HoldingsTableHolding[];
  subtotal: number;
  percentageOfPortfolio: number;
}
export class HoldingsTableHolding {
  accountName?: string;
  ticker?: string;
  name: string;
  value: number;
  percentage: number;
}
export class CategorizationObject {
  title: string;
  categorization: string;
}

export enum AssetTableCategorization{
  AssetAllocation = 'assetTypes',
  StockDomesticInternational = 'stockDomesticInternational',
  StockIndustrySector = 'stockIndustrySector',
  StockMarketCap = 'stockMarketCap',
  StockInvestmentStyle = 'stockInvestmentStyle',
  StockInternationalRegions = 'stockInternationalRegions',
  BondDomesticInternational = 'bondDomesticInternational',
  BondMaturity = 'bondMaturity',
  bondNineBox = 'bondNineBox',
  bondTax = 'bondTax',
  bondCreditQuality = 'bondCreditQuality',
  InvestmentCost = 'investmentCost',
  RiskReturnAnalysis = 'riskReturnAnalysisResponse',
  TaxEfficiency = 'taxEfficiency',


}

export enum HoldingSubtotalCategorization{
  USStocks = 'USStocks',
  InternationalStocks = 'InternationalStocks',
  OtherStocks = 'OtherStocks',
  USBonds = 'USBonds',
  InternationalBonds = 'InternationalBonds',
  ShortTermReserves = 'ShortTermReserves',
  OtherBonds = 'OtherBonds',
  OtherTypes = 'OtherTypes',
  Other = 'Other',
}
