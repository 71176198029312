import { ChartData } from '../chart-models/chart-data';

export class ManagerRiskResponse {
  activelyManagedFunds: number;
  individualSecurities: number;
  indexFunds: number;

  public static buildChartDataList(managerRiskResponseData: ManagerRiskResponse): ChartData {
    var retData = new ChartData();
    const fraction_digits = 2;

    var labelList = new Array<string>();
    labelList.push('Actively Managed Funds', 'Individual Securities', 'Index Funds');

    var chartValueList = new Array<number>();
    chartValueList.push(managerRiskResponseData.activelyManagedFunds).toFixed(fraction_digits);
    chartValueList.push(managerRiskResponseData.individualSecurities).toFixed(fraction_digits);
    chartValueList.push(managerRiskResponseData.indexFunds).toFixed(fraction_digits);

    retData.labelDetails = labelList;
    retData.percentageDetails = chartValueList;

    return retData;
  }
}
