<account-group-assets></account-group-assets>
<div class="cardsRow">
  <div class="cardContainer">
    <asset-card
      class="main-page-card hypotheticalAssetCard"
      [isHypotheticalData]="false"
      [chartIdForCurrent]="'testerToolAssetCardCurrentId'"
      [chartIdForTarget]="'testerToolAssetCardTargetId'"
    ></asset-card>
  </div>
  <div class="cardContainer">
    <asset-card
      class="hypotheticalAssetCard"
      id="hypotheticalAssetCard"
      [isHypotheticalData]="true"
      [chartIdForCurrent]="'testerToolHypotheticalAssetCardCurrentId'"
      [chartIdForTarget]="'testerToolHypotheticalAssetCardTargetId'"
    ></asset-card>
  </div>
</div>
<div class="cardsRow">
  <div class="cardContainer">
    <stock-card
      class="main-page-card hypotheticalStockCard"
      [isHypotheticalData]="false"
      [chartId]="'testerToolStockCardCurrentId'"
    ></stock-card>
  </div>
  <div class="cardContainer">
    <stock-card
      class="hypotheticalStockCard"
      id="hypotheticalStockCard"
      [isHypotheticalData]="true"
      [chartId]="'testerToolHypotheticalStockCardCurrentId'"
    ></stock-card>
  </div>
</div>
<div class="cardsRow">
  <div class="cardContainer">
    <bond-card
      class="main-page-card hypotheticalBondCard"
      [isHypotheticalData]="false"
      [chartId]="'testerToolBondCardCurrentId'"
    ></bond-card>
  </div>
  <div class="cardContainer">
    <bond-card
      class="hypotheticalBondCard"
      id="hypotheticalBondCard"
      [isHypotheticalData]="true"
      [chartId]="'testerToolHypotheticalBondCardCurrentId'"
    ></bond-card>
  </div>
</div>
<div class="cardsRow">
  <div class="cardContainer">
    <cost-card
      class="main-page-card hypotheticalCostsCard"
      [isHypotheticalData]="false"
      [industryChartId]="'testerToolCostCardModernizedIndustryChartId'"
      [vanguardChartId]="'testerToolCostCardModernizedVanguardChartId'"
      [yourCostsChartId]="'testerToolCostCardModernizedYourCostsChartId'"
    ></cost-card>
  </div>
  <div class="cardContainer">
    <cost-card
      class="hypotheticalCostsCard"
      id="hypotheticalCostsCard"
      [isHypotheticalData]="true"
      [industryChartId]="'testerToolHypotheticalCostCardModernizedIndustryChartId'"
      [vanguardChartId]="'testerToolHypotheticalCostCardModernizedVanguardChartId'"
      [yourCostsChartId]="'testerToolHypotheticalCostCardModernizedYourCostsChartId'"
    ></cost-card>
  </div>
</div>
<PW1-investment-costs-disclaimer-accordion></PW1-investment-costs-disclaimer-accordion>
<PW1-disclaimer
  contentTag="PWCosts_Disclaimer_Body"
  headerTag="PWCosts_Disclaimer_Header"
  linkTag="PWCosts_Disclaimer_Link_Vanguard"
></PW1-disclaimer>
