<div *ngIf='chartData'>
    <mat-tab-group animationDuration="500ms" [disableRipple]="true" [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="updateUrl($event)" class="bondTabTable">
        <mat-tab label="Credit Quality" role="link">
            <PW1-hypothetical-credit-quality-table [chartData]='chartData' [hypotheticalData]="hypotheticalData"></PW1-hypothetical-credit-quality-table>
        </mat-tab>
        <mat-tab label="Interest Rate Sensitivity" role="link">
            <PW1-hypothetical-effective-maturity-table [chartData]='chartData' [hypotheticalData]='hypotheticalData'></PW1-hypothetical-effective-maturity-table>
        </mat-tab>
        <mat-tab label="Bond 9-box style" role="link">
            <PW1-hypothetical-nine-box-component [chartData]='chartData' [hypotheticalChartData]="hypotheticalData"></PW1-hypothetical-nine-box-component>
        </mat-tab>
    </mat-tab-group>
</div>
