import { Component } from '@angular/core';

@Component({
  selector: 'PW1-target-mix-disclaimer',
  templateUrl: './target-mix-disclaimer.component.html',
  styleUrls: ['./target-mix-disclaimer.component.scss'],
})
export class TargetMixDisclaimerComponent {
  constructor() {
    // do nothing.
  }
}
