export class Log {
  constructor(public level: LoggerLevel, public message: string, public code: LoggerCode) {}
}

export enum LoggerLevel {
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
  ALERT = 'alert',
}

export enum LoggerCode {
  // Server Side Codes
  UNKNOWN_ERROR = 'S001',
  HTTP_SUCCESS = 'S002',
  HTTP_FAILURE = 'S003',
  GATEKEEPER_ERROR = 'S004',
  GATEKEEPER_SUCCESS = 'S005',
  APP_ENTRY_DECISION = 'S006',

  // Client Log Codes
  HTTP_CLIENT_ERROR = 'C001',
  PORTFOLIO_OVERVIEW_LOADED = 'C002',
  CHART_DATA_LOADED = 'C003',
  ADOBE_ANALYTICS_MISSING = 'C004',
  CHART_DATA_NOT_LOADED = 'C008',
  HYPOTHETICAL_DATA_LOADED = 'C009',
  HYPOTHETICAL_DATA_NOT_LOADED = 'C010',
  ALERT_DISPLAYED = 'C011',
  ALERT_CLOSED = 'C012',
  PRINT_BUTTON_CLICKED = 'C013',
  CREATE_NEW_ACCT_GROUP = 'C014',
  MODIFY_ACCT_GROUP = 'C015',
  SELECTING_ACCT_GROUP = 'C016',
  EXPORTED_CSV = 'C017',
  PW2_ECS_GATEKEEPER_FAILURE = 'C018',
  GRAPH_QL = 'C019',
  MODERNIZED_SITE_LOADED = 'C020',
  OLD_SITE_LOADED = 'C021',
  MODIFY_VIEW_ID = 'C022',
  AEM_CONTENT_NOT_LOADED = 'C023',
  PAGE_LOAD = 'C024',
  PORTFOLIO_WATCH_LOADED_DURATION = 'C025',
  NO_POSITIVE_BALANCES = 'C026',
  CHART_DATA_RESPONSE_TIME = 'C027',
  TARGET_MIX_SAVED = 'C028',
  TARGET_MIX_NOT_SAVED = 'C029',
  SELECTED_CUSTOM_TOOL_TARGET_MIX = 'C030',
  SELECTED_QUESTIONNAIRE_TARGET_MIX = 'C031',
  SAVING_TARGET_MIX_FROM_CUSTOM_TOOL = 'C032',
  SAVING_TARGET_MIX_FROM_QUESTIONNAIRE_TOOL = 'C033',
  FUND_NAME_NULL_ERROR = 'C034',
  GENERATE_HOLDINGS_DETAILS = 'C035',
  FOCUS_PANEL_DATA_ERROR = 'C036',
  FOCUS_PANEL_MFE_ERROR = 'C037',
}
