<div class="targetAssetResult" #content="pwContent" pwContent>
    <div class="container">
        <div class="row">
            <div class="col p-0">
                <div class="back-icon-display">
                    <c11n-icon [name]="'link-left'" [size]="'small'" [altText]="'Back to Portfolio Watch'"></c11n-icon>
                </div>
                <a c11n-link tabindex="0"
                   class="backToPWLink"
                   (click)="goBackToOverview()"
                   (keyup.enter)="goBackToOverview()"
                   [variant]="'secondary-independent'"
                   [fontWeight]="'bold'">{{content['PWQuestionnaire_BackToPortfolioWatch']}}
                </a>
                <div class="target-asset-mix-header">
                    <div class="header-text" >
                        <h2>{{content['TargetAssetResult_SuggestAssetMix']}}
                            <span [innerHTML]="portfolioModel"></span>
                        </h2>
                    </div>
                    <div class="header-list-items">
                        <h5 [innerHTML]="content['TargetAssetResult_' + portfolioModel + 'Mixes']"></h5>
                        <ul>
                            <li [innerHTML]="content['TargetAssetResult_' + portfolioModel + 'Potential']"></li>
                            <li [innerHTML]="content['TargetAssetResult_' + portfolioModel + 'Ideal']"></li>
                            <li [innerHTML]="content['TargetAssetResult_' + portfolioModel + 'MayExperience']"></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="target-asset-mix-container">
        <div class="target-asset-mix-chart">
            <div class="suggest-asset-mix">
                <h2 [innerHTML]="content['TargetAssetResult_SuggestedTarget']"></h2>
                <stacked-bar-chart [barChartData]="suggestedTargetMixChartData" [isFullWidth]="isFullWidth">
                </stacked-bar-chart>
                <table class="assetMixChartLegend">
                    <tr *ngFor="let chartSlice of suggestedTargetMixChartData.chartSlices">
                        <td class="rowLabel">
                            <svg height="12" width="12" class="display-svg">
                                <circle cx="6" cy="6" r="6"
                                    attr.fill="{{chartSlice.color}}" />
                            </svg>
                            <span>{{chartSlice.label}}:</span>
                        </td>
                        <td class="rowLabel">
                            <span>{{chartSlice.value}}%</span>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="current-asset-mix">
                <h2 [innerHTML]="content['TargetAssetResult_CurrentAsset']"></h2>
                <stacked-bar-chart [barChartData]="currentMixChartData" [isFullWidth]="isFullWidth">
                </stacked-bar-chart>
                <table class="assetMixChartLegend">
                    <tr *ngFor="let chartSlice of currentMixChartData.chartSlices">
                        <td class="rowLabel">
                            <svg height="12" width="12" class="display-svg">
                                <circle cx="6" cy="6" r="6"
                                    attr.fill="{{chartSlice.color}}" />
                            </svg>
                            <span>{{chartSlice.label}}:</span>
                        </td>
                        <td class="rowLabel">
                            <span>{{chartSlice.value}}%</span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="target-asset-mix-analysis">
            <table class="suggested-risk-return-analysis" style="width:100%;" *ngIf="hasLoadedTargetMixRiskReturnData">
				<thead class="table-header">
                <tr>
                 <th colspan="3">
                     <h2 [innerHTML]="content['TargetAssetResult_RiskReturnAnalysisTable_Header']"></h2>
                 </th>
                </tr>
                </thead>
                <tbody class="table-body">
                <tr>
                    <td class="left-align">
                        <p [innerHTML]="content['TargetAssetResult_RiskReturnAnalysisTable_AverageReturn']"></p>
                    </td>
                    <td class="right-align">
                        <p>{{this.averageReturn}}%</p>
                    </td>
                </tr>
                <tr>
                    <td class="left-align">
                        <p>{{content['PWAsset_DetailsCard_Accordion_TargetBestYear'] + ' (' + this.bestYear + ')'}}</p>
                    </td>
                    <td class="right-align">
                        <p>{{this.bestYearAverage}}%</p>
                    </td>
                </tr>
                <tr>
                    <td class="left-align">
                        <p>{{content['PWAsset_DetailsCard_Accordion_TargetWorstYear'] + ' (' + this.worstYear + ')'}}</p>
                    </td>
                    <td class="right-align">
                        <p>{{this.worstYearAverage}}%</p>
                    </td>
                </tr>
                </tbody>
                <tfoot class="table-footer">
                    <tr>
                     <td colspan="3" class="table-footer left-align">
                         <p>{{content['TargetAssetResult_RiskReturnAnalysisTable_Disclaimer_1']}} {{content['TargetAssetResult_RiskReturnAnalysisTable_Disclaimer_2']}}{{this.fromYear}}-{{this.toYear}}.
                             {{content['TargetAssetResult_RiskReturnAnalysisTable_Disclaimer_3']}}{{this.yearsWithLoss}} of {{this.totalYears}} ({{this.percentYearsOfLoss}}%)
                         </p>
                     </td>
                    </tr>
                </tfoot>
            </table>
        </div>
        <div class="target-buttons-container">
            <c11n-banner
                    *ngIf="saveTargetMixError"
                    [size]="'medium'"
                    [hasCloseControl]="false"
                    [hasDetails]="false"
                    [variant]="'error'"
                    [headingText]="content['TargetAssetResult_SaveTargetMixError']"></c11n-banner>
            <button type="button" class="c11n-button c11n-button--medium c11n-button--primary"
                (click)="saveTargetAllocationBtn($event)">
                <span class="c11n-button__box" [innerHTML]="content['TargetAssetResult_SaveAsTarget']">
                </span>
            </button>           
            <button type="button" class="c11n-button c11n-button--medium c11n-button--secondary" (click)="retakeQuestionnaire()">
                <span class="c11n-button__box" [innerHTML]="content['TargetAssetResult_RetakeQuestionnaire']">
                </span>
            </button>
            <button type="button" class="c11n-button c11n-button--medium c11n-button--secondary"
                (click)="chooseTarget()">
                <span class="c11n-button__box" [innerHTML]="content['TargetAssetResult_TargetAssetMix']">
                </span>
            </button>
        </div>
    </div>
</div>
<want-help-banner></want-help-banner>
