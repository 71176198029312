/* eslint-disable */
import { Component, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PercentFormattingPipe } from '../../pipes/percent-formatting/percent-formatting.pipe';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HoldingsTableData } from '../../models/holdings-table';
import { LoggerService } from '../../services/logger/logger.service';
import { LoggerCode } from '../../../models/logger';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';

@Component({
  selector: 'PW1-export-to-csv',
  templateUrl: './export-to-csv.component.html',
  styleUrls: ['./export-to-csv.component.scss'],
})
export class ExportToCsvComponent {
  @Input() parsableArray;
  @Input() balance;
  @Input() total;
  @Input() fileName;
  @Input() modernized: boolean = false;
  csvString: string = '';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private percentPipe: PercentFormattingPipe,
    public domSanitizer: DomSanitizer,
    private logger: LoggerService,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {
    if (!this.fileName) this.fileName = 'PortfolioWatchData';
  }

  exportToCsv() {
    this.csvString = '';
    let resultsString = '';
    let results;

    if (this.modernized) {
      if (this.fileName == 'MutualFundCostData')
        results = this.generateResultsForMutualFunds(this.parsableArray);
      else if (this.fileName == 'TaxEfficiencyData')
        results = this.generateResultsForTaxEfficiency(this.parsableArray);
      else results = this.generateResultsFromHoldings(this.parsableArray);
    } else {
      if (this.fileName == 'MutualFundCostData')
        results = this.generateResultsForMutualFundsOld(this.parsableArray);
      else if (this.fileName == 'TaxEfficiencyData')
        results = this.generateResultsForTaxEfficiencyOld(this.parsableArray);
      else results = this.generateResultsFromHoldingsOld(this.parsableArray);
    }

    results.forEach(function (rowItem) {
      rowItem.forEach(function (colItem) {
        resultsString += colItem + ',';
      });
      resultsString += '\r\n';
    });

    this.csvString = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(resultsString);

    this.logger.info({ message: 'CSV file exported' }, LoggerCode.EXPORTED_CSV);
    this.adobeAnalyticsService.clickedOnExportData(this.fileName);
    return this.csvString;
  }

  generateResultsFromHoldings(arr: HoldingsTableData): Array<any> {
    let cats = arr.categorizations;
    let results = [];
    results.push(['Account Name', 'Fund Name', 'Symbol', 'Value', '% of Portfolio']);

    for (let i = 0; i < cats.length; i++) {
      cats[i].accounts.length ? results.push([cats[i].header.replace(/,/g, ';')]) : null;

      for (let ii = 0; ii < cats[i].accounts.length; ii++) {
        for (let iii = 0; iii < cats[i].accounts[ii].holdings.length; iii++) {
          results.push([
            cats[i].accounts[ii].holdings[iii].accountName.replace(/,/g, ';'),
            cats[i].accounts[ii].holdings[iii].name.replace(/,/g, ';'),
            cats[i].accounts[ii].holdings[iii].ticker
              ? cats[i].accounts[ii].holdings[iii].ticker
              : '',
            '$' + this.roundNumber(cats[i].accounts[ii].holdings[iii].value),
            this.percentPipe.transform(cats[i].accounts[ii].holdings[iii].percentage, null, cats[i].accounts[ii].holdings[iii].value),
          ]);
        }
        results.push([
          '',
          '',
          'Subtotal:',
          '$' + this.roundNumber(cats[i].accounts[ii].subtotal),
          this.percentPipe.transform(cats[i].accounts[ii].percentageOfPortfolio, null, cats[i].accounts[ii].subtotal),
        ]);
      }
    }
    results.push(['', '', 'Total:', '$' + this.roundNumber(arr.holdingsTotal)]);

    return results;
  }

  generateResultsForMutualFunds(arr) {
    let results = [];
    results.push(['Account Name', 'Symbol', 'Fund Name', 'Value', 'Expense Ratio']);

    for (let i = 0; i < arr.length; i++) {
      for (let ii = 0; ii < arr[i].holdings.length; ii++) {
        results.push([
          arr[i].holdings[ii].accountName.replace(/,/g, ';'),
          arr[i].holdings[ii].ticker,
          arr[i].holdings[ii].fundName.replace(/,/g, ';'),
          '$' + this.roundNumber(arr[i].holdings[ii].fundBalance),
          arr[i].holdings[ii].categorization.expenseRatio,
        ]);
      }
    }
    return results;
  }

  generateResultsForTaxEfficiency(arr) {
    let results = [];

    arr.forEach((e) => {
      results.push([
        e.assets.replace(/,/g, ';'),
        e.column2.replace(/,/g, ';'),
        e.column3.replace(/,/g, ';'),
        e.column4.replace(/,/g, ';'),
      ]);
    });
    return results;
  }

  generateResultsFromHoldingsOld(arr): Array<any> {
    arr = arr.filter(function (e) {
      return e;
    });

    let results = [];
    let accountName: string = '';

    for (let i = 0; i < arr.length; i++) {
      results.push(['Name', 'Account', 'Value', '% of Portfolio']);
      results.push([arr[i].header]);

      for (let j = 0; j < arr[i].accounts.length; j++) {
        if (arr[i].accounts[j].value == null) {
          accountName = arr[i].accounts[j].name.replace(/,/g, ';');
        } else if (j == arr[i].accounts.length - 1) {
          results.push([
            arr[i].accounts[j].name.replace(/,/g, ';'),
            '',
            '$' + this.roundNumber(arr[i].accounts[j].value),
            this.percentPipe.transform(arr[i].accounts[j].percentage),
          ]);
        } else {
          results.push([
            arr[i].accounts[j].name.replace(/,/g, ';'),
            accountName,
            '$' + this.roundNumber(arr[i].accounts[j].value),
            this.percentPipe.transform(arr[i].accounts[j].percentage),
          ]);
        }
      }
    }
    results.push([
      'Total',
      '',
      '$' + this.roundNumber(this.balance),
      this.percentPipe.transform(this.total),
    ]);
    return results;
  }

  generateResultsForMutualFundsOld(arr) {
    arr = arr.filter(function (e) {
      return e;
    });

    let results = [];
    let accountName: string = '';

    results.push(['Name', 'Account', 'Value', 'Expense Ratio']);

    for (let i = 0; i < arr.length; i++) {
      if (arr[i].value == null) {
        accountName = arr[i].name.replace(/,/g, ';');
      } else {
        results.push([
          arr[i].name.replace(/,/g, ';'),
          accountName,
          '$' + this.roundNumber(arr[i].value),
          this.roundNumber(arr[i].expenseRatio),
        ]);
      }
    }

    return results;
  }

  generateResultsForTaxEfficiencyOld(arr) {
    arr = arr.filter(function (e) {
      return e;
    });

    let results = [];

    results.push(['Percentage in Portfolio']);

    for (let i = 0; i < arr.length; i++) {
      results.push([
        arr[i].assets.replace(/,/g, ';'),
        arr[i].column2.replace(/,/g, ';'),
        arr[i].column3.replace(/,/g, ';'),
        arr[i].column4.replace(/,/g, ';'),
      ]);
    }

    return results;
  }

  roundNumber(num) {
    return Number(+(Math.round(Number(num + 'e+2')) + 'e-2')).toFixed(2);
  }

  getCSV(): SafeUrl {
    return this.domSanitizer.bypassSecurityTrustUrl(this.csvString);
  }

  getDownload(): string {
    return this.fileName + '.csv';
  }
}
