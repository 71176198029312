import { Component, OnInit } from '@angular/core';
import { HoldingsViewHelperService } from '../../services/holdings-view-helper/holdings-view-helper.service';
import { ActivatedRoute } from '@angular/router';
import { PortfolioDataService } from '../../services/portfolio-data-service/portfolio-data.service';
import {
  HoldingsTableAccountWithHoldings,
  HoldingsTableCategorization,
  HoldingsTableData,
} from '../../models/holdings-table';
import { HoldingsTableBuilderService } from '../../services/holdings-table-builder/holdings-table-builder.service';
import { DrawerTrackerService } from '@vanguard/pfx-components';
import { DRAWER_CONSTANTS } from 'src/app/models/drawer.constants';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';

@Component({
  selector: 'PW1-international-domestic-holdings',
  templateUrl: './international-domestic-holdings.component.html',
  styleUrls: ['./international-domestic-holdings.component.scss'],
})
export class InternationalDomesticHoldingsComponent implements OnInit {
  isStocks: boolean;
  chartData;

  tableDataByHoldings = [];
  holdingsTableDataModernized: HoldingsTableData;
  holdingsTotal: number = 0;
  applicableBalance: number = 0;

  constructor(
    private holdingsViewHelper: HoldingsViewHelperService,
    private activatedRoute: ActivatedRoute,
    private portfolioDataService: PortfolioDataService,
    private modernizedHoldingsTableBuilderService: HoldingsTableBuilderService,
    private drawerTrackerService: DrawerTrackerService,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {
    this.portfolioDataService.getChartDataPayload().subscribe((data) => {
      this.chartData = data;
    });
    this.isStocks = this.activatedRoute.snapshot.data['subcard'] === 'stock';
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe((data) => {
      this.adobeAnalyticsService.trackRoutingData(data);
    });

    if (this.chartData) {
      this.setHoldingsData();
      this.getHoldingsDataModernized();
    }
  }

  setHoldingsData() {
    const domesticStocks = this.getHoldingsForTableSection(
      'U.S. stocks & stock funds',
      'stockDomesticInternational',
      'domestic',
    );
    const internationalStocks = this.getHoldingsForTableSection(
      'International stocks & stock funds',
      'stockDomesticInternational',
      'international',
    );
    const stockOthers = this.getHoldingsForTableSection(
      'Other stocks & stock funds',
      'stockDomesticInternational',
      'uncategorized',
    );

    const domesticBonds = this.getHoldingsForTableSection(
      'U.S. bond funds',
      'bondDomesticInternational',
      'domestic',
    );
    const internationalBonds = this.getHoldingsForTableSection(
      'International bond funds',
      'bondDomesticInternational',
      'international',
    );
    const bondOthers = this.getHoldingsForTableSection(
      'Other bond funds',
      'bondDomesticInternational',
      'uncategorized',
    );

    this.tableDataByHoldings = this.isStocks
      ? [domesticStocks, internationalStocks, stockOthers]
      : [domesticBonds, internationalBonds, bondOthers];

    let category = { chart: {} };
    category.chart = this.createCategoryArray(this.tableDataByHoldings);

    this.holdingsViewHelper.roundingHelper(this.tableDataByHoldings, category, 'asset');
    this.holdingsTotal = this.holdingsViewHelper.calculateTotal(this.tableDataByHoldings);

    this.applicableBalance = this.isStocks
      ? this.chartData.stockDomesticInternational.applicableBalance
      : this.chartData.bondDomesticInternational.applicableBalance;
  }

  getHoldingsForTableSection(sectionTitle, dataNode, categorization) {
    const holdingsForDomestic = this.holdingsViewHelper.getArrayOfHoldingsForCategorization(
      this.chartData,
      dataNode,
      categorization,
    );
    const acctsForDomestic =
      this.holdingsViewHelper.getArrayOfAccountsForCategorization(holdingsForDomestic);

    let arrayOfAccounts = [];
    let categorizationSubtotal = 0;
    let categorizationPercentageSubTotal = 0;

    acctsForDomestic.forEach((accountName) => {
      arrayOfAccounts.push(this.holdingsViewHelper.getAccountNameObject(accountName));

      let holdingsArrayForAccount = this.holdingsViewHelper.getHoldingsFromSpecificAccount(
        holdingsForDomestic,
        accountName,
      );

      holdingsArrayForAccount.forEach((holding) => {
        const acctObject =
          this.holdingsViewHelper.getHoldingInformationWithoutDifferenceByPercentage(
            holding,
            categorization,
          );
        categorizationSubtotal += acctObject.value;
        categorizationPercentageSubTotal += acctObject.percentage;
        arrayOfAccounts.push(acctObject);
      });
    });

    if (arrayOfAccounts.length > 0) {
      arrayOfAccounts.push(
        this.holdingsViewHelper.getSubtotalObjectWithoutDifference(
          categorizationSubtotal,
          categorizationPercentageSubTotal,
        ),
      );
      return {
        header: sectionTitle,
        accounts: arrayOfAccounts,
      };
    }
    return null;
  }

  createCategoryArray(arr: any) {
    let newArr = [];
    arr = arr.filter(function (e) {
      return e;
    });
    let total = 0;
    for (let i: number = 0; i < arr.length; i++) {
      for (let j: number = 0; j < arr[i].accounts.length; j++) {
        if (arr[i].accounts[j].name === 'Subtotal') {
          let roundedVal = Number(
            Number(+(Math.round(Number(arr[i].accounts[j].percentage + 'e+2')) + 'e-2')).toFixed(2),
          );
          total += roundedVal;
          newArr.push(roundedVal);
        }
      }
    }
    const difference = 100 - total;
    if (difference >= 0.01 || difference <= -0.01) {
      newArr[0] += difference;
    }
    return newArr;
  }

  getHoldingsForTableSectionModernized(
    sectionTitle,
    dataNode,
    categorization,
  ): HoldingsTableCategorization {
    const arrayOfAllHoldingsForCategorization =
      this.holdingsViewHelper.getArrayOfHoldingsForCategorization(
        this.chartData,
        dataNode,
        categorization,
      );
    const acctsForDomestic =
      this.modernizedHoldingsTableBuilderService.getArrayOfAccountNamesForCategorization(
        arrayOfAllHoldingsForCategorization,
      );
    let arrayOfAccounts: HoldingsTableAccountWithHoldings[] = [];

    acctsForDomestic.forEach((accountName) => {
      const acctWithHoldings: HoldingsTableAccountWithHoldings = {
        accountName: accountName,
        subtotal: 0,
        percentageOfPortfolio: 0,
        holdings: [],
      };

      let holdingsArrayForAccount = this.holdingsViewHelper.getHoldingsFromSpecificAccount(
        arrayOfAllHoldingsForCategorization,
        accountName,
      );

      holdingsArrayForAccount.forEach((holding) => {
        const holdingObject =
          this.modernizedHoldingsTableBuilderService.getHoldingInformationWithoutDifferenceByPercentageNew(
            holding,
            categorization
          );
        acctWithHoldings.subtotal += holdingObject.value;
        acctWithHoldings.percentageOfPortfolio += holdingObject.percentage;
        acctWithHoldings.holdings.push(holdingObject);
      });

      arrayOfAccounts.push(acctWithHoldings);
    });

    return {
      header: sectionTitle,
      categorization: categorization,
      accounts: arrayOfAccounts,
    };
  }

  getHoldingsDataModernized() {
    const domesticStocks = this.getHoldingsForTableSectionModernized(
      'U.S. stocks & stock funds',
      'stockDomesticInternational',
      'domestic',
    );
    const internationalStocks = this.getHoldingsForTableSectionModernized(
      'International stocks & stock funds',
      'stockDomesticInternational',
      'international',
    );
    const stockOthers = this.getHoldingsForTableSectionModernized(
      'Other stocks & stock funds',
      'stockDomesticInternational',
      'uncategorized',
    );

    const domesticBonds = this.getHoldingsForTableSectionModernized(
      'U.S. bonds & bond funds',
      'bondDomesticInternational',
      'domestic',
    );
    const internationalBonds = this.getHoldingsForTableSectionModernized(
      'International bonds & bond funds',
      'bondDomesticInternational',
      'international',
    );
    const bondOthers = this.getHoldingsForTableSectionModernized(
      'Other bond funds',
      'bondDomesticInternational',
      'uncategorized',
    );

    this.holdingsTableDataModernized = {
      categorizations: this.isStocks
        ? [domesticStocks, internationalStocks, stockOthers]
        : [domesticBonds, internationalBonds, bondOthers],
      holdingsTotal: this.isStocks
        ? this.chartData.stockDomesticInternational.applicableBalance
        : this.chartData.bondDomesticInternational.applicableBalance,
    };
  }
  openInternationalDomesticDrawer() {
    if ((this.isStocks = this.activatedRoute.snapshot.data['subcard'] === 'stock')) {
      this.drawerTrackerService.setSelectSection(
        DRAWER_CONSTANTS.DOMESTIC_VS_INTERNATIONAL_STOCK_HOLDING_DETAILS,
      );
    } else {
      this.drawerTrackerService.setSelectSection(
        DRAWER_CONSTANTS.DOMESTIC_VS_INTERNATIONAL_BOND_HOLDING_DETAILS,
      );
    }
  }
}
