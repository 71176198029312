/* eslint-disable */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { PortfolioDataService } from '../../../services/portfolio-data-service/portfolio-data.service';
import { AllChartsResponseData } from '../../../models/chart-data-response-models/all-charts-response-data';
import { HoldingsViewHelperService } from '../../../services/holdings-view-helper/holdings-view-helper.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Holding } from '../../../models/chart-data-response-models/holding';
import { DOCUMENT } from '@angular/common';
import { PortfolioCardsService } from '../../../services/portfolio-cards/portfolio-cards.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AccountInfo } from 'src/app/models/chart-data-response-models/account-group-details';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';

@Component({
  selector: 'PW1-tester-tool',
  templateUrl: './tester-tool.component.html',
  styleUrls: ['./tester-tool.component.scss'],
})
export class TesterToolComponent implements OnInit {
  chartData: AllChartsResponseData;
  accounts: any[];
  accountsWithHoldings: any[];
  selectedAccountGroup: AccountInfo;
  form: FormGroup;
  additionsFormArray: FormArray;
  hasLoadedChartData = false;
  selectLabel: any;
  hasHoldingsError: boolean = false;
  canContinue: boolean = false;
  hypotheticalCallError: boolean = false;
  buttonEnabled: boolean = false;
  modernizedEnabled: Observable<boolean>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private ref: ChangeDetectorRef,
    private portfolioDataService: PortfolioDataService,
    private holdingsViewHelperService: HoldingsViewHelperService,
    private cardsService: PortfolioCardsService,
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {
    this.portfolioDataService.setShowPWHeader(true);
  }

  ngOnInit() {
    this.buttonEnabled = false;
    this.hasHoldingsError = false;
    this.canContinue = false;
    this.hypotheticalCallError = false;

    this.route.data.subscribe((data) => {
      this.adobeAnalyticsService.trackRoutingData(data);
    });

    this.additionsFormArray = new FormArray([]);
    this.portfolioDataService.getTesterToolResetValue().subscribe((value) => {
      if (value) {
        this.additionsFormArray = new FormArray([]);
      }
    });
    this.portfolioDataService.getChartDataPayload().subscribe((data: AllChartsResponseData) => {
      this.chartData = data;

      this.getAccountsViewAndAddInputs();

      this.hasLoadedChartData = true;
    });

    this.portfolioDataService.getSelectedAccountGroupPayload().subscribe((data) => {
      this.selectedAccountGroup = data;
    });
  }

  addAdditionLine() {
    this.additionsFormArray.push(
      new FormGroup({
        tickerControl: new FormControl(null, Validators.required),
        accountControl: new FormControl('', Validators.required),
        valueControl: new FormControl(
          null,
          Validators.compose([Validators.required, Validators.min(0)]),
        ),
      }),
    );
    // this.additionsFormArray.controls.accountControl.setValue(this.accounts[0], {onlySelf: true});
    this.ref.detectChanges();
  }

  buttonCheck() {
    this.buttonEnabled = false;
    let that = this;
    let tickerError = false;
    let holdingsError = false;
    let holdingsChange = false;

    const hasTickerError = (e) => {
      !e.valid;
    };
    this.accountsWithHoldings.forEach(function (account, i) {
      account.formGroups.controls.forEach(function (group: FormGroup, j) {
        if (that.holdingsErrorStyle(i, j, group)) {
          holdingsError = true;
        }
        if (
          group.valid &&
          group.get('balanceChangeControl').value &&
          !that.holdingsErrorStyle(i, j, group)
        ) {
          holdingsChange = true;
        }
      });
    });

    tickerError = this.additionsFormArray.controls.some(hasTickerError);

    if (!holdingsChange && !this.additionsFormArray.value.length) this.buttonEnabled = false;
    else this.buttonEnabled = !tickerError && !holdingsError;
  }

  deleteAdditionLine(i: any) {
    this.additionsFormArray.controls.splice(i, 1);
    this.additionsFormArray.value.splice(i, 1);
    this.buttonCheck();
  }

  getArrayOfAccountNamesAndIds(arrayOfHoldings: Holding<any>[]): AccountNameAndObject[] {
    return arrayOfHoldings
      .map((item) => {
        return { accountName: item.accountName, accountId: item.accountId };
      })
      .filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) => t.accountName === value.accountName && t.accountId === value.accountId,
          ),
      );
  }

  getHoldingsForAccount(
    arrayOfHoldings: any[],
    accountObject: AccountNameAndObject,
  ): Holding<any>[] {
    return arrayOfHoldings.filter((holding) => {
      return (
        holding.accountName === accountObject.accountName &&
        holding.accountId === accountObject.accountId
      );
    });
  }

  getAccountsViewAndAddInputs() {
    const holdings: Holding<any>[] = this.holdingsViewHelperService.getAllHoldings(
      this.chartData,
      'assetTypes',
    );
    const accounts: AccountNameAndObject[] = this.getArrayOfAccountNamesAndIds(holdings);
    this.accounts = accounts;
    let accountsHoldingsArray = [];
    accounts.forEach((account: AccountNameAndObject) => {
      const accountFormArray = new FormArray([]);
      let holdingsArrayForAccount: Holding<any>[] = this.getHoldingsForAccount(holdings, account);
      holdingsArrayForAccount.forEach((holding) => {
        accountFormArray.push(
          new FormGroup({
            positionIdControl: new FormControl(holding.positionId),
            tickerControl: new FormControl(holding.ticker),
            fundNameControl: new FormControl(holding.fundName),
            percentageControl: new FormControl(holding.holdingPercentage),
            fundBalanceControl: new FormControl(holding.fundBalance),
            balanceChangeControl: new FormControl('', Validators.min(holding.fundBalance * -1)),
          }),
        );
      });

      accountsHoldingsArray.push({
        accountName: account.accountName,
        accountId: account.accountId,
        formGroups: accountFormArray,
      });
    });

    this.accountsWithHoldings = accountsHoldingsArray;
  }

  async analyzeHypothetical() {
    this.document.body.classList.add('wait');

    this.hasHoldingsError = false;
    this.canContinue = true;
    this.hypotheticalCallError = false;

    const that = this;
    const changesToMake: HoldingsChangeObject[] = [];
    const wrapperObj: any = { hypotheticals: changesToMake };

    for (let i = 0; i < this.additionsFormArray.controls.length; i++) {
      const group = this.additionsFormArray.controls[i];

      await this.validateTicker(i);
      this.validateAccountGroup(i);
      this.validateNumber(i);
      if (
        this.validateAccountGroup(i) &&
        this.validateNumber(i) &&
        (await this.validateTicker(i))
      ) {
        changesToMake.push({
          accountId: group.get('accountControl').value,
          positionId: null,
          cusipOrTicker: group.get('tickerControl').value,
          value: group.get('valueControl').value,
          isAdded: true,
        });
      } else {
        this.canContinue = false;
      }
    }
    this.accountsWithHoldings.forEach(function (account, i) {
      account.formGroups.controls.forEach(function (group: FormGroup, j) {
        if (that.holdingsErrorStyle(i, j, group)) that.hasHoldingsError = true;

        if (
          group.valid &&
          group.get('balanceChangeControl').value &&
          !that.holdingsErrorStyle(i, j, group)
        ) {
          changesToMake.push({
            accountId: account.accountId,
            positionId: group.get('positionIdControl').value,
            cusipOrTicker: group.get('tickerControl').value,
            value: group.get('balanceChangeControl').value,
            isAdded: false,
          });
        }
      });
    });

    if (changesToMake.length) {
      if (this.canContinue && !this.hasHoldingsError) {
        if (await this.portfolioDataService.loadHypotheticalDataPayload(wrapperObj)) {
          this.portfolioDataService.setTesterToolActive(true);
          this.cardsService.navigateToPage('hypothetical-overview');
        } else {
          this.hypotheticalCallError = true;
        }
      } else {
        this.portfolioDataService.setTesterToolActive(false);
        this.document.body.scrollTop = this.document.documentElement.scrollTop = 0;
      }
    }
    this.document.body.classList.remove('wait');
    return wrapperObj;
  }

  async validateTicker(i: number) {
    let elem = this.document.getElementById('ticker-' + i);
    let elemError = this.document.getElementById('ticker-error-' + i);
    let securityDataUrl =
      this.portfolioDataService.getSecurityDataUrl() +
      'id=' +
      this.additionsFormArray.value[i].tickerControl;

    if (!this.additionsFormArray.value[i].tickerControl) {
      elem.setAttribute('style', 'border: 1px solid red !important');
      elemError.setAttribute('style', 'display: flex !important');
      return false;
    } else {
      elem.setAttribute('style', 'border: 1px solid #787878 !important');
      elemError.setAttribute('style', 'display: none !important');
      return await this.httpClient
        .get<any>(securityDataUrl, { withCredentials: true })
        .toPromise()
        .then((response) => {
          elem.setAttribute('style', 'border: 1px solid #787878 !important');
          elemError.setAttribute('style', 'display: none !important');
          return true;
        })
        .catch((err) => {
          if (err.status == 200) {
            elem.setAttribute('style', 'border: 1px solid #787878 !important');
            elemError.setAttribute('style', 'display: none !important');
            return true;
          } else if (elem != null) {
            elem.setAttribute('style', 'border: 1px solid red !important');
            elemError.setAttribute('style', 'display: flex !important');
            return false;
          } else {
            return false;
          }
        });
    }
  }

  validateAccountGroup(i: number): boolean {
    const dropdownEl = this.document.getElementById('ticker-dropdown-' + i);
    const elemError = this.document.getElementById('ticker-account-error-' + i);
    if (this.additionsFormArray.value[i].accountControl) {
      dropdownEl.setAttribute('style', 'border: 1px solid #787878 !important');
      elemError.setAttribute('style', 'display: none !important');
      return true;
    } else {
      dropdownEl.setAttribute('style', 'border: 1px solid red !important');
      elemError.setAttribute('style', 'display: flex !important');
      return false;
    }
  }

  validateNumber(i: number): boolean {
    const elem = this.document.getElementById('ticker-value-' + i);
    const elemError = this.document.getElementById('ticker-value-error-' + i);

    if (
      this.additionsFormArray.value[i].valueControl > 0 &&
      this.additionsFormArray.value[i].valueControl
    ) {
      elem.setAttribute('style', 'border: 1px solid #787878 !important');
      elemError.setAttribute('style', 'display: none !important');
      return true;
    } else {
      elem.setAttribute('style', 'border: 1px solid red !important');
      elemError.setAttribute('style', 'display: flex !important');
      return false;
    }
  }

  holdingsErrorStyle(i: number, j: number, group: any): boolean {
    const elem = this.document.getElementById('holdings-value-change-' + i + j) as HTMLInputElement;
    const elemError = this.document.getElementById('number-error-' + i + j);

    elemError.setAttribute('style', 'display: none !important');
    elem.setAttribute('style', 'border: 1px solid #787878 !important');

    if (elem.validity.badInput || Number.isNaN(Number(elem.value)) || !group.valid) {
      elemError.setAttribute('style', 'display: flex !important');
      elem.setAttribute('style', 'border: 1px solid red !important');
      return true;
    }
    return false;
  }

  goBackToPortfolioWatch() {
    this.adobeAnalyticsService.clickedBackToOverview();
    this.cardsService.navigateToPage('');
  }
}

class AccountNameAndObject {
  accountName: string;
  accountId: number;
}

class HoldingsChangeObject {
  accountId: number;
  positionId: number;
  cusipOrTicker: string;
  value: number;
  isAdded: boolean;
}
