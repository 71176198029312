<form [formGroup]="form">
    <div class="tableViewOptionControls">
        <h2 id="select-view-option">View:</h2>        
        <c11n-radio-group [groupId]="'table-view'" [hideLegend]="true">
            <c11n-radio (stateChange)="radioChange($event)" labelText="Simplified">
            <input c11nRadioInput id="radio1"  formControlName="viewByHoldings" type="radio" name="viewByHoldings" value="false" />
          </c11n-radio>
          <c11n-radio class="radio-label" (stateChange)="radioChange($event)" labelText="Holdings details">
            <input c11nRadioInput id="radio2"  formControlName="viewByHoldings" type="radio" name="viewByHoldings" value="true" />
          </c11n-radio>
        </c11n-radio-group>
    </div>
</form>