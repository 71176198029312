<div *ngIf='chartData' class="tab-table modernizedTabs">
    <mat-tab-group animationDuration="500ms" [disableRipple]="true" [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="updateUrl($event)" class="bondTabTable">
        <mat-tab label="Credit quality" role="link">
            <PW1-credit-quality-table [chartData]='chartData'></PW1-credit-quality-table>
        </mat-tab>
        <mat-tab label="Interest rate sensitivity" role="link">
            <PW1-effective-maturity-table [chartData]='chartData'></PW1-effective-maturity-table>
        </mat-tab>
        <mat-tab label="Bond 9-box style" role="link">
            <PW1-nine-box-component [chartData]='chartData'></PW1-nine-box-component>
        </mat-tab>
    </mat-tab-group>
</div>
