/* eslint-disable */
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DrawerTrackerService } from '@vanguard/pfx-components';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';
import { DRAWER_CONSTANTS } from '../../../models/drawer.constants';
@Component({
  selector: 'PW1-nine-box-component',
  templateUrl: './nine-box.component.html',
  styleUrls: ['./nine-box.component.scss'],
})
export class NineBoxComponent implements OnInit {
  @Input() chartData;

  xHeaders = ['Low', 'Medium', 'High'];
  yHeaders = ['High', 'Medium', 'Low'];
  dataArray: string[][];
  constructor(
    private router: Router,
    private drawerTrackerService: DrawerTrackerService,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {}

  ngOnInit() {
    if (this.chartData) {
      this.setTableData();
    }
  }

  setTableData() {
    this.dataArray = [
      [
        this.chartData.bondNineBox.maturityLowQualityHigh + '%',
        this.chartData.bondNineBox.maturityLowQualityMedium + '%',
        this.chartData.bondNineBox.maturityLowQualityLow + '%',
      ],
      [
        this.chartData.bondNineBox.maturityMediumQualityHigh + '%',
        this.chartData.bondNineBox.maturityMediumQualityMedium + '%',
        this.chartData.bondNineBox.maturityMediumQualityLow + '%',
      ],
      [
        this.chartData.bondNineBox.maturityHighQualityHigh + '%',
        this.chartData.bondNineBox.maturityHighQualityMedium + '%',
        this.chartData.bondNineBox.maturityHighQualityLow + '%',
      ],
    ];
  }

  navigateToLearnMorePage() {
    this.adobeAnalyticsService.clickedOnLearnMore('/learn-more/bond-nine-box-style');
    this.router.navigate(['/learn-more/bond-nine-box-style']);
  }
  openNineBoxDrawer() {
    this.drawerTrackerService.setSelectSection(DRAWER_CONSTANTS.BOND_NINE_BOX);
  }
}
