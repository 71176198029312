<mat-card #content="pwContent" pwContent class="modernized-cost-card">
  <mat-card-header>
    <div class="titleWrapper">
      <mat-card-title>
        <span
          *ngIf="!isHypotheticalData"
          class="center-vertically"
          [innerHTML]="content['PWOverview_InvestmentCosts']"
        ></span>
        <span
          *ngIf="isHypotheticalData"
          class="center-vertically"
          [innerHTML]="content['TesterTool_Overview_HypotheticalCostsCard_Title']"
        ></span>
        <button
          class="icon icon-question-mark-black"
          (click)="selectSection('Investment costs')"
          aria-label="Investment Costs help button"
        ></button>
      </mat-card-title>
      <mat-card-actions>
        <button
          class="navigateLink seeDetails"
          aria-label="Investment Costs see details"
          (click)="goToInvestmentCostsPage()"
        >
          <span [innerHTML]="content['PWOverview_InvestmentCosts_SeeDetails']"></span
          ><i class="icon icon-right-arrow-blue-modernized"></i>
        </button>
      </mat-card-actions>
    </div>
    <mat-card-subtitle></mat-card-subtitle>
  </mat-card-header>
  <mat-card-content *ngIf="hasLoadedChartData">
    <div [ngClass]="{ isHypothetical: isHypotheticalData }" class="modernizedContainer">
      <div class="cardModernizedChart">
        <bar-chart
          [chartData]="modernizedChartData"
          [industryChartId]="industryChartId"
          [vanguardChartId]="vanguardChartId"
          [yourCostsChartId]="yourCostsChartId"
          [isFullWidth]="false"
        ></bar-chart>
      </div>
      <div class="investment-costs-disclaimer">
        <!-- TODO : update the AEM content key once the AEM changes are available in PROD -->
        <p>*As of December 2022</p>
      </div>
    </div>
  </mat-card-content>
</mat-card>
