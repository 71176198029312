<div #content="pwContent" pwContent class="tableComponentWrapper">
  <PW1-alert-notifications [pageName]="'/bond-analysis/credit-quality'"></PW1-alert-notifications>
  <div class="tableHeaderWrapper">
    <div class="titleAndExport">
      <div class='tableTitle'>
        <span [innerHTML]="content['PWBond_DetailsCard_CreditQuality']"></span>
        <button class="icon icon-question-mark-black" (click)="openCreditQualityDrawer()" aria-label="Help Button"></button>
        <PW1-export-to-csv *ngIf="viewByHoldings"
                           [parsableArray]="generateTableDataByHoldings()"
                           [fileName]="'BondCreditQualityData'" [modernized]="true"></PW1-export-to-csv>
      </div>
    </div>
    <div class="spacer"></div>
    <div class="domesticDisclaimer" [innerHTML]="content['PWBond_DetailsCard_CreditQuality_DomesticDisclaimer']"></div>
    <div class="spacer"></div>
    <PW1-table-view-controls (tableViewChanged)="changeTableView($event)"></PW1-table-view-controls>
  </div>

  <ng-container *ngIf="loaded && !viewByHoldings">
    <table role="presentation" class="c11n-table c11n-table--medium">
      <thead>
      <tr class="c11n-table__tr">
        <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-left"><strong>{{content['PWBond_DetailsCard_CreditQuality_Headers_Quality']}}</strong></th>
        <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-right"><strong>{{content['PWBond_DetailsCard_CreditQuality_Headers_YourTaxableUSBonds']}}</strong></th>
        <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-right"><strong>{{content['PWBond_DetailsCard_CreditQuality_Headers_YourMunicipalUSBonds']}}</strong></th>
        <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-right"><strong>{{content['PWBond_DetailsCard_CreditQuality_Headers_YourTotalUSBonds']}}</strong></th>
      </tr>
    </thead>
    <tbody>
      <tr class="c11n-table__tr" *ngFor="let tableRow of tableData">
        <th scope="row" class="c11n-table__td c11n-table__tbody-th c11n-table__td--align-left">{{tableRow.quality}}</th>
        <td class="c11n-table__td c11n-table__td--align-right">{{tableRow.yourTaxableBonds | percentFormatted: 1}}</td>
        <td class="c11n-table__td c11n-table__td--align-right">{{tableRow.yourMunicipal | percentFormatted: 1}}</td>
        <td class="c11n-table__td c11n-table__td--align-right">{{tableRow.yourTotalBonds | percentFormatted: 1}}</td>
      </tr>
      <tr class="c11n-table__tr" *ngFor="let totalRow of totalData" class="totalRow">
        <td class="c11n-table__td c11n-table__td--align-left"><strong>{{totalRow.quality}}</strong></td>
        <td class="c11n-table__td c11n-table__td--align-right"><strong>{{totalRow.yourTaxableBonds | percentFormatted: 1}}</strong></td>
        <td class="c11n-table__td c11n-table__td--align-right"><strong>{{totalRow.yourMunicipal | percentFormatted: 1}}</strong></td>
        <td class="c11n-table__td c11n-table__td--align-right"><strong>{{totalRow.yourTotalBonds | percentFormatted: 0}}</strong></td>
      </tr>
    </tbody>
    </table>
  </ng-container>
  <ng-container *ngIf="loaded && viewByHoldings">
    <PW1-holdings-table [tableData]="tableDataByHoldings"></PW1-holdings-table>
  </ng-container>
</div>
