<div #content="pwContent" pwContent>
    <ng-container *ngIf="loaded && loadedHypothetical">
        <div class="side-by-side-tables">
            <div class="table-wrapper">
                <h3 class="tableTitle" [innerHTML]="content['TesterTool_HypotheticalCostsCard_TabTable_CurrentHeader']"></h3>
                <table role="presentation" class="table holdingsTable">
                    <ng-container [ngTemplateOutlet]="tableHeaders"></ng-container>
                    <tr *ngFor="let acct of tableData" [ngClass]="{'accountName': !acct.value && !acct.expenseRatio}">
                        <td>{{acct.name}}</td>
                        <td *ngIf="acct.value">{{acct.value | currency}}</td>
                        <td *ngIf="acct.value">{{acct.expenseRatio.toFixed(2)}}</td>
                    </tr>
                </table>
            </div>
            <div class="table-wrapper">
                <h3 class="tableTitle" [innerHTML]="content['TesterTool_HypotheticalCostsCard_TabTable_HypotheticalHeader']"></h3>
                <table role="presentation" class="table hypothetical holdingsTable">
                    <ng-container [ngTemplateOutlet]="tableHeaders"></ng-container>
                    <tr *ngFor="let acct of tableDataHypothetical" [ngClass]="{'accountName': !acct.value && !acct.expenseRatio}">
                        <td>{{acct.name}}</td>
                        <td *ngIf="acct.value">{{acct.value | currency}}</td>
                        <td *ngIf="acct.value">{{acct.expenseRatio.toFixed(2)}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </ng-container>
    <ng-template #tableHeaders>
        <tr>
            <th [innerHTML]="content['TesterTool_HypotheticalCosts_MutualFunds_Table_Name']"></th>
            <th [innerHTML]="content['TesterTool_HypotheticalCosts_MutualFunds_Table_Value']"></th>
            <th [innerHTML]="content['TesterTool_HypotheticalCosts_MutualFunds_Table_ExpenseRatio']"></th>
        </tr>
    </ng-template>
</div>
