<div *ngIf='loaded' #content="pwContent" pwContent class="tableComponentWrapper">
    <PW1-alert-notifications [pageName]="'/stock-analysis/industry-sectors'"></PW1-alert-notifications>
    <div class="tableHeaderWrapper">
        <div class="titleAndExport">
            <div class='tableTitle'>
                <span [innerHTML]="content['PWStocks_DetailsCard_IndustrySectors']"></span>
                <button class="icon icon-question-mark-black" (click)="openIndustrySectorsDrawer()" aria-label="Help Button"></button>
                <PW1-export-to-csv *ngIf="viewByHoldings"
                                   [parsableArray]="generateTableDataByHoldingsModernized()"
                                   [fileName]="'StockIndustrySectorData'" [modernized]="true"></PW1-export-to-csv>
            </div>
        </div>
        <div class="spacer"></div>
        <div class="domesticDisclaimer" [innerHTML]="content['PWStocks_DetailsCard_IndustrySectors_Disclaimer']"></div>
        <div class="spacer"></div>
        <PW1-table-view-controls (tableViewChanged)="changeTableView($event)"></PW1-table-view-controls>
    </div>

    <ng-container *ngIf="loaded && !viewByHoldings">
        <table class="c11n-table c11n-table--medium">
          <thead>
            <tr class="c11n-table__tr" scope="row">
              <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-left"><strong>{{content['PWStocks_DetailsCard_IndustrySectors_Headers_Sector']}}</strong></th>
              <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-right"><strong>{{content['PWStocks_DetailsCard_IndustrySectors_Headers_StockPortfolio']}}</strong></th>
              <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-right"><strong>{{content['PWStocks_DetailsCard_IndustrySectors_Headers_StockMarket']}}</strong></th>
              <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-right"><strong>{{content['PWStocks_DetailsCard_IndustrySectors_Headers_Difference']}}</strong></th>
            </tr>
          </thead>
          <tbody>
            <tr class="c11n-table__tr" scope="row" *ngFor="let tableRow of tableData" [ngClass]="{'totalRow': tableRow.sector === 'Total'}">
              <td scope="col" class="c11n-table__td c11n-table__tbody-th c11n-table__td--align-left">{{tableRow.sector}}</td>
              <td scope="col" class="c11n-table__td c11n-table__td--align-right">{{tableRow.yourStock}}</td>
              <td scope="col" class="c11n-table__td c11n-table__td--align-right">{{tableRow.stockUS}}</td>
              <td scope="col" class="c11n-table__td c11n-table__td--align-right">{{tableRow.chartDifference}}</td>
            </tr>
          </tbody>
        </table>
    </ng-container>
    <ng-container *ngIf="loaded && viewByHoldings">
        <PW1-holdings-table [tableData]="holdingsTableDataModernized"></PW1-holdings-table>
    </ng-container>
</div>
