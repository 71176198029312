/* eslint-disable */
import { Injectable } from '@angular/core';
import { Holding } from 'src/app/models/chart-data-response-models/holding';

@Injectable({
  providedIn: 'root',
})
export class HoldingsViewHelperService {
  getAllHoldings(chartData, chartDataNode: string) {
    return chartData[chartDataNode].holdings;
  }

  getArrayOfHoldingsForCategorization(chartData, chartDataNode: string, categorization: string) {
    return chartData[chartDataNode].holdings.filter((holding) => {
      return holding.categorization[categorization] && holding.categorization[categorization] !== 0;
    });
  }

  getArrayOfHoldingsForSubtotalCategorization(chartData, chartDataNode: string, categorization: string) {
    return chartData[chartDataNode].holdings.filter((holding) => {
      return this.getHoldingValue(holding, categorization) !== 0;
    });
  }

  getArrayOfHoldingsForMultipleCategorizations(
    chartData,
    chartDataNode: string,
    categorizations: string[],
  ) {
    return chartData[chartDataNode].holdings.filter((holding) => {
      let found = 0;
      categorizations.forEach((categorization) => {
        if (
          holding.categorization[categorization] &&
          holding.categorization[categorization] !== 0
        ) {
          found += 1;
        }
      });
      return found > 0;
    });
  }

  getArrayOfAccountsForCategorization(arrayOfHoldings) {
    return arrayOfHoldings
      .map((item) => item.accountName)
      .filter((value, index, self) => self.indexOf(value) === index);
  }

  getHoldingsFromSpecificAccount(arrayOfHoldings: any[], accountName: string): Holding<any>[] {
    return arrayOfHoldings.filter((holding) => {
      return holding.accountName === accountName;
    });
  }

  getHoldingInformationWithoutDifference(
    holding,
    categorization: string,
  ) {
    const regionValue = this.getHoldingValue(holding, categorization);
    const regionPercentage = this.getHoldingPercentage(holding, categorization);
    return {
      name: holding.fundName + ' (' + holding.categorization[categorization].toFixed(2) + '%)',
      value: regionValue,
      percentage: regionPercentage,
    };
  }

  getHoldingInformationWithoutDifferenceByPercentage(
    holding,
    categorization: string,
  ) {
    const regionValue = this.getHoldingValue(holding, categorization);
    const regionPercentage = this.getHoldingPercentage(holding, categorization);
    return {
      name: holding.fundName,
      value: regionValue,
      percentage: regionPercentage,
    };
  }

  getHoldingInformationWithoutDifferenceForMultipleCategorizations(
    holding,
    categorizations: string[],
  ) {
    let categorizationsValue = 0;
    let categorizationsAggregatePercentage = 0;
    let categorizationsPercentage = 0;

    categorizations.forEach((categorization) => {
      categorizationsValue += this.getHoldingValue(holding, categorization);
      categorizationsAggregatePercentage += holding.categorization[categorization];
      categorizationsPercentage += this.getHoldingPercentage(holding, categorization);
    });

    return {
      name: holding.fundName + ' (' + categorizationsAggregatePercentage.toFixed(2) + '%)',
      value: categorizationsValue,
      percentage: categorizationsPercentage,
    };
  }

  getAccountNameObject(accountName: string) {
    return {
      name: accountName,
      value: null,
      percentage: null,
    };
  }

  getSubtotalObjectWithoutDifference(valueSubtotal, percentageSubtotal) {
    return {
      name: 'Subtotal',
      value: valueSubtotal,
      percentage: percentageSubtotal,
    };
  }

  getSubtotalObjectWithDifference(valueSubtotal, percentageSubtotal, difference) {
    return {
      name: 'Subtotal',
      value: valueSubtotal,
      percentage: percentageSubtotal,
      difference: difference,
    };
  }

  getGeneratedDataWithoutDifference(categorizationObjects, chartData, chartDataNode) {
    let processedTableData = [];
    categorizationObjects.forEach((categorizationObject) => {
      let arrayOfAccounts = [];
      let categorizationSubtotal = 0;
      let categorizationPercentageSubTotal = 0;
      let holdingsWithSpecifiedCategorization = this.getArrayOfHoldingsForCategorization(
        chartData,
        chartDataNode,
        categorizationObject.dataPoint,
      );
      let accountsWithSpecifiedCategorization = this.getArrayOfAccountsForCategorization(
        holdingsWithSpecifiedCategorization,
      );

      accountsWithSpecifiedCategorization.forEach((accountName) => {
        arrayOfAccounts.push(this.getAccountNameObject(accountName));

        let holdingsArrayForAccount = this.getHoldingsFromSpecificAccount(
          holdingsWithSpecifiedCategorization,
          accountName,
        );

        holdingsArrayForAccount.forEach((holding) => {
          const acctObject = this.getHoldingInformationWithoutDifference(
            holding,
            categorizationObject.dataPoint,

          );
          categorizationSubtotal += acctObject.value;
          categorizationPercentageSubTotal += acctObject.percentage;
          arrayOfAccounts.push(acctObject);
        });
      });

      if (accountsWithSpecifiedCategorization.length > 0) {
        arrayOfAccounts.push(
          this.getSubtotalObjectWithoutDifference(
            categorizationSubtotal,
            categorizationPercentageSubTotal,
          ),
        );
        processedTableData.push({
          header: categorizationObject.title,
          accounts: arrayOfAccounts,
        });
      }
    });
    this.roundingHelper(processedTableData, chartData[chartDataNode], chartDataNode);
    return processedTableData;
  }

  getGeneratedDataWithoutDifferenceForTesterTool(categorizationObjects, chartData, chartDataNode) {
    let processedTableData = [];
    categorizationObjects.forEach((categorizationObject) => {
      let arrayOfAccounts = [];
      let categorizationSubtotal = 0;
      let categorizationPercentageSubTotal = 0;
      let holdingsWithSpecifiedCategorization = this.getArrayOfHoldingsForCategorization(
        chartData,
        chartDataNode,
        categorizationObject.dataPoint,
      );
      let accountsWithSpecifiedCategorization = this.getArrayOfAccountsForCategorization(
        holdingsWithSpecifiedCategorization,
      );

      accountsWithSpecifiedCategorization.forEach((accountName) => {
        arrayOfAccounts.push(this.getAccountNameObject(accountName));

        let holdingsArrayForAccount = this.getHoldingsFromSpecificAccount(
          holdingsWithSpecifiedCategorization,
          accountName,
        );

        holdingsArrayForAccount.forEach((holding) => {
          const acctObject = this.getHoldingInformationWithoutDifference(
            holding,
            categorizationObject.dataPoint,
          );
          categorizationSubtotal += acctObject.value;
          categorizationPercentageSubTotal += acctObject.percentage;
          arrayOfAccounts.push(acctObject);
        });
      });

      if (accountsWithSpecifiedCategorization.length > 0) {
        arrayOfAccounts.push(
          this.getSubtotalObjectWithoutDifference(
            categorizationSubtotal,
            categorizationPercentageSubTotal,
          ),
        );
      }

      processedTableData.push({
        header: categorizationObject.title,
        accounts: arrayOfAccounts,
        dataNode: chartDataNode,
        categorization: categorizationObject.dataPoint,
      });
    });
    this.roundingHelper(processedTableData, chartData[chartDataNode], chartDataNode);
    return processedTableData;
  }

  getGeneratedDataWithDifference(
    categorizationObjects,
    chartData,
    chartDataNode,
    categorizationObjectForDifferences,
  ) {
    let processedTableData = [];
    categorizationObjects.forEach((categorizationObject) => {
      let arrayOfAccounts = [];
      let categorizationSubtotal = 0;
      let categorizationPercentageSubTotal = 0;
      let holdingsWithSpecifiedCategorization = this.getArrayOfHoldingsForCategorization(
        chartData,
        chartDataNode,
        categorizationObject.dataPoint,
      );
      let accountsWithSpecifiedCategorization = this.getArrayOfAccountsForCategorization(
        holdingsWithSpecifiedCategorization,
      );

      accountsWithSpecifiedCategorization.forEach((accountName) => {
        arrayOfAccounts.push(this.getAccountNameObject(accountName));

        let holdingsArrayForAccount = this.getHoldingsFromSpecificAccount(
          holdingsWithSpecifiedCategorization,
          accountName,
        );

        holdingsArrayForAccount.forEach((holding) => {
          const acctObject = this.getHoldingInformationWithoutDifference(
            holding,
            categorizationObject.dataPoint,

          );
          categorizationSubtotal += acctObject.value;
          categorizationPercentageSubTotal += acctObject.percentage;
          arrayOfAccounts.push(acctObject);
        });
      });

      if (accountsWithSpecifiedCategorization.length > 0) {
        const subTotalDifference =
          categorizationPercentageSubTotal -
          categorizationObjectForDifferences[categorizationObject.dataPoint];
        arrayOfAccounts.push(
          this.getSubtotalObjectWithDifference(
            categorizationSubtotal,
            categorizationPercentageSubTotal,
            subTotalDifference,
          ),
        );
        processedTableData.push({
          header: categorizationObject.title,
          accounts: arrayOfAccounts,
        });
      }
    });
    this.roundingHelper(processedTableData, chartData[chartDataNode], chartDataNode);
    return processedTableData;
  }

  getGeneratedDataWithDifferenceForTesterTool(
    categorizationObjects,
    chartData,
    chartDataNode,
    categorizationObjectForDifferences,
  ) {
    let processedTableData = [];
    categorizationObjects.forEach((categorizationObject) => {
      let arrayOfAccounts = [];
      let categorizationSubtotal = 0;
      let categorizationPercentageSubTotal = 0;
      let holdingsWithSpecifiedCategorization = this.getArrayOfHoldingsForCategorization(
        chartData,
        chartDataNode,
        categorizationObject.dataPoint,
      );
      let accountsWithSpecifiedCategorization = this.getArrayOfAccountsForCategorization(
        holdingsWithSpecifiedCategorization,
      );

      accountsWithSpecifiedCategorization.forEach((accountName) => {
        arrayOfAccounts.push(this.getAccountNameObject(accountName));

        let holdingsArrayForAccount = this.getHoldingsFromSpecificAccount(
          holdingsWithSpecifiedCategorization,
          accountName,
        );

        holdingsArrayForAccount.forEach((holding) => {
          const acctObject = this.getHoldingInformationWithoutDifference(
            holding,
            categorizationObject.dataPoint,

          );
          categorizationSubtotal += acctObject.value;
          categorizationPercentageSubTotal += acctObject.percentage;
          arrayOfAccounts.push(acctObject);
        });
      });

      if (accountsWithSpecifiedCategorization.length > 0) {
        const subTotalDifference =
          categorizationPercentageSubTotal -
          categorizationObjectForDifferences[categorizationObject.dataPoint];
        arrayOfAccounts.push(
          this.getSubtotalObjectWithDifference(
            categorizationSubtotal,
            categorizationPercentageSubTotal,
            subTotalDifference,
          ),
        );
      }

      processedTableData.push({
        header: categorizationObject.title,
        accounts: arrayOfAccounts,
        dataNode: chartDataNode,
        categorization: categorizationObject.dataPoint,
      });
    });
    this.roundingHelper(processedTableData, chartData[chartDataNode], chartDataNode);
    return processedTableData;
  }

  getGeneratedDataWithoutDifferenceForMultipleCategorizations(
    categorizationsObjects,
    chartData,
    chartDataNode,
  ) {
    let processedTableData = [];
    categorizationsObjects.forEach((categorizationObject) => {
      const currentCategorizations = categorizationObject.categorizations;
      let arrayOfAccounts = [];
      let categorizationSubtotal = 0;
      let categorizationPercentageSubTotal = 0;
      let holdingsWithSpecifiedCategorizations = this.getArrayOfHoldingsForMultipleCategorizations(
        chartData,
        chartDataNode,
        currentCategorizations,
      );
      let accountsWithSpecifiedCategorizations = this.getArrayOfAccountsForCategorization(
        holdingsWithSpecifiedCategorizations,
      );

      accountsWithSpecifiedCategorizations.forEach((accountName) => {
        arrayOfAccounts.push(this.getAccountNameObject(accountName));

        let holdingsArrayForAccount = this.getHoldingsFromSpecificAccount(
          holdingsWithSpecifiedCategorizations,
          accountName,
        );

        holdingsArrayForAccount.forEach((holding) => {
          const acctObject = this.getHoldingInformationWithoutDifferenceForMultipleCategorizations(
            holding,
            currentCategorizations
          );
          categorizationSubtotal += acctObject.value;
          categorizationPercentageSubTotal += acctObject.percentage;
          arrayOfAccounts.push(acctObject);
        });
      });

      if (accountsWithSpecifiedCategorizations.length > 0) {
        arrayOfAccounts.push(
          this.getSubtotalObjectWithoutDifference(
            categorizationSubtotal,
            categorizationPercentageSubTotal,
          ),
        );
        processedTableData.push({
          header: categorizationObject.title,
          accounts: arrayOfAccounts,
        });
      }
    });
    this.roundingHelper(processedTableData, chartData[chartDataNode], chartDataNode);
    return processedTableData;
  }

  getGeneratedDataWithoutDifferenceForMultipleCategorizationsForTesterTool(
    categorizationsObjects,
    chartData,
    chartDataNode,
  ) {
    let processedTableData = [];
    categorizationsObjects.forEach((categorizationObject) => {
      const currentCategorizations = categorizationObject.categorizations;
      let arrayOfAccounts = [];
      let categorizationSubtotal = 0;
      let categorizationPercentageSubTotal = 0;
      let holdingsWithSpecifiedCategorizations = this.getArrayOfHoldingsForMultipleCategorizations(
        chartData,
        chartDataNode,
        currentCategorizations,
      );
      let accountsWithSpecifiedCategorizations = this.getArrayOfAccountsForCategorization(
        holdingsWithSpecifiedCategorizations,
      );

      accountsWithSpecifiedCategorizations.forEach((accountName) => {
        arrayOfAccounts.push(this.getAccountNameObject(accountName));

        let holdingsArrayForAccount = this.getHoldingsFromSpecificAccount(
          holdingsWithSpecifiedCategorizations,
          accountName,
        );

        holdingsArrayForAccount.forEach((holding) => {
          const acctObject = this.getHoldingInformationWithoutDifferenceForMultipleCategorizations(
            holding,
            currentCategorizations
          );
          categorizationSubtotal += acctObject.value;
          categorizationPercentageSubTotal += acctObject.percentage;
          arrayOfAccounts.push(acctObject);
        });
      });

      if (accountsWithSpecifiedCategorizations.length > 0) {
        arrayOfAccounts.push(
          this.getSubtotalObjectWithoutDifference(
            categorizationSubtotal,
            categorizationPercentageSubTotal,
          ),
        );
      }

      processedTableData.push({
        header: categorizationObject.title,
        accounts: arrayOfAccounts,
        dataNode: chartDataNode,
        categorizations: categorizationObject.categorizations,
      });
    });
    this.roundingHelper(processedTableData, chartData[chartDataNode], chartDataNode);
    return processedTableData;
  }

  calculateTotal(arr: any) {
    arr = arr.filter(function (e) {
      return e;
    });

    let total: number = 0;
    for (let i: number = 0; i < arr.length; i++) {
      for (let j: number = 0; j < arr[i].accounts.length; j++) {
        if (arr[i].accounts[j].name === 'Subtotal') {
          total += arr[i].accounts[j].percentage;
        }
      }
    }
    return Number(total.toFixed(0));
  }

  roundingHelper(arr: any, category: any = null, node: any = null): any[] {
    if (category && category.chart) {
      arr = arr.filter(function (e) {
        return e;
      });

      let values: number[] = Object.values(category.chart);

      if (node.includes('stock')) {
        values = values.filter((e) => e > 0);
      }

      for (let i: number = 0; i < arr.length; i++) {
        let filteredArr = arr[i].accounts.filter(
          (accounts) =>
            accounts.percentage &&
            accounts.percentage > 0.0001 &&
            accounts.value &&
            accounts.name != 'Subtotal',
        );

        if (filteredArr.length > 0) {
          let sumOfIndividualPercents: number = Number(
            this.roundNumber(
              arr[i].accounts
                .filter(
                  (accounts) =>
                    accounts.percentage &&
                    accounts.percentage > 0.0001 &&
                    accounts.name != 'Subtotal',
                )
                .map((accounts) => Number(this.roundNumber(accounts.percentage)))
                .reduce((acc, curr) => acc + curr),
            ),
          );

          let sumOfIndividualValues: number = Number(
            this.roundNumber(
              arr[i].accounts
                .filter(
                  (accounts) => accounts.value && accounts.value > 0 && accounts.name != 'Subtotal',
                )
                .map((accounts) => Number(this.roundNumber(accounts.value)))
                .reduce((acc, curr) => acc + curr),
            ),
          );

          let difference = node.includes('bond')
            ? Number(this.roundNumber(values[i] + values[i + 4] - sumOfIndividualPercents))
            : Number(this.roundNumber(values[i] - sumOfIndividualPercents));
          if (
            (difference >= 0.01 && difference < 0.05) ||
            (difference <= -0.01 && difference > -0.05)
          ) {
            if (
              arr[i].accounts
                .filter((accounts) => accounts.percentage && accounts.name != 'Subtotal')
                .sort((a, b) => b.percentage - a.percentage).length > 0
            ) {
              arr[i].accounts
                .filter((accounts) => accounts.percentage && accounts.name != 'Subtotal')
                .sort((a, b) => b.percentage - a.percentage)[0].percentage += difference;
            }
          }

          sumOfIndividualPercents = Number(
            this.roundNumber(
              arr[i].accounts
                .filter(
                  (accounts) =>
                    accounts.percentage &&
                    accounts.percentage > 0.0001 &&
                    accounts.name != 'Subtotal',
                )
                .map((accounts) => Number(this.roundNumber(accounts.percentage)))
                .reduce((acc, curr) => acc + curr),
            ),
          );

          arr[i].accounts[arr[i].accounts.length - 1].percentage = sumOfIndividualPercents;
          arr[i].accounts[arr[i].accounts.length - 1].value = sumOfIndividualValues;
        }
      }
    }
    return arr;
  }
  roundNumber(num) {
    return Number(+(Math.round(Number(num + 'e+2')) + 'e-2')).toFixed(2);
  }

  getHoldingValue(holding: Holding<any>, categorization: string) {
    return holding.subtotals.find(x => x.name.toLowerCase() == categorization.toLowerCase()).portfolioValue;
  }

  getHoldingPercentage(holding: Holding<any>, categorization: string){
    return holding.subtotals.find(x => x.name.toLowerCase() == categorization.toLowerCase()).portfolioPercentage;
  }
}
