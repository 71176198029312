/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { PortfolioCardsService } from '../../services/portfolio-cards/portfolio-cards.service';
import { ChartDataService } from '../../services/chart-data-service/chart-data.service';
import { ChartData } from '../../models/chart-models/chart-data';
import { LegendLine } from '../../models/chart-models/legend-line';
import { ChartColors } from '../../models/chart-models/chart-colors';
import { PortfolioDataService } from '../../services/portfolio-data-service/portfolio-data.service';
import { RiskAnalysisType } from '../../models/chart-data-response-models/risk-analysis-type';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';
const ZERO_INDEX = 0;

@Component({
  selector: 'PW1-asset-allocation',
  templateUrl: './asset-allocation.component.html',
  styleUrls: ['./asset-allocation.component.scss'],
})
export class AssetAllocationComponent implements OnInit {
  chartData;

  // charts
  actualAssetAllocationChartData: ChartData;
  targetAssetAllocationChartData: ChartData;

  actualAssetAllocation = 'actualAssetAllocation';
  targetAssetAllocation = 'targetAssetAllocation';

  hasLoadedAssetChartData = false;
  hasLoadedTargetChartData = false;
  hasLoadedDifferenceChartData = false;
  hasLoadedHistoricRiskReturnData = false;

  legendHeader = 'Difference in target vs Actual';
  differenceLegendArray: LegendLine[];
  actualLegendArray: LegendLine[];
  targetLegendArray: LegendLine[];
  chartColorMap = new Map();
  chartDifferenceMap = new Map();

  stockColor;
  bondColor;
  strColor;
  otherColor;

  //Actual Asset values
  actualBonds: number | string;
  actualOthers: number;
  actualShortTermReserves: number;
  actualStocks: number | string;

  //Target Asset values
  targetBonds: number;
  targetOthers: number;
  targetShortTermReserves: number;
  targetStocks: number;

  // Current Allocation Historic Risk/Return response
  currentAllocationHistoricRiskReturn: RiskAnalysisType;

  // Target Allocation Historic Risk/Return response
  targetAllocationHistoricRiskReturn: RiskAnalysisType;

  modernizedEnabled: Observable<boolean>;
  hasSetNewMix: Observable<boolean>;
  showModernizedTargetPage;

  constructor(
    private cardsService: PortfolioCardsService,
    private chartService: ChartDataService,
    private portfolioDataService: PortfolioDataService,
    private route: ActivatedRoute,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {
    this.chartColorMap = ChartColors.getColorMap();

    this.stockColor = this.chartColorMap.get('stocks');
    this.bondColor = this.chartColorMap.get('bonds');
    this.strColor = this.chartColorMap.get('shortTermReserves');
    this.otherColor = this.chartColorMap.get('other');
  }

  ngOnInit() {
    this.setupCharts();

    this.route.data.subscribe((data) => {
      this.adobeAnalyticsService.trackRoutingData(data);
    });
    this.hasSetNewMix = this.portfolioDataService.getTargetMixSuccess();
  }

  setupCharts() {
    this.setupPercentValues();
    this.setupActualAssetAllocationChart();
    this.setupTargetAllocationChart();
    this.setupLegend();
    this.setupHistoricRiskReturn();
  }
  setupPercentValues() {
    this.portfolioDataService.getChartDataPayload().subscribe((data) => {
      if (data.assetTypes.chart && data.assetTypeTarget) {
        this.chartData = data;

        if (
          data.assetTypes.chart.bonds === 0 &&
          data.bondDomesticInternational.applicableBalance > 0
        ) {
          this.actualBonds = '<1';
        } else {
          this.actualBonds = Math.floor(data.assetTypes.chart.bonds);
        }

        this.actualOthers = Math.floor(data.assetTypes.chart.others);
        this.actualShortTermReserves = Math.floor(data.assetTypes.chart.shortTermReserves);

        if (
          data.assetTypes.chart.stocks === 0 &&
          data.stockDomesticInternational.applicableBalance > 0
        ) {
          this.actualStocks = '<1';
        } else {
          this.actualStocks = Math.floor(data.assetTypes.chart.stocks);
        }

        this.targetBonds = Math.floor(data.assetTypeTarget.bonds);
        this.targetOthers = Math.floor(data.assetTypeTarget.others);
        this.targetShortTermReserves = Math.floor(data.assetTypeTarget.shortTermReserves);
        this.targetStocks = Math.floor(data.assetTypeTarget.stocks);

        this.actualLegendArray = this.createFourLineLegend(
          this.actualStocks,
          this.actualBonds,
          this.actualShortTermReserves,
          this.actualOthers,
        );
        this.targetLegendArray = this.createFourLineLegend(
          this.targetStocks,
          this.targetBonds,
          this.targetShortTermReserves,
          this.targetOthers,
        );
      }
    });
  }

  setupActualAssetAllocationChart() {
    this.chartService.getAssetAllocationChartPayload().subscribe((data) => {
      if (data.percentageDetails.length !== ZERO_INDEX) {
        this.actualAssetAllocationChartData = data;
        this.hasLoadedAssetChartData = true;
      }
    });
  }

  setupTargetAllocationChart() {
    this.chartService.getTargetAssetAllocationPayload().subscribe((data) => {
      if (data.percentageDetails.length !== ZERO_INDEX) {
        this.targetAssetAllocationChartData = data;
        this.hasLoadedTargetChartData = true;
      }
    });
  }

  setupLegend() {
    this.chartService.getAllocationDifferenceMapPayload().subscribe((data) => {
      if (data) {
        this.chartDifferenceMap = data;
        this.hasLoadedDifferenceChartData = true;

        this.differenceLegendArray = this.createFourLineLegend(
          this.chartDifferenceMap.get('stocks'),
          this.chartDifferenceMap.get('bonds'),
          this.chartDifferenceMap.get('shortTermReserves'),
          this.chartDifferenceMap.get('other'),
        );
      }
    });
  }

  setupHistoricRiskReturn() {
    this.portfolioDataService.getChartDataPayload().subscribe((data) => {
      if (data.riskReturnAnalysisResponse) {
        this.currentAllocationHistoricRiskReturn =
          data.riskReturnAnalysisResponse.currentAllocationRiskAnalysis;
        this.targetAllocationHistoricRiskReturn =
          data.riskReturnAnalysisResponse.targetAllocationRiskAnalysis;
        this.hasLoadedHistoricRiskReturnData = true;
      }
    });
  }

  changeTargetCard() {
    this.cardsService.navigateToPage('target-allocation');
  }

  createFourLineLegend(
    stockPercent: number | string,
    bondPercent: number | string,
    shortTermReservePercent: number,
    otherPercent: number,
  ): LegendLine[] {
    const array: LegendLine[] = [];

    const stocks: LegendLine = {
      percentageAllocationOffset: stockPercent,
      legendLineName: 'Stocks',
      circleColor: this.chartColorMap.get('stocks'),
    };

    const bonds: LegendLine = {
      percentageAllocationOffset: bondPercent,
      legendLineName: 'Bonds',
      circleColor: this.chartColorMap.get('bonds'),
    };

    const shortTermReserves: LegendLine = {
      percentageAllocationOffset: shortTermReservePercent,
      legendLineName: 'Short Term Reserves',
      circleColor: this.chartColorMap.get('shortTermReserves'),
    };

    const other: LegendLine = {
      percentageAllocationOffset: otherPercent,
      legendLineName: 'Other',
      circleColor: this.chartColorMap.get('other'),
    };

    array.push(stocks);
    array.push(bonds);
    array.push(shortTermReserves);
    array.push(other);

    return array;
  }

  closeBanner() {
    this.portfolioDataService.setTargetMixSuccess(false);
  }
}
