<div *ngIf='chartData' class="tab-table">
    <mat-tab-group animationDuration="500ms" [disableRipple]="true" [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="updateUrl($event)">
        <mat-tab label="Market capitalization" role="link">
            <PW1-hypothetical-market-cap-table [chartData]='chartData' [hypotheticalData]='hypotheticalData'></PW1-hypothetical-market-cap-table>
        </mat-tab>
        <mat-tab label="Investment style" role="link">
            <PW1-hypothetical-investment-style-table [chartData]='chartData' [hypotheticalData]='hypotheticalData'></PW1-hypothetical-investment-style-table>
        </mat-tab>
        <mat-tab label="Industry sectors" role="link">
            <PW1-hypothetical-industry-sectors-table [chartData]='chartData' [hypotheticalData]='hypotheticalData'></PW1-hypothetical-industry-sectors-table>
        </mat-tab>
        <mat-tab label="International regions" role="link">
            <PW1-hypothetical-international-regions-table [chartData]='chartData' [hypotheticalData]='hypotheticalData'></PW1-hypothetical-international-regions-table>
        </mat-tab>
    </mat-tab-group>
</div>
