import { Component, OnInit, Input } from '@angular/core';
import { INDEX_CONSTANTS } from '../../../../../models/chart.constants';
import { HoldingsViewHelperService } from '../../../../../services/holdings-view-helper/holdings-view-helper.service';

@Component({
  selector: 'PW1-hypothetical-credit-quality-table',
  templateUrl: './hypothetical-credit-quality-table.component.html',
  styleUrls: ['./hypothetical-credit-quality-table.component.scss'],
})
export class HypotheticalCreditQualityTableComponent implements OnInit {
  @Input() chartData;
  @Input() hypotheticalData;

  total;
  taxableTotal;
  municipalTotal;

  hypotheticalTotal;
  hypotheticalTaxableTotal;
  hypotheticalMunicipalTotal;

  isUncategorizedEnabled = false;
  isUncategorizedEnabledHypothetical = false;

  tableData = [];
  totalData = [];

  hypotheticalTableData = [];
  hypotheticalTotalData = [];

  holdingsTablesData: any[];

  loaded = false;
  loadedHypothetical = false;
  viewByHoldings = false;

  private totalHigh = INDEX_CONSTANTS.ZERO;
  private totalMedium = INDEX_CONSTANTS.ZERO;
  private totalLow = INDEX_CONSTANTS.ZERO;

  private taxableHigh = INDEX_CONSTANTS.ZERO;
  private taxableMedium = INDEX_CONSTANTS.ZERO;
  private taxableLow = INDEX_CONSTANTS.ZERO;

  private municipalHigh = INDEX_CONSTANTS.ZERO;
  private municipalMedium = INDEX_CONSTANTS.ZERO;
  private municipalLow = INDEX_CONSTANTS.ZERO;

  private taxableUncategorized = INDEX_CONSTANTS.ZERO;
  private municipalUncategorized = INDEX_CONSTANTS.ZERO;
  private totalUncategorized = INDEX_CONSTANTS.ZERO;

  private hypotheticalTotalHigh = INDEX_CONSTANTS.ZERO;
  private hypotheticalTotalMedium = INDEX_CONSTANTS.ZERO;
  private hypotheticalTotalLow = INDEX_CONSTANTS.ZERO;

  private hypotheticalTaxableHigh = INDEX_CONSTANTS.ZERO;
  private hypotheticalTaxableMedium = INDEX_CONSTANTS.ZERO;
  private hypotheticalTaxableLow = INDEX_CONSTANTS.ZERO;

  private hypotheticalMunicipalHigh = INDEX_CONSTANTS.ZERO;
  private hypotheticalMunicipalMedium = INDEX_CONSTANTS.ZERO;
  private hypotheticalMunicipalLow = INDEX_CONSTANTS.ZERO;

  private hypotheticalTaxableUncategorized = INDEX_CONSTANTS.ZERO;
  private hypotheticalMunicipalUncategorized = INDEX_CONSTANTS.ZERO;
  private hypotheticalTotalUncategorized = INDEX_CONSTANTS.ZERO;

  constructor(private holdingsViewHelper: HoldingsViewHelperService) {}

  ngOnInit() {
    if (this.chartData && this.hypotheticalData) {
      this.setChartData();
      this.setTableData();
      this.setChartDataHypothetical();
      this.setTableDataHypothetical();
      this.setBothHoldingsTables();
    }
  }

  setChartData() {
    this.taxableHigh = this.chartData.bondCreditQuality.chart.taxableHigh;
    this.municipalHigh = this.chartData.bondCreditQuality.chart.municipalHigh;
    this.totalHigh = Number((this.taxableHigh + this.municipalHigh).toFixed(2));
    this.taxableMedium = this.chartData.bondCreditQuality.chart.taxableMedium;
    this.municipalMedium = this.chartData.bondCreditQuality.chart.municipalMedium;
    this.totalMedium = Number((this.taxableMedium + this.municipalMedium).toFixed(2));
    this.municipalLow = this.chartData.bondCreditQuality.chart.municipalLow;
    this.taxableLow = this.chartData.bondCreditQuality.chart.taxableLow;
    this.totalLow = Number((this.taxableLow + this.municipalLow).toFixed(1));
    this.taxableUncategorized = this.chartData.bondCreditQuality.chart.taxableUncategorized;
    this.municipalUncategorized = this.chartData.bondCreditQuality.chart.municipalUncategorized;
    this.totalUncategorized = Number(
      (this.taxableUncategorized + this.municipalUncategorized).toFixed(1),
    );
    this.setUncategorizedData();
  }
  setUncategorizedData() {
    this.taxableTotal = Number(
      (this.taxableHigh + this.taxableMedium + this.taxableLow + this.taxableUncategorized).toFixed(
        1,
      ),
    );
    this.municipalTotal = Number(
      (
        this.municipalHigh +
        this.municipalMedium +
        this.municipalLow +
        this.municipalUncategorized
      ).toFixed(1),
    );
    this.total = Number(
      (this.totalHigh + this.totalMedium + this.totalLow + this.totalUncategorized).toFixed(1),
    );
    this.isUncategorizedEnabled = this.taxableUncategorized > 0 || this.municipalUncategorized > 0;
  }
  setTableData() {
    this.tableData = [
      {
        quality: 'High',
        yourTaxableBonds: this.taxableHigh,
        yourMunicipal: this.municipalHigh,
        yourTotalBonds: this.totalHigh,
      },
      {
        quality: 'Medium',
        yourTaxableBonds: this.taxableMedium,
        yourMunicipal: this.municipalMedium,
        yourTotalBonds: this.totalMedium,
      },
      {
        quality: 'Low',
        yourTaxableBonds: this.taxableLow,
        yourMunicipal: this.municipalLow,
        yourTotalBonds: this.totalLow,
      },
    ];
    if (this.isUncategorizedEnabled) {
      this.tableData.push({
        quality: 'Uncategorized',
        yourTaxableBonds: this.taxableUncategorized,
        yourMunicipal: this.municipalUncategorized,
        yourTotalBonds: this.totalUncategorized,
      });
    }
    this.totalData = [
      {
        quality: 'Total',
        yourTaxableBonds: this.taxableTotal,
        yourMunicipal: this.municipalTotal,
        yourTotalBonds: this.total,
      },
    ];
    this.loaded = true;
  }

  generateTableDataByHoldings() {
    const categorizationsObjects: any[] = [
      {
        title: 'Current high credit quality bonds & bond funds',
        categorizations: ['taxableHigh', 'municipalHigh'],
      },
      {
        title: 'Current medium credit quality bonds & bond funds',
        categorizations: ['taxableMedium', 'municipalMedium'],
      },
      {
        title: 'Current low credit quality bonds & bond funds',
        categorizations: ['taxableLow', 'municipalLow'],
      },
      {
        title: 'Actual uncategorized credit quality bond funds',
        categorizations: ['taxableUncategorized', 'municipalUncategorized'],
      },
    ];
    return this.holdingsViewHelper.getGeneratedDataWithoutDifferenceForMultipleCategorizationsForTesterTool(
      categorizationsObjects,
      this.chartData,
      'bondCreditQuality',
    );
  }

  setChartDataHypothetical() {
    this.hypotheticalTaxableHigh = this.hypotheticalData.bondCreditQuality.chart.taxableHigh;
    this.hypotheticalMunicipalHigh = this.hypotheticalData.bondCreditQuality.chart.municipalHigh;
    this.hypotheticalTotalHigh = Number(
      (this.hypotheticalTaxableHigh + this.hypotheticalMunicipalHigh).toFixed(2),
    );
    this.hypotheticalTaxableMedium = this.hypotheticalData.bondCreditQuality.chart.taxableMedium;
    this.hypotheticalMunicipalMedium =
      this.hypotheticalData.bondCreditQuality.chart.municipalMedium;
    this.hypotheticalTotalMedium = Number(
      (this.hypotheticalTaxableMedium + this.hypotheticalMunicipalMedium).toFixed(2),
    );
    this.hypotheticalMunicipalLow = this.hypotheticalData.bondCreditQuality.chart.municipalLow;
    this.hypotheticalTaxableLow = this.hypotheticalData.bondCreditQuality.chart.taxableLow;
    this.hypotheticalTotalLow = Number(
      (this.hypotheticalTaxableLow + this.hypotheticalMunicipalLow).toFixed(1),
    );
    this.hypotheticalTaxableUncategorized =
      this.hypotheticalData.bondCreditQuality.chart.taxableUncategorized;
    this.hypotheticalMunicipalUncategorized =
      this.hypotheticalData.bondCreditQuality.chart.municipalUncategorized;
    this.hypotheticalTotalUncategorized = Number(
      (this.hypotheticalTaxableUncategorized + this.hypotheticalMunicipalUncategorized).toFixed(1),
    );
    this.setUncategorizedDataHypothetical();
  }
  setUncategorizedDataHypothetical() {
    this.hypotheticalTaxableTotal = Number(
      (
        this.hypotheticalTaxableHigh +
        this.hypotheticalTaxableMedium +
        this.hypotheticalTaxableLow +
        this.hypotheticalTaxableUncategorized
      ).toFixed(1),
    );
    this.hypotheticalMunicipalTotal = Number(
      (
        this.hypotheticalMunicipalHigh +
        this.hypotheticalMunicipalMedium +
        this.hypotheticalMunicipalLow +
        this.hypotheticalMunicipalUncategorized
      ).toFixed(1),
    );
    this.hypotheticalTotal = Number(
      (
        this.hypotheticalTotalHigh +
        this.hypotheticalTotalMedium +
        this.hypotheticalTotalLow +
        this.hypotheticalTotalUncategorized
      ).toFixed(1),
    );
    this.isUncategorizedEnabledHypothetical =
      this.hypotheticalTaxableUncategorized > 0 || this.hypotheticalMunicipalUncategorized > 0;
  }
  setTableDataHypothetical() {
    this.hypotheticalTableData = [
      {
        quality: 'High',
        yourTaxableBonds: this.hypotheticalTaxableHigh,
        yourMunicipal: this.hypotheticalMunicipalHigh,
        yourTotalBonds: this.hypotheticalTotalHigh,
      },
      {
        quality: 'Medium',
        yourTaxableBonds: this.hypotheticalTaxableMedium,
        yourMunicipal: this.hypotheticalMunicipalMedium,
        yourTotalBonds: this.hypotheticalTotalMedium,
      },
      {
        quality: 'Low',
        yourTaxableBonds: this.hypotheticalTaxableLow,
        yourMunicipal: this.hypotheticalMunicipalLow,
        yourTotalBonds: this.hypotheticalTotalLow,
      },
    ];
    if (this.isUncategorizedEnabledHypothetical) {
      this.hypotheticalTableData.push({
        quality: 'Uncategorized',
        yourTaxableBonds: this.hypotheticalTaxableUncategorized,
        yourMunicipal: this.hypotheticalMunicipalUncategorized,
        yourTotalBonds: this.hypotheticalTotalUncategorized,
      });
    }
    this.hypotheticalTotalData = [
      {
        quality: 'Total',
        yourTaxableBonds: this.hypotheticalTaxableTotal,
        yourMunicipal: this.hypotheticalMunicipalTotal,
        yourTotalBonds: this.hypotheticalTotal,
      },
    ];
    this.loadedHypothetical = true;
  }

  generateTableDataByHoldingsHypothetical() {
    const categorizationsObjects: any[] = [
      {
        title: 'Hypothetical high credit quality bonds & bond funds',
        categorizations: ['taxableHigh', 'municipalHigh'],
      },
      {
        title: 'Hypothetical medium credit quality bonds & bond funds',
        categorizations: ['taxableMedium', 'municipalMedium'],
      },
      {
        title: 'Hypothetical low credit quality bonds & bond funds',
        categorizations: ['taxableLow', 'municipalLow'],
      },
      {
        title: 'Hypothetical uncategorized credit quality bond funds',
        categorizations: ['taxableUncategorized', 'municipalUncategorized'],
      },
    ];
    return this.holdingsViewHelper.getGeneratedDataWithoutDifferenceForMultipleCategorizationsForTesterTool(
      categorizationsObjects,
      this.hypotheticalData,
      'bondCreditQuality',
    );
  }

  changeTableView(val: boolean) {
    this.viewByHoldings = val;
  }

  setBothHoldingsTables() {
    const tableDataByHoldingsActual = this.generateTableDataByHoldings();
    const tableDataByHoldingsHypothetical = this.generateTableDataByHoldingsHypothetical();

    const unfiltered = [
      {
        actual: this.getTable(tableDataByHoldingsActual, 'bondCreditQuality', [
          'taxableHigh',
          'municipalHigh',
        ]),
        hypothetical: this.getTable(tableDataByHoldingsHypothetical, 'bondCreditQuality', [
          'taxableHigh',
          'municipalHigh',
        ]),
      },
      {
        actual: this.getTable(tableDataByHoldingsActual, 'bondCreditQuality', [
          'taxableMedium',
          'municipalMedium',
        ]),
        hypothetical: this.getTable(tableDataByHoldingsHypothetical, 'bondCreditQuality', [
          'taxableMedium',
          'municipalMedium',
        ]),
      },
      {
        actual: this.getTable(tableDataByHoldingsActual, 'bondCreditQuality', [
          'taxableLow',
          'municipalLow',
        ]),
        hypothetical: this.getTable(tableDataByHoldingsHypothetical, 'bondCreditQuality', [
          'taxableLow',
          'municipalLow',
        ]),
      },
      {
        actual: this.getTable(tableDataByHoldingsActual, 'bondCreditQuality', [
          'taxableUncategorized',
          'municipalUncategorized',
        ]),
        hypothetical: this.getTable(tableDataByHoldingsHypothetical, 'bondCreditQuality', [
          'taxableUncategorized',
          'municipalUncategorized',
        ]),
      },
    ];

    this.holdingsTablesData = unfiltered.filter((category) => {
      return category.actual.accounts.length > 0 || category.hypothetical.accounts.length > 0;
    });
  }

  getTable(dataSet, dataNode, categorizations: string[]) {
    const filteredTableData = dataSet.filter((tableData) => {
      if (tableData && tableData.dataNode === dataNode) {
        return categorizations.join(',') === tableData.categorizations.join(',');
      }
      return false;
    });

    return filteredTableData[0];
  }
}
