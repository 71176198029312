import { Component, OnInit } from '@angular/core';
import { PortfolioDataService } from '../../../../services/portfolio-data-service/portfolio-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';

@Component({
  selector: 'PW1-hypothetical-stock-analysis-tab-table',
  templateUrl: './hypothetical-stock-analysis-tab-table.component.html',
  styleUrls: ['./hypothetical-stock-analysis-tab-table.component.scss'],
})
export class HypotheticalStockAnalysisTabTableComponent implements OnInit {
  selectedTabIndex;
  navigation;
  chartData;
  hypotheticalData;
  urlList: string[] = [
    'market-capitalization',
    'investment-style',
    'industry-sectors',
    'international-regions',
  ];

  constructor(
    private portfolioDataService: PortfolioDataService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {
    this.navigation = this.router.getCurrentNavigation();
  }

  ngOnInit() {
    this.portfolioDataService.getChartDataPayload().subscribe((data) => {
      if (data) {
        this.chartData = data;
      }
    });
    this.portfolioDataService.getHypotheticalDataPayload().subscribe((data) => {
      if (data) {
        this.hypotheticalData = data;
      }
    });
    this.selectedTabIndex = this.route.snapshot.data['tabId'];

    this.route.data.subscribe((data) => {
      this.adobeAnalyticsService.trackRoutingData(data);
    });
  }

  updateUrl(tab) {
    const url: string = '/hypothetical-stock-analysis/' + this.urlList[tab.index];
    this.location.go(url);
    this.adobeAnalyticsService.clickedOnTab(url);
    this.route.data.subscribe((data) => {
      this.adobeAnalyticsService.trackRoutingData(data);
    });
  }
}
