<section #content="pwContent" pwContent id="asset-analysis-content" tabindex="-1">
    <div>
        <div class="mainCardContent">
            <PW1-alert-notifications [pageName]="'/asset-mix'"></PW1-alert-notifications>
            <target-mix-success-banner class="success-banner" *ngIf="hasSetNewMix | async" (activate)="closeBanner()"></target-mix-success-banner>
            <asset-mix-dashboard [isFullWidth]=true
                                            [actualAssetAllocationChartId]="'modernizedAssetPageActualAssetAllocationId'"
                                            [targetAssetAllocationChartId]="'modernizedAssetPageTargetAssetAllocationId'"></asset-mix-dashboard>

            <div class='spacer'></div>
            <PW1-asset-allocation-holdings [chartData]="chartData"></PW1-asset-allocation-holdings>
            <div class='spacer'></div>

            <PW1-accordion [title]="content['PWAsset_DetailsCard_Accordion_RiskReturnAllocationAnalysis']">
                <risk-return-analysis></risk-return-analysis>
            </PW1-accordion>
            <div class='spacer'></div>
        </div>
    </div>
</section>
