<div
  id="modernizedPrintPage"
  *ngIf="hasLoadedTables && hasLoadedCharts"
  #content="pwContent"
  pwContent
>
  <div class="page">
    <div class="header">
      <p>
        {{ content['PWPrint_Preparation_Start'] }}{{ chartData.clientName
        }}{{ content['PWPrint_Preparation_AsOf'] }}{{ currentDate | date : 'longDate' }}
      </p>
      <h1 [innerHTML]="content['PWPrint_Header']"></h1>
    </div>
    <div class="accountGroupAndAlerts">
      <h2>{{ accountGroup }}</h2>
      <PW1-alert-container></PW1-alert-container>
    </div>
    <div class="cardsRow">
      <div class="cardContainer">
        <asset-card
          [chartIdForCurrent]="'printCurrentAssetsChartId'"
          [chartIdForTarget]="'printTargetAssetsChartId'"
          class="main-page-card"
        ></asset-card>
      </div>
      <div class="cardContainer">
        <stock-card [chartId]="'printStockDonutChartId'" class="main-page-card"></stock-card>
      </div>
    </div>
    <div class="cardsRow">
      <div class="cardContainer">
        <bond-card [chartId]="'printBondDonutChartId'" class="main-page-card"></bond-card>
      </div>
      <div class="cardContainer">
        <cost-card
          [industryChartId]="'printOverviewCostModernizedIndustryChartId'"
          [vanguardChartId]="'printOverviewCostModernizedVanguardChartId'"
          [yourCostsChartId]="'printOverviewCostModernizedYourCostsChartId'"
          class="main-page-card"
        ></cost-card>
      </div>
    </div>
  </div>

  <div class="pageBreak"></div>

  <div class="page container-fluid">
    <div class="row disclaimers">
      <PW1-investment-costs-disclaimer-accordion
        [printView]="true"
        class="col-6 c11n-grid-story__cell investment-costs-disclaimer-accordion--half-screen"
      ></PW1-investment-costs-disclaimer-accordion>
      <PW1-disclaimer
        class="col-6 c11n-grid-story__cell"
        contentTag="PWCosts_Disclaimer_Body"
        headerTag="PWCosts_Disclaimer_Header"
        linkTag="PWCosts_Disclaimer_Link_Vanguard"
      ></PW1-disclaimer>
    </div>
  </div>

  <div class="pageBreak"></div>

  <div class="page">
    <PW1-asset-allocation-holdings [chartData]="chartData"></PW1-asset-allocation-holdings>
    <!--        hafta do this hacky thing to force a page break, it won't be visible though-->
    <p style="color: transparent">.</p>
  </div>

  <div class="pageBreak"></div>

  <div class="page">
    <PW1-accordion [title]="content['PWAsset_DetailsCard_Accordion_RiskReturnAllocationAnalysis']">
      <risk-return-analysis></risk-return-analysis>
    </PW1-accordion>
    <!--        hafta do this hacky thing to force a page break, it won't be visible though-->
    <p style="color: transparent">.</p>
  </div>

  <div class="pageBreak"></div>

  <div class="page">
    <h2 [innerHTML]="content['PWPrint_StockSection_Header']"></h2>
    <p [innerHTML]="content['PWPrint_StockSection_Summary']"></p>
    <stock-dashboard [chartId]="'printViewStockStackedBarChart'"></stock-dashboard>
    <market-cap-table [chartData]="chartData"></market-cap-table>
    <investment-style-table [chartData]="chartData"></investment-style-table>
    <industry-sectors-table [chartData]="chartData"></industry-sectors-table>
    <international-regions-table [chartData]="chartData"></international-regions-table>
  </div>

  <div class="pageBreak"></div>

  <div class="page">
    <h2 [innerHTML]="content['PWPrint_BondSection_Header']"></h2>
    <p [innerHTML]="content['PWPrint_BondSection_Summary']"></p>
    <bond-dashboard [chartId]="'printBondStackedBarChartId'"></bond-dashboard>
    <PW1-credit-quality-table [chartData]="chartData"></PW1-credit-quality-table>
    <PW1-effective-maturity-table [chartData]="chartData"></PW1-effective-maturity-table>
    <PW1-nine-box-component [chartData]="chartData"></PW1-nine-box-component>
  </div>

  <div class="pageBreak"></div>

  <div class="page">
    <h2 [innerHTML]="content['PWPrint_CostSection_Header']"></h2>
    <p [innerHTML]="content['PWPrint_CostSection_Summary']"></p>
    <bar-chart
      [chartData]="costBarChartData"
      [industryChartId]="'printCostModernizedIndustryChartId'"
      [vanguardChartId]="'printCostModernizedVanguardChartId'"
      [yourCostsChartId]="'printCostModernizedYourCostsChartId'"
      [isFullWidth]="true"
    ></bar-chart>
    <!-- TODO : update the AEM content key once the AEM changes are available in PROD -->
    <p style="font-size: 11px">*As of December 2022</p>
    <PW1-investment-costs-disclaimer-accordion
      [printView]="true"
    ></PW1-investment-costs-disclaimer-accordion>
    <mutual-fund-table [chartData]="chartData"></mutual-fund-table>
  </div>
</div>

<div class="pageBreak"></div>

<div class="page container-fluid">
  <div class="row">
    <tax-efficiency-table
      class="col-6 c11n-grid-story__cell"
      [chartData]="chartData"
    ></tax-efficiency-table>
    <PW1-disclaimer
      class="col-6 c11n-grid-story__cell"
      contentTag="PWCosts_Disclaimer_Body"
      headerTag="PWCosts_Disclaimer_Header"
      linkTag="PWCosts_Disclaimer_Link_Vanguard"
    ></PW1-disclaimer>
  </div>
</div>

<!-- TODO: FIX the misalignments of overview cards (also an issue on non-print page) -->
