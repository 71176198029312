import { AssetTableCategorization, HoldingSubtotalCategorization } from 'src/app/models/holdings-table';
import { Component, OnInit, Input } from '@angular/core';
import { HoldingsViewHelperService } from '../../services/holdings-view-helper/holdings-view-helper.service';
import { HoldingsTableBuilderService } from '../../services/holdings-table-builder/holdings-table-builder.service';
import {
  HoldingsTableAccountWithHoldings,
  HoldingsTableCategorization,
  HoldingsTableData,
} from '../../models/holdings-table';
import { Router } from '@angular/router';

@Component({
  selector: 'PW1-asset-allocation-holdings',
  templateUrl: './asset-allocation-holdings.component.html',
  styleUrls: ['./asset-allocation-holdings.component.scss'],
})
export class AssetAllocationHoldingsComponent implements OnInit {
  @Input() chartData;

  showHoldingsTable: boolean = false;
  tableDataByHoldings = [];
  holdingsTableDataModernized: HoldingsTableData;
  holdingsTotal: number = 0;

  constructor(
    private holdingsViewHelper: HoldingsViewHelperService,
    private holdingsTableBuilderService: HoldingsTableBuilderService,
    public router: Router,
  ) {}

  ngOnInit() {
    if (this.chartData) {
      this.getHoldingsDataModernized();
    }
  }

  toggleHoldingsView() {
    this.showHoldingsTable = !this.showHoldingsTable;
  }
  handleClosableClick = (event) => {
    const pressedState = event.currentTarget.getAttribute('aria-pressed') === 'true';
    event.currentTarget.setAttribute('aria-pressed', pressedState);
    const visibilityState = pressedState ? '' : 'c11n-banner--closed';
    event.currentTarget.parentElement.parentElement.classList.add(`${visibilityState}`);
  };

  getHoldingsForTableSection(sectionTitle, dataNode, categorization) {
    const holdingsForDomestic = this.holdingsViewHelper.getArrayOfHoldingsForCategorization(
      this.chartData,
      dataNode,
      categorization,
    );
    const acctsForDomestic =
      this.holdingsViewHelper.getArrayOfAccountsForCategorization(holdingsForDomestic);

    let arrayOfAccounts = [];
    let categorizationSubtotal = 0;
    let categorizationPercentageSubTotal = 0;

    acctsForDomestic.forEach((accountName) => {
      arrayOfAccounts.push(this.holdingsViewHelper.getAccountNameObject(accountName));

      let holdingsArrayForAccount = this.holdingsViewHelper.getHoldingsFromSpecificAccount(
        holdingsForDomestic,
        accountName,
      );

      holdingsArrayForAccount.forEach((holding) => {
        const acctObject =
          this.holdingsViewHelper.getHoldingInformationWithoutDifferenceByPercentage(
            holding,
            categorization,
          );
        categorizationSubtotal += acctObject.value;
        categorizationPercentageSubTotal += acctObject.percentage;
        arrayOfAccounts.push(acctObject);
      });
    });

    if (arrayOfAccounts.length > 0) {
      arrayOfAccounts.push(
        this.holdingsViewHelper.getSubtotalObjectWithoutDifference(
          categorizationSubtotal,
          categorizationPercentageSubTotal,
        ),
      );
      return {
        header: sectionTitle,
        accounts: arrayOfAccounts,
      };
    }
    return null;
  }

  createCategoryArray(arr: any) {
    let newArr = [];
    arr = arr.filter(function (e) {
      return e;
    });
    let total = 0;
    for (let i: number = 0; i < arr.length; i++) {
      for (let j: number = 0; j < arr[i].accounts.length; j++) {
        if (arr[i].accounts[j].name === 'Subtotal') {
          let roundedVal = Number(
            Number(+(Math.round(Number(arr[i].accounts[j].percentage + 'e+2')) + 'e-2')).toFixed(2),
          );
          total += roundedVal;
          newArr.push(roundedVal);
        }
      }
    }
    const difference = 100 - total;
    if (difference >= 0.01 || difference <= -0.01) {
      newArr[0] += difference;
    }
    return newArr;
  }

  getHoldingsForTableSectionModernized(
    sectionTitle,
    dataNode,
    categorization,
  ): HoldingsTableCategorization {
    const arrayOfAllHoldingsForCategorization =
      this.holdingsViewHelper.getArrayOfHoldingsForSubtotalCategorization(
        this.chartData,
        dataNode,
        categorization,
      );
    const acctsForDomestic =
      this.holdingsTableBuilderService.getArrayOfAccountNamesForCategorization(
        arrayOfAllHoldingsForCategorization,
      );
    let arrayOfAccounts: HoldingsTableAccountWithHoldings[] = [];

    acctsForDomestic.forEach((accountName) => {
      const acctWithHoldings: HoldingsTableAccountWithHoldings = {
        accountName: accountName,
        subtotal: 0,
        percentageOfPortfolio: 0,
        holdings: [],
      };

      let holdingsArrayForAccount = this.holdingsViewHelper.getHoldingsFromSpecificAccount(
        arrayOfAllHoldingsForCategorization,
        accountName,
      );

      holdingsArrayForAccount.forEach((holding) => {
        const holdingObject =
          this.holdingsTableBuilderService.getHoldingInformationWithoutDifferenceByPercentageNew(
            holding,
            categorization,
          );
        acctWithHoldings.subtotal += this.holdingsTableBuilderService.getHoldingValue(holding, categorization);
        acctWithHoldings.percentageOfPortfolio += this.holdingsTableBuilderService.getHoldingPercentage(holding, categorization);
        acctWithHoldings.holdings.push(holdingObject);
      });

      arrayOfAccounts.push(acctWithHoldings);
    });

    return {
      header: sectionTitle,
      categorization: categorization,
      accounts: arrayOfAccounts,
    };
  }

  getHoldingsDataModernized() {
    const domesticStocks = this.getHoldingsForTableSectionModernized(
      'U.S. stocks & stock funds',
      AssetTableCategorization.AssetAllocation,
      HoldingSubtotalCategorization.USStocks,
    );
    const internationalStocks = this.getHoldingsForTableSectionModernized(
      'International stocks & stock funds',
      AssetTableCategorization.AssetAllocation,
      HoldingSubtotalCategorization.InternationalStocks,
    );
    const stockOthers = this.getHoldingsForTableSectionModernized(
      'Other stocks & stock funds',
      AssetTableCategorization.AssetAllocation,
      HoldingSubtotalCategorization.OtherStocks,
    );
    const domesticBonds = this.getHoldingsForTableSectionModernized(
      'U.S. bonds & bond funds',
      AssetTableCategorization.AssetAllocation,
      HoldingSubtotalCategorization.USBonds,
    );
    const internationalBonds = this.getHoldingsForTableSectionModernized(
      'International bonds & bond funds',
      AssetTableCategorization.AssetAllocation,
      HoldingSubtotalCategorization.InternationalBonds,
    );
    const bondOthers = this.getHoldingsForTableSectionModernized(
      'Other bond funds',
      AssetTableCategorization.AssetAllocation,
      HoldingSubtotalCategorization.OtherBonds,
    );
    const shortTermReserves = this.getHoldingsForTableSectionModernized(
      'Short term reserves',
      AssetTableCategorization.AssetAllocation,
      HoldingSubtotalCategorization.ShortTermReserves,
    );
    const othersTypes = this.getHoldingsForTableSectionModernized(
      'Other asset types',
      AssetTableCategorization.AssetAllocation,
      HoldingSubtotalCategorization.OtherTypes,
    );

    this.holdingsTableDataModernized = {
      categorizations: [
        domesticStocks,
        internationalStocks,
        stockOthers,
        domesticBonds,
        internationalBonds,
        bondOthers,
        shortTermReserves,
        othersTypes,
      ],
      holdingsTotal: this.chartData.assetTypes.applicableBalance,
    };
  }
}
