<div *ngIf='chartData' class="tab-table modernizedTabs">
    <mat-tab-group animationDuration="500ms" [disableRipple]="true" [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="updateUrl($event)">
        <mat-tab label="Market capitalization" role="link">
            <market-cap-table [chartData]='chartData'></market-cap-table>
        </mat-tab>
        <mat-tab label="Investment style" role="link">
            <investment-style-table [chartData]='chartData'></investment-style-table>
        </mat-tab>
        <mat-tab label="Industry sectors" role="link">
            <industry-sectors-table [chartData]='chartData'></industry-sectors-table>
        </mat-tab>
        <mat-tab label="International regions" role="link">
            <international-regions-table [chartData]='chartData'></international-regions-table>
        </mat-tab>
    </mat-tab-group>
</div>
