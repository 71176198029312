import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class PortfolioCardsService {
  constructor(private router: Router) {}

  // navigate to page to delay
  navigateToPage(routerLink) {
    setTimeout(() => {
      this.router.navigate([routerLink]);
    }, 500);
  }
}
