export const DRAWER_CONSTANTS = {
  CREDIT_QUALITY: 'Credit quality',
  DOMESTIC_VS_INTERNATIONAL_STOCK_HOLDING_DETAILS:
    'Domestic vs. international stock holdings details',
  DOMESTIC_VS_INTERNATIONAL_BOND_HOLDING_DETAILS:
    'Domestic vs. international bond holdings details',
  INTEREST_RATE_SENSITIVITY: 'Interest rate sensitivity',
  BOND_NINE_BOX: 'Bond 9-box',
  DOMESTIC_VS_INTERNATIONAL_BONDS: 'Domestic vs. international bonds',
  DOMESTIC_VS_INTERNATIONAL_STOCKS: 'Domestic vs. international stocks',
  MUTUAL_FUND_COSTS: 'Mutual fund costs',
  TAX_EFFICIENCY: 'Tax efficiency',
  INDUSTRY_SECTORS: 'Industry sectors',
  INTERNATIONAL_REGIONS: 'International regions',
  INVESTEMENT_STYLE: 'Investment style',
  MARKET_CAPITALIZATION: 'Market capitalization',
};
