import { Component, Input, OnInit } from '@angular/core';
import {
  ModernizedStackedBarChartData,
  ModernizedAssetMixColors,
} from '../../models/chart-models/modernized-stacked-bar-chart';
import { PortfolioDataService } from '../../services/portfolio-data-service/portfolio-data.service';
import { AllChartsResponseData } from '../../models/chart-data-response-models/all-charts-response-data';
import { Observable } from 'rxjs';
import { DomainHelperPipe } from '../../pipes/domain-helper/domain-helper.pipe';
import { Router } from '@angular/router';
import { DrawerTrackerService } from '@vanguard/pfx-components';

@Component({
  selector: 'asset-mix-dashboard',
  templateUrl: './asset-mix-dashboard.component.html',
  styleUrls: ['./asset-mix-dashboard.component.scss'],
})
export class AssetMixDashboardComponent implements OnInit {
  @Input() isFullWidth: boolean;
  @Input() actualAssetAllocationChartId: string;
  @Input() targetAssetAllocationChartId: string;
  @Input() isHypotheticalData: boolean;
  @Input() isMFE: boolean;

  actualAssetMixChartData: ModernizedStackedBarChartData;
  targetAssetMixChartData: ModernizedStackedBarChartData | null;
  inlineHelpDrawerEnabled: Observable<boolean>;
  isLoading: boolean = true;
  isHypotheticalPage: boolean = false;

  constructor(
    private portfolioDataService: PortfolioDataService,
    private router: Router,
    private drawerTrackerService: DrawerTrackerService,
  ) {}

  ngOnInit() {
    this.portfolioDataService.isLoading.subscribe((val) => {
      if (val === false && this.isLoading) {
        this.init();
        this.isLoading = false;
      }
    });
    this.isHypotheticalPage =
      this.router.url.includes('hypothetical-overview') ||
      this.router.url.includes('hypothetical-asset-mix');
  }

  private init() {
    let chartDataObservable: Observable<any>;

    if (this.isHypotheticalData) {
      chartDataObservable = this.portfolioDataService.getHypotheticalDataPayload();
    } else {
      chartDataObservable = this.portfolioDataService.getChartDataPayload();
    }

    chartDataObservable.subscribe((response: AllChartsResponseData) => {
      this.actualAssetMixChartData = {
        chartId: this.actualAssetAllocationChartId, // 'actualAssetChartModernizedId',
        chartLabel: 'Current:',
        chartSlices: [
          {
            name: 'stocks',
            label: 'Stocks',
            value: response.assetTypes.chart.stocks,
            color: ModernizedAssetMixColors.getColorMap().get('stocks'),
            nonZeroValue: response.stockDomesticInternational.applicableBalance > 0,
          },
          {
            name: 'bonds',
            label: 'Bonds',
            value: response.assetTypes.chart.bonds,
            color: ModernizedAssetMixColors.getColorMap().get('bonds'),
            nonZeroValue: response.bondDomesticInternational.applicableBalance > 0,
          },
          {
            name: 'shortTermReserves',
            label: 'Short term reserves',
            value: response.assetTypes.chart.shortTermReserves,
            color: ModernizedAssetMixColors.getColorMap().get('shortTermReserves'),
          },
          {
            name: 'others',
            label: 'Others',
            value: response.assetTypes.chart.others ?? 0,
            color: ModernizedAssetMixColors.getColorMap().get('others'),
          },
        ],
        usesMasks: false,
      };

      if (
        !response.assetTypeTarget ||
        (response.assetTypeTarget.stocks === 0 &&
          response.assetTypeTarget.bonds === 0 &&
          response.assetTypeTarget.shortTermReserves === 0 &&
          response.assetTypeTarget.others === 0)
      ) {
        this.targetAssetMixChartData = null;
      } else {
        this.targetAssetMixChartData = {
          chartId: this.targetAssetAllocationChartId, // 'targetAssetChartModernizedId',
          chartLabel: 'Target:',
          chartSlices: [
            {
              name: 'stocks',
              label: 'Stocks',
              value: response.assetTypeTarget.stocks,
              color: ModernizedAssetMixColors.getColorMap().get('stocks'),
            },
            {
              name: 'bonds',
              label: 'Bonds',
              value: response.assetTypeTarget.bonds,
              color: ModernizedAssetMixColors.getColorMap().get('bonds'),
            },
            {
              name: 'shortTermReserves',
              label: 'Short term reserves',
              value: response.assetTypeTarget.shortTermReserves,
              color: ModernizedAssetMixColors.getColorMap().get('shortTermReserves'),
            },
            {
              name: 'others',
              label: 'Others',
              value: response.assetTypeTarget.others ?? 0,
              color: ModernizedAssetMixColors.getColorMap().get('others'),
            },
          ],
          usesMasks: true,
        };
      }

      this.filterAssetMixOtherCategory();
    });
  }

  filterAssetMixOtherCategory() {
    let actualAssetMixChartDataOthers = this.actualAssetMixChartData.chartSlices.find(slices => slices.name === 'others');
    let targetAssetMixChartDataOthers = this.targetAssetMixChartData?.chartSlices.find(slices => slices.name === 'others');
    if (actualAssetMixChartDataOthers.value === 0 && targetAssetMixChartDataOthers.value === 0) {
      this.actualAssetMixChartData.chartSlices = this.actualAssetMixChartData.chartSlices.filter(
        (category) => category.name !== 'others',
      );
    }
  }

  goToTargetAllocationPage() {
    window.location.href = DomainHelperPipe.prototype.transform(
      '/us/AnalyticsPageController?FW_Event=AnalyticsDetermineTarget',
    );
  }

  selectSection(sectionLabel: string) {
    this.drawerTrackerService.setSelectSection(sectionLabel);
  }
}
