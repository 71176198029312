<mat-card #content="pwContent" pwContent>
    <mat-card-header>
        <div class="titleWrapper">
            <mat-card-title>
                <span *ngIf="!isHypotheticalData" class="center-vertically" [innerHTML]="content['PWOverview_AssetAllocation']"></span>
                <span *ngIf="isHypotheticalData" class="center-vertically" [innerHTML]="content['TesterTool_Overview_HypotheticalAssetCard_Title']"></span>
                <span *ngIf="!isMFE">
                    <button class="icon icon-question-mark-black " (click)="selectSection('Asset mix')" aria-label="Help AssetMix"></button>
                </span>
            </mat-card-title>
            <mat-card-actions>
                <button class="navigateLink seeDetails" [ngClass]="{'hide-see-details': (isChartDataFailed | async)}" aria-label='Asset Allocation see details' (click)="goToAssetAllocationPage()">
                    <span [innerHTML]="content['PWOverview_AssetAllocation_SeeDetails']"></span><i class="icon icon-right-arrow-blue-modernized"></i>
                </button>
            </mat-card-actions>
        </div>
        <mat-card-subtitle></mat-card-subtitle>
    </mat-card-header>
    <ng-container *ngIf="(isChartDataFailed | async); else assetMixDashboardContent">
        <div class="asset-mix-error-message">
            <mat-card-content>
                <c11n-banner
                [size]="'medium'"
                [headingText]="content['AssetMixCard_ErrorState_Title']"
                [hasCloseControl]="false"
                [hasDetails]="false"
                [closeAriaLabel]="''"
                [variant]="'error'"
                >
                {{ content['AssetMixCard_ErrorState_Content'] }}
                </c11n-banner>
            </mat-card-content>
        </div>
    </ng-container>
    <ng-template #assetMixDashboardContent>
        <mat-card-content>
            <asset-mix-dashboard [isFullWidth]=false
                                            [isHypotheticalData]="isHypotheticalData"
                                            [actualAssetAllocationChartId]="chartIdForCurrent"
                                            [targetAssetAllocationChartId]="chartIdForTarget"
                                            [isMFE]="false"></asset-mix-dashboard>
        </mat-card-content>
    </ng-template>
</mat-card>
