import { Component, Input, OnInit } from '@angular/core';
import { HoldingsViewHelperService } from '../../../services/holdings-view-helper/holdings-view-helper.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DrawerTrackerService } from '@vanguard/pfx-components';
import { DRAWER_CONSTANTS } from 'src/app/models/drawer.constants';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';
@Component({
  selector: 'mutual-fund-table',
  templateUrl: './mutual-fund-table.component.html',
  styleUrls: ['./mutual-fund-table.component.scss'],
})
export class MutualFundTableComponent implements OnInit {
  @Input() chartData;

  tableData = [];
  loaded = false;
  inlineHelpDrawerEnabled: Observable<boolean>;

  constructor(
    private holdingsViewHelper: HoldingsViewHelperService,
    private router: Router,
    private drawerTrackerService: DrawerTrackerService,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {}

  ngOnInit() {
    if (this.chartData) {
      this.setTableData();
    }
  }

  setTableData() {
    this.tableData = this.generateTableDataModernized();
    this.loaded = true;
  }

  // TODO: Adding this function here so the export to csv component doesn't break.
  generateTableData() {
    const holdings = this.holdingsViewHelper.getArrayOfHoldingsForCategorization(
      this.chartData,
      'investmentCost',
      'expenseRatio',
    );
    const accounts = this.holdingsViewHelper.getArrayOfAccountsForCategorization(holdings);
    let arrayOfAccounts = [];

    accounts.forEach((accountName) => {
      arrayOfAccounts.push(this.holdingsViewHelper.getAccountNameObject(accountName));

      let holdingsArrayForAccount = this.holdingsViewHelper.getHoldingsFromSpecificAccount(
        holdings,
        accountName,
      );

      holdingsArrayForAccount.forEach((holding) => {
        arrayOfAccounts.push({
          name: holding.fundName,
          value: holding.categoryBalance,
          expenseRatio: holding.categorization.expenseRatio,
        });
      });
    });

    return arrayOfAccounts;
  }

  generateTableDataModernized() {
    const holdings = this.holdingsViewHelper.getArrayOfHoldingsForCategorization(
      this.chartData,
      'investmentCost',
      'expenseRatio',
    );
    const accounts = this.holdingsViewHelper.getArrayOfAccountsForCategorization(holdings);
    let arrayOfAccounts = [];

    accounts.forEach((accountName) => {
      let holdingsArrayForAccount = this.holdingsViewHelper.getHoldingsFromSpecificAccount(
        holdings,
        accountName,
      );

      arrayOfAccounts.push({
        accountName: accountName,
        holdings: holdingsArrayForAccount,
      });
    });

    return arrayOfAccounts;
  }

  navigateToLearnMorePage() {
    this.adobeAnalyticsService.clickedOnLearnMore('/learn-more/mutual-fund-costs');
    this.router.navigate(['/learn-more/mutual-fund-costs']);
  }
  openMutualFundDrawer() {
    this.drawerTrackerService.setSelectSection(DRAWER_CONSTANTS.MUTUAL_FUND_COSTS);
  }
}
