import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'PW1-questionnaire-page',
  templateUrl: './questionnaire-page.component.html',
  styleUrls: ['./questionnaire-page.component.scss'],
})
export class QuestionnairePageComponent implements OnInit, AfterViewInit {
  @Input() numAnswers: number;
  @Input() questionNumber: number;

  @Output() next: EventEmitter<any> = new EventEmitter();
  @Output() previous: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() submit: EventEmitter<any> = new EventEmitter();

  answersAndValues;
  question: string;
  additionalContent: string;

  constructor() {
    return;
  }

  ngOnInit(): void {
    //answerAndValues contains the content that corresponds to the label of the radio button and the value it has.
    //numAnswers is the number of answers a questions have. Some have 5, some have , etc
    //Method uses content to create questionnaire page.
    this.question = 'PWQuestionnaire_Question_' + this.questionNumber;
    this.additionalContent = this.question + '_AdditionalContent';

    this.answersAndValues = new Array<AnswerValue>(this.numAnswers).fill({
      answer: null,
      value: null,
    });
    for (let i = 0; i < this.answersAndValues.length; i++) {
      this.answersAndValues[i] = {
        answer: this.question + '_Answer_' + (i + 1),
        value: this.question + '_Value_' + (i + 1),
      };
    }
  }

  ngAfterViewInit() {
    let width = (this.questionNumber / 11) * 100;
    document.getElementById('current-progress-' + this.questionNumber).style.width = width + '%';
  }

  emit(i: number) {
    if (i === 0) this.previous.emit(null);
    if (i === 1) this.next.emit(null);
    if (i === 2) this.submit.emit(null);
  }
}
export class AnswerValue {
  answer: string;
  value: string;
}
