import { Component, OnInit, ViewChild, Input, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { PortfolioDataService } from '../../services/portfolio-data-service/portfolio-data.service';
import { ModalDialogComponent } from '@vg-constellation/angular-13/modal-dialog';
import { LoggerService } from '../../services/logger/logger.service';
import { targetAssetMixRoutes } from '../../models/chart-models/target-allocation';
import { LoggerCode } from '../../../models/logger';
import { AccountInfo } from 'src/app/models/chart-data-response-models/account-group-details';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';

@Component({
  selector: 'set-target-asset-mix-modal',
  templateUrl: './set-target-asset-mix-modal.component.html',
  styleUrls: ['./set-target-asset-mix-modal.component.scss'],
})
export class SetTargetAssetMixModalComponent implements OnInit, AfterViewInit {
  @Input() oldContent: boolean = false;
  accountGroup: AccountInfo;
  targetAssetMixRoute: string = '/';

  @ViewChild('targetAssetMixModal', { static: true })
  targetAssetMixModal: ModalDialogComponent;

  constructor(
    private router: Router,
    private portfolioDataService: PortfolioDataService,
    private loggerService: LoggerService,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {}

  ngOnInit() {
    this.portfolioDataService.getSelectedAccountGroupPayload().subscribe((data) => {
      this.accountGroup = data;
    });
  }

  ngAfterViewInit() {
    let showTargetModal = this.portfolioDataService.getShowTargetModal();
    let emptyEvent = new Event('');

    if (showTargetModal) {
      this.targetAssetMixModal.openModalDialog(emptyEvent);
      this.portfolioDataService.setShowTargetModal(false);
    }
  }

  navigateToTargetAssetMixPage() {
    this.adobeAnalyticsService.clickedOnTargetMixModalContinueButton(this.targetAssetMixRoute);
    this.router.navigate([this.targetAssetMixRoute]);
    this.portfolioDataService.setTargetMixSuccess(false);
    if (this.targetAssetMixRoute == targetAssetMixRoutes.selectionTool) {
      this.loggerService.info(
        {
          message: 'Selected Target Mix Custom Tool',
        },
        LoggerCode.SELECTED_CUSTOM_TOOL_TARGET_MIX,
      );
    } else if (this.targetAssetMixRoute == targetAssetMixRoutes.questionnaire) {
      this.loggerService.info(
        {
          message: 'selected target mix questionnaire tool',
        },
        LoggerCode.SELECTED_QUESTIONNAIRE_TARGET_MIX,
      );
    }
  }

  chooseTargetAssetMixRoute(choice: string) {
    if (choice == 'chooseAssetMix') {
      this.targetAssetMixRoute = '/target-mix-custom-view';
    } else if (choice == 'suggestAssetMix') {
      this.targetAssetMixRoute = '/target-mix-questionnaire';
    } else {
      this.targetAssetMixRoute = '/';
    }
  }

  navigateToAboutPage() {
    this.adobeAnalyticsService.clickedOnTargetMixAboutPageLink();
    this.targetAssetMixModal.closeDialogModal();
    this.router.navigate(['/target-mix-about']);
  }

  openDialog($event: any) {    
    this.adobeAnalyticsService.ClickedonSetTargetAssetMixLink();
    this.targetAssetMixModal.openModalDialog($event);
  }
}
