import { Component, OnInit, Input } from '@angular/core';
import { INDEX_CONSTANTS } from '../../../../../models/chart.constants';
import { HoldingsViewHelperService } from '../../../../../services/holdings-view-helper/holdings-view-helper.service';

@Component({
  selector: 'PW1-hypothetical-effective-maturity-table',
  templateUrl: './hypothetical-effective-maturity-table.component.html',
  styleUrls: ['./hypothetical-effective-maturity-table.component.scss'],
})
export class HypotheticalEffectiveMaturityTableComponent implements OnInit {
  @Input() chartData;
  @Input() hypotheticalData;

  total: number = 0;
  totalHypothetical: number = 0;
  taxableTotal;
  taxableTotalHypothetical;
  municipalTotal;
  municipalTotalHypothetical;

  isUncategorizedEnabled = false;
  isUncategorizedEnabledHypothetical = false;

  tableData = [];
  tableDataHypothetical = [];
  totalData = [];
  totalDataHypothetical = [];
  holdingsTotal: number = 0;
  holdingsTotalHypothetical: number = 0;
  holdingsTablesData: any[];

  loaded = false;
  loadedHypothetical = false;

  viewByHoldings = false;
  private totalHigh = INDEX_CONSTANTS.ZERO;
  private totalMedium = INDEX_CONSTANTS.ZERO;
  private totalLow = INDEX_CONSTANTS.ZERO;
  private totalHighHypothetical = INDEX_CONSTANTS.ZERO;
  private totalMediumHypothetical = INDEX_CONSTANTS.ZERO;
  private totalLowHypothetical = INDEX_CONSTANTS.ZERO;

  private taxableHigh = INDEX_CONSTANTS.ZERO;
  private taxableMedium = INDEX_CONSTANTS.ZERO;
  private taxableLow = INDEX_CONSTANTS.ZERO;
  private taxableHighHypothetical = INDEX_CONSTANTS.ZERO;
  private taxableMediumHypothetical = INDEX_CONSTANTS.ZERO;
  private taxableLowHypothetical = INDEX_CONSTANTS.ZERO;

  private municipalHigh = INDEX_CONSTANTS.ZERO;
  private municipalMedium = INDEX_CONSTANTS.ZERO;
  private municipalLow = INDEX_CONSTANTS.ZERO;
  private municipalHighHypothetical = INDEX_CONSTANTS.ZERO;
  private municipalMediumHypothetical = INDEX_CONSTANTS.ZERO;
  private municipalLowHypothetical = INDEX_CONSTANTS.ZERO;

  private taxableUncategorized = INDEX_CONSTANTS.ZERO;
  private municipalUncategorized = INDEX_CONSTANTS.ZERO;
  private totalUncategorized = INDEX_CONSTANTS.ZERO;
  private taxableUncategorizedHypothetical = INDEX_CONSTANTS.ZERO;
  private municipalUncategorizedHypothetical = INDEX_CONSTANTS.ZERO;
  private totalUncategorizedHypothetical = INDEX_CONSTANTS.ZERO;

  constructor(private holdingsViewHelper: HoldingsViewHelperService) {}

  ngOnInit() {
    if (this.chartData && this.hypotheticalData) {
      this.setChartData();
      this.setChartDataHypothetical();
      this.setTableData();
      this.setTableDataHypothetical();
      this.setBothHoldingsTables();
    }
  }

  setChartData() {
    this.taxableHigh = this.chartData.bondMaturity.chart.taxableHigh;
    this.municipalHigh = this.chartData.bondMaturity.chart.municipalHigh;
    this.totalHigh = this.taxableHigh + this.municipalHigh;

    this.taxableMedium = this.chartData.bondMaturity.chart.taxableMedium;
    this.municipalMedium = this.chartData.bondMaturity.chart.municipalMedium;
    this.totalMedium = this.taxableMedium + this.municipalMedium;

    this.municipalLow = this.chartData.bondMaturity.chart.municipalLow;
    this.taxableLow = this.chartData.bondMaturity.chart.taxableLow;
    this.totalLow = this.taxableLow + this.municipalLow;

    this.taxableUncategorized = this.chartData.bondMaturity.chart.taxableUncategorized;
    this.municipalUncategorized = this.chartData.bondMaturity.chart.municipalUncategorized;
    this.totalUncategorized = this.taxableUncategorized + this.municipalUncategorized;
    this.setUncategorizedData();
  }

  setChartDataHypothetical() {
    this.taxableHighHypothetical = this.hypotheticalData.bondMaturity.chart.taxableHigh;
    this.municipalHighHypothetical = this.hypotheticalData.bondMaturity.chart.municipalHigh;
    this.totalHighHypothetical = this.taxableHighHypothetical + this.municipalHighHypothetical;

    this.taxableMediumHypothetical = this.hypotheticalData.bondMaturity.chart.taxableMedium;
    this.municipalMediumHypothetical = this.hypotheticalData.bondMaturity.chart.municipalMedium;
    this.totalMediumHypothetical =
      this.taxableMediumHypothetical + this.municipalMediumHypothetical;

    this.municipalLowHypothetical = this.hypotheticalData.bondMaturity.chart.municipalLow;
    this.taxableLowHypothetical = this.hypotheticalData.bondMaturity.chart.taxableLow;
    this.totalLowHypothetical = this.taxableLowHypothetical + this.municipalLowHypothetical;

    this.taxableUncategorizedHypothetical =
      this.hypotheticalData.bondMaturity.chart.taxableUncategorized;
    this.municipalUncategorizedHypothetical =
      this.hypotheticalData.bondMaturity.chart.municipalUncategorized;
    this.totalUncategorizedHypothetical =
      this.taxableUncategorizedHypothetical + this.municipalUncategorizedHypothetical;
    this.setUncategorizedDataHypothetical();
  }

  setUncategorizedData() {
    this.taxableTotal =
      this.taxableHigh + this.taxableMedium + this.taxableLow + this.taxableUncategorized;
    this.municipalTotal =
      this.municipalHigh + this.municipalMedium + this.municipalLow + this.municipalUncategorized;
    this.total = this.totalHigh + this.totalMedium + this.totalLow + this.totalUncategorized;
    this.isUncategorizedEnabled = this.taxableUncategorized > 0 || this.municipalUncategorized > 0;
  }

  setUncategorizedDataHypothetical() {
    this.taxableTotalHypothetical =
      this.taxableHighHypothetical +
      this.taxableMediumHypothetical +
      this.taxableLowHypothetical +
      this.taxableUncategorizedHypothetical;
    this.municipalTotalHypothetical =
      this.municipalHighHypothetical +
      this.municipalMediumHypothetical +
      this.municipalLowHypothetical +
      this.municipalUncategorizedHypothetical;
    this.totalHypothetical =
      this.totalHighHypothetical +
      this.totalMediumHypothetical +
      this.totalLowHypothetical +
      this.totalUncategorizedHypothetical;
    this.isUncategorizedEnabledHypothetical =
      this.taxableUncategorizedHypothetical > 0 || this.municipalUncategorizedHypothetical > 0;
  }

  setTableData() {
    this.tableData = [
      {
        quality: 'High',
        yourTaxableBonds: this.taxableHigh,
        yourMunicipal: this.municipalHigh,
        yourTotalBonds: this.totalHigh,
      },
      {
        quality: 'Medium',
        yourTaxableBonds: this.taxableMedium,
        yourMunicipal: this.municipalMedium,
        yourTotalBonds: this.totalMedium,
      },
      {
        quality: 'Low',
        yourTaxableBonds: this.taxableLow,
        yourMunicipal: this.municipalLow,
        yourTotalBonds: this.totalLow,
      },
    ];

    if (this.isUncategorizedEnabled) {
      this.tableData.push({
        quality: 'Uncategorized',
        yourTaxableBonds: this.taxableUncategorized,
        yourMunicipal: this.municipalUncategorized,
        yourTotalBonds: this.totalUncategorized,
      });
    }

    this.totalData = [
      {
        quality: 'Total',
        yourTaxableBonds: this.taxableTotal,
        yourMunicipal: this.municipalTotal,
        yourTotalBonds: this.total.toFixed(0),
      },
    ];

    this.loaded = true;
  }

  setTableDataHypothetical() {
    this.tableDataHypothetical = [
      {
        quality: 'High',
        yourTaxableBonds: this.taxableHighHypothetical,
        yourMunicipal: this.municipalHighHypothetical,
        yourTotalBonds: this.totalHighHypothetical,
      },
      {
        quality: 'Medium',
        yourTaxableBonds: this.taxableMediumHypothetical,
        yourMunicipal: this.municipalMediumHypothetical,
        yourTotalBonds: this.totalMediumHypothetical,
      },
      {
        quality: 'Low',
        yourTaxableBonds: this.taxableLowHypothetical,
        yourMunicipal: this.municipalLowHypothetical,
        yourTotalBonds: this.totalLowHypothetical,
      },
    ];

    if (this.isUncategorizedEnabledHypothetical) {
      this.tableDataHypothetical.push({
        quality: 'Uncategorized',
        yourTaxableBonds: this.taxableUncategorizedHypothetical,
        yourMunicipal: this.municipalUncategorizedHypothetical,
        yourTotalBonds: this.totalUncategorizedHypothetical,
      });
    }

    this.totalDataHypothetical = [
      {
        quality: 'Total',
        yourTaxableBonds: this.taxableTotalHypothetical,
        yourMunicipal: this.municipalTotalHypothetical,
        yourTotalBonds: this.totalHypothetical.toFixed(0),
      },
    ];

    this.loadedHypothetical = true;
  }

  generateTableDataByHoldings() {
    const categorizationsObjects: any[] = [
      {
        title: 'Current high sensitivity bonds & bond funds',
        categorizations: ['taxableHigh', 'municipalHigh'],
      },
      {
        title: 'Current medium sensitivity bonds & bond funds',
        categorizations: ['taxableMedium', 'municipalMedium'],
      },
      {
        title: 'Current low sensitivity bonds & bond funds',
        categorizations: ['taxableLow', 'municipalLow'],
      },
      {
        title: 'Current uncategorized sensitivity bond funds',
        categorizations: ['taxableUncategorized', 'municipalUncategorized'],
      },
    ];

    const holdingsArray: any[] =
      this.holdingsViewHelper.getGeneratedDataWithoutDifferenceForMultipleCategorizationsForTesterTool(
        categorizationsObjects,
        this.chartData,
        'bondMaturity',
      );
    this.holdingsTotal = this.holdingsViewHelper.calculateTotal(holdingsArray);
    return holdingsArray;
  }

  generateTableDataByHoldingsHypothetical() {
    const categorizationsObjects: any[] = [
      {
        title: 'Hypothetical high sensitivity bonds & bond funds',
        categorizations: ['taxableHigh', 'municipalHigh'],
      },
      {
        title: 'Hypothetical medium sensitivity bonds & bond funds',
        categorizations: ['taxableMedium', 'municipalMedium'],
      },
      {
        title: 'Hypothetical low sensitivity bonds & bond funds',
        categorizations: ['taxableLow', 'municipalLow'],
      },
      {
        title: 'Hypothetical uncategorized sensitivity bond funds',
        categorizations: ['taxableUncategorized', 'municipalUncategorized'],
      },
    ];

    const holdingsArray: any[] =
      this.holdingsViewHelper.getGeneratedDataWithoutDifferenceForMultipleCategorizationsForTesterTool(
        categorizationsObjects,
        this.hypotheticalData,
        'bondMaturity',
      );
    this.holdingsTotalHypothetical = this.holdingsViewHelper.calculateTotal(holdingsArray);
    return holdingsArray;
  }

  changeTableView(val: boolean) {
    this.viewByHoldings = val;
  }

  setBothHoldingsTables() {
    const tableDataByHoldingsActual = this.generateTableDataByHoldings();
    const tableDataByHoldingsHypothetical = this.generateTableDataByHoldingsHypothetical();

    const unfiltered = [
      {
        actual: this.getTable(tableDataByHoldingsActual, 'bondMaturity', [
          'taxableHigh',
          'municipalHigh',
        ]),
        hypothetical: this.getTable(tableDataByHoldingsHypothetical, 'bondMaturity', [
          'taxableHigh',
          'municipalHigh',
        ]),
      },
      {
        actual: this.getTable(tableDataByHoldingsActual, 'bondMaturity', [
          'taxableMedium',
          'municipalMedium',
        ]),
        hypothetical: this.getTable(tableDataByHoldingsHypothetical, 'bondMaturity', [
          'taxableMedium',
          'municipalMedium',
        ]),
      },
      {
        actual: this.getTable(tableDataByHoldingsActual, 'bondMaturity', [
          'taxableLow',
          'municipalLow',
        ]),
        hypothetical: this.getTable(tableDataByHoldingsHypothetical, 'bondMaturity', [
          'taxableLow',
          'municipalLow',
        ]),
      },
      {
        actual: this.getTable(tableDataByHoldingsActual, 'bondMaturity', [
          'taxableUncategorized',
          'municipalUncategorized',
        ]),
        hypothetical: this.getTable(tableDataByHoldingsHypothetical, 'bondMaturity', [
          'taxableUncategorized',
          'municipalUncategorized',
        ]),
      },
    ];

    this.holdingsTablesData = unfiltered.filter((category) => {
      return category.actual.accounts.length > 0 || category.hypothetical.accounts.length > 0;
    });
  }

  getTable(dataSet, dataNode, categorizations: string[]) {
    const filteredTableData = dataSet.filter((tableData) => {
      if (tableData && tableData.dataNode === dataNode) {
        return categorizations.join(',') === tableData.categorizations.join(',');
      }
      return false;
    });

    return filteredTableData[0];
  }
}
