import { Component, OnInit } from '@angular/core';
import { ChartData } from '../../../models/chart-models/chart-data';
import { LegendLine } from '../../../models/chart-models/legend-line';
import { PortfolioCardsService } from '../../../services/portfolio-cards/portfolio-cards.service';
import { ChartDataService } from '../../../services/chart-data-service/chart-data.service';
import { HypotheticalChartDataService } from '../../../services/hypothetical-chart-data-service/hypothetical-chart-data.service';
import { Observable } from 'rxjs';
import { DrawerTrackerService } from '@vanguard/pfx-components';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';
const ZERO_INDEX = 0;

@Component({
  selector: 'hypothetical-bond-analysis',
  templateUrl: './hypothetical-bond-analysis.component.html',
  styleUrls: ['./hypothetical-bond-analysis.component.scss'],
})
export class HypotheticalBondAnalysisComponent implements OnInit {
  chartData: ChartData;
  hypotheticalChartData: ChartData;

  legendArray: Array<LegendLine>;
  hypotheticalLegendArray: Array<LegendLine>;

  legendHeader: string;
  hypotheticalLegendHeader: string;

  hasLoadedChartData = false;
  hasLoadedHypotheticalChartData = false;

  bondChartId = 'bondChartId';
  hypotheticalBondChartId = 'hypotheticalBondChartId';

  legendArrayModern: Array<LegendLine>;
  hypotheticalLegendArrayModern: Array<LegendLine>;

  modernizedEnabled: Observable<boolean>;

  constructor(
    private cardsService: PortfolioCardsService,
    private chartService: ChartDataService,
    private hypotheticalChartDataService: HypotheticalChartDataService,
    private drawerTrackerService: DrawerTrackerService,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {}

  ngOnInit() {
    this.setupCurrentBondAnalysisChart();
    this.setupHypotheticalBondAnalysisChart();
  }

  setupCurrentBondAnalysisChart() {
    this.legendHeader = "Bonds (Domestic vs. Int'l)";
    this.chartService.getBondAnalysisChartPayload().subscribe((data) => {
      if (data.percentageDetails.length !== ZERO_INDEX) {
        this.chartData = data;
        this.legendArray = this.chartService.getBondAnalysisCardLegend();
        this.legendArrayModern = this.chartService.getBondAnalysisCardLegendModern();
        this.hasLoadedChartData = true;
      }
    });
  }

  setupHypotheticalBondAnalysisChart() {
    this.hypotheticalLegendHeader = "Hypothetical bonds (domestic vs. int'l)";
    this.hypotheticalChartDataService.getBondAnalysisChartPayload().subscribe((data) => {
      if (data.percentageDetails.length !== ZERO_INDEX) {
        this.hypotheticalChartData = data;
        this.hypotheticalLegendArray =
          this.hypotheticalChartDataService.getBondAnalysisCardLegend();
        this.hypotheticalLegendArrayModern =
          this.hypotheticalChartDataService.getBondAnalysisCardLegendModern();
        this.hasLoadedHypotheticalChartData = true;
      }
    });
  }

  goBackToOverview() {
    this.adobeAnalyticsService.clickedBackToOverview(true);
    this.cardsService.navigateToPage('hypothetical-overview');
  }

  selectSection(sectionLabel: string) {
    this.drawerTrackerService.setSelectSection(sectionLabel);
  }
}
