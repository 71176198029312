import { Component, Input } from '@angular/core';

@Component({
  selector: 'PW1-accordion',
  templateUrl: './custom-accordion.component.html',
  styleUrls: ['./custom-accordion.component.scss'],
})
export class CustomAccordionComponent {
  opened: boolean = false;
  @Input() title?: string;
  @Input() accountBalance?: number;

  toggleState() {
    this.opened = !this.opened;
  }
}
