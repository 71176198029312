import { Component, Input } from '@angular/core';
import { LegendLine } from '../../models/chart-models/legend-line';

@Component({
  selector: 'PW1-chart-legend-line',
  templateUrl: './chart-legend-line.component.html',
  styleUrls: ['./chart-legend-line.component.scss'],
})
export class ChartLegendLineComponent {
  @Input()
  legendLine: LegendLine;
}
